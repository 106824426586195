import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { BodyText, IconBadge, PressableOpacity, TypeAhead, XIcon } from '../../atoms'
import { TypeAheadCustomOptionsConfig } from '../../atoms/formElements/typeAhead/TypeAheadCustomOptionsConfig'
import { Corners } from '../../atoms/iconBadge/IconBadge'

const Container = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  zIndex: 'unset',
  height: '69px',
  padding: theme.spacing['12px'],
  gap: theme.spacing['8px'],
  borderBottomColor: theme.colors.borderDefault,
  borderBottomWidth: '1px',
}))

const SelectedItemText = styled(BodyText)(({ theme }) => ({
  padding: theme.spacing['4px'],
}))

export type ProviderComposeTypeaheadProps = {
  clients: { id: string; name: string }[]
  onClientSelected: (clientId: string | null) => void
}

export const ProviderComposeTypeahead: FunctionComponent<ProviderComposeTypeaheadProps> = ({
  clients,
  onClientSelected,
}) => {
  const [value, setValue] = useState<string[]>([])
  const [focused, setFocused] = useState(true)
  const onFocus = () => setFocused(true)
  const onBlur = () => setFocused(false)
  const inputElement = useRef<{ focus: () => void } | null>(null)
  const onChange = (text: string[]) => {
    setValue(text)
  }
  const { colors } = useTheme()

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus()
    }
  }, [inputElement])

  useEffect(() => {
    if (value.length === 0) {
      inputElement.current?.focus()
    }
  }, [value.length])
  useEffect(() => {
    onClientSelected(value.length > 0 ? value[0] : null)
  }, [onClientSelected, value])

  const selectedClientName = useMemo(() => {
    if (value.length === 0) {
      return null
    }
    return clients.find((client) => client.id === value[0])?.name ?? null
  }, [clients, value])
  return (
    <Container>
      <BodyText text={<FormattedMessage defaultMessage='To:' description='Label for composing messsage' />} />
      {selectedClientName ? (
        <IconBadge
          text={<SelectedItemText color={colors.textActive} text={selectedClientName} />}
          color={colors.backgroundHighlightPrimary}
          cornerType={Corners.CURVED}
          backIcon={
            <PressableOpacity onPress={() => setValue([])}>
              <XIcon size={16} fillColor={colors.iconDefault} />
            </PressableOpacity>
          }
        />
      ) : (
        <TypeAhead
          onChange={onChange}
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
          isFocused={focused}
          showSearchIcon={false}
          baseInputStyle={{ marginBottom: 0 }}
          name='compose'
          setFocusRef={(ref) => {
            inputElement.current = ref
          }}
          inputContainerStyle={{ borderWidth: 0 }}
          fixedDropdownMenuPosition
          showNoResultsDialog={false}
          customOptionsConfig={new TypeAheadCustomOptionsConfig(clients, undefined, undefined, 'name', 'id')}
        />
      )}
    </Container>
  )
}
