import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Client, Message } from '@twilio/conversations'
import { get } from 'lodash-es'

import { getAuthConfig, getAuthUserId } from '../../../data/auth/authSelectors'
import { updateEndpointCache } from '../../../data/rtkQuery'
import { useAppDispatch } from '../../../data/store'
import messagesApi, { useGetAccessTokenQuery } from '../../../lyraTherapy/messages/data/messagesApi'
import { getConversationsClient } from '../../../lyraTherapy/messages/data/messagesSelectors'
import { setConversationsClient } from '../../../lyraTherapy/messages/data/messagesSlice'
import { TwilioConversations } from '../../twilio-conversations'

/**
 * Hook to use Twilio messaging.
 * Creates a Twilio conversations client
 */
export default () => {
  const config = useSelector(getAuthConfig)
  const { data: accessToken } = useGetAccessTokenQuery(undefined, { skip: !config?.bcPlatform })
  const dispatch = useAppDispatch()
  const userId = useSelector(getAuthUserId)
  const conversationsClient = useSelector(getConversationsClient)

  useEffect(() => {
    if (accessToken && !conversationsClient && config?.bcPlatform) {
      const twilioClient = new TwilioConversations(accessToken)
      dispatch(setConversationsClient(twilioClient as Client))
    }
  }, [accessToken, config?.bcPlatform, conversationsClient, dispatch])

  useEffect(() => {
    const incrementCount = (message: Message) => {
      dispatch(
        updateEndpointCache(messagesApi, 'getConversations')
          .where({ providerId: userId })
          .items({ conversation_id: message.conversation.sid })
          .build((conversation) => {
            const attributes = conversation.conversation_attributes
            if (get(message, 'state.attributes.from') !== userId) {
              const currentUnreadCount = attributes.unread_provider_messages_count ?? 0
              attributes.unread_provider_messages_count = currentUnreadCount + 1
            }
            conversation.conversation_last_message_sent_datetime = new Date().toISOString()
          }),
      )
    }

    if (conversationsClient) {
      conversationsClient.on('messageAdded', incrementCount)
    }
    return () => {
      if (conversationsClient) {
        conversationsClient.off('messageAdded', incrementCount)
      }
    }
  }, [conversationsClient, dispatch, userId])
}
