import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { GestureResponderEvent } from 'react-native'

import { get, isEmpty, noop, uniqueId } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { Assignment, AssignmentGroup, Content, EpisodeContents, Provider } from '@lyrahealth-inc/shared-app-logic'

import { AssignmentsListItem } from './AssignmentsListItem'
import { AssignmentsNone } from './AssignmentsNone'
import { CheckMarkIcon, GenericListItem, MinusIcon, PressableOpacity, Subhead, TextButton } from '../../atoms'
import { SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

export interface AssignmentsListProps {
  userRole: string
  addActivityHandler?: () => void | null
  providers: Provider[]
  sessionData?: { sessionCount: number }
  itemActions?: Array<ItemAction>
  assignments: Assignment[]
  futureEpisodes?: (Assignment | EpisodeContents)[]
  noDataText?: string
  itemClickHandler?: (val: any) => void
  title?: string
  assignmentTags?: {
    id: string
  }
  clientFirstName?: string
  combineLinkedAssignments?: boolean
  simplifyStatus?: boolean
  bulkEditMode?: boolean
  onCheckboxPress?: (id: Assignment | EpisodeContents) => void
  bulkActionSelectedItems?: (Assignment | EpisodeContents)[]
  onSelectAllPress?: () => void
  unassignableAssignmentsList?: (Assignment | EpisodeContents)[]
}

export interface ItemAction {
  name: string
  text: string
  selectHandler: (
    e?: GestureResponderEvent,
    name?: string,
    assignment?: Assignment | Content | EpisodeContents,
    sessionCount?: number,
  ) => void
  shouldShow: (assignment?: Assignment | EpisodeContents, sessionCount?: number) => void
}

const AddActivityContainer = styled.View<{
  isMobileSized: boolean
  bulkEditMode: boolean
  theme: ThemeType
}>(({ isMobileSized, bulkEditMode, theme: { colors } }) => ({
  height: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: isMobileSized ? '0px 17px' : '0px 23px',
  borderBottomLeftRadius: '12px',
  borderBottomRightRadius: '12px',
  ...(bulkEditMode && {
    backgroundColor: colors.backgroundSecondary,
  }),
}))

const ItemsListContainer = styled.View<{ theme: ThemeType }>(({ theme: { colors } }) => ({
  margin: '0 0 20px 0',
  padding: 0,
  borderRadius: '12px',
  border: `1px solid ${colors.borderDefault}`,
  backgroundColor: colors.backgroundPrimary,
}))

const SubheadText = styled(Subhead)({
  marginBottom: '8px',
  display: 'block',
})

const DashedCheckboxContainer = styled(PressableOpacity)<{ theme: ThemeType; selected: boolean }>(
  ({ selected, theme: { colors } }) => ({
    backgroundColor: selected ? colors.checkboxBackgroundSelected : colors.checkboxBackgroundPrimary,
    borderColor: selected ? colors.checkboxOutlineSelected : colors.checkboxOutlineDefault,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '21px',
    borderRadius: '4px',
    marginRight: '4px',
    marginTop: '3px',
    borderWidth: '1px',
  }),
)

export const AssignmentsList: React.FC<AssignmentsListProps> = ({
  addActivityHandler,
  assignments,
  futureEpisodes,
  title,
  noDataText,
  providers = [],
  clientFirstName,
  combineLinkedAssignments = false,
  simplifyStatus = false,
  bulkEditMode,
  onCheckboxPress,
  bulkActionSelectedItems = [],
  onSelectAllPress = noop,
  unassignableAssignmentsList = [],
  ...props
}) => {
  const intl = useIntl()
  const { breakpoints, colors } = useTheme()

  const renderList = (
    assignments: Assignment[] | undefined,
    futureEpisodes: (Assignment | EpisodeContents)[] | undefined,
  ) => {
    const { itemClickHandler, userRole, itemActions, sessionData, assignmentTags } = props
    return (
      <>
        {assignments &&
          assignments.map((assignment, idx) => (
            <AssignmentsListItem
              key={uniqueId('assignmentListItem')}
              assignment={assignment}
              assignments={assignments}
              provider={providers.find((provider) => provider.provider.lyra_id === assignment.provider_id)}
              multiProvider={providers.length > 1}
              itemClickHandler={itemClickHandler}
              userRole={userRole}
              itemActions={itemActions}
              sessionCount={get(sessionData, 'sessionCount')}
              assignmentTags={assignmentTags?.[assignment.id]}
              isFirstItem={idx === 0}
              isLastItem={idx === assignments.length - 1 && !addActivityHandler}
              clientFirstName={clientFirstName}
              largeText
              combineLinkedAssignments={combineLinkedAssignments}
              simplifyStatus={simplifyStatus}
              onBulkActionPress={onCheckboxPress}
              bulkActionSelectedItems={bulkActionSelectedItems}
              bulkEditMode={bulkEditMode}
              showBulkCheckbox={(itemActions: ItemAction[], group: string) => {
                return itemActions.some((action) => action.name === 'unassign') && group !== AssignmentGroup.ASSESSMENT
              }}
            />
          ))}
        {futureEpisodes &&
          futureEpisodes.map((episode, idx) => (
            <AssignmentsListItem
              key={uniqueId('assignmentListItem')}
              futureEpisode={episode}
              itemClickHandler={itemClickHandler}
              userRole={userRole}
              itemActions={itemActions}
              sessionCount={get(sessionData, 'sessionCount')}
              isFirstItem={idx === 0}
              isLastItem={idx === futureEpisodes.length - 1 && !addActivityHandler}
              clientFirstName={clientFirstName}
              largeText
              combineLinkedAssignments={combineLinkedAssignments}
              simplifyStatus={simplifyStatus}
              onBulkActionPress={onCheckboxPress}
              bulkActionSelectedItems={bulkActionSelectedItems}
              bulkEditMode={bulkEditMode}
              showBulkCheckbox={() => {
                return true
              }}
            />
          ))}
      </>
    )
  }

  const renderBulkItemSelector = () => {
    const actions = []
    actions.push(
      /*
       ** Dashed Checkbox shows a
       ** - Minus Icon if at least one item is selected but not all
       ** - Checkbox Icon if all items are selected
       ** - Empty box if no items are selected
       */
      <DashedCheckboxContainer
        onPress={() => onSelectAllPress()}
        selected={bulkActionSelectedItems.length > 0}
        testID={tID('AssignmentsList-bulkSelectAll')}
      >
        {bulkActionSelectedItems.length > 0 &&
          unassignableAssignmentsList.length === bulkActionSelectedItems.length && (
            <CheckMarkIcon
              stroke={colors.checkboxCheckSelected}
              fillColor={colors.checkboxCheckSelected}
              width='12'
              height='8'
            />
          )}
        {bulkActionSelectedItems.length > 0 && unassignableAssignmentsList.length > bulkActionSelectedItems.length && (
          <MinusIcon
            stroke={colors.checkboxCheckSelected}
            fillColor={colors.checkboxCheckSelected}
            width={12}
            height={8}
          />
        )}
      </DashedCheckboxContainer>,
    )

    return (
      <GenericListItem
        onClick={() => onSelectAllPress()}
        title={
          <FormattedMessage
            defaultMessage='{count, plural,
              zero {None selected}
              other {{count} selected}
            }'
            description='Count of how many entries user has selected'
            values={{
              count: bulkActionSelectedItems.length,
            }}
          />
        }
        rightItems={actions}
        isFirstItem={true}
        height={'48'}
        containerStyle={{ backgroundColor: colors.backgroundSecondary }}
        textStyle={{ color: colors.textSecondary }}
      />
    )
  }

  let activities, assessments, lessons, exercises, infoSheets
  if (!isEmpty(assignments)) {
    lessons = assignments.filter((a: Assignment) => {
      const group = a.group || get(a, 'content.group')
      return group === AssignmentGroup.LESSON
    })
    assessments = assignments.filter((a: Assignment) => {
      const group = a.group || get(a, 'content.group')
      return group === AssignmentGroup.ASSESSMENT
    })
    exercises = assignments.filter((a: Assignment) => {
      const group = a.group || get(a, 'content.group')
      return group === AssignmentGroup.EXERCISE
    })
    infoSheets = assignments.filter((a: Assignment) => {
      const group = a.group || get(a, 'content.group')
      return group === AssignmentGroup.INFO_SHEET
    })
    // Order activities: lessons > worksheets > infosheets > assessments
    activities = lessons.concat(exercises, infoSheets, assessments)
  }

  return (
    <>
      <SubheadText text={title} size={SubheadSize.MEDIUM} />
      <ItemsListContainer testID={tID('AssignmentList-ItemListContainer')}>
        {isEmpty(activities) && isEmpty(futureEpisodes) ? (
          <AssignmentsNone addActivityHandler={addActivityHandler} noDataText={noDataText} />
        ) : (
          <>
            {bulkEditMode && renderBulkItemSelector()}
            {renderList(activities, futureEpisodes)}
            {addActivityHandler ? (
              <AddActivityContainer isMobileSized={breakpoints.isMobileSized} bulkEditMode={!!bulkEditMode}>
                <TextButton
                  textAlign='left'
                  testID={tID('AssignmentList-addActivityTextButton')}
                  onPress={addActivityHandler}
                  text={intl.formatMessage({
                    defaultMessage: '+ Add Activity',
                    description:
                      'Button to add a new activity. The plus sign at the beginning signifies adding something new.',
                  })}
                  disabled={bulkEditMode}
                  style={{ ...(bulkEditMode && { opacity: 0.5 }) }}
                />
              </AddActivityContainer>
            ) : (
              ''
            )}
          </>
        )}
      </ItemsListContainer>
    </>
  )
}
