import React from 'react'
import { FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { BodyText, BodyTextSize } from '@lyrahealth-inc/ui-core-crossplatform'

const Container = styled.View({
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
})

const MessengerEmptyView: React.FC = () => {
  const theme = useTheme()
  return (
    <Container>
      <BodyText
        size={BodyTextSize.LARGE}
        color={theme.colors.textSecondary}
        text={
          <FormattedMessage
            defaultMessage='No conversation selected'
            description='Text to show when no conversation is selected'
          />
        }
      />
    </Container>
  )
}

export default MessengerEmptyView
