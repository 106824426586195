import { useSelector } from 'react-redux'

import { getAuthConfig, getAuthUserId } from '../../../../data/auth/authSelectors'
import { RTKQueryHook, RTKQueryResultType } from '../../../../data/baseApi'
import { useGetConversationsQuery } from '../messagesApi'
import { getDerivedProperties } from '../utils'

export const useConversationsData: RTKQueryHook<typeof useGetConversationsQuery> = (args, opts) => {
  const userId = useSelector(getAuthUserId)
  const showOverdueMessageFlag = useSelector(getAuthConfig)?.showOverdueMessageFlag
  return useGetConversationsQuery(
    {
      providerId: userId,
      derivedProperties: getDerivedProperties(showOverdueMessageFlag),
      ...args,
    },
    opts,
  ) as RTKQueryResultType<typeof useGetConversationsQuery>
}
