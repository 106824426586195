import { addDays } from 'date-fns'

import { Appointment, AvailabilitiesResponse } from '@lyrahealth-inc/shared-app-logic'

import { getLTAppointmentsForPatient } from './appointmentsSlice'
import { AxiosInstance, baseApi, RTKQueryTag } from '../../../../data/baseApi'

type GetAppointmentsArgs = {
  providerId: string
}

type PutAppointmentArgs = {
  providerId: string
  data: Partial<Appointment>
}

type PostAppointmentArgs = {
  providerId: string
  data: Partial<Appointment>
}

type DeleteAppointmentArgs = {
  providerId: string
  appointmentId: number
  changeReason?: string
}

type GetAppointmentsForPatientArgs = {
  providerId: string
  patientId: string
}

type GetProviderAvailabilityArgs = {
  providerId: string
  daysToFetch: number
  params: {
    slice_duration_minutes: number
    daily_start_time: string
    daily_end_time: string
    clientele: string
    treatment: string
    offering: string
    partner: string
    appointment_class: 'initial' | 'recurring'
    lead_time_hours: number
    appointment_duration_minutes?: number
  }
}

const appointmentsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAppointments: builder.query<Appointment[], GetAppointmentsArgs>({
      query: ({ providerId }) => ({
        method: 'get',
        url: `/v1/${providerId}/appointments`,
      }),
      providesTags: [RTKQueryTag.APPOINTMENT],
    }),
    updateAppointment: builder.mutation<Appointment, PutAppointmentArgs>({
      query: ({ providerId, data }) => ({
        method: 'put',
        url: `/v1/${providerId}/appointments`,
        data,
      }),
      invalidatesTags: [RTKQueryTag.APPOINTMENT],
    }),
    createAppointment: builder.mutation<Appointment, PostAppointmentArgs>({
      query: ({ providerId, data }) => ({
        method: 'post',
        url: `/v1/${providerId}/appointments`,
        data,
      }),
      invalidatesTags: [RTKQueryTag.APPOINTMENT],
    }),
    createRecurringAppointment: builder.mutation<void, PostAppointmentArgs>({
      query: ({ providerId, data }) => ({
        method: 'post',
        url: `/v1/${providerId}/appointments-recurring`,
        data,
      }),
      invalidatesTags: [RTKQueryTag.APPOINTMENT],
    }),
    cancelAppointment: builder.mutation<void, DeleteAppointmentArgs>({
      query: ({ providerId, appointmentId, changeReason }) => ({
        method: 'delete',
        url: `/v1/${providerId}/appointments/${appointmentId}`,
        params: { changeReason },
      }),
      invalidatesTags: [RTKQueryTag.APPOINTMENT],
    }),
    getAppointmentsForPatient: builder.query<Appointment[], GetAppointmentsForPatientArgs>({
      query: ({ providerId, patientId }) => ({
        method: 'get',
        url: `/v1/${providerId}/${patientId}/appointments`,
      }),
      providesTags: [RTKQueryTag.APPOINTMENT],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(getLTAppointmentsForPatient(data))
        } catch {}
      },
    }),
    getProviderAvailability: builder.query<AvailabilitiesResponse, GetProviderAvailabilityArgs>({
      query: ({ providerId, params, daysToFetch }) => ({
        method: 'post',
        url: `/api/v1/provider/availability`,
        data: { provider_ids: [providerId] },
        params: {
          start_datetime: new Date().toISOString(),
          end_datetime: addDays(new Date(), daysToFetch).toISOString(),
          ...params,
        },
        axiosInstanceType: AxiosInstance.CALENDAR,
      }),
    }),
  }),
})

export const {
  useGetAppointmentsQuery,
  useUpdateAppointmentMutation,
  useCreateAppointmentMutation,
  useCreateRecurringAppointmentMutation,
  useCancelAppointmentMutation,
  useGetAppointmentsForPatientQuery,
  useGetProviderAvailabilityQuery,
} = appointmentsApi
