import React, { FunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ViewStyle } from 'react-native'

import styled from 'styled-components/native'

import { MaximizeIcon, MinimizeIcon, SecondaryTertiaryIconButton } from '../../atoms'
import { BaseButton, ButtonSize, ButtonType } from '../../atoms/baseButton/BaseButton'
import { useZoom } from '../../hooks/useZoom'
import { DisplayMode } from '../../hooks/useZoom/types'
import { tID } from '../../utils'

export type ZoomDisplayModeButtonProps = {
  isMaximize?: boolean
  style?: ViewStyle
}

const IconButton = styled(SecondaryTertiaryIconButton)({
  padding: '8px',
})

export const ZoomDisplayModeButton: FunctionComponent<ZoomDisplayModeButtonProps> = ({ isMaximize = false, style }) => {
  const { formatMessage } = useIntl()
  const { setDisplayMode } = useZoom()
  if (isMaximize) {
    return (
      <IconButton
        testID={tID('ZoomDisplayModeButton-maximize')}
        style={style}
        size={ButtonSize.SMALL}
        leftIcon={<MaximizeIcon />}
        onPress={() => setDisplayMode(DisplayMode.FULL_SCREEN)}
        accessibilityLabel={formatMessage({
          defaultMessage: 'Maximize video',
          description: 'Accessibility label for button to maximize video to full screen',
        })}
      />
    )
  }

  // Exception for this button type
  return (
    <BaseButton
      testID={tID('ZoomDisplayModeButton-minimize')}
      style={style}
      buttonType={ButtonType.SECONDARY_TERTIARY_ICON}
      leftIcon={<MinimizeIcon />}
      onPress={() => setDisplayMode(DisplayMode.BANNER)}
      text={<FormattedMessage defaultMessage='Minimize' description='Button text to minimize video to banner' />}
    />
  )
}
