import React, { FunctionComponent, useCallback, useEffect, useRef } from 'react'
import CSSModules from 'react-css-modules'
import { useSelector } from 'react-redux'

import { useFlags } from '@lyrahealth-inc/shared-app-logic'

import BaseChat from './BaseChat'
import { inLiveMsgSession } from './data/messagesSelectors'
import styles from './messagesPopover.module.scss'
import { getClientDetailsData } from '../../data/lyraTherapy/clientSelectors'

type MessagePopoverProps = {
  closePopover: () => void
  onUpdateMessageCount?: () => void
}

export const MessagesPopover: FunctionComponent<MessagePopoverProps> = ({ closePopover, onUpdateMessageCount }) => {
  const inLiveSession = useSelector(inLiveMsgSession)
  const messageContainer = useRef(null)
  const { isPreferredNameEnabled } = useFlags()
  const client = useSelector(getClientDetailsData)

  const handleOutsideClick = useCallback(
    (e) => {
      e.stopPropagation()
      if (messageContainer.current) {
        const chatBoundingRectangle = (messageContainer.current as HTMLElement).children[0].getBoundingClientRect()
        const isTargetInsideMessageContainer =
          e.clientX > chatBoundingRectangle.left &&
          e.clientX < chatBoundingRectangle.right &&
          e.clientY > chatBoundingRectangle.top &&
          e.clientY < chatBoundingRectangle.bottom
        if (!isTargetInsideMessageContainer) {
          closePopover()
        }
      }
    },
    [closePopover],
  )

  useEffect(() => {
    window.addEventListener('mousedown', handleOutsideClick, false)
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick, false)
    }
  }, [handleOutsideClick])

  return (
    <div className='popover-container' styleName='popover-container' ref={messageContainer}>
      <BaseChat
        chatHeaderTitle={
          inLiveSession
            ? 'In-session messaging'
            : isPreferredNameEnabled && client?.employer === 'apple'
            ? client?.preferred_first_name ?? client?.first_name
            : client?.first_name ?? ''
        }
        liveSessionBadge={inLiveSession ? 'LIVE SESSION' : ''}
        closePopover={closePopover}
        onUpdateMessageCount={onUpdateMessageCount}
      />
    </div>
  )
}

export default CSSModules(MessagesPopover, styles)
