import React, { FunctionComponent, Suspense, useEffect, useMemo } from 'react'
import { IntlProvider } from 'react-intl'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { Provider, useSelector } from 'react-redux'
import { createRoutesFromElements, Navigate, Outlet, Route, RouterProvider, useLocation } from 'react-router'

import { useLDClient } from 'launchdarkly-react-client-sdk'
import { PersistGate } from 'redux-persist/integration/react'
import styled from 'styled-components'
import { FocusVisibleManager } from 'use-focus-visible'

// Store must be imported first or else test will not load app for login via Cypress
// eslint-disable-next-line import/order
import store, { persistor } from './data/store'

import {
  LAUNCHDARKLY_WEB_CLIENT_ID,
  LD_SHARED_CONTEXT_KEYS,
  shouldDisableCostlyVendors,
  useFlags,
  usePrevious,
  withLDProvider,
} from '@lyrahealth-inc/shared-app-logic'
import { colors, HostWrapper, LDOverride, LoadingIndicator, ThemeProvider } from '@lyrahealth-inc/ui-core-crossplatform'

import AppContainer from './AppContainer'
import ApplicationContext from './ApplicationContext'
import { CalendarActivationNavBar } from './calendar/CalendarActivationNavBar'
import CalendarCallback from './calendar/CalendarCallback'
import CalendarSetup from './calendar/CalendarSetup'
import CurrentCalendar from './calendar/CurrentCalendar'
import ClientsDashboard from './clients/dashboard/ClientsDashboard'
// Root Pages
import OutcomesConsent from './clients/dashboard/outcomesConsent/OutcomesConsent'
import ClientDetails from './clients/individualClient/ClientDetails'
import AlertsContainer from './common/components/alerts/AlertsContainer'
import SelectCalendars from './common/components/calendar/SelectCalendars'
import SessionLookup from './common/components/clientSession/SessionLookup'
import DownloadPaymentHistory from './common/components/downloadPaymentHistory/downloadPaymentHistory'
import GoSearchBar from './common/components/goSearchBar/GoSearchBar'
import JSErrorHandler from './common/components/JSErrorHandler/JSErrorHandler'
import Header from './common/components/layout/header/Header'
import ProviderSession from './common/components/providerSession/ProviderSession'
import AuthorizedRoute from './common/components/route/authorizedRoute/AuthorizedRoute'
import NotFoundRoute from './common/components/route/notFoundRoute/NotFoundRoute'
import PublicRoute from './common/components/route/publicRoute/PublicRoute'
import ToastContainer from './common/components/ToastContainer/ToastContainer'
import { APPOINTMENT_OPERATIONS, ROLES, SUPERVISOR_ROLES } from './common/constants/appConstants'
import {
  BC_CASELOAD,
  BC_CASELOAD_DETAILED_WEEKLY_VIEW,
  BC_DASHBOARD,
  BC_MEASUREMENT_BASED_CARE,
  BC_PERFORMACE_DETAILED_WEEKLY_VIEW,
  BC_PERFORMANCE,
  BC_PLATFORM_ENGAGEMENT,
  BC_PLATFORM_ENGAGEMENT_QUARTERLY_DETAILS,
  CALENDAR,
  CLIENT_EMAIL,
  CLIENT_END_OF_SESSION,
  CLIENT_HOME,
  CLIENT_MEDICATIONS,
  CLIENT_NEW_NOTE,
  CLIENT_NOTE,
  CLIENT_NOTES,
  CLIENT_OUTCOMES,
  CLIENT_PROFILE,
  CLIENT_PROGRESS,
  CLIENT_SESSION_LOOKUP,
  CLIENTS,
  CLIENTS_ASSIGNMENT_DETAILS,
  CLIENTS_CONTENT_LIBRARY,
  CLIENTS_DETAILS,
  CLIENTS_NEW_ASSIGNMENT,
  CLIENTS_NEW_SESSION,
  CLIENTS_NEW_SESSION_CONFIRMATION,
  CLIENTS_NEW_SESSION_DETAILS,
  CLIENTS_NEW_TRACK,
  CLIENTS_RESCHEDULE_SESSION,
  CLIENTS_RESCHEDULE_SESSION_CONFIRMATION,
  CLIENTS_RESCHEDULE_SESSION_DETAILS,
  CLIENTS_SESSIONS,
  CLIENTS_TRACK_LIBRARY,
  DOWNLOADS,
  ELIGIBILITY_CHECKER,
  HEALTHPLAN_INFO,
  LC_CALENDAR,
  LC_CALENDAR_ACTIVATE,
  LC_CALENDAR_ACTIVATE_SETUP,
  LC_CALENDAR_CALLBACK,
  LC_CALENDAR_CURRENT,
  LC_CALENDAR_EDIT,
  LC_CALENDAR_EDIT_SELECT_CALENDARS,
  LC_CALENDAR_SELECT_CALENDARS,
  LC_CALENDAR_SETUP,
  LOGIN,
  LT_CLIENTS,
  MESSENGER,
  MESSENGER_POPOUT,
  MY_INFO,
  OKTACALLBACK,
  OUTCOMES_CONSENT,
  PAYMENT_HISTORY,
  PAYMENTS,
  PRACTICES,
  PRACTICES_REGISTER,
  PROVIDERS,
  PROVIDERS_REGISTER,
  RECONCILER,
  RECONCILER_DETAILS,
  REQUEST_PAYMENT,
  SCHEDULING,
  SCHEDULING_ACTIVATE,
  SCHEDULING_OAUTH_CALLBACK,
  SETTINGS,
} from './common/constants/routingConstants'
import { hasRole } from './common/utils/utils'
import { getAuthConfig } from './data/auth/authSelectors'
import ZendeskWidget from './data/ZendeskWidget'
import EligibilityChecker from './eligibilityChecker/EligibilityChecker'
import { sentryCreateBrowserRouter } from './index'
import Login from './login/Login'
import OktaCallback from './login/OktaCallback'
import AssignmentDetails from './lyraTherapy/assignments/assignmentDetails/AssignmentDetails'
import Assignments from './lyraTherapy/assignments/Assignments'
import BCDashboard from './lyraTherapy/bcDashboard/BCDashboard'
import ClientNoteDetails from './lyraTherapy/clientNotes/ClientNoteDetails'
import ClientNotes from './lyraTherapy/clientNotes/ClientNotes'
import ClientNotesRoutes from './lyraTherapy/clientNotes/ClientNotesRoutes'
import ClientProgress from './lyraTherapy/clientProgress/ClientProgress'
import Client from './lyraTherapy/clients/clientDetails/Client'
import EndOfSession from './lyraTherapy/clients/clientDetails/EndOfSession'
import ClientProfile from './lyraTherapy/clients/clientDetails/profile/ClientProfile'
import ClientListContainer from './lyraTherapy/clients/clientsContainer/ClientListContainer'
import ClientsContainer from './lyraTherapy/clients/clientsContainer/ClientsContainer'
import ClientShareOutcomes from './lyraTherapy/clientShareOutcomes/ClientShareOutcomes'
import ConfigAssignmentContainer from './lyraTherapy/configAssignmentContainer/ConfigAssignmentContainer'
import ConfigTrackContainer from './lyraTherapy/configTrackContainer/ConfigTrackContainer'
import ContentLibrary from './lyraTherapy/contentLibrary/ContentLibrary'
import TrackLibrary from './lyraTherapy/contentLibrary/TrackLibrary'
import Medications from './lyraTherapy/medications/Medications'
import Messenger from './lyraTherapy/messages/messenger/Messenger'
import PopoutMessenger from './lyraTherapy/messages/messenger/PopoutMessenger'
import CaseloadManagementContainer from './lyraTherapy/performance/CaseloadManagementContainer'
import CaseloadManagementDetailedWeeklyViewContainer from './lyraTherapy/performance/CaseloadManagementDetailedWeeklyViewContainer'
import MeasurementBasedCare from './lyraTherapy/performance/MeasurementBasedCare'
import PlatformEngagementContainer from './lyraTherapy/performance/platformEngagement/PlatformEngagementContainer'
import PlatformEngagementQuarterlyDetailsContainer from './lyraTherapy/performance/platformEngagement/PlatformEngagementQuarterlyDetailsContainer'
import ProviderCaseload from './lyraTherapy/performance/ProviderCaseload'
import SessionDetailsContainer from './lyraTherapy/sessions/SessionDetailsContainer'
import Sessions from './lyraTherapy/sessions/Sessions'
import SessionSelectionConfirmation from './lyraTherapy/sessions/SessionSelectionConfirmation'
import SessionSelectionContainer from './lyraTherapy/sessions/SessionSelectionContainer'
import PaymentHistory from './paymentHistory/PaymentHistory'
import ReconcilerDashboard from './reconciler/dashboard/ReconcilerDashboard'
import ReconcilerDetails from './reconciler/individualClient/ReconcilerDetails'
import ClientEmail from './requestPayment/clientEmail/ClientEmail'
import CalendarRoutes from './routing/CalendarRoutes'
import ClientsRoutes from './routing/ClientsRoutes'
import HealthPlanInfoRoutes from './routing/HealthPlanInfoRoutes'
import MyInfoRoutes from './routing/MyInfoRoutes'
import { UNSAFE_listenBeforeNavigate, UNSAFE_patchRouterNavigate } from './routing/patchRouterNavigate'
import PaymentsRoutes from './routing/PaymentsRoutes'
import PracticesRoutes from './routing/PracticesRoutes'
import preserveSearchParamsListener from './routing/preserveSearchParams'
import ProvidersRoutes from './routing/ProvidersRoutes'
import ReconcilerRoutes from './routing/ReconcilerRoutes'
import RequestPaymentRoutes from './routing/RequestPaymentRoutes'
import usePathChanged from './routing/usePathChanged'
import SchedulingOAuth from './scheduling/SchedulingOAuth'
import SmartScheduling from './scheduling/SmartScheduling'
import SchedulingWalkthrough from './scheduling/walkthrough/SchedulingWalkthrough'
import Settings from './settings/Settings'
import { initializeTrackingWithProxy } from '../../mixpanel/mixpanelTracking'
import ErrorBoundaryWrapper from '../js/error/ErrorBoundaryWrapper'

// @ts-expect-error TS(7030): Not all code paths return a value.
export const getInitialRoute = () => {
  const auth = store.getState().auth
  if (auth.isLoggedIn) {
    const roles = auth.user?.roles
    if (hasRole(roles, [ROLES.PRACTICES_ADMIN, ROLES.INDIVIDUAL_PROVIDER])) {
      if (auth.user?.is_registered) {
        if (auth.config?.bcPlatform) {
          return BC_DASHBOARD.route
        } else {
          if (hasRole(roles, [ROLES.ICAS_PROVIDER])) {
            return CLIENTS.route
          }
          return REQUEST_PAYMENT.route
        }
      } else if (hasRole(roles, [ROLES.PRACTICES_ADMIN])) {
        return PRACTICES_REGISTER.route
      } else if (hasRole(roles, [ROLES.INDIVIDUAL_PROVIDER])) {
        return PROVIDERS_REGISTER.route
      }
    } else if (hasRole(roles, SUPERVISOR_ROLES)) {
      return LT_CLIENTS.route
    } else if (hasRole(roles, ROLES.PROVIDERS_ADMIN)) {
      return PROVIDERS.route
    } else if (hasRole(roles, ROLES.PAYMENTS_ADMIN)) {
      return PAYMENTS.route
    } else if (hasRole(roles, ROLES.RECONCILER)) {
      return RECONCILER.route
    } else if (hasRole(roles, ROLES.PRACTICE_PROVIDER)) {
      return MY_INFO.route
    }
  } else {
    return LOGIN.route
  }
}

const CommonComponentsWrapperContainer = styled.div<{ height?: string }>(({ height }) => ({
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  backgroundColor: colors.ui_oatmeal1,
  height,
}))

const CommonComponentsWrapper: FunctionComponent = () => {
  const location = useLocation()
  return (
    <CommonComponentsWrapperContainer
      height={
        location.pathname.includes(LC_CALENDAR_CURRENT.route) || location.pathname.includes(MESSENGER.route)
          ? '100vh'
          : undefined
      }
    >
      <CommonComponents />
    </CommonComponentsWrapperContainer>
  )
}

const App = () => {
  initializeTrackingWithProxy()
  const ldClient = useLDClient()

  const router = useMemo(() => {
    const routes = createRoutesFromElements(
      <Route
        element={
          <ErrorBoundaryWrapper>
            <CommonComponentsWrapper />
          </ErrorBoundaryWrapper>
        }
      >
        {/* @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message */}
        <Route path='/' Component={() => <Navigate to={getInitialRoute()} />} />
        <Route
          path='/python'
          element={() => (
            <div style={{ textAlign: 'center', paddingTop: '60' }}>
              <LoadingIndicator size={45} />
            </div>
          )}
        />
        <Route element={<PublicRoute />}>
          <Route path={LOGIN.route} element={<Login />} />
          <Route path={OKTACALLBACK.route} element={<OktaCallback />} />
          <Route path={CLIENT_OUTCOMES.route} element={<ClientShareOutcomes />} />
        </Route>
        <Route element={<AuthorizedRoute />}>
          <Route path={BC_DASHBOARD.route} element={<BCDashboard />} />
          <Route path={`${PRACTICES.route}/*`} element={<PracticesRoutes />} />
          <Route path={`${PROVIDERS.route}/*`} element={<ProvidersRoutes />} />
          <Route path={PAYMENTS.route} element={<PaymentsRoutes />} />
          <Route path={REQUEST_PAYMENT.route} element={<RequestPaymentRoutes />} />
          <Route path={HEALTHPLAN_INFO.route} element={<HealthPlanInfoRoutes />} />
          <Route path={CLIENT_EMAIL.route} element={<ClientEmail />} />
          <Route path={BC_DASHBOARD.route} element={<BCDashboard />} />
          <Route path={BC_PERFORMANCE.route}>
            <Route index element={<ProviderCaseload />} />
            <Route
              path={BC_PERFORMACE_DETAILED_WEEKLY_VIEW.route}
              element={<CaseloadManagementDetailedWeeklyViewContainer />}
            />
            <Route
              path={BC_CASELOAD_DETAILED_WEEKLY_VIEW.route}
              element={<CaseloadManagementDetailedWeeklyViewContainer />}
            />
            <Route path={BC_CASELOAD.route} element={<CaseloadManagementContainer />} />
            <Route path={BC_MEASUREMENT_BASED_CARE.route} element={<MeasurementBasedCare />} />
            <Route path={BC_PLATFORM_ENGAGEMENT.route} element={<PlatformEngagementContainer />} />
            <Route
              path={BC_PLATFORM_ENGAGEMENT_QUARTERLY_DETAILS.route}
              element={<PlatformEngagementQuarterlyDetailsContainer />}
            />
          </Route>
          <Route path={CLIENTS.route} element={<ClientsRoutes />}>
            {/* @ts-expect-error TS(2740) */}
            <Route index element={<ClientsDashboard />} />
            <Route path={CLIENTS_DETAILS.route} element={<ClientDetails />} />
            <Route path={OUTCOMES_CONSENT.route} element={<OutcomesConsent />} />
            <Route path={LT_CLIENTS.route} element={<ClientsContainer />}>
              <Route index element={<ClientListContainer />} />
              <Route path={CLIENT_HOME.route} element={<Client />}>
                <Route index element={<Assignments />} />
                <Route path={CLIENTS_ASSIGNMENT_DETAILS.route} element={<AssignmentDetails />} />
                <Route path={CLIENTS_NEW_ASSIGNMENT.route} element={<ConfigAssignmentContainer />} />
                <Route path={CLIENTS_NEW_TRACK.route} element={<ConfigTrackContainer />} />
                <Route path={CLIENTS_CONTENT_LIBRARY.route} element={<ContentLibrary />} />
                <Route path={CLIENTS_TRACK_LIBRARY.route} element={<TrackLibrary />} />
                <Route path={CLIENTS_SESSIONS.route} element={<Sessions />} />
                <Route
                  path={CLIENTS_NEW_SESSION.route}
                  element={<SessionDetailsContainer operation={APPOINTMENT_OPERATIONS.NEW} />}
                />
                <Route
                  path={CLIENTS_NEW_SESSION_DETAILS.route}
                  element={<SessionSelectionContainer operation={APPOINTMENT_OPERATIONS.NEW} />}
                />
                <Route
                  path={CLIENTS_RESCHEDULE_SESSION.route}
                  element={<SessionDetailsContainer operation={APPOINTMENT_OPERATIONS.RESCHEDULE} />}
                />
                <Route
                  path={CLIENTS_RESCHEDULE_SESSION_DETAILS.route}
                  element={<SessionSelectionContainer operation={APPOINTMENT_OPERATIONS.RESCHEDULE} />}
                />
                <Route
                  path={CLIENTS_NEW_SESSION_CONFIRMATION.route}
                  element={<SessionSelectionConfirmation operation={APPOINTMENT_OPERATIONS.NEW} />}
                />
                <Route
                  path={CLIENTS_RESCHEDULE_SESSION_CONFIRMATION.route}
                  element={<SessionSelectionConfirmation operation={APPOINTMENT_OPERATIONS.RESCHEDULE} />}
                />
                <Route
                  path={CLIENT_PROGRESS.route}
                  // @ts-expect-error TS(2739)
                  element={<ClientProgress />}
                />
                <Route path={CLIENT_PROFILE.route} element={<ClientProfile />} />
                <Route path={CLIENT_END_OF_SESSION.route} element={<EndOfSession />} />
                <Route path={CLIENT_NOTES.route} element={<ClientNotesRoutes />}>
                  <Route index element={<ClientNotes />} />
                  <Route path={CLIENT_NOTE.route} element={<ClientNoteDetails />} />
                  <Route path={CLIENT_NEW_NOTE.route} element={<ClientNoteDetails />} />
                </Route>
                {/* @ts-expect-error TS(2739) */}
                <Route path={CLIENT_MEDICATIONS.route} element={<Medications />} />
              </Route>
            </Route>
          </Route>
          <Route path={RECONCILER.route} element={<ReconcilerRoutes />}>
            <Route index element={<ReconcilerDashboard />} />
            <Route path={RECONCILER_DETAILS.route} element={<ReconcilerDetails />} />
          </Route>
          <Route path={MY_INFO.route} element={<MyInfoRoutes />} />
          <Route path={DOWNLOADS.route} element={<DownloadPaymentHistory />} />
          <Route path={CALENDAR.route} element={<CalendarRoutes />} />
          <Route path={LC_CALENDAR.route}>
            <Route path={LC_CALENDAR_CURRENT.route} element={<CurrentCalendar />} />
            <Route path={LC_CALENDAR_CALLBACK.route} element={<CalendarCallback />} />
            <Route path={LC_CALENDAR_SETUP.route} element={<CalendarSetup />} />
            <Route path={LC_CALENDAR_EDIT.route} element={<CalendarSetup isEdit />} />
            <Route path={LC_CALENDAR_EDIT_SELECT_CALENDARS.route} element={<SelectCalendars isEdit />} />
            <Route path={LC_CALENDAR_ACTIVATE.route} element={<CalendarActivationNavBar />}>
              <Route path={LC_CALENDAR_SELECT_CALENDARS.route} element={<SelectCalendars />} />
              <Route path={LC_CALENDAR_ACTIVATE_SETUP.route} element={<CalendarSetup />} />
            </Route>
          </Route>
          <Route path={SCHEDULING.route} element={<SmartScheduling />} />
          <Route path={SCHEDULING_OAUTH_CALLBACK.route} element={<SchedulingOAuth />} />
          <Route path={SCHEDULING_ACTIVATE.route} element={<SchedulingWalkthrough />} />
          <Route path={`${PAYMENT_HISTORY.route}/:type?`} element={<PaymentHistory />} />
          <Route path={CLIENT_SESSION_LOOKUP.route} element={<SessionLookup />} />
          <Route path={ELIGIBILITY_CHECKER.route} element={<EligibilityChecker />} />
          <Route path={SETTINGS.route} element={<Settings />} />
          <Route path={MESSENGER.route}>
            <Route index element={<Messenger />} />
            <Route path={MESSENGER_POPOUT.route} element={<PopoutMessenger />} />
          </Route>
        </Route>
        <Route path='*' element={<NotFoundRoute />} />
      </Route>,
    )
    const router = sentryCreateBrowserRouter(routes)
    UNSAFE_patchRouterNavigate(router)
    UNSAFE_listenBeforeNavigate(router, preserveSearchParamsListener)
    return router
  }, [])
  return (
    <PersistGate loading={null} persistor={persistor}>
      <LDOverride ldClient={ldClient} disable={!__DEV__}>
        <SafeAreaProvider>
          <Provider store={store}>
            <ApplicationContext>
              <FocusVisibleManager>
                <ThemeProvider>
                  <AppContainer>
                    <ErrorBoundaryWrapper>
                      <IntlProvider locale='en-US' defaultLocale='en-US'>
                        <Suspense fallback={<LoadingIndicator />}>
                          <RouterProvider router={router} />
                        </Suspense>
                      </IntlProvider>
                    </ErrorBoundaryWrapper>
                  </AppContainer>
                </ThemeProvider>
              </FocusVisibleManager>
            </ApplicationContext>
          </Provider>
        </SafeAreaProvider>
      </LDOverride>
    </PersistGate>
  )
}

const CommonComponents: FunctionComponent = () => {
  const { isZendeskWidgetEnabled } = useFlags()
  const config = useSelector(getAuthConfig)
  usePathChanged()
  const location = useLocation()
  const hideHeader =
    location.pathname.includes(CLIENT_OUTCOMES.route) ||
    location.pathname.includes(SCHEDULING_ACTIVATE.route) ||
    location.pathname.includes(LC_CALENDAR_SETUP.route) ||
    location.pathname.includes(LC_CALENDAR_EDIT.route) ||
    location.pathname.includes(LC_CALENDAR_EDIT_SELECT_CALENDARS.route) ||
    location.pathname.includes(LC_CALENDAR_ACTIVATE.route) ||
    location.pathname.includes(LC_CALENDAR_SELECT_CALENDARS.route) ||
    location.pathname.includes(LC_CALENDAR_ACTIVATE_SETUP.route)

  const isPopout = location.pathname.includes(MESSENGER_POPOUT.route)
  const prevIsPopout = usePrevious(isPopout)
  useEffect(() => {
    if (prevIsPopout && !isPopout) {
      window.close()
    }
  }, [prevIsPopout, isPopout])
  return (
    <HostWrapper>
      <JSErrorHandler />
      {!isPopout && (
        <>
          {config?.videoSessionsEnabled && <ProviderSession />}
          {config?.bcPlatform && <GoSearchBar />}
          {isZendeskWidgetEnabled && <ZendeskWidget />}
          {!hideHeader && <Header />}
        </>
      )}
      <AlertsContainer />
      <ToastContainer />
      <Outlet />
    </HostWrapper>
  )
}

export default withLDProvider({
  clientSideID: LAUNCHDARKLY_WEB_CLIENT_ID,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  context: {
    kind: 'user',
    key: LD_SHARED_CONTEXT_KEYS.LAUNCH_DARKLY_USER_UUID,
  },
  options: {
    sendEvents: !shouldDisableCostlyVendors,
  },
})(App)
