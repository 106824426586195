import React from 'react'
import CSSModules from 'react-css-modules'

import { CustomerInfo, useFlags } from '@lyrahealth-inc/shared-app-logic'

import styles from './requestPayment.module.scss'
import sessionResetDate from '../common/content/sessionResetDate'

const SessionsWarningModal = ({ firstName, lastName, sessionsLeft, company }: SessionsWarningModalProps) => {
  const { isProgramLevelSessionLimitEnabled } = useFlags()
  return (
    <div styleName='modal-content' data-test-id='RequestPayment-sessions-warning-modal'>
      <h2 styleName='main-header'>Thank you!</h2>
      <div>
        <p>
          Your payment was successfully submitted. As a reminder, <span styleName='capitalize'>{firstName}</span>{' '}
          <span styleName='capitalize'>{lastName}</span> has {sessionsLeft}{' '}
          {isProgramLevelSessionLimitEnabled && company?.sessions_limit_by_program && 'therapy '}
          {sessionsLeft > 1 ? 'sessions' : 'session'} remaining where costs are covered by their Lyra benefit.{' '}
          {sessionResetDate({ employer: company?.label, date: company?.session_count_starts_on })}
        </p>
        <p className='paragraph-small'>
          We suggest that you and <span styleName='capitalize'>{firstName}</span> start planning to complete treatment
          or begin discussing longer term care. Please note that once the session limit has been reached, Lyra is unable
          to reimburse for services. For any questions, please contact{' '}
          <a href='mailto:providers@lyrahealth.com'>providers@lyrahealth.com</a>
        </p>
      </div>
    </div>
  )
}

type SessionsWarningModalProps = {
  firstName: string
  lastName: string
  sessionsLeft: number
  company?: CustomerInfo
}

export default CSSModules(SessionsWarningModal, styles)
