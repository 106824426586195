import React, { useEffect, useRef, useState } from 'react'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'

import styled from 'styled-components/native'

import { usePrevious } from '@lyrahealth-inc/shared-app-logic'
import { ChatHandle, ProviderChatHeader, tID } from '@lyrahealth-inc/ui-core-crossplatform'

import MessengerEmptyView from './MessengerEmptyView'
import BaseChat from '../BaseChat'

type MessengerProps = {
  clientName?: string
  isLoading: boolean
  showEmptyView: boolean
  onOpenDetailsPanel: () => void
  isDetailsPanelOpen: boolean
  isComposing: boolean
  clients: { id: string; name: string }[]
  onMessageSent: () => void
  onClientSelected: (clientId: string | null) => void
  onOpenNewWindow?: () => void
}

const PANEL_WIDTH = 320

const Container = styled(Animated.View)({
  flexDirection: 'column',
  flex: 1,
})

const MessengerChat: React.FC<MessengerProps> = ({
  clientName,
  isLoading,
  showEmptyView,
  onOpenDetailsPanel,
  isDetailsPanelOpen,
  isComposing,
  clients,
  onMessageSent,
  onClientSelected,
  onOpenNewWindow,
}) => {
  const prevPanelState = usePrevious(isDetailsPanelOpen)
  const contentMarginRight = useSharedValue(isDetailsPanelOpen ? 0 : PANEL_WIDTH)
  const [chatHandle, setChatHandle] = useState<ChatHandle | null>(null)
  const isAnimating = useRef(false)

  const showEmptyComposeView = isComposing && !clientName
  useEffect(() => {
    const shouldPanelClose = showEmptyView || showEmptyComposeView || !isDetailsPanelOpen
    const newValue = shouldPanelClose ? 0 : PANEL_WIDTH
    if (isAnimating.current) {
      return
    }
    // Only animate when panel is opened via user interaction
    if (prevPanelState == null || prevPanelState === isDetailsPanelOpen) {
      contentMarginRight.value = newValue
      return
    }

    isAnimating.current = true
    contentMarginRight.value = withTiming(newValue, {}, () => {
      isAnimating.current = false
    })
  }, [contentMarginRight, isComposing, isDetailsPanelOpen, prevPanelState, showEmptyComposeView, showEmptyView])

  const contentStyle = useAnimatedStyle(() => ({
    marginRight: contentMarginRight.value,
  }))

  useEffect(() => {
    if (clientName) {
      chatHandle?.focusInput()
    }
  }, [chatHandle, clientName, isComposing])

  return (
    <Container testID={tID('MessengerChat')} style={contentStyle}>
      <ProviderChatHeader
        clientName={clientName}
        isComposing={isComposing}
        onOpenDetailsPanel={onOpenDetailsPanel}
        isDetailsPanelOpen={isDetailsPanelOpen}
        clients={clients}
        onClientSelected={onClientSelected}
        onOpenNewWindow={onOpenNewWindow}
      />
      {showEmptyView ? (
        <MessengerEmptyView />
      ) : (
        <BaseChat
          onChatHandleChange={setChatHandle}
          hideChatHeader
          expandedButton
          showEmptyChatView={showEmptyComposeView}
          disableSend={showEmptyComposeView}
          isLoading={isLoading && !isComposing}
          onMessageSent={onMessageSent}
        />
      )}
    </Container>
  )
}

export default MessengerChat
