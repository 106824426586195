import {
  GET_CLIENT_EPISODES,
  GET_CLOSED_EPISODES,
  SELECT_EPISODE,
  UPDATE_EPISODE,
} from '../../../common/constants/reduxConstants'

export const getAllClientEpisodes = ({ clientId, cancelToken }: any) => ({
  request: {
    method: 'get',
    url: `/lt/v1/patient/${clientId}/episodes`,
    cancelToken,
  },
})

export const getEpisode = ({ episodeId }: any) => ({
  request: {
    method: 'get',
    url: `/lt/v1/episodes/${episodeId}`,
  },
})

export const getClientEpisodes = ({ provider_id, patient_id, cancelToken }: any) => ({
  action: GET_CLIENT_EPISODES,
  request: {
    method: 'get',
    url: '/lt/v1/episodes',
    params: { provider_id, patient_id },
    cancelToken,
  },
})

export const getClosedEpisodes = ({ startDate, endDate, providerId }: any) => ({
  action: GET_CLOSED_EPISODES,
  request: {
    method: 'get',
    url: `/v1/providers/episodes/closed`,
    params: { startDate, endDate, providerId },
  },
})

export const updateEpisode = ({ id, ...data }: any) => ({
  action: UPDATE_EPISODE,
  request: {
    method: 'put',
    url: `/lt/v1/episodes/${id}`,
    data,
  },
})

export const updateCurriculum = ({ id, ...data }: any) => ({
  action: UPDATE_EPISODE,
  request: {
    method: 'patch',
    url: `/lt/v1/episodes/${id}`,
    data,
  },
})

export const setSelectedEpisode = (data: any) => ({
  type: SELECT_EPISODE,
  data,
})

export const assignCurriculum = ({ id }: any) => ({
  request: {
    method: 'post',
    url: `/lt/v1/episode/${id}/assignCurriculum`,
  },
})

export const getAssessmentTracksByProgram = ({ programId }: any) => ({
  request: {
    method: 'get',
    url: `/lt/v1/assessmentSets`,
    params: { program_id: programId },
  },
})

export const updateAssessmentTrack = ({ id, ...data }: any) => ({
  action: UPDATE_EPISODE,
  request: {
    method: 'patch',
    url: `/lt/v1/episodes/${id}`,
    data,
  },
})
