import React from 'react'
import { ViewStyle } from 'react-native'

import styled from 'styled-components/native'

import { ConversationList } from './ConversationList'
import { ConversationListEmpty } from './ConversationListEmpty'
import { ConversationListSkeleton } from './ConversationListSkeleton'
import { ChatSideBarHeader } from '../../molecules'
import { Conversation, ConversationFilter } from '../../ui-models'
import { tID } from '../../utils'

export type ChatSideBarProps = {
  selectedClientId: string | null
  conversations: Conversation[]
  onComposePressed: () => void
  onConversationPressed: (clientId: string) => void
  filters: ConversationFilter[]
  onFiltersChanged: (filters: ConversationFilter[]) => void
  isLoading: boolean
  style?: ViewStyle
}

const Container = styled.View(({ theme }) => ({
  flexDirection: 'column',
  width: '320px',
  backgroundColor: theme.colors.backgroundPrimary,
}))

export const ChatSideBar: React.FC<ChatSideBarProps> = ({
  conversations,
  onComposePressed,
  onConversationPressed,
  selectedClientId,
  filters,
  onFiltersChanged,
  isLoading,
  style,
}) => {
  return (
    <Container testID={tID('ChatSideBar')} style={style}>
      <ChatSideBarHeader onComposePressed={onComposePressed} filters={filters} onFiltersChanged={onFiltersChanged} />
      {isLoading ? (
        <ConversationListSkeleton />
      ) : conversations.length === 0 ? (
        <ConversationListEmpty hasFilters={filters.length > 0} />
      ) : (
        <ConversationList
          conversations={conversations}
          onConversationPressed={onConversationPressed}
          selectedClientId={selectedClientId}
        />
      )}
    </Container>
  )
}
