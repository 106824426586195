// This is a modal component used in the calendar view to add a time(s)
// for a specific date

import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

import { EventApi, EventContentArg, EventInput } from '@fullcalendar/core'
import {
  addDays,
  addMinutes,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  isValid,
  max,
  parse,
  parseISO,
  setMinutes,
  startOfDay,
  startOfMinute,
  subMinutes,
} from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import { isEmpty } from 'lodash-es'
import styled from 'styled-components/native'

import { EventExtendedProps, isSessionOrSlot } from '@lyrahealth-inc/shared-app-logic'

import { ProviderCalendarBaseSlotSelectionModal } from './ProviderCalendarBaseSlotSelectionModal'
import { DateField } from '../../atoms/formElements/DateField.web'

const DatePickerContainer = styled.View({
  zIndex: 1,
})

export type ProviderCalendarDateSlotSelectionModalProps = {
  getEvents?: (params: { startDate: string; endDate: string }) => Promise<EventInput[]>
  eventToEdit?: EventContentArg | null
  initialEvents?: EventApi[] | null
  onConfirmationButtonPress: (events: { startTime: string; endTime: string }[]) => void
  onRequestClose: () => void
  rangeToAdd?: {
    start: string
    end: string
    startLocal: string
    endLocal: string
  } | null
  sessionDuration?: number
  setShowAddEventModal: (showModal: boolean) => void
  showAddEventModal: boolean
  slotMaxTime?: string
  slotMinTime?: string
}

export const ProviderCalendarDateSlotSelectionModal: FunctionComponent<ProviderCalendarDateSlotSelectionModalProps> = ({
  eventToEdit,
  getEvents,
  initialEvents,
  onConfirmationButtonPress,
  onRequestClose,
  rangeToAdd,
  sessionDuration = 50,
  setShowAddEventModal,
  showAddEventModal,
  slotMaxTime = '22:00:00',
  slotMinTime = '07:00:00',
}) => {
  const { formatMessage } = useIntl()
  const locale = Intl.DateTimeFormat().resolvedOptions().locale

  const localeDateFormat = useMemo(() => {
    const currentDate = new Date()
    const formatter = new Intl.DateTimeFormat(locale, { day: '2-digit', month: '2-digit', year: 'numeric' })
    const datePieces = formatter.formatToParts(currentDate)
    const formatParts = datePieces.map((part) => {
      if (part.type === 'day') return 'dd'
      if (part.type === 'month') return 'MM'
      if (part.type === 'year') return 'yyyy'
      return part.value
    })
    return formatParts.join('')
  }, [locale])

  const [selectedDate, setSelectedDate] = useState<string>(format(new Date(), localeDateFormat))
  const [selectedDateTimes, setSelectedDateTimes] = useState<{ startTime: string; endTime: string }[] | undefined>(
    undefined,
  )
  const [selectedStartTimes, setSelectedStartTimes] = useState<string[]>([''])
  const [initialTime, setInitialTime] = useState<string | undefined>(undefined)
  const [isValidDateSelection, setValidDateSelection] = useState<boolean>(true)
  const holdDurationMinutes = Math.ceil(sessionDuration / 30) * 30
  const selectedDateMidnight = useMemo(() => {
    return startOfDay(parse(selectedDate, localeDateFormat, new Date()))
  }, [localeDateFormat, selectedDate])
  const eventToEditStartTime = useMemo(() => {
    return eventToEdit
      ? parse(eventToEdit.event.startStr.replace('Z', ''), "yyyy-MM-dd'T'HH:mm:ss", new Date())
      : undefined
  }, [eventToEdit])
  const [startDate, endDate] = useMemo(() => {
    return [
      parse(slotMinTime, 'HH:mm:ss', parse(selectedDate, localeDateFormat, new Date()) ?? new Date()),
      parse(slotMaxTime, 'HH:mm:ss', parse(selectedDate, localeDateFormat, new Date()) ?? new Date()),
    ]
  }, [localeDateFormat, selectedDate, slotMinTime, slotMaxTime])

  useEffect(() => {
    if (eventToEditStartTime) {
      setSelectedDate(format(eventToEditStartTime, localeDateFormat))
      setSelectedStartTimes([format(eventToEditStartTime, 'hh:mm a')])
      setInitialTime(format(eventToEditStartTime, 'hh:mm a'))
    }
  }, [eventToEditStartTime, localeDateFormat])

  const onDateChange = useCallback(
    (date: string) => {
      setSelectedDate(date)
      const parsedDate = parse(date, localeDateFormat, new Date())
      if (!(isValid(parsedDate) && date === format(parsedDate, localeDateFormat))) {
        setValidDateSelection(false)
        return
      }
      const midnight = startOfDay(parse(date, localeDateFormat, new Date()))
      if (isBefore(midnight, startOfDay(new Date()))) {
        setValidDateSelection(false)
        return
      }
      if (!getEvents) {
        return
      }
      setValidDateSelection(true)
      const startOfDate = startOfDay(parse(date, localeDateFormat, new Date()))
      getEvents({
        startDate: startOfDate.toISOString(),
        endDate: addDays(startOfDate, 1).toISOString(),
      }).then((events: { start: string; end: string; extendedProps: EventExtendedProps }[]) => {
        const formattedEvents = events
          .filter((event: { start: string; end: string; extendedProps: EventExtendedProps }) => {
            return isSessionOrSlot(event.extendedProps as EventExtendedProps)
          })
          .map((event: { start: string; end: string; extendedProps: EventExtendedProps }) => {
            return {
              startTime: format(utcToZonedTime(parseISO(event.start), 'UTC'), 'hh:mm a'),
              endTime: format(utcToZonedTime(parseISO(event.end), 'UTC'), 'hh:mm a'),
            }
          })
          .filter(({ startTime }: { startTime: string }) => {
            return startTime !== initialTime
          })
        setSelectedDateTimes(formattedEvents)
      })
    },
    [getEvents, initialTime, localeDateFormat],
  )

  useEffect(() => {
    const initialDate = eventToEditStartTime ?? new Date()
    const formattedEvents = initialEvents
      ?.filter((event) => {
        return (
          isSessionOrSlot(event.extendedProps as EventExtendedProps) &&
          isAfter(new Date(event.startStr), startOfDay(initialDate)) &&
          isBefore(new Date(event.endStr), startOfDay(addDays(initialDate, 1)))
        )
      })
      .map((event) => {
        return {
          startTime: format(utcToZonedTime(parseISO(event.startStr), 'UTC'), 'hh:mm a'),
          endTime: format(utcToZonedTime(parseISO(event.endStr), 'UTC'), 'hh:mm a'),
        }
      })
    setSelectedDateTimes(formattedEvents)
  }, [eventToEditStartTime, initialEvents])

  useEffect(() => {
    if (rangeToAdd) {
      const rangeStartTime = new Date(rangeToAdd.startLocal)
      const rangeEndTime = new Date(rangeToAdd.endLocal)
      onDateChange(format(rangeStartTime, localeDateFormat))
      let currentTime = rangeStartTime
      const initialSelectedTimeSlots: string[] = []
      while (
        isBefore(currentTime, subMinutes(rangeEndTime, holdDurationMinutes)) ||
        isEqual(currentTime, subMinutes(rangeEndTime, holdDurationMinutes))
      ) {
        initialSelectedTimeSlots.push(format(currentTime, 'hh:mm a'))
        currentTime = addMinutes(currentTime, holdDurationMinutes)
      }
      setSelectedStartTimes(initialSelectedTimeSlots)
    }
  }, [holdDurationMinutes, localeDateFormat, onDateChange, rangeToAdd])

  const blockedRelativeStartTimes = useMemo(() => {
    const adjacentBlockHalfHourCount = Math.ceil(sessionDuration / 30) - 1
    const blockedRelativeStartTimes: number[] = []
    for (let startTime = -adjacentBlockHalfHourCount; startTime <= adjacentBlockHalfHourCount; startTime++) {
      blockedRelativeStartTimes.push(startTime * 30)
    }
    return blockedRelativeStartTimes
  }, [sessionDuration])

  const allAvailableTimeSlots = useMemo(() => {
    const timeSlots: { label: string; value: string }[] = []
    let currentTime = startDate

    const formatTimeToLocale = (time: Date) => {
      return new Intl.DateTimeFormat(locale, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      }).format(time)
    }

    if (isSameDay(selectedDateMidnight, startOfDay(new Date()))) {
      currentTime = max([startDate, setMinutes(new Date(), new Date().getMinutes() < 30 ? 30 : 60)])
    }

    while (
      isBefore(startOfMinute(currentTime), startOfMinute(subMinutes(endDate, holdDurationMinutes))) ||
      isEqual(startOfMinute(currentTime), startOfMinute(subMinutes(endDate, holdDurationMinutes)))
    ) {
      timeSlots.push({
        label: `${formatTimeToLocale(currentTime)} - ${formatTimeToLocale(
          addMinutes(currentTime, holdDurationMinutes),
        )}`,
        value: format(currentTime, 'hh:mm a'),
      })
      currentTime = addMinutes(currentTime, 30)
    }

    const blockedStartTimes = selectedDateTimes
      ? [
          ...new Set(
            selectedDateTimes
              .map((selectedDateExistingEvent) => selectedDateExistingEvent.startTime)
              .filter((startTime) =>
                eventToEditStartTime ? startTime !== format(eventToEditStartTime, 'hh:mm a') : true,
              )
              .reduce((allStartTimes: string[], existingEventTime) => {
                const blockedTimes = blockedRelativeStartTimes.map((blockedRelativeStartTime) =>
                  format(
                    addMinutes(parse(existingEventTime, 'hh:mm a', new Date()), blockedRelativeStartTime),
                    'hh:mm a',
                  ),
                )
                return allStartTimes.concat(blockedTimes)
              }, []),
          ),
        ]
      : []

    return timeSlots.filter((timeSlot) => !blockedStartTimes.includes(timeSlot.value))
  }, [
    blockedRelativeStartTimes,
    endDate,
    eventToEditStartTime,
    holdDurationMinutes,
    locale,
    selectedDateMidnight,
    selectedDateTimes,
    startDate,
  ])

  const blockedTimeSlotValues = useMemo(() => {
    return [
      ...new Set(
        selectedStartTimes.reduce(
          (allStartTimes: string[], selectedStartTime) =>
            isEmpty(selectedStartTime)
              ? allStartTimes
              : allStartTimes.concat(
                  blockedRelativeStartTimes.map((blockedRelativeStartTime) =>
                    format(
                      addMinutes(parse(selectedStartTime, 'hh:mm a', new Date()), blockedRelativeStartTime),
                      'hh:mm a',
                    ),
                  ),
                ),
          [],
        ),
      ),
    ]
  }, [blockedRelativeStartTimes, selectedStartTimes])

  const hasValidTimeSelections = useMemo(() => {
    return selectedStartTimes.filter((selectedStartTime) => !isEmpty(selectedStartTime)).length > 0
  }, [selectedStartTimes])

  const confirmSelection = () => {
    setShowAddEventModal(false)
    const startAndEndTimes = selectedStartTimes
      .filter((selectedStartTime) => !isEmpty(selectedStartTime))
      .map((selectedStartTime) => {
        const startTime = parse(selectedStartTime, 'hh:mm a', parse(selectedDate, localeDateFormat, new Date()))
        return {
          startTime: format(startTime, "yyyy-MM-dd'T'HH:mm:ss.SSSX", { timeZone: 'UTC' }),
          endTime: format(addMinutes(startTime, holdDurationMinutes), "yyyy-MM-dd'T'HH:mm:ss.SSSX", {
            timeZone: 'UTC',
          }),
        }
      })
    onConfirmationButtonPress(startAndEndTimes)
  }

  const handleAnimationEnd = () => {
    setSelectedStartTimes([''])
    setSelectedDate(format(new Date(), localeDateFormat))
  }

  return (
    <ProviderCalendarBaseSlotSelectionModal
      allAvailableTimeSlots={allAvailableTimeSlots}
      blockedRelativeStartTimes={blockedRelativeStartTimes}
      blockedTimeSlotValues={blockedTimeSlotValues}
      cancelButtonText={formatMessage({
        defaultMessage: 'Cancel',
        description: 'Button to cancel creating or editing event time and date',
      })}
      confirmationButtonText={
        eventToEdit
          ? formatMessage({
              defaultMessage: 'Save',
              description: 'Button to save new event time and date',
            })
          : formatMessage({
              defaultMessage: 'Add',
              description: 'Button to add new event time and date',
            })
      }
      handleAnimationEnd={handleAnimationEnd}
      confirmButtonDisabled={!isValidDateSelection || !hasValidTimeSelections}
      dayDateSelector={
        <DatePickerContainer>
          <DateField
            error={
              isValidDateSelection
                ? ''
                : formatMessage({
                    defaultMessage: 'Date is in the past',
                    description:
                      'Error message for when provider tries to add availability for a date that is in the past',
                  })
            }
            label={formatMessage({
              defaultMessage: 'Enter a date',
              description: 'Label for field where providers select the date they are adding availability for',
            })}
            readOnly={false}
            maxDate={addDays(new Date(), 90)}
            isClearable={false}
            input={{
              name: 'date',
              onChange: onDateChange,
              onBlur: () => {},
              onFocus: () => {},
              value: selectedDate,
            }}
            appendFormat
            createInPortal={false}
            submitError={isValidDateSelection ? '' : 'Date is in the past'}
            locale={locale}
          />
        </DatePickerContainer>
      }
      eventToEdit={eventToEdit}
      onConfirmationButtonPress={confirmSelection}
      onRequestClose={onRequestClose}
      modalTitle={
        eventToEdit
          ? formatMessage({
              defaultMessage: 'Edit one-time availability',
              description: 'Title of modal to update an availabilty',
            })
          : formatMessage({
              defaultMessage: 'Add one-time availability',
              description: 'Title of modal to add an availabilty for this week to provider calendar',
            })
      }
      selectedStartTimes={selectedStartTimes}
      sessionDuration={sessionDuration}
      setSelectedStartTimes={setSelectedStartTimes}
      visible={showAddEventModal}
    />
  )
}
