import { BCC_ROLES, BCT_ROLES, ROLES, SUPERVISOR_ROLES } from './appConstants'

// ================================
// Auth Section
// ================================
export const LOGIN = {
  route: '/login',
  roles: Object.values(ROLES),
}
export const OKTACALLBACK = {
  route: '/okta/callback',
  roles: Object.values(ROLES),
}

// ================================
// Practices Section
// ================================
const practicesSectionAccess = [ROLES.PAYMENTS_ADMIN, ROLES.PROVIDERS_ADMIN, ROLES.VIEW_ONLY]
export const PRACTICES = {
  route: '/practices',
  roles: practicesSectionAccess,
}
export const PRACTICES_DETAILS = {
  route: `${PRACTICES.route}/details`,
  subroute: '/details',
  roles: practicesSectionAccess,
}
export const PRACTICES_DETAILS_INFO = {
  route: `${PRACTICES_DETAILS.route}/info`,
  subroute: '/info',
  roles: practicesSectionAccess,
}
export const PRACTICES_DETAILS_EDIT_INFO = {
  route: `${PRACTICES_DETAILS_INFO.route}/edit`,
  subroute: '/info/edit',
  roles: practicesSectionAccess,
}
export const PRACTICES_DETAILS_PROVIDERS = {
  route: `${PRACTICES_DETAILS.route}/providers`,
  subroute: '/providers',
  roles: practicesSectionAccess,
}
export const PRACTICES_DETAILS_NEW_PAYMENT = {
  route: `${PRACTICES_DETAILS.route}/new-payment`,
  subroute: '/new-payment',
  roles: practicesSectionAccess,
}

export const PRACTICES_DETAILS_HEALTHPLAN_PAYMENT_INFO = {
  route: `${PRACTICES_DETAILS.route}/health-plan-info`,
  subroute: '/health-plan-info',
  roles: practicesSectionAccess,
}

export const PRACTICES_DETAILS_PAYMENT_HISTORY = {
  route: `${PRACTICES_DETAILS.route}/payment-history`,
  subroute: '/payment-history',
  roles: practicesSectionAccess,
}
export const PRACTICES_REGISTER = {
  route: `${PRACTICES.route}/register`,
  subroute: '/register',
  roles: [ROLES.PRACTICES_ADMIN],
}

// ================================
// Providers Admin Section
// ================================
const providersSectionAccess = [ROLES.PAYMENTS_ADMIN, ROLES.PROVIDERS_ADMIN, ROLES.VIEW_ONLY]
export const PROVIDERS = {
  route: '/providers',
  roles: providersSectionAccess,
}
export const PROVIDERS_DETAILS = {
  route: `${PROVIDERS.route}/details`,
  subroute: '/details',
  roles: providersSectionAccess,
}
export const PROVIDERS_DETAILS_INFO = {
  route: `${PROVIDERS_DETAILS.route}/info`,
  subroute: '/info',
  roles: providersSectionAccess,
}
export const PROVIDERS_DETAILS_EDIT_INFO = {
  route: `${PROVIDERS_DETAILS_INFO.route}/edit`,
  subroute: '/edit',
  roles: providersSectionAccess,
}
export const PROVIDERS_DETAILS_RATES = {
  route: `${PROVIDERS_DETAILS.route}/rates`,
  subroute: '/rates',
  roles: providersSectionAccess,
}
export const PROVIDERS_DETAILS_EDIT_RATES = {
  route: `${PROVIDERS_DETAILS_RATES.route}/edit`,
  subroute: '/rates/edit',
  roles: providersSectionAccess,
}
export const PROVIDERS_DETAILS_CALENDAR = {
  route: `/python/calendar`,
  roles: [ROLES.PROVIDERS_ADMIN],
}

export const PROVIDERS_DETAILS_NEW_PAYMENT = {
  route: `${PROVIDERS_DETAILS.route}/new-payment`,
  subroute: '/new-payment',
  roles: providersSectionAccess,
}
export const PROVIDERS_DETAILS_PAYMENT_HISTORY = {
  route: `${PROVIDERS_DETAILS.route}/payment-history`,
  subroute: '/payment-history',
  roles: providersSectionAccess,
}
export const PROVIDERS_DETAILS_FREQUENCY_CONFLICT = {
  route: `${PROVIDERS_DETAILS.route}/frequency-conflict`,
  subroute: '/frequency-conflict',
  roles: providersSectionAccess,
}

export const PROVIDERS_DETAILS_HEALTHPLAN_PAYMENT_INFO = {
  route: `${PROVIDERS_DETAILS.route}/health-plan-info`,
  subroute: '/health-plan-info',
  roles: providersSectionAccess,
}

export const PROVIDERS_REGISTER = {
  route: `${PROVIDERS.route}/register`,
  subroute: '/register',
  roles: [ROLES.INDIVIDUAL_PROVIDER],
}

// ================================
// Payments Admin Section
// ================================
const paymentsSectionAccess = [ROLES.PAYMENTS_ADMIN, ROLES.VIEW_ONLY]
export const PAYMENTS = {
  route: '/payments',
  roles: paymentsSectionAccess,
}
export const CLIENT_SESSION_LOOKUP = {
  route: `/session-lookup`,
  roles: [ROLES.PAYMENTS_ADMIN, ROLES.VIEW_ONLY],
}
// ================================
// Reconciler Section
// ================================
const reconcilerSectionAccess = [ROLES.PAYMENTS_ADMIN, ROLES.RECONCILER]
export const RECONCILER = {
  route: '/reconciler',
  roles: reconcilerSectionAccess,
}
export const RECONCILER_DETAILS = {
  route: `${RECONCILER.route}/details`,
  subroute: '/details',
  roles: reconcilerSectionAccess,
}

// ================================
// Payment History Section
// ================================
const paymentHistorySectionAccess = [ROLES.INDIVIDUAL_PROVIDER, ROLES.PRACTICES_ADMIN]
export const PAYMENT_HISTORY = {
  route: '/payment-history',
  roles: paymentHistorySectionAccess,
  feature: 'requestPayment',
}
export const PAYMENT_HISTORY_ALL = {
  route: `${PAYMENT_HISTORY.route}/all`,
  subroute: '/all',
  roles: paymentHistorySectionAccess,
  feature: 'requestPayment',
}
export const PAYMENT_HISTORY_SUBMITTED = {
  route: `${PAYMENT_HISTORY.route}/submitted`,
  subroute: '/submitted',
  roles: paymentHistorySectionAccess,
  feature: 'requestPayment',
}
export const PAYMENT_HISTORY_APPROVED = {
  route: `${PAYMENT_HISTORY.route}/approved`,
  subroute: '/approved',
  roles: paymentHistorySectionAccess,
  feature: 'requestPayment',
}
export const PAYMENT_HISTORY_REJECTED = {
  route: `${PAYMENT_HISTORY.route}/rejected`,
  subroute: '/rejected',
  roles: paymentHistorySectionAccess,
  feature: 'requestPayment',
}
export const PAYMENT_HISTORY_CANCELLED = {
  route: `${PAYMENT_HISTORY.route}/cancelled`,
  subroute: '/cancelled',
  roles: paymentHistorySectionAccess,
  feature: 'requestPayment',
}

export const PAYMENT_HISTORY_REVERSED = {
  route: `${PAYMENT_HISTORY.route}/reversed`,
  subroute: '/reversed',
  roles: paymentHistorySectionAccess,
  feature: 'requestPayment',
}

// ================================
// My Info Section
// ================================
const myInfoSectionAccess = [ROLES.INDIVIDUAL_PROVIDER, ROLES.PRACTICE_PROVIDER, ROLES.PRACTICES_ADMIN]
export const MY_INFO = {
  route: '/my-info',
  roles: myInfoSectionAccess,
}

// ================================
// Clients Section
// ================================
const clientsSectionAccess = [ROLES.INDIVIDUAL_PROVIDER, ROLES.PRACTICE_PROVIDER, ...SUPERVISOR_ROLES]
export const CLIENTS = {
  route: '/clients',
  roles: clientsSectionAccess,
  feature: 'directAccessClientList',
}
export const CLIENTS_DETAILS = {
  route: `${CLIENTS.route}/details`,
  roles: clientsSectionAccess,
  feature: 'directAccessClientList',
}
export const LT_CLIENTS = {
  route: `${CLIENTS.route}/lt`,
  roles: clientsSectionAccess,
  feature: 'bcPlatform',
}
export const CLIENT_HOME = {
  route: `${LT_CLIENTS.route}/home`,
  roles: clientsSectionAccess,
  feature: 'bcPlatform',
}
export const CLIENT_PROFILE = {
  route: `${CLIENT_HOME.route}/profile`,
  roles: clientsSectionAccess,
  feature: 'bcPlatform',
}
export const CLIENT_PROGRESS = {
  route: `${CLIENT_HOME.route}/progress`,
  roles: clientsSectionAccess,
  feature: 'bcPlatform',
}
export const CLIENT_NOTES = {
  route: `${CLIENT_HOME.route}/notes`,
  roles: clientsSectionAccess,
  feature: 'bcPlatform',
}
export const CLIENT_NOTE = {
  route: `${CLIENT_NOTES.route}/details`,
  roles: clientsSectionAccess,
  feature: 'bcPlatform',
}
export const CLIENT_NEW_NOTE = {
  route: `${CLIENT_NOTES.route}/new`,
  roles: clientsSectionAccess,
  feature: 'bcPlatform',
}
export const CLIENT_MEDICATIONS = {
  route: `${CLIENT_HOME.route}/medications`,
  roles: clientsSectionAccess,
  feature: 'medicationEnabled',
}
export const CLIENTS_ASSIGNMENT_DETAILS = {
  route: `${CLIENT_HOME.route}/assignment`,
  roles: clientsSectionAccess,
  feature: 'bcPlatform',
}
export const CLIENTS_CONTENT_LIBRARY = {
  route: `${CLIENT_HOME.route}/library`,
  roles: clientsSectionAccess,
  feature: 'bcPlatform',
}
export const CLIENTS_TRACK_LIBRARY = {
  route: `${CLIENT_HOME.route}/tracks`,
  roles: clientsSectionAccess,
  feature: 'bcPlatform',
}
export const CLIENTS_NEW_ASSIGNMENT = {
  route: `${CLIENT_HOME.route}/assign`,
  roles: clientsSectionAccess,
  feature: 'bcPlatform',
}
export const CLIENTS_NEW_TRACK = {
  route: `${CLIENT_HOME.route}/track`,
  roles: clientsSectionAccess,
  feature: 'bcPlatform',
}
export const CLIENTS_SESSIONS = {
  route: `${CLIENT_HOME.route}/sessions`,
  roles: clientsSectionAccess,
  feature: 'bcPlatform',
}
export const CLIENT_END_OF_SESSION = {
  route: `${CLIENT_HOME.route}/close-session`,
  roles: clientsSectionAccess,
  feature: 'bcPlatform',
}
export const CLIENTS_NEW_SESSION = {
  route: `${CLIENTS_SESSIONS.route}/new`,
  roles: clientsSectionAccess,
  feature: 'bcPlatform',
}
export const CLIENTS_NEW_SESSION_DETAILS = {
  route: `${CLIENTS_NEW_SESSION.route}/details`,
  roles: clientsSectionAccess,
  feature: 'bcPlatform',
}
export const CLIENTS_RESCHEDULE_SESSION = {
  route: `${CLIENTS_SESSIONS.route}/reschedule`,
  roles: clientsSectionAccess,
  feature: 'bcPlatform',
}
export const CLIENTS_RESCHEDULE_SESSION_DETAILS = {
  route: `${CLIENTS_RESCHEDULE_SESSION.route}/details`,
  roles: clientsSectionAccess,
  feature: 'bcPlatform',
}
export const CLIENTS_NEW_SESSION_CONFIRMATION = {
  route: `${CLIENTS_NEW_SESSION.route}/confirm`,
  roles: clientsSectionAccess,
  feature: 'bcPlatform',
}
export const CLIENTS_RESCHEDULE_SESSION_CONFIRMATION = {
  route: `${CLIENTS_RESCHEDULE_SESSION.route}/confirm`,
  roles: clientsSectionAccess,
  feature: 'bcPlatform',
}

// ================================
// Scheduling Section
// ================================
export const SCHEDULING = {
  route: '/scheduling',
  roles: [ROLES.INDIVIDUAL_PROVIDER, ROLES.PRACTICE_PROVIDER, ROLES.PRACTICES_ADMIN],
}
export const SCHEDULING_ACTIVATE = {
  route: '/scheduling/activate',
  roles: [ROLES.INDIVIDUAL_PROVIDER, ROLES.PRACTICE_PROVIDER, ROLES.PRACTICES_ADMIN],
}

export const SCHEDULING_OAUTH_CALLBACK = {
  route: '/scheduling/oauthv2/oauth2callback',
  roles: [ROLES.INDIVIDUAL_PROVIDER, ROLES.PRACTICE_PROVIDER, ROLES.PRACTICES_ADMIN],
}

// ================================
// Other Pages
// ================================
export const REQUEST_PAYMENT = {
  route: '/request-payment',
  roles: [ROLES.INDIVIDUAL_PROVIDER, ROLES.PRACTICES_ADMIN],
  feature: 'requestPayment',
}
export const HEALTHPLAN_INFO = {
  route: '/health-plan-info',
  roles: [ROLES.INDIVIDUAL_PROVIDER, ROLES.PRACTICES_ADMIN],
  feature: 'requestPayment',
}
export const CALENDAR = {
  route: '/python/calendar',
  roles: [ROLES.INDIVIDUAL_PROVIDER, ROLES.PRACTICE_PROVIDER, ROLES.PRACTICES_ADMIN],
}
export const CLIENT_EMAIL = {
  route: '/client-email',
  roles: [ROLES.INDIVIDUAL_PROVIDER, ROLES.PRACTICES_ADMIN],
  feature: 'requestPayment',
}

export const OUTCOMES_CONSENT = {
  route: `${CLIENTS.route}/outcomes-consent`,
  roles: [ROLES.INDIVIDUAL_PROVIDER, ROLES.PRACTICE_PROVIDER],
}

export const BC_DASHBOARD = {
  route: '/dashboard',
  roles: clientsSectionAccess,
  feature: 'bcPlatform',
}

export const LC_CALENDAR = {
  route: '/lc-calendar',
  roles: clientsSectionAccess,
}

export const LC_CALENDAR_CURRENT = {
  route: `${LC_CALENDAR.route}/current`,
  subroute: 'current',
  roles: clientsSectionAccess,
}

export const LC_CALENDAR_CALLBACK = {
  route: `${LC_CALENDAR.route}/callback`,
  subroute: 'callback',
  roles: clientsSectionAccess,
}

export const LC_CALENDAR_SETUP = {
  route: `${LC_CALENDAR.route}/setup`,
  subroute: 'setup',
  roles: clientsSectionAccess,
}

export const LC_CALENDAR_EDIT = {
  route: `${LC_CALENDAR.route}/edit`,
  subroute: 'edit',
  roles: clientsSectionAccess,
}

export const LC_CALENDAR_EDIT_SELECT_CALENDARS = {
  route: `${LC_CALENDAR.route}/edit-select-calendars`,
  subroute: 'edit-select-calendars',
  roles: clientsSectionAccess,
}

export const LC_CALENDAR_ACTIVATE = {
  route: `${LC_CALENDAR.route}/activate`,
  subroute: 'activate',
  roles: clientsSectionAccess,
}

export const LC_CALENDAR_ACTIVATE_SETUP = {
  route: `${LC_CALENDAR_ACTIVATE.route}/setup`,
  subroute: 'activate/setup',
  roles: clientsSectionAccess,
}

export const LC_CALENDAR_SELECT_CALENDARS = {
  route: `${LC_CALENDAR_ACTIVATE.route}/select-calendars`,
  subroute: 'activate/select-calendars',
  roles: clientsSectionAccess,
}

export const BC_PERFORMANCE = {
  route: '/performance',
  roles: clientsSectionAccess,
  feature: 'bcPlatform',
}

export const BC_PERFORMACE_DETAILED_WEEKLY_VIEW = {
  route: `${BC_PERFORMANCE.route}/detailed-weekly-view`,
  subroute: '/detailed-weekly-view',
  roles: clientsSectionAccess,
  feature: 'bcPlatform',
}

export const BC_CASELOAD = {
  route: `${BC_PERFORMANCE.route}/new-caseload-dashboard`,
  subroute: '/new-caseload-dashboard',
  roles: BCC_ROLES,
  feature: 'bcPlatform',
}

export const BC_CASELOAD_DETAILED_WEEKLY_VIEW = {
  route: `${BC_PERFORMANCE.route}/new-caseload-dashboard/detailed-week`,
  subroute: `new-caseload-dashboard/detailed-week`,
  roles: BCC_ROLES,
  feature: 'bcPlatform',
}

export const BC_MEASUREMENT_BASED_CARE = {
  route: `${BC_PERFORMANCE.route}/measurement-based-care`,
  subroute: '/measurement-based-care',
  roles: clientsSectionAccess,
  feature: 'bcPlatform',
}

export const BC_PLATFORM_ENGAGEMENT = {
  route: `${BC_PERFORMANCE.route}/platform-engagement`,
  subroute: '/platform-engagement',
  roles: BCT_ROLES,
  feature: 'bcPlatform',
}

export const BC_PLATFORM_ENGAGEMENT_QUARTERLY_DETAILS = {
  route: `${BC_PLATFORM_ENGAGEMENT.route}/quarterly-details`,
  subroute: '/platform-engagement/quarterly-details',
  roles: BCT_ROLES,
  feature: 'bcPlatform',
}

export const ELIGIBILITY_CHECKER = {
  route: '/eligibility-checker',
  roles: [
    ROLES.INDIVIDUAL_PROVIDER,
    ROLES.PRACTICES_ADMIN,
    ROLES.PAYMENTS_ADMIN,
    ROLES.PROVIDERS_ADMIN,
    ROLES.PRACTICE_PROVIDER,
  ],
  feature: 'eligibilityChecker',
}

export const DOWNLOADS = {
  route: '/downloads',
  roles: [ROLES.PRACTICES_ADMIN, ROLES.INDIVIDUAL_PROVIDER],
}

export const SETTINGS = {
  route: '/settings',
  roles: clientsSectionAccess,
}

export const MESSENGER = {
  route: '/messages',
  roles: clientsSectionAccess,
  feature: 'bcPlatform',
}

export const MESSENGER_POPOUT = {
  route: `${MESSENGER.route}/popout`,
  roles: clientsSectionAccess,
  feature: 'bcPlatform',
}

// ================================
// Public routes Section
// ================================
export const CLIENT_OUTCOMES = {
  route: '/share-outcomes',
  roles: Object.values(ROLES),
}
