import React, { FunctionComponent, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { addMinutes, format, parse } from 'date-fns'
import { isEmpty } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { ClockIcon, IconButton, SelectField, TrashIcon } from '../../atoms'
import { ButtonModifier } from '../../atoms/baseButton/BaseButton'
import { ThemeType } from '../../utils'
import { tID } from '../../utils/utils'

export type ProviderCalendarTimeSlotSelectorProps = {
  index: number
  allOptions: { label: string; value: string }[]
  blockedRelativeStartTimes: number[]
  blockedValues: string[]
  showDeleteIcon: boolean
  selectedOption: string
  selectedOptionList: string[]
  setSelectedOptionList: (startTimes: string[]) => void
  singleTimeSelector: boolean
}

const TimeSlotSelectionContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['8px'],
  flexDirection: 'row',
  alignSelf: 'flex-start',
  width: '100%',
  gap: theme.spacing['16px'],
}))

const TimeSlotInputContainer = styled.View<{ singleTimeSelector: boolean }>(({ singleTimeSelector }) => ({
  flexDirection: 'column',
  justifyContent: 'center',
  ...(singleTimeSelector ? { width: '100%' } : { flexGrow: 1 }),
}))

const DeleteIconContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  padding: theme.spacing['8px'],
  width: '40px',
}))

export const ProviderCalendarTimeSlotSelector: FunctionComponent<ProviderCalendarTimeSlotSelectorProps> = ({
  allOptions,
  index,
  blockedRelativeStartTimes,
  blockedValues,
  showDeleteIcon,
  selectedOption,
  selectedOptionList,
  setSelectedOptionList,
  singleTimeSelector,
}) => {
  const { colors } = useTheme() as ThemeType
  const { formatMessage } = useIntl()

  const updateSelectedOption = (newValue: string, index: number) => {
    const startTimes = [...selectedOptionList]
    startTimes[index] = newValue
    setSelectedOptionList(startTimes)
    return
  }

  const possibleTimeSelections = useMemo(() => {
    const filteredTimeslots = isEmpty(selectedOption)
      ? allOptions.filter((timeSlot) => !blockedValues.includes(timeSlot.value))
      : allOptions.filter(
          (timeSlot) =>
            !blockedValues.includes(timeSlot.value) ||
            blockedRelativeStartTimes
              .map((blockedRelativeStartTime) =>
                format(addMinutes(parse(selectedOption, 'hh:mm a', new Date()), blockedRelativeStartTime), 'hh:mm a'),
              )
              .includes(timeSlot.value),
        )
    return filteredTimeslots
  }, [allOptions, blockedRelativeStartTimes, blockedValues, selectedOption])

  return (
    <TimeSlotSelectionContainer
      key={selectedOption}
      testID={tID(`ProviderCalendarSlotSelection-timeSlotContainer-${index}`)}
    >
      <TimeSlotInputContainer singleTimeSelector={singleTimeSelector}>
        <SelectField
          accessibilityLabel={formatMessage({
            defaultMessage: 'Time slot selector',
            description: 'Input to select a timeslot for the new availability event',
          })}
          value={selectedOption}
          name={`selectedStartTime-${index}`}
          onChange={(e: string) => updateSelectedOption(e, index)}
          icon={<ClockIcon size={24} fillColor={colors.iconDefault} />}
          options={possibleTimeSelections}
          baseInputStyle={{ marginBottom: 12 }}
        />
      </TimeSlotInputContainer>
      <DeleteIconContainer>
        {showDeleteIcon && (
          <IconButton
            leftIcon={<TrashIcon size={24} fillColor={colors.iconDefault} />}
            onPress={() => {
              const newStartTimes = [...selectedOptionList]
              newStartTimes.splice(index, 1)
              setSelectedOptionList(newStartTimes)
            }}
            modifier={ButtonModifier.UNSTYLED}
            accessibilityLabel={formatMessage({
              defaultMessage: 'Remove availability timeslot',
              description: 'Button to remove the availability timeslot',
            })}
            textAlign='center'
            testID={tID(`ProviderCalendarSlotSelection-removeTimeslot-${index}`)}
          />
        )}
      </DeleteIconContainer>
    </TimeSlotSelectionContainer>
  )
}
