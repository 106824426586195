import { createSelector } from 'reselect'

import { getLyraTherapyState } from '../../../data/lyraTherapy/clientSelectors'

export const getMessenger = createSelector(getLyraTherapyState, (lyraTherapyState) => lyraTherapyState?.messenger)

export const getSelectedConversationId = createSelector(getMessenger, (messenger) => messenger?.selectedConversationId)

export const getMessengerDrafts = createSelector(getMessenger, (messenger) => messenger?.drafts)

export const getConversationsClient = createSelector(getMessenger, (messenger) => messenger?.conversationsClient)

export const inLiveMsgSession = createSelector(getMessenger, (messenger) => messenger?.inLiveMsgSession)

export const getMessagesActiveLiveMsgAppt = createSelector(getMessenger, (messenger) => messenger?.activeLiveMsgAppt)

export const getMessagesLiveMsgClientIdToOpen = createSelector(
  getMessenger,
  (messenger) => messenger?.liveMsgClientIdToOpen,
)
