import React, { FunctionComponent, useEffect } from 'react'
import CSSModules from 'react-css-modules'

import { CustomerInfo, useFlags } from '@lyrahealth-inc/shared-app-logic'

import styles from './clientEmail.module.scss'
import { STSTypes } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { sessionTracking } from '../../common/constants/appConstants'

type Props = {
  initialSession?: boolean
  sessionsLeft?: number
  firstName?: string
  lastName?: string
  customer?: CustomerInfo
}

const ThankYou: FunctionComponent<Props> = ({ initialSession, sessionsLeft, firstName, lastName, customer }) => {
  const { isProgramLevelSessionLimitEnabled } = useFlags()

  useEffect(() => {
    if (initialSession) {
      track({ event: 'STS', properties: { type: (STSTypes as any).STS_INITIAL } })
    }
  })

  return (
    <span>
      <h1 styleName='title'>Thank you!</h1>
      {customer?.display_visits_per_issue_per_year ? (
        <div>
          <p styleName='success'>
            Your payment was successfully submitted. As a reminder, your client,{' '}
            <span styleName='capitalize'>{firstName}</span> <span styleName='capitalize'>{lastName}</span> has session
            costs covered for a set number of sessions as part of their Lyra benefit
          </p>
          <p styleName='remaining-sessions'>
            Please note that <span styleName='capitalize'>{firstName}</span> has{' '}
            {customer?.display_visits_per_issue_per_year} sessions per issue per year. Issues determined by you and your
            client.
          </p>
          <p className='paragraph-small'>
            For any questions, please contact <a href='mailto:providers@lyrahealth.com'>providers@lyrahealth.com</a>
          </p>
        </div>
      ) : initialSession ? (
        <div>
          <p styleName='success'>
            Your payment was successfully submitted. As a reminder, your client,{' '}
            <span styleName='capitalize'>{firstName}</span> <span styleName='capitalize'>{lastName}</span> has session
            costs covered for a set number of sessions as part of their Lyra benefit
          </p>
          <p styleName='remaining-sessions'>
            Please note that <span styleName='capitalize'>{firstName}</span> has {sessionsLeft}{' '}
            {isProgramLevelSessionLimitEnabled && 'therapy '}sessions remaining.
          </p>
          <p className='paragraph-small'>
            We’ll let you both know when there are only {sessionTracking.WARNING_THRESHOLD}{' '}
            {isProgramLevelSessionLimitEnabled && customer?.sessions_limit_by_program && 'therapy '}sessions left so you
            and {firstName} can start planning to complete treatment or discussing longer term care. For any questions,
            please contact <a href='mailto:providers@lyrahealth.com'>providers@lyrahealth.com</a>
          </p>
        </div>
      ) : (
        <h2 styleName='success'>Your payment was successfully submitted.</h2>
      )}
    </span>
  )
}

export default CSSModules(ThankYou, styles)
