import React from 'react'

import styled, { useTheme } from 'styled-components/native'

import { AIDraftStatus, aiSummaryStatusMessages } from '@lyrahealth-inc/shared-app-logic'
import { BodyText, SparklesIcon, ThemeType } from '@lyrahealth-inc/ui-core-crossplatform'

interface AssignmentAISummaryStatusProps {
  status: AIDraftStatus
}

const TopAIStatusMessage = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginLeft: spacing['16px'],
  marginTop: spacing['8px'],
  width: '600px',
}))

const TopAIStatusMessageIcon = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginRight: theme.spacing['4px'],
}))

const AssignmentAISummaryStatus: React.FC<AssignmentAISummaryStatusProps> = ({ status }) => {
  const { colors } = useTheme()
  return (
    <TopAIStatusMessage>
      {status === AIDraftStatus.COMPLETE && (
        <TopAIStatusMessageIcon>
          <SparklesIcon size={16} color={colors.textSuccess} />
        </TopAIStatusMessageIcon>
      )}
      <BodyText
        text={aiSummaryStatusMessages[status]}
        color={
          status === AIDraftStatus.IN_PROGRESS
            ? colors.components.avatar.goldTheme.text
            : status === AIDraftStatus.COMPLETE
            ? colors.textSuccess
            : colors.backgroundDarkOverlay
        }
      />
    </TopAIStatusMessage>
  )
}

export default AssignmentAISummaryStatus
