export enum ConversationFilter {
  UNREAD = 'unread',
  ACTIVE = 'active',
  UNSCHEDULED = 'unscheduled',
}

export enum ConversationTag {
  INACTIVE = 'inactive',
  UNSCHEDULED = 'unscheduled',
}

export type Conversation = {
  clientId: string
  clientName: string
  lastMessageTime: Date | null
  hasUnreadMessage: boolean
  tag?: ConversationTag
}
