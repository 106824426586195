import React from 'react'
import { FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { BodyText } from '../../atoms'
import { BodyTextSize } from '../../styles'

const Container = styled.View(({ theme }) => ({
  padding: theme.spacing['24px'],
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
}))

type ConversationListEmptyProps = {
  hasFilters: boolean
}

export const ConversationListEmpty: React.FC<ConversationListEmptyProps> = ({ hasFilters }) => {
  const { colors } = useTheme()
  return (
    <Container>
      <BodyText
        size={BodyTextSize.LARGE}
        color={colors.textSecondary}
        textAlign='center'
        text={
          hasFilters ? (
            <FormattedMessage
              defaultMessage='No matching conversations, remove filters to see more.'
              description='No conversations found for selected filters'
            />
          ) : (
            <FormattedMessage
              defaultMessage='No conversations available.'
              description='No conversations found for provider'
            />
          )
        }
      />
    </Container>
  )
}
