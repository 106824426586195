import React from 'react'
import { useIntl } from 'react-intl'

import styled from 'styled-components/native'

import {
  createRemainingSessionCountByProgram,
  isProgramAvailableForMinor,
  isUnlimitedSessionLimit,
  ProgramCategory,
  SessionCountByProgram,
  SessionLimitByProgram,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'
import { BodyText, colors, ColumnView } from '@lyrahealth-inc/ui-core-crossplatform'

import { programString, ProgramStringKey } from '../../constants/appConstants'

type ClientSessionLimitInfoProps = {
  program?: ProgramStringKey
  displayVisitsPerIssuePerYear?: number
  usedSessionsByProgram?: SessionCountByProgram
  EAPSessionLimitByProgram?: SessionLimitByProgram
  sessionLimit?: number
  sessionUsed?: number
  benefitResetDate?: string
  isMinor?: boolean
  hideProgram?: ProgramCategory
  warningThreshold?: number
  bold?: boolean
  textColor?: string
  testID?: string
}

const ProgramLevelSessionLimitTextContainer = styled(ColumnView)({
  marginRight: '8px',
})

export const ClientSessionLimitInfo: React.FC<ClientSessionLimitInfoProps> = ({
  program = 'eap',
  sessionUsed,
  sessionLimit,
  displayVisitsPerIssuePerYear,
  usedSessionsByProgram,
  EAPSessionLimitByProgram,
  benefitResetDate,
  isMinor,
  bold = false,
  textColor,
  hideProgram,
  warningThreshold,
  testID = 'ClientSessionLimitInfo',
}) => {
  const { formatDate } = useIntl()
  const { isProgramLevelSessionLimitEnabled } = useFlags()
  let sessionCountNode: React.ReactElement | null = null
  let formattedResetDate: string | undefined = undefined
  if (benefitResetDate) {
    formattedResetDate = formatDate(benefitResetDate, { month: 'long', day: 'numeric' })
  }
  if (displayVisitsPerIssuePerYear) {
    sessionCountNode = (
      <BodyText
        text={`${displayVisitsPerIssuePerYear} visits per issue per year. Issues determined between you and your client.`}
        bold={bold}
        testID={testID}
        color={textColor}
      />
    )
  } else if (
    isProgramLevelSessionLimitEnabled &&
    EAPSessionLimitByProgram &&
    program === programString.eap.toLowerCase()
  ) {
    const remainingSessionsByProgram = createRemainingSessionCountByProgram(
      EAPSessionLimitByProgram,
      usedSessionsByProgram,
    )
    sessionCountNode = (
      <ProgramLevelSessionLimitTextContainer testID={testID}>
        {Object.keys(remainingSessionsByProgram).map((programCategory: ProgramCategory, index: number) => {
          if (isMinor && !isProgramAvailableForMinor(programCategory)) return
          if (hideProgram && hideProgram === programCategory) return
          let sessionUsedText = ''
          if (isUnlimitedSessionLimit(EAPSessionLimitByProgram[programCategory])) {
            sessionUsedText = `Unlimited ${programCategory} sessions.`
          } else {
            sessionUsedText = `${usedSessionsByProgram?.[programCategory] ?? 0} of ${
              EAPSessionLimitByProgram[programCategory]
            } `
            if (formattedResetDate) {
              sessionUsedText += `${programCategory} sessions used. (${
                remainingSessionsByProgram?.[programCategory] ?? 0
              } remaining, benefit resets on ${formattedResetDate})`
            } else if (program === programString.eap.toLowerCase()) {
              sessionUsedText += `${programString.eap} ${programCategory} sessions used.`
            } else {
              sessionUsedText += 'sessions used.'
            }
            if (
              warningThreshold &&
              remainingSessionsByProgram &&
              remainingSessionsByProgram.hasOwnProperty(programCategory) &&
              remainingSessionsByProgram[programCategory]! < warningThreshold
            ) {
              textColor = colors.red4
            }
          }
          return <BodyText key={index} text={sessionUsedText} bold={bold} color={textColor} />
        })}
      </ProgramLevelSessionLimitTextContainer>
    )
  } else if (sessionLimit !== undefined && sessionUsed !== undefined) {
    const sessionRemaining = sessionLimit - sessionUsed
    let sessionUsedText = `${sessionUsed} of ${sessionLimit} `
    if (formattedResetDate) {
      sessionUsedText += `sessions used. (${sessionRemaining} remaining, benefit resets on ${formattedResetDate})`
    } else if (program === programString.eap.toLowerCase()) {
      sessionUsedText += `${programString.eap} sessions used.`
    } else {
      sessionUsedText += 'sessions used.'
    }
    if (warningThreshold && typeof sessionRemaining === 'number' && sessionRemaining < warningThreshold) {
      textColor = colors.red4
    }
    sessionCountNode = <BodyText text={sessionUsedText} bold={bold} testID={testID} color={textColor} />
  }

  return sessionCountNode
}
