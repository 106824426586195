import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { bindActionCreators } from 'redux'

import { useFlags } from '@lyrahealth-inc/shared-app-logic'

import BaseMessenger from './BaseMessenger'
import { CLIENT_HOME } from '../../../common/constants/routingConstants'
import {
  clearSelectedClientData,
  clearSelectedClientStore,
  getClientId,
  getLtClient,
} from '../../clients/clientDetails/data/ltClientDetailsAutoActions'
import { getClientEpisodes } from '../../episodes/data/episodesAutoActions'
import { REL_ID_PARAM } from '../hooks/useChatPopout'
import useGetClientId from '../hooks/useGetClientId'
import useGetRelId from '../hooks/useGetRelId'

type PopoutMessengerProps = ConnectedProps<typeof connector>

const PopoutMessenger: React.FC<PopoutMessengerProps> = ({ actions: { getClientId } }) => {
  const { isMessagingCenterEnabled } = useFlags()
  const [selectedClientId, setSelectedClientId] = useState<string | null>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const relId = searchParams.get(REL_ID_PARAM)
  const hasSetInitialClientId = useRef(!relId)

  const [isComposing, setIsComposing] = useState(!relId)

  const getClientIdFromRelId = useGetClientId(
    getClientId as unknown as ({ relationshipId }: { relationshipId: string }) => Promise<{ patient_id: string }>,
  )
  const getRelIdFromClientId = useGetRelId()
  useEffect(() => {
    const handleRelID = async () => {
      if (relId) {
        const clientId = await getClientIdFromRelId(relId)
        if (!clientId) {
          return
        }
        hasSetInitialClientId.current = true
        setSelectedClientId(clientId)
      }
    }

    handleRelID()
  }, [getClientId, getClientIdFromRelId, relId, setSelectedClientId])

  useEffect(() => {
    if (!hasSetInitialClientId.current) {
      return
    }
    if (!selectedClientId) {
      setSearchParams(new URLSearchParams())
      return
    }
    const patientRelId = getRelIdFromClientId(selectedClientId)
    if (!patientRelId) {
      return
    }
    setSearchParams(new URLSearchParams({ [REL_ID_PARAM]: patientRelId }))
  }, [getRelIdFromClientId, selectedClientId, setSearchParams])

  const onOpenClientProfile = useCallback(() => {
    if (!relId) {
      return
    }
    const url = new URL(`${window.location.origin}${CLIENT_HOME.route}`)
    url.searchParams.set(REL_ID_PARAM, relId)
    window.open(url, '_blank')
  }, [relId])

  if (!isMessagingCenterEnabled) {
    return null
  }
  const isLoadingClientId = relId != null && selectedClientId == null
  return (
    <BaseMessenger
      selectedClientId={selectedClientId}
      isComposing={isComposing}
      onSelectedClientIdChanged={setSelectedClientId}
      showEmptyView={false}
      onIsComposingChanged={setIsComposing}
      onOpenClientProfile={onOpenClientProfile}
      initialDetailPanelOpen={false}
      isLoadingClient={isLoadingClientId}
    />
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      getLtClient,
      getClientEpisodes,
      clearSelectedClientData,
      clearSelectedClientStore,
      getClientId,
    },
    dispatch,
  ),
})

const connector = connect(null, mapDispatchToProps)
export default connector(PopoutMessenger)
