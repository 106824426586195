import { useCallback, useState } from 'react'

import useGetRelId from './useGetRelId'
import { MESSENGER } from '../../../common/constants/routingConstants'

export const REL_ID_PARAM = 'relId'

const POPOUT_SIZE = 800

const useChatPopout = (selectedClientId: string | null) => {
  const [windowPopouts, setWindowPopouts] = useState<Window[]>([])
  const getRelIdFromClientId = useGetRelId()

  const findWindow = useCallback(
    (patientRelId: string | null) => {
      return windowPopouts.find((popout) => {
        const searchParams = new URLSearchParams(popout.location.search)
        const relId = searchParams.get(REL_ID_PARAM)
        return relId === patientRelId && !popout.closed
      })
    },
    [windowPopouts],
  )

  const onOpenNewWindow = useCallback(() => {
    const patientRelId = getRelIdFromClientId(selectedClientId)
    const url = new URL(`${window.location.origin}${MESSENGER.route}/popout`)
    if (patientRelId) {
      url.searchParams.set(REL_ID_PARAM, patientRelId)
    }

    const existingWindow = findWindow(patientRelId)
    if (existingWindow) {
      existingWindow.focus()
      return
    }
    const newWindow = window.open(url.toString(), '_blank', `width=${POPOUT_SIZE},height=${POPOUT_SIZE}`)
    if (newWindow) {
      setWindowPopouts((prev) => [...prev, newWindow])
    }
  }, [findWindow, getRelIdFromClientId, selectedClientId])

  return { onOpenNewWindow }
}

export default useChatPopout
