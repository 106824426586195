import { useCallback, useRef } from 'react'

const useGetClientId = (
  getClientId: ({ relationshipId }: { relationshipId: string }) => Promise<{ patient_id: string }>,
) => {
  const cache = useRef<Record<string, string>>({})
  return useCallback(
    async (relId: string) => {
      if (cache.current[relId]) {
        return cache.current[relId]
      }
      const res = await getClientId({ relationshipId: relId })
      cache.current[relId] = res.patient_id
      return res.patient_id
    },
    [getClientId],
  )
}

export default useGetClientId
