import React, { FunctionComponent, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { getDay, parseISO } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { sortBy, uniq } from 'lodash-es'
import { bindActionCreators } from 'redux'
import styled from 'styled-components/native'

import { useFlags } from '@lyrahealth-inc/shared-app-logic'
import {
  LoadingIndicator,
  ProviderBookableCalendarSettings,
  ProviderHybridCalendarSettings,
  Subhead,
  SubheadSize,
  useFetcher,
} from '@lyrahealth-inc/ui-core-crossplatform'

import './providerBookableCalendar.scss'
import { actions as mixpanelActions, mixpanelEvents } from '../../../mixpanel/mixpanelConstants'
import { track } from '../../../mixpanel/mixpanelTracking'
import CalendarSetupCard from '../calendar/CalendarSetupCard'
import {
  getCalendarAvailabilitySlots,
  getCalendarProvider,
  getCalendars,
  getCalendarToken,
} from '../calendar/data/calendarActions'
import {
  getCalendarAvailabilitySlots as getCalendarAvailabilitySlotsSelector,
  getCalendarProvider as getCalendarProviderSelector,
  getCalendarRequiresAuthorization,
} from '../calendar/data/calendarSelectors'
import { useProviderCalendarLive } from '../calendar/hooks/useProviderCalendarLive'
import { getBookableMaxSlots, getBookableTarget } from '../calendar/utils'
import { LC_CALENDAR_EDIT, LC_CALENDAR_EDIT_SELECT_CALENDARS } from '../common/constants/routingConstants'
import { getAuthConfig, getAuthEmploymentStatus, getAuthUserId } from '../data/auth/authSelectors'

const Container = styled.View({
  width: '100%',
})

const Header = styled.View(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  padding: theme.spacing['24px'],
  borderBottomWidth: '1px',
  borderBottomColor: theme.colors.borderDefault,
}))

const ContentContainer = styled.View(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  paddingTop: theme.spacing['24px'],
}))

const CalendarContainer = styled.View(({ theme }) => ({
  flexDirection: 'column',
  maxWidth: '944px',
  width: '100%',
  gap: theme.spacing['24px'],
}))

const CalendarSettingsTitle = styled(Subhead)({
  alignSelf: 'flex-start',
})

const Settings: FunctionComponent<SettingsProps> = ({
  actions: { getCalendarAvailabilitySlots, getCalendars, getCalendarToken, getCalendarProvider },
}) => {
  const { isInProductCalendarEnabled } = useFlags()
  const availabilitySlots = useSelector(getCalendarAvailabilitySlotsSelector)
  const employmentStatus = useSelector(getAuthEmploymentStatus)
  const userId = useSelector(getAuthUserId)
  const config = useSelector(getAuthConfig)
  const intl = useIntl()
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'America/Los_Angeles'
  const requiresAuth = useSelector(getCalendarRequiresAuthorization)
  const calendarProvider = useSelector(getCalendarProviderSelector)
  const navigate = useNavigate()
  const providerCalendarLive = useProviderCalendarLive(config, calendarProvider)

  const [loading] = useFetcher(
    [
      [getCalendars, { providerId: userId }, !!userId],
      [getCalendarAvailabilitySlots, { providerId: userId }, !!userId],
      [getCalendarToken, { providerId: userId }, !!userId],
      [getCalendarProvider, { providerId: userId }, !!userId],
    ],
    [userId],
  )

  const initialEvents = useMemo(() => {
    return availabilitySlots?.map((slot) => ({
      startTime: slot.start_datetimetz,
      endTime: slot.end_datetimetz,
    }))
  }, [availabilitySlots])

  const availabilityText = useMemo(() => {
    const sortedByDay = sortBy(initialEvents, (event) => {
      const dateInZone = utcToZonedTime(event.startTime, timeZone)
      return getDay(dateInZone)
    })
    return intl.formatList(
      uniq(
        sortedByDay.map((event) => {
          const date = parseISO(event.startTime)
          return intl.formatDate(date, { weekday: 'long', timeZone })
        }),
      ),
    )
  }, [initialEvents, intl, timeZone])

  const target = getBookableTarget(employmentStatus)
  const maxSlots = getBookableMaxSlots(employmentStatus)
  if (!isInProductCalendarEnabled) {
    return null
  }

  if (initialEvents == null || loading) {
    return <LoadingIndicator />
  }

  return (
    <Container>
      <Header>
        <Subhead
          size={SubheadSize.LARGE}
          text={<FormattedMessage defaultMessage='Settings' description='Header for settings' />}
        />
      </Header>
      <ContentContainer>
        <CalendarContainer>
          <CalendarSettingsTitle
            size={SubheadSize.MEDIUM}
            text={
              config?.inProductCalendar?.hybridBookingMode ? (
                <FormattedMessage defaultMessage='Lyra calendar' description='Header for Lyra calendar settings' />
              ) : (
                <FormattedMessage defaultMessage='Calendar settings' description='Header for calendar settings' />
              )
            }
          />
          {requiresAuth || !providerCalendarLive ? (
            <CalendarSetupCard />
          ) : (
            <div className='lc-bookable-calendar'>
              {config?.inProductCalendar?.hybridBookingMode ? (
                <ProviderHybridCalendarSettings
                  availabilityText={availabilityText}
                  onAvailabilityEditPressed={() => {
                    // TODO: Add in Mixpanel event: DACN-1336
                    navigate(LC_CALENDAR_EDIT.route)
                  }}
                  subCalendarSelection={config?.inProductCalendar?.subCalendarSelection}
                  onSubCalendarEditPressed={() => {
                    // TODO: Add in Mixpanel event: DACN-1336
                    navigate(LC_CALENDAR_EDIT_SELECT_CALENDARS.route)
                  }}
                  onOpenHelpCenterPressed={() => {
                    // TODO: Add in Mixpanel event: DACN-1336
                    window.open(
                      'https://provider-support.lyrahealth.com/hc/en-us/requests/new?ticket_form_id=26162178922387',
                      '_blank',
                    )
                  }}
                />
              ) : (
                <ProviderBookableCalendarSettings
                  availabilityText={availabilityText}
                  events={initialEvents}
                  onEditPressed={() => {
                    track({ event: mixpanelEvents.BUTTON_PRESS, action: mixpanelActions.CALENDAR_TEMPLATE_EDIT })
                    navigate(LC_CALENDAR_EDIT.route)
                  }}
                  bookableTarget={target}
                  bookableMax={maxSlots}
                />
              )}
            </div>
          )}
        </CalendarContainer>
      </ContentContainer>
    </Container>
  )
}

export type SettingsProps = {
  actions: {
    getCalendarAvailabilitySlots: (params: Parameters<typeof getCalendarAvailabilitySlots>[0]) => Promise<any>
    getCalendars: (params: Parameters<typeof getCalendars>[0]) => Promise<any>
    getCalendarToken: (params: Parameters<typeof getCalendarToken>[0]) => Promise<any>
    getCalendarProvider: (params: Parameters<typeof getCalendarProvider>[0]) => Promise<any>
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      getCalendarAvailabilitySlots: getCalendarAvailabilitySlots as any,
      getCalendars: getCalendars as any,
      getCalendarToken: getCalendarToken as any,
      getCalendarProvider: getCalendarProvider as any,
    },
    dispatch,
  ),
})

const connector = connect(null, mapDispatchToProps)

export default connector(Settings)
