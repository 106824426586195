import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { View } from 'react-native'
import { connect, useSelector } from 'react-redux'

import { CancelToken } from 'axios'
import { isEmpty } from 'lodash-es'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import styled from 'styled-components/native'

import {
  Assignment,
  ClientObject,
  Episode,
  EpisodeStates,
  ProgramNames,
  ProviderAdminProviderInfo,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'
import {
  BodyText,
  ButtonType,
  ChartIcon,
  CloseIcon,
  colors,
  DropdownButton,
  DropdownMenuItem,
  GearIcon,
  HeaderText,
  LoadingIndicator,
  Modal,
  PressableOpacity,
  SecondaryButton,
  SubheadSize,
  ThemeType,
  tID,
  useFetcher,
} from '@lyrahealth-inc/ui-core-crossplatform'

import ClientOutcomes from '../../clients/individualClient/ClientOutcomes'
import { programs, ROLES } from '../../common/constants/appConstants'
import { getEpisodeDropdownItems, getEpisodeDropdownText, hasRole } from '../../common/utils/utils'
import { getAuthIsLTSupervisor } from '../../data/auth/authSelectors'
import {
  getClientAssignmentsData,
  getClientDetailsData,
  getClientSelectedEpisode,
} from '../../data/lyraTherapy/clientSelectors'
import { RootState } from '../../data/store'
import { getProviderDetailsData } from '../../providers/data/providerSelectors'
import AssessmentTrackSettingsModal from '../clients/AssessmentTrackSettingsModal'
import { getAllClientEpisodes, setSelectedEpisode } from '../episodes/data/episodesAutoActions'

export type ClientProgressProps = {
  clientDetails?: ClientObject
  providerDetailsData: ProviderAdminProviderInfo
  assignments: Assignment[]
  currentEpisode: Episode
  actions: {
    getAllClientEpisodes: ({ clientId }: { clientId?: string; cancelToken?: CancelToken }) => Promise<object>
    setSelectedEpisode: ({ episode }: { episode: Episode }) => void
  }
}

const OuterContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  width: '100%',
  padding: `0 ${spacing['16px']}`,
}))

const Row = styled.View({
  flexDirection: 'row',
  justifyContent: 'center',
})

const Column = styled.View({
  width: '100%',
  maxWidth: '750px',
  alignSelf: 'center',
})

const TopDropdownContainer = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex: 10,
})

const LoadingContainer = styled.View({
  display: 'flex',
  justifyContent: 'center',
})

const HeaderTextContainer = styled.View({
  margin: '20px 0 10px',
})

const AligningContainer = styled.View({
  flexDirection: 'row',
  margin: `60px 0 0`,
})

const NoDataContainer = styled.View({
  background: colors.white,
  border: `1px solid ${colors.charcoal2}`,
  borderRadius: '12px',
  padding: '15px',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '50px',
  clear: 'both',
})

const ClientOutcomesContainer = styled.View({
  marginTop: '30px',
})

const TextContainer = styled.View({
  textAlign: 'center',
  maxWidth: '300px',
})

const EpisodeSelectorTextContainer = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: `${theme.spacing['24px']}`,
  marginBottom: '10px',
  display: 'block',
}))

const CloseIconContainer = styled(PressableOpacity)({
  alignSelf: 'flex-end',
})

const EpisodeSelectorContainer = styled.View({
  flex: '1',
  alignItems: 'flex-start',
})

const AssessmentTrackSettingsContainer = styled.View({
  flex: '1',
  alignItems: 'flex-end',
})

const ClientProgress: React.FC<ClientProgressProps> = ({
  clientDetails,
  providerDetailsData,
  assignments,
  actions: { getAllClientEpisodes, setSelectedEpisode },
}) => {
  const { formatMessage } = useIntl()
  const isLtSupervisor: boolean = useSelector(getAuthIsLTSupervisor)
  const [isAssessmentTrackModalOpen, setAssessmentTrackModalOpen] = useState(false)
  const [, episodes = [], hasFetchedEpisodes] = useFetcher([getAllClientEpisodes, { clientId: clientDetails?.id }, []])
  const [filteredEpisodes, setFilteredEpisodes] = useState<Episode[]>([])
  const clientEpisode = useSelector(getClientSelectedEpisode)
  const [selectedProgressEpisode, setSelectedProgressEpisode] = useState<{ episode?: Episode }>({
    episode: clientEpisode,
  })

  const isProviderCoach = hasRole(providerDetailsData?.roles, [ROLES.LT_COACH, ROLES.LT_SS_COACH])
  const isProviderLTCoach = hasRole(providerDetailsData?.roles, [ROLES.LT_COACH])
  const isProviderCFPCoach = hasRole(providerDetailsData?.roles, [ROLES.LT_CFP_COACH])
  const { isCFPEnabled, isSudsExpansionEnabled } = useFlags()
  const isProviderAbleToSeeSharedOutcomes = hasRole(providerDetailsData?.roles, [
    ROLES.LT_CLINICAL_LEAVE_EVALUATOR,
    ROLES.LT_PRESCRIBER,
    ROLES.LT_PRESCRIBER_SUPERVISOR,
    ROLES.LT_THERAPIST,
    ROLES.LT_SUPERVISOR,
    ROLES.LT_FELLOW_THERAPIST,
    ROLES.LT_FELLOW_SUPERVISOR,
  ])

  const isSelectedEpisodeAUD = selectedProgressEpisode?.episode?.program_name === ProgramNames.AlcoholUseDisorderTherapy

  const outcomes =
    ((isSelectedEpisodeAUD && !isProviderCoach) || isProviderAbleToSeeSharedOutcomes) &&
    selectedProgressEpisode?.episode?.state === EpisodeStates.IN_PROGRESS &&
    clientDetails?.outcomes
      ? clientDetails.outcomes.filter(
          (outcome) =>
            outcome.response_date &&
            outcome.program_name &&
            outcome.episode_id === selectedProgressEpisode?.episode?.id &&
            ![programs.Coaching, programs.SingleSessionCoaching].includes(outcome.program_name),
        )
      : clientDetails?.outcomes &&
        clientDetails.outcomes.filter(
          (outcome) => outcome.response_date && outcome.episode_id === selectedProgressEpisode?.episode?.id,
        )

  const assignmentIdsFromOutcomes = outcomes?.map((outcome) => outcome.assignment_id)
  const assignmentsWithSelfReflection = assignments.filter(
    (assignment) =>
      assignmentIdsFromOutcomes?.includes(assignment.id) &&
      assignment?.assignment_responses?.[0]?.response?.self_reflection,
  )

  // align local state with the redux state
  useEffect(() => {
    setSelectedProgressEpisode({ episode: clientEpisode })
  }, [clientEpisode])

  useEffect(() => {
    const isCoaching = hasRole(providerDetailsData?.roles, [ROLES.LT_COACH, ROLES.LT_SS_COACH])
    const filteredEpisodes = episodes.filter((episode: Episode) => {
      if (isCoaching) {
        return (
          episode.program_name === ProgramNames.Coaching || episode.program_name === ProgramNames.SingleSessionCoaching
        )
      } else {
        return (
          episode.program_name !== ProgramNames.Coaching && episode.program_name !== ProgramNames.SingleSessionCoaching
        )
      }
    })
    if (!isEmpty(filteredEpisodes)) {
      setFilteredEpisodes(filteredEpisodes as Episode[])
    }
  }, [episodes, providerDetailsData?.roles])

  if (!hasFetchedEpisodes) {
    return (
      <LoadingContainer>
        <LoadingIndicator size={45} />
      </LoadingContainer>
    )
  }

  const setEpisode = (selectedEpisode: { episode: Episode }) => {
    // Update redux and local state
    // redux
    setSelectedEpisode({ episode: selectedEpisode.episode })
    // local
    setSelectedProgressEpisode({ episode: selectedEpisode.episode })
  }
  // check to see if the selected episode is already in the filteredEpisodes
  const selectedEpisodeInFilteredEpisode = filteredEpisodes.some((ep) => ep.id === selectedProgressEpisode?.episode?.id)

  // combine the selected episode with filtered episodes if it's not yet included
  const combinedEpisodes = selectedEpisodeInFilteredEpisode
    ? filteredEpisodes
    : [selectedProgressEpisode.episode, ...filteredEpisodes]

  const dropdownItems = getEpisodeDropdownItems(combinedEpisodes, setEpisode) as DropdownMenuItem[]

  return (
    <OuterContainer>
      <Row>
        <Column>
          <TopDropdownContainer>
            {filteredEpisodes.length > 1 && selectedProgressEpisode?.episode && (
              <EpisodeSelectorContainer>
                <EpisodeSelectorTextContainer
                  text={formatMessage({ defaultMessage: 'Episode', description: 'Episode heading label' })}
                />
                <DropdownButton
                  testID={tID('Assignments-select-episode')}
                  dropdownItems={dropdownItems}
                  dropdownWidth='350px'
                  buttonType={ButtonType.PRIMARY}
                  customTextColor={colors.white}
                  preselectedValue={getEpisodeDropdownText(selectedProgressEpisode.episode)}
                />
              </EpisodeSelectorContainer>
            )}
            {/*
              Show the Assessment Track selector if:
                - They are a supervisor, OR:
                - The currently selected episode IS an AUD program AND SudsExpansion flag is enabled, OR
                - They are an LT CFP coach AND have the isCFPEnabled flag enabled
            */}
            {(isLtSupervisor ||
              (isSelectedEpisodeAUD && isSudsExpansionEnabled) ||
              (isProviderCFPCoach && isCFPEnabled)) &&
              selectedProgressEpisode?.episode && (
                <AssessmentTrackSettingsContainer>
                  <AligningContainer>
                    <SecondaryButton
                      testID={tID('ClientProgress-assessmentTrackButton-update')}
                      onPress={() => setAssessmentTrackModalOpen(true)}
                      leftIcon={<GearIcon isFilled />}
                      text={formatMessage({
                        defaultMessage: 'Assessment track settings',
                        description: 'assessment track settings button label',
                      })}
                    />
                  </AligningContainer>
                  <View>
                    <Modal
                      modalContents={
                        <>
                          <CloseIconContainer onPress={() => setAssessmentTrackModalOpen(false)}>
                            <CloseIcon size={16} />
                          </CloseIconContainer>
                          <AssessmentTrackSettingsModal
                            selectedEpisode={selectedProgressEpisode.episode}
                            closeModal={() => setAssessmentTrackModalOpen(false)}
                            isProviderLTCoach={isProviderLTCoach}
                          />
                        </>
                      }
                      scrollable={false}
                      disableFullScreenModal
                      disableBottomSheet
                      visible={isAssessmentTrackModalOpen}
                      onRequestClose={() => setAssessmentTrackModalOpen(false)}
                      closeOnScrim
                    />
                  </View>
                </AssessmentTrackSettingsContainer>
              )}
          </TopDropdownContainer>
          {isEmpty(outcomes) ? (
            <NoDataContainer testID={tID('ClientProgress-noData')}>
              <TextContainer>
                <HeaderTextContainer>
                  <HeaderText
                    text={formatMessage({ defaultMessage: 'No data yet', description: 'no data title' })}
                    size={SubheadSize.MEDIUM}
                    textAlign='center'
                  />
                </HeaderTextContainer>
                <BodyText
                  text={formatMessage({
                    defaultMessage:
                      'Your client hasn’t submitted assessments yet. Encourage your client to complete them and check back to track their progress.',
                    description: 'label indicating that there is no data available from the client',
                  })}
                  textAlign='center'
                />
              </TextContainer>
              <ChartIcon />
            </NoDataContainer>
          ) : (
            <>
              <ClientOutcomesContainer>
                <ClientOutcomes
                  outcomes={outcomes}
                  assignmentsWithSelfReflection={assignmentsWithSelfReflection}
                  clientFullName={clientDetails?.full_name}
                  clientFirstName={clientDetails?.first_name}
                  selectedProgressEpisode={selectedProgressEpisode?.episode}
                />
              </ClientOutcomesContainer>
            </>
          )}
        </Column>
      </Row>
    </OuterContainer>
  )
}

const mapStateToProps = (state: RootState): any => {
  return {
    assignments: getClientAssignmentsData(state),
    clientDetails: getClientDetailsData(state),
    providerDetailsData: getProviderDetailsData(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators({ getAllClientEpisodes, setSelectedEpisode }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientProgress)
