import React from 'react'

import styled from 'styled-components/native'

import { BodyText, Headline } from '../../atoms'
import { BodyTextSize, HeadlineSize } from '../../styles'

const Container = styled.View(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.spacing['16px'],
}))

const DetailContainer = styled.View(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.spacing['8px'],
}))

const Row = styled.View({
  flexDirection: 'row',
})

const Label = styled(BodyText)({
  width: '112px',
  lineHeight: '24px',
})

type Item = {
  key: string
  label: React.ReactNode
  value?: React.ReactNode
  textValue?: React.ReactNode
  testID?: string
}
export type ClientDetailsSideBarSectionProps = {
  items: Item[]
  headerText: React.ReactNode
}

export const ClientDetailsSideBarSection: React.FC<ClientDetailsSideBarSectionProps> = ({ headerText, items }) => {
  return (
    <Container>
      <Headline size={HeadlineSize.EYEBROW} text={headerText} />
      <DetailContainer>
        {items.map((item) => (
          <Row key={item.key} testID={item.testID}>
            <Label size={BodyTextSize.CAPTION} text={item.label} />
            {item.textValue ? <BodyText text={item.textValue} /> : item.value}
          </Row>
        ))}
      </DetailContainer>
    </Container>
  )
}
