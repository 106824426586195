import { ThunkAction, ThunkDispatch } from '@reduxjs/toolkit'
import { Recipe } from '@reduxjs/toolkit/dist/query/core/buildThunks'
import { Api, EndpointDefinitions, QueryArgFrom, QueryKeys, ResultTypeFrom } from '@reduxjs/toolkit/query'
import { isEqual, isMatch } from 'lodash-es'
import { AnyAction } from 'redux'

import store, { RootState } from './store'

interface UpdateCacheEndpointBuilder<Params, Data> {
  where(params: Params): this
  build(updater: Data): ThunkAction<any, RootState, any, AnyAction>
}

type Args<Definitions extends EndpointDefinitions, EndpointKey extends QueryKeys<Definitions>> = Partial<
  QueryArgFrom<Definitions[EndpointKey]>
>
type Updater<Definitions extends EndpointDefinitions, EndpointKey extends QueryKeys<Definitions>> = Recipe<
  ResultTypeFrom<Definitions[EndpointKey]>
>
type ArrayElement<T> = T extends (infer U)[] ? U : never
type ElementArray<Definitions extends EndpointDefinitions, EndpointKey extends QueryKeys<Definitions>> = ResultTypeFrom<
  Definitions[EndpointKey]
> extends any[]
  ? ResultTypeFrom<Definitions[EndpointKey]>
  : never
type ListUpdater<Definitions extends EndpointDefinitions, EndpointKey extends QueryKeys<Definitions>> = Recipe<
  ArrayElement<ElementArray<Definitions, EndpointKey>>
>

class BaseEndpointCacheUpdater<Definitions extends EndpointDefinitions, EndpointKey extends QueryKeys<Definitions>> {
  protected args?: Args<Definitions, EndpointKey>

  constructor(protected api: Api<any, Definitions, any, any>, protected endpoint: EndpointKey) {}

  protected matchArgs(originalArgs: QueryArgFrom<Definitions[EndpointKey]>) {
    if (!this.args) {
      return true
    }

    if (typeof this.args !== 'object') {
      return isEqual(originalArgs, this.args)
    }
    return isMatch(originalArgs, this.args)
  }

  where(args: Args<Definitions, EndpointKey>): this {
    this.args = args
    return this
  }
}

class EndpointCacheUpdater<
    Definitions extends EndpointDefinitions,
    EndpointKey extends QueryKeys<Definitions>,
    Result extends any[] = ResultTypeFrom<Definitions[EndpointKey]>,
  >
  extends BaseEndpointCacheUpdater<Definitions, EndpointKey>
  implements UpdateCacheEndpointBuilder<Args<Definitions, EndpointKey>, Updater<Definitions, EndpointKey>>
{
  items(params?: Partial<ArrayElement<Result>>) {
    return new EndpointCacheItemUpdater(this.api, this.endpoint, this.args, params)
  }

  build(updater: Updater<Definitions, EndpointKey>) {
    return (dispatch: ThunkDispatch<any, undefined, AnyAction>) => {
      if (!updater) {
        return
      }
      const state = store.getState().api
      Object.values(state.queries).forEach((query) => {
        const originalArgs = query?.originalArgs as QueryArgFrom<Definitions[EndpointKey]>

        if (query?.endpointName === this.endpoint && this.matchArgs(originalArgs)) {
          dispatch(this.api.util.updateQueryData(this.endpoint, originalArgs, updater))
        }
      })
    }
  }
}

class EndpointCacheItemUpdater<
    Definitions extends EndpointDefinitions,
    EndpointKey extends QueryKeys<Definitions>,
    Result extends any[] = ResultTypeFrom<Definitions[EndpointKey]>,
  >
  extends BaseEndpointCacheUpdater<Definitions, EndpointKey>
  implements UpdateCacheEndpointBuilder<Args<Definitions, EndpointKey>, ListUpdater<Definitions, EndpointKey>>
{
  constructor(
    protected api: Api<any, Definitions, any, any>,
    protected endpoint: EndpointKey,
    protected args?: Args<Definitions, EndpointKey>,
    protected params?: Partial<ArrayElement<Result>>,
  ) {
    super(api, endpoint)
    this.args = args
  }

  matchParams(item: Partial<ArrayElement<Result>>) {
    if (!this.params) {
      return true
    }

    if (typeof this.params !== 'object') {
      return isEqual(item, this.params)
    }
    return isMatch(item, this.params)
  }

  build(updater: ListUpdater<Definitions, EndpointKey>) {
    return (dispatch: ThunkDispatch<any, undefined, AnyAction>) => {
      if (!updater) {
        return
      }
      const state = store.getState().api
      Object.values(state.queries).forEach((query) => {
        const originalArgs = query?.originalArgs as QueryArgFrom<Definitions[QueryKeys<Definitions>]>
        if (query?.endpointName === this.endpoint && this.matchArgs(originalArgs)) {
          dispatch(
            this.api.util.updateQueryData(this.endpoint, originalArgs, (data: ArrayElement<Result>[]) => {
              data.forEach((item, i) => {
                if (!this.matchParams(item)) {
                  return
                }
                const result = updater(item)
                if (result) {
                  data[i] = result as ArrayElement<Result>
                }
              })
            }),
          )
        }
      })
    }
  }
}

export function updateEndpointCache<
  Definitions extends EndpointDefinitions,
  EndpointKey extends QueryKeys<Definitions>,
>(api: Api<any, Definitions, any, any>, endpoint: EndpointKey) {
  return new EndpointCacheUpdater(api, endpoint)
}
