import React from 'react'
import { FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { BodyText, IconBadge } from '../../atoms'
import { Corners } from '../../atoms/iconBadge/IconBadge'
import { BodyTextSize } from '../../styles'

const Container = styled.View(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing['8px'],
  alignItems: 'center',
}))

export type ClientDetailsEpisodeTagsProps = {
  programName: string | null
  isActive: boolean
}

export const ClientDetailsEpisodeTags: React.FC<ClientDetailsEpisodeTagsProps> = ({ programName, isActive }) => {
  const { colors } = useTheme()
  return (
    <Container>
      <IconBadge
        color={colors.backgroundInactive}
        cornerType={Corners.CURVED}
        text={
          <BodyText
            text={
              isActive ? (
                <FormattedMessage defaultMessage='Active' description='Active episode' />
              ) : (
                <FormattedMessage defaultMessage='Inactive' description='Inactive episode' />
              )
            }
            size={BodyTextSize.BADGE}
            color={colors.textInactive}
          />
        }
      />
      {programName && (
        <IconBadge
          color={colors.backgroundInactive}
          cornerType={Corners.CURVED}
          text={<BodyText text={programName} size={BodyTextSize.BADGE} color={colors.textInactive} />}
        />
      )}
    </Container>
  )
}
