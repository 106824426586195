import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import { isEmpty, isNil } from 'lodash-es'
import styled from 'styled-components/native'

import { BRANCH_APP_LINK, ProgramCustomerPropertyId, ProgramNames } from '@lyrahealth-inc/shared-app-logic'
import {
  BodyText,
  BodyTextSize,
  CareNavigatorCharacterSpotIllustration,
  colors,
  ControlledMedsIllustration,
  CopyToClipboard,
  GuidedSelfCareSecondaryRec,
  HeaderText,
  IndividualTherapy,
  NicotineQuitlineIllustration,
  ProfileBookingCoachingParentsIllustration,
  ProfileBookingLyraCommunityMedicationIllustration,
  ProfileBookingLyraCommunityTherapyIllustration,
  ProfileBookingShortTermDisabilityEvaluationIllustration,
  RenewIllustration,
  Subhead,
  SubheadSize,
  ThemeType,
  tID,
} from '@lyrahealth-inc/ui-core-crossplatform'

import {
  benefitsProgramNames,
  coverageStates,
  LWUrl,
  programBookingLinkProgramNames,
  programConfig,
  programNameTitleMap,
} from '../../../../common/constants/appConstants'
import { getClientDetailsData } from '../../../../data/lyraTherapy/clientSelectors'

const ProgramContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  border: `1px solid ${theme.colors.borderDefault}`,
  borderRadius: theme.spacing['16px'],
  flexDirection: 'row',
  marginBottom: theme.spacing['16px'],
  padding: theme.spacing['16px'],
}))

const ProgramIconCotainer = styled.View({
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '72px',
})

const ProgramContainerContents = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flex: 1,
  flexDirection: 'column',
  marginLeft: theme.spacing['16px'],
  overflow: 'wrap',
}))

const SubheadContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['16px'],
}))

const CopyProfileLinkContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: 0,
  marginTop: theme.spacing['16px'],
}))

const ProgramTypeHeading = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['16px'],
}))

const BenefitsEligibility: React.FC<BenefitsEligibilityProps> = ({
  currentProgramCoverageBreakdown,
  programCoverageName,
  clientCountryCode,
  nonProgramOfferings,
}) => {
  const clientDetails = useSelector(getClientDetailsData)

  // Coaching for Parents has the same properties as the Coaching program, but it has its own Program Booking Links
  //  Non-US does not see Coaching for parents
  const modifiedProgramCoverageBreakdown = { ...currentProgramCoverageBreakdown }
  if (currentProgramCoverageBreakdown.hasOwnProperty('stressManagement') && clientCountryCode === 'US') {
    modifiedProgramCoverageBreakdown.coachingForParents = { ...currentProgramCoverageBreakdown.stressManagement }
  }

  const getProgramIconByProgramName = (programName: string) => {
    if (isNil(programName) || programName.length === 0) {
      return <></>
    }
    switch (programName) {
      case ProgramCustomerPropertyId.alcoholUseDisorderTherapy:
        return <RenewIllustration />
      case ProgramCustomerPropertyId.blendedCareMeds:
        return <ControlledMedsIllustration />
      case ProgramCustomerPropertyId.clinicalLeaveEvaluation:
        return <ProfileBookingShortTermDisabilityEvaluationIllustration />
      case ProgramCustomerPropertyId.considerLyraTherapy:
        return <IndividualTherapy size={72} />
      case ProgramCustomerPropertyId.stressManagement:
        return <CareNavigatorCharacterSpotIllustration size={72} />
      case ProgramCustomerPropertyId.coachingForParents:
        return <ProfileBookingCoachingParentsIllustration />
      case ProgramCustomerPropertyId.guidedSelfCareEnabled:
        return <GuidedSelfCareSecondaryRec />
      case ProgramCustomerPropertyId.directAccessTherapy:
        return <ProfileBookingLyraCommunityTherapyIllustration />
      case ProgramCustomerPropertyId.considerMeds:
        return <ProfileBookingLyraCommunityMedicationIllustration />
      case ProgramCustomerPropertyId.nicotineQuitline:
        return <NicotineQuitlineIllustration size='72' />
      default:
        return <></>
    }
  }

  const shouldShowProgramBookingLink = (programCoverageName: string, bookingLinkProgram: string) => {
    return programConfig[programCoverageName].programBookingLinksAllowed.includes(bookingLinkProgram)
  }

  const getIsProgramCovered = (program: string) => {
    if (!(program in modifiedProgramCoverageBreakdown) && nonProgramOfferings?.includes(program)) {
      return true
    }
    return (
      program in modifiedProgramCoverageBreakdown &&
      !(
        !modifiedProgramCoverageBreakdown[program as keyof ProgramCoverageBreakdown]?.eapSupported &&
        !modifiedProgramCoverageBreakdown[program as keyof ProgramCoverageBreakdown]?.bhbSupported &&
        !modifiedProgramCoverageBreakdown[program as keyof ProgramCoverageBreakdown]?.specialtyBhbSupported
      )
    )
  }

  const getProgramCoverageDescription = (program: ProgramNamesAndNonProgramOfferings) => {
    if (program === ProgramNamesAndNonProgramOfferings.nicotineQuitline) {
      return coverageStates.NICOTINE
    } else if (program in modifiedProgramCoverageBreakdown) {
      if (
        !modifiedProgramCoverageBreakdown[program]?.eapSupported &&
        !modifiedProgramCoverageBreakdown[program]?.bhbSupported &&
        modifiedProgramCoverageBreakdown[program]?.specialtyBhbSupported
      ) {
        return coverageStates.SPECIALTY
      } else if (
        !modifiedProgramCoverageBreakdown[program]?.eapSupported &&
        modifiedProgramCoverageBreakdown[program]?.bhbSupported &&
        !modifiedProgramCoverageBreakdown[program]?.specialtyBhbSupported
      ) {
        return coverageStates.HP
      } else if (
        !modifiedProgramCoverageBreakdown[program]?.eapSupported &&
        modifiedProgramCoverageBreakdown[program]?.bhbSupported &&
        modifiedProgramCoverageBreakdown[program]?.specialtyBhbSupported
      ) {
        return coverageStates.HP_SPECIALTY
      } else if (
        modifiedProgramCoverageBreakdown[program]?.eapSupported &&
        !modifiedProgramCoverageBreakdown[program]?.bhbSupported &&
        !modifiedProgramCoverageBreakdown[program]?.specialtyBhbSupported
      ) {
        return coverageStates.EAP
      } else if (
        modifiedProgramCoverageBreakdown[program]?.eapSupported &&
        !modifiedProgramCoverageBreakdown[program]?.bhbSupported &&
        modifiedProgramCoverageBreakdown[program]?.specialtyBhbSupported
      ) {
        return coverageStates.EAP_SPECIALTY
      } else if (
        modifiedProgramCoverageBreakdown[program]?.eapSupported &&
        modifiedProgramCoverageBreakdown[program]?.bhbSupported &&
        !modifiedProgramCoverageBreakdown[program]?.specialtyBhbSupported
      ) {
        return coverageStates.EAP_HP
      } else if (
        modifiedProgramCoverageBreakdown[program]?.eapSupported &&
        modifiedProgramCoverageBreakdown[program]?.bhbSupported &&
        modifiedProgramCoverageBreakdown[program]?.specialtyBhbSupported
      ) {
        return coverageStates.EAP_HP_SPECIALTY
      }
    }
    return null
  }

  const getProgramBookingLinkByProgramName = (programName: string, employer: string) => {
    if (isEmpty(programName) || isEmpty(employer)) {
      return ''
    }
    let treatment = ''
    if (
      programName === ProgramCustomerPropertyId.considerLyraTherapy ||
      programName === ProgramCustomerPropertyId.alcoholUseDisorderTherapy
    ) {
      treatment = programNameTitleMap.Therapy
    } else if (
      programName === ProgramCustomerPropertyId.stressManagement ||
      programName === ProgramCustomerPropertyId.coachingForParents ||
      programName === ProgramCustomerPropertyId.guidedSelfCareEnabled
    ) {
      treatment = programNameTitleMap.Coaching
    } else if (programName === ProgramCustomerPropertyId.blendedCareMeds) {
      treatment = programBookingLinkProgramNames.MedicationManagement
    } else if (programName === ProgramCustomerPropertyId.clinicalLeaveEvaluation) {
      treatment = programBookingLinkProgramNames.Assessment
    }

    let offering = 'Default'
    if (programName === ProgramCustomerPropertyId.alcoholUseDisorderTherapy) {
      offering = programBookingLinkProgramNames.AlcoholUseDisorder
    } else if (programName === ProgramCustomerPropertyId.coachingForParents) {
      offering = programBookingLinkProgramNames.Parenting
    } else if (programName === ProgramCustomerPropertyId.guidedSelfCareEnabled) {
      offering = programBookingLinkProgramNames.SingleSession
    } else if (programName === ProgramCustomerPropertyId.clinicalLeaveEvaluation) {
      offering = programBookingLinkProgramNames.ClinicalLeave
    }
    // only the BRANCH_APP_LINK needs query params, it will be passed down to the fallback link
    const baseLink = `${BRANCH_APP_LINK}directPathBooking?directPath=true&clientele=Individual&treatment=${treatment}&partner=BlendedCare&offering=${offering}&directLinkIntent=DIRECT_LINK_CONCURRENT_CARE_REFERRAL&directLinkSource=provider_message&$fallback_url=${LWUrl(
      employer,
    )}/secure/onboard/match-location`
    return baseLink
  }

  const programLinks = [
    {
      name: ProgramCustomerPropertyId.considerLyraTherapy,
      category: 'lyraCare',
      bookingLink: getProgramBookingLinkByProgramName(
        ProgramCustomerPropertyId.considerLyraTherapy,
        clientDetails?.employer ?? '',
      ),
    },
    {
      name: ProgramCustomerPropertyId.alcoholUseDisorderTherapy,
      category: 'lyraCare',
      bookingLink: getProgramBookingLinkByProgramName(
        ProgramCustomerPropertyId.alcoholUseDisorderTherapy,
        clientDetails?.employer ?? '',
      ),
    },
    {
      name: ProgramCustomerPropertyId.stressManagement,
      category: 'lyraCare',
      bookingLink: getProgramBookingLinkByProgramName(
        ProgramCustomerPropertyId.stressManagement,
        clientDetails?.employer ?? '',
      ),
    },
    {
      name: ProgramCustomerPropertyId.coachingForParents,
      category: 'lyraCare',
      bookingLink: getProgramBookingLinkByProgramName(
        ProgramCustomerPropertyId.coachingForParents,
        clientDetails?.employer ?? '',
      ),
    },
    {
      name: ProgramCustomerPropertyId.guidedSelfCareEnabled,
      category: 'lyraCare',
      bookingLink: getProgramBookingLinkByProgramName(
        ProgramCustomerPropertyId.guidedSelfCareEnabled,
        clientDetails?.employer ?? '',
      ),
    },
    {
      name: ProgramCustomerPropertyId.blendedCareMeds,
      category: 'lyraCare',
      bookingLink: getProgramBookingLinkByProgramName(
        ProgramCustomerPropertyId.blendedCareMeds,
        clientDetails?.employer ?? '',
      ),
    },
    {
      name: ProgramCustomerPropertyId.clinicalLeaveEvaluation,
      category: 'lyraCare',
      bookingLink: getProgramBookingLinkByProgramName(
        ProgramCustomerPropertyId.clinicalLeaveEvaluation,
        clientDetails?.employer ?? '',
      ),
    },
    {
      name: ProgramCustomerPropertyId.directAccessTherapy,
      category: 'directAccess',
    },
    {
      name: ProgramCustomerPropertyId.considerMeds,
      category: 'directAccess',
    },
    {
      name: ProgramCustomerPropertyId.nicotineQuitline,
      category: 'careTeam',
      bookingLink: 'https://go.oncehub.com/NicotineQuitline?referralsource=NqProviderReferral',
    },
  ]
  const programCoverageBreakdownKeys = Object.keys(modifiedProgramCoverageBreakdown)
  programCoverageBreakdownKeys.sort((a, b) => {
    return (
      programLinks.map((item) => item.name).indexOf(a as ProgramCustomerPropertyId) -
      programLinks.map((item) => item.name).indexOf(b as ProgramCustomerPropertyId)
    )
  })

  const lyraCarePrograms = programCoverageBreakdownKeys.filter((item: string) =>
    programLinks.find((program) => program.category === 'lyraCare' && program.name === item),
  )
  const directAccessPrograms = programCoverageBreakdownKeys.filter((item: string) =>
    programLinks.find((program) => program.category === 'directAccess' && program.name === item),
  )

  const careTeamPrograms = [ProgramCustomerPropertyId.nicotineQuitline]

  const getProgramContainer = (programNames: Array<string>, hideNonBookingLinkPrograms = false) => {
    return programNames
      .filter((program: string) => Object.keys(benefitsProgramNames).includes(program) && getIsProgramCovered(program))
      .map((program: string) => {
        const programBookingLink = programLinks.find((item) => item.name === program)?.bookingLink
        if (
          !hideNonBookingLinkPrograms ||
          (!isNil(programBookingLink) && shouldShowProgramBookingLink(programCoverageName, program))
        ) {
          return (
            <ProgramContainer key={program} testID={tID(`BenefitsEligibility-${program}`)}>
              <ProgramIconCotainer>{getProgramIconByProgramName(program)}</ProgramIconCotainer>
              <ProgramContainerContents>
                <SubheadContainer>
                  <Subhead
                    testID={tID(`BenefitsEligibility-${program}-programName`)}
                    text={benefitsProgramNames[program as keyof typeof benefitsProgramNames]}
                    size={SubheadSize.XSMALL}
                    color={colors.ui_oatmeal6}
                  />
                </SubheadContainer>
                <BodyText
                  testID={tID(`BenefitsEligibility-${program}-coverageDescription`)}
                  text={getProgramCoverageDescription(program as ProgramNamesAndNonProgramOfferings)}
                  size={BodyTextSize.DEFAULT}
                  color={colors.ui_oatmeal5}
                />

                {!isNil(programBookingLink) && shouldShowProgramBookingLink(programCoverageName, program) && (
                  <CopyProfileLinkContainer>
                    <CopyToClipboard
                      displayText='Program Booking Link'
                      testID={tID(`BenefitsEligibility-program-booking-link-${program}`)}
                      copyText={programBookingLink}
                    />
                  </CopyProfileLinkContainer>
                )}
              </ProgramContainerContents>
            </ProgramContainer>
          )
        } else {
          return <></>
        }
      })
  }
  return (
    <View>
      <ProgramTypeHeading testID={tID(`BenefitsEligibility-programingBookingLinksHeader-lyraCare`)}>
        <HeaderText text='Lyra Care' />
      </ProgramTypeHeading>
      {getProgramContainer(lyraCarePrograms, programCoverageName === ProgramNames.TeensTherapy)}
      <ProgramTypeHeading testID={tID(`BenefitsEligibility-programingBookingLinksHeader-directAccess`)}>
        <HeaderText text='Direct Access' />
      </ProgramTypeHeading>
      {getProgramContainer(directAccessPrograms)}
      {getIsProgramCovered(ProgramNamesAndNonProgramOfferings.nicotineQuitline) &&
        shouldShowProgramBookingLink(programCoverageName, ProgramNamesAndNonProgramOfferings.nicotineQuitline) && (
          <>
            <ProgramTypeHeading testID={tID(`BenefitsEligibility-programingBookingLinksHeader-careTeam`)}>
              <HeaderText text='Care Navigator Team' />
            </ProgramTypeHeading>
            {getProgramContainer(careTeamPrograms)}
          </>
        )}
    </View>
  )
}

export type ProgramCoverageBreakdown = {
  blendedCareMeds?: {
    bhbSupported: boolean
    eapSupported: boolean
    specialtyBhbSupported: boolean
  }
  directAccessTherapy?: {
    bhbSupported: boolean
    eapSupported: boolean
    specialtyBhbSupported: boolean
  }
  considerLyraTherapy?: {
    bhbSupported: boolean
    eapSupported: boolean
    specialtyBhbSupported: boolean
  }
  alcoholUseDisorderTherapy?: {
    bhbSupported: boolean
    eapSupported: boolean
    specialtyBhbSupported: boolean
  }
  guidedSelfCareEnabled?: {
    bhbSupported: boolean
    eapSupported: boolean
    specialtyBhbSupported: boolean
  }
  considerMeds?: {
    bhbSupported: boolean
    eapSupported: boolean
    specialtyBhbSupported: boolean
  }
  stressManagement?: {
    bhbSupported: boolean
    eapSupported: boolean
    specialtyBhbSupported: boolean
  }
  coachingForParents?: {
    bhbSupported?: boolean
    eapSupported?: boolean
    specialtyBhbSupported?: boolean
  }
}
enum ProgramNamesAndNonProgramOfferings {
  blendedCareMeds = 'blendedCareMeds',
  directAccessTherapy = 'directAccessTherapy',
  considerLyraTherapy = 'considerLyraTherapy',
  alcoholUseDisorderTherapy = 'alcoholUseDisorderTherapy',
  guidedSelfCareEnabled = 'guidedSelfCareEnabled',
  considerMeds = 'considerMeds',
  stressManagement = 'stressManagement',
  coachingForParents = 'coachingForParents',
  nicotineQuitline = 'nicotineQuitline',
}

type BenefitsEligibilityProps = {
  currentProgramCoverageBreakdown: ProgramCoverageBreakdown
  programCoverageName: string
  clientCountryCode: string
  nonProgramOfferings?: string[]
}

export default BenefitsEligibility
