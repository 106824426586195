/* eslint-disable @typescript-eslint/no-unsafe-function-type */
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import CSSModules from 'react-css-modules'
import { connect } from 'react-redux'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

import Axios, { Canceler, CancelToken } from 'axios'
import { isValid as isValidDate, parse, subDays } from 'date-fns'
import { Map } from 'immutable'
import { get, includes, isEmpty, isEqual, isString, pick, throttle, uniqueId } from 'lodash-es'
import queryString from 'query-string'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { change, formValueSelector, getFormMeta, getFormSyncErrors, isValid, reduxForm } from 'redux-form/immutable'
import { useTheme } from 'styled-components/native'

import {
  Address,
  ChargeCopyPrepopInfo,
  ChargeEligiblityPayload,
  CustomerInfo,
  ICD10_PRIMARY_FIELD,
  ICD10_SECONDARY_FIELD,
  ImmutableTypedMap,
  LyraCodeValidationInfo,
  ProviderAdminProviderInfo,
  ProviderInfo,
  ProviderRates,
  ProviderUser,
  Transfer,
  useFlags,
  usePrevious,
} from '@lyrahealth-inc/shared-app-logic'
import {
  BaseModal,
  BootstrapContainer,
  ContentLayout,
  dateUtils,
  formValidationConstants,
  LoadingIndicator,
} from '@lyrahealth-inc/ui-core'
import { BodyTextSize, Link, useFetcher } from '@lyrahealth-inc/ui-core-crossplatform'

import CancelledSessionsExceededModal from './CancelledSessionsExceededModal'
import ClientIneligibleModal from './ClientIneligibleModal'
import * as requestPaymentActions from './data/requestPaymentActions'
import DuplicatePaymentModal from './DuplicatePaymentModal'
import FirstSessionModal from './FirstSessionModal'
import LastSessionModal from './LastSessionModal'
import OutcomeReminder from './OutcomeReminder'
import styles from './requestPayment.module.scss'
import SessionsExceededModal from './SessionsExceededModal'
import SessionsWarningModal from './SessionsWarningModal'
import { actions, mixpanelEvents } from '../../../mixpanel/mixpanelConstants'
import { track } from '../../../mixpanel/mixpanelTracking'
import * as clientsDataActions from '../clients/data/clientsDataActions'
import AvailabilityCard from '../common/components/availabilityCard/AvailabilityCard'
import paymentRequestAsyncValidate from '../common/components/forms/paymentRequest/paymentRequestAsyncValidate'
import PaymentRequestForm from '../common/components/forms/paymentRequest/PaymentRequestForm'
import paymentRequestValidate from '../common/components/forms/paymentRequest/paymentRequestValidate'
import {
  bannerMessages,
  coachingSessionTypesList,
  LYRA_CODE_LENGTH,
  outcomesEligibleSessionTypes,
  PAYMENT_FORM,
  ROLES,
  specialityCareAttestationStatuses,
} from '../common/constants/appConstants'
import {
  CLIENT_EMAIL,
  HEALTHPLAN_INFO,
  PRACTICES_DETAILS_HEALTHPLAN_PAYMENT_INFO,
  PRACTICES_DETAILS_NEW_PAYMENT,
  PRACTICES_REGISTER,
  PROVIDERS_DETAILS_HEALTHPLAN_PAYMENT_INFO,
  PROVIDERS_DETAILS_NEW_PAYMENT,
  PROVIDERS_REGISTER,
  SCHEDULING,
} from '../common/constants/routingConstants'
import {
  getChargeCopyPrepopInfo,
  getErrorText,
  getPatientPrepopInfo,
  hasRole,
  sanitizeLyraCode,
  shouldShowPaymentFormEligibleMemberDetails,
} from '../common/utils/utils'
import * as alertActions from '../data/alertActions'
import { getAuthConfig, getAuthUser } from '../data/auth/authSelectors'
import { saveHealthPlanCharge } from '../data/healthPlanAutoActions'
import {
  getRequestPaymentCopy,
  getRequestPaymentCustomers,
  getRequestPaymentDataData,
  getRequestPaymentDataOutcomeReminderStatus,
  getRequestPaymentDataSessionTrackingStatus,
  getRequestPaymentEligibilityStatus,
  getRequestPaymentLyraCodeValidationInfo,
  getRequestPaymentPatient,
  getRequestPaymentProvidersList,
  getRequestPaymentRates,
  getRequestPaymentResponseDataSessionTrackingStatus,
  getRequestPaymentSelectedProvider,
  getRequestPaymentShowCancelledSessionsExceededModal,
  getRequestPaymentShowDuplicateModal,
  getRequestPaymentShowIneligibleClientModal,
} from '../data/requestPayment/requestPaymentSelectors'
import { RootState } from '../data/store'
import { Config } from '../lyraTherapy/types'
import { getPracticeDetailsDataId } from '../practices/individualPractice/data/practiceDetailsSelectors'
import { getProviderCapacity } from '../providers/data/providersDataActions'
import {
  getProviderDetailsAddresses,
  getProviderDetailsCalendarState,
  getProviderDetailsData,
  getProviderDetailsDiffPricingEnabled,
  getProviderDetailsDiffPricingModifier,
  getProviderDetailsRates,
} from '../providers/data/providerSelectors'
import * as providerDetailsActions from '../providers/individualProvider/data/providerDetailsActions'

const cancelToken = Axios.CancelToken
let cancelGetClient: Canceler

const requestPaymentWarnings = ($$values = Map(), props: RequestPaymentProps) => {
  const warnings = {}
  // Only display lyra code date of birth match warning for payments admin
  if (hasRole(props.user.roles, ROLES.PAYMENTS_ADMIN)) {
    const lyraCodeDOB = props.lyraCodeValidationInfo?.user_info?.dob
    const enteredDOB = $$values.get(PAYMENT_FORM.FIELDS.DATE_OF_BIRTH)
    if (
      lyraCodeDOB &&
      enteredDOB &&
      isString(enteredDOB) &&
      isEqual(parse(enteredDOB, 'MM/dd/yyyy', new Date()), parse(lyraCodeDOB, 'MM-dd-yyyy', new Date()))
    ) {
      warnings[PAYMENT_FORM.FIELDS.DATE_OF_BIRTH] = 'Date of birth does not correspond with Lyra code'
    }
  }
  return warnings
}

export const RequestPayment: React.FC<RequestPaymentProps> = ({
  user,
  providersList,
  initialize,
  initialized,
  guaranteedTime,
  sessionType,
  modality,
  rates,
  prepopInfo,
  synchronousErrors,
  dispatch,
  eligibilityCheckFields,
  sessionTrackingStatus,
  showCancelledSessionsExceededModal,
  showDuplicateModal,
  paymentResponse,
  sessionsExceeded,
  outcomeReminderStatus,
  customers,
  enteredVisitDate,
  enteredPatient,
  enteredlyraCode,
  activeField,
  touch,
  asyncValidate,
  selectedProvider,
  attendance,
  selectedCompany,
  eligibilityStatus,
  formIsValid,
  handleSubmit,
  submitting,
  showEligibleMemberDetails,
  showDiagnosisDescription,
  showIneligibleClientModal,
  clientOutcomesEligibility,
  initialCompany,
  initialDiagnoses,
  initialModality,
  guaranteedTimeEligible,
  providerOutcomesAgreed,
  patientOutcomesAgreed = true,
  config,
  isPractice,
  providerAddresses,
  providerCalendarState,
  lyraCodeValidationInfo,
  primaryCondition,
  secondaryCondition,
  ebtType,
  differentialPricingEnabled,
  differentialPricingModifier,
  provider,
  actions: {
    addAlert,
    getProviderRates,
    getProviderCapacity,
    getProvider,
    getChargeToCopy,
    selectProvider,
    checkEligibility,
    displayIneligibleClientModal,
    getClient,
    hideAlerts,
    submitPaymentRequest,
    clearLyraCodeValidationInfo,
    closeRequestPaymentModal,
    clearRequestPaymentStore,
    saveHealthPlanCharge,
  },
}) => {
  const [loading, setLoading] = useState(true)
  const [submittedEligibityCheckData, setSubmittedEligibilityCheckData] = useState({})
  const [dateInputKey, setDateInputKey] = useState(uniqueId('dateInput'))
  const prevActiveField = usePrevious(activeField)
  const prevLyraCode = usePrevious(enteredlyraCode)
  const prevGuaranteedTime = usePrevious(guaranteedTime)
  const prevPrepopInfo = usePrevious(prepopInfo)
  const prevSessionType = usePrevious(sessionType)
  const prevRates = usePrevious(rates)
  const prevSelectedCompany = usePrevious(selectedCompany)
  const prevModality = usePrevious(modality)
  const location = useLocation()
  const navigate = useNavigate()
  const {
    differentialPricingCalculationEnabled,
    isProgramLevelSessionLimitEnabled,
    differentialPricingRatesEnabled,
    isInProductCalendarEnabled,
  } = useFlags()
  const { colors } = useTheme()

  const roles = user.roles

  // https://stackoverflow.com/questions/54666401/how-to-use-throttle-or-debounce-with-react-hook
  const useDebouncedEffect = (effect: () => void, deps: Array<string>, delay: number) => {
    useEffect(() => {
      const handler = setTimeout(() => effect(), delay)

      return () => clearTimeout(handler)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...(deps || []), delay])
  }

  useEffect(() => {
    return () => clearRequestPaymentStore()
  }, [clearRequestPaymentStore])

  useFetcher(
    [
      [getProviderCapacity, { id: user.id }, isEmpty(user.capacity_value)],
      [getProvider, [user.id], isEmpty(provider)],
      [getProviderRates, { id: user.id }, user.onsite && isEmpty(rates)],
    ],
    [],
    {
      onFinally: () => setLoading(false),
    },
  )

  useEffect(() => {
    if (prepopInfo?.provider_lyra_id && !(providersList?.length == 0) && !selectedProvider) {
      selectProvider(prepopInfo.provider_lyra_id)
    }
  })

  useEffect(() => {
    // When company, lyra code, or visit date lose focus trigger eligibilty check
    const eligibilityTriggerFields = [
      PAYMENT_FORM.FIELDS.COMPANY,
      PAYMENT_FORM.FIELDS.LYRA_CODE,
      PAYMENT_FORM.FIELDS.VISIT_DATE,
    ]
    if (
      eligibilityTriggerFields.some((field) => {
        return field === prevActiveField && field !== activeField
      })
    ) {
      eligibilityCheck()
    }
  })

  const _getClient = () => {
    if (cancelGetClient) {
      cancelGetClient() // cancel any pending axios requests
    }
    getClient(
      enteredPatient,
      new cancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancelGetClient = c
      }),
    )
  }

  useDebouncedEffect(
    () => {
      //new values entered

      // test them
      if (
        enteredPatient.first_name &&
        enteredPatient.first_name.length > 1 &&
        enteredPatient.last_name &&
        enteredPatient.last_name.length > 1 &&
        enteredPatient.dob &&
        isValidDate(new Date(enteredPatient.dob))
      ) {
        // valid required data for retrieving patient
        _getClient()
      }
    },
    [enteredPatient.first_name, enteredPatient.last_name, enteredPatient.dob],
    1000,
  )

  useEffect(() => {
    // checks for updated company field and force sessionType to be cleared(required to be reselected again)
    // coach's session type should never be touched as they can only submit smp_session + field is disabled
    const prevCompany = prevSelectedCompany?.[0]?.value
    const currCompany = selectedCompany?.[0]?.value
    // We check to see if prevCompany is undefined because if you reload the page after copying a charge in payment history tab, prevCompany will be undefined
    // causing session type to be cleared when it shouldn't be.
    if (prevCompany) {
      if (prevCompany !== currCompany && !hasRole(roles, [ROLES.LT_COACH, ROLES.LT_SS_COACH]) && !guaranteedTime) {
        dispatch(change(PAYMENT_FORM.NAME, PAYMENT_FORM.FIELDS.SESSION_TYPE, ''))
      }
    }
  }, [dispatch, guaranteedTime, prevSelectedCompany, roles, selectedCompany])

  useEffect(() => {
    // Manually trigger async validation. This is needed so that it fires on the last character press
    if (prevLyraCode !== enteredlyraCode && sanitizeLyraCode(enteredlyraCode).length === LYRA_CODE_LENGTH) {
      asyncValidate(PAYMENT_FORM.FIELDS.LYRA_CODE, enteredlyraCode, 'manual')
      // Set lyra_code field to touched so that the error message displays immediately
      touch(PAYMENT_FORM.FIELDS.LYRA_CODE)
    }
  }, [prevLyraCode, enteredlyraCode, asyncValidate, touch])

  const locationChangeHandler = useCallback(
    (address: Address[]) => {
      dispatch(change(PAYMENT_FORM.NAME, PAYMENT_FORM.FIELDS.LOCATION, address))
    },
    [dispatch],
  )

  const getInitialValues = useCallback(
    (isGuaranteedTime: boolean) => {
      let initialValuesByLyraType = {}
      let disabledFieldsByLyraType = {}
      if (hasRole(roles, [ROLES.LT_THERAPIST, ROLES.INDIVIDUAL_PROVIDER])) {
        initialValuesByLyraType = PAYMENT_FORM.THERAPIST.initialValues
        disabledFieldsByLyraType = PAYMENT_FORM.THERAPIST.disabledFields
      }

      // When copying a payment from payment history, only the location state/zipcode are stored on the charge and is represented as prepopInfo.location
      // Need to match the address in provider addresses list to the state/zipcode and initialize the form with that address
      const locationObject: any = {}
      if (differentialPricingRatesEnabled) {
        delete prepopInfo.modality
        delete prepopInfo.location
      } else {
        const initialLocation = prepopInfo.location
        let matchedAddress: Address | undefined
        if (
          prepopInfo.modality === 'in_person' &&
          initialLocation &&
          prepopInfo.provider_lyra_id &&
          providerAddresses?.length > 1
        ) {
          matchedAddress = providerAddresses?.find((address: Address) => {
            return address.state === initialLocation.state && address.zipcode === initialLocation.zipcode
          })
          if (matchedAddress) {
            locationObject.location = [matchedAddress]
          }
        }
      }
      // loads the base default values (applicable for all provider types) and then fields based on provider type
      let initialValues = {
        ...PAYMENT_FORM.DEFAULT.initialValues,
        ...initialValuesByLyraType,
        ...prepopInfo,
        ...locationObject,
      }
      let disabledFields = { ...(PAYMENT_FORM.DEFAULT as any).disabledFields, ...disabledFieldsByLyraType }

      // load guaranteed time specific field values and provider+guaranteed time specific fields values if guaranted time box is checked
      if (isGuaranteedTime) {
        let initialValuesByLyraTypeGuaranteedTime = {}
        let disabledFieldsByLyraTypeGuaranteedTime = {}
        if (hasRole(roles, [ROLES.LT_THERAPIST, ROLES.INDIVIDUAL_PROVIDER])) {
          initialValuesByLyraTypeGuaranteedTime = PAYMENT_FORM.THERAPIST_GUARANTEED_TIME.initialValues
          disabledFieldsByLyraTypeGuaranteedTime = PAYMENT_FORM.THERAPIST_GUARANTEED_TIME.disabledFields
        }

        initialValues = {
          ...initialValues,
          ...PAYMENT_FORM.GUARANTEED_TIME.initialValues,
          ...initialValuesByLyraTypeGuaranteedTime,
        }
        disabledFields = {
          ...disabledFields,
          ...PAYMENT_FORM.GUARANTEED_TIME.disabledFields,
          ...disabledFieldsByLyraTypeGuaranteedTime,
        }
      }
      if (differentialPricingCalculationEnabled) {
        if (modality && sessionType && sessionType !== 'other' && rates && Object.keys(rates).length > 0) {
          if (differentialPricingEnabled && modality == 'in_person') {
            initialValues.session_rate = (differentialPricingModifier + parseFloat(rates[sessionType])).toString()
          } else {
            initialValues.session_rate = rates[sessionType]
          }
        } else {
          initialValues.session_rate = '0'
        }
      } else {
        initialValues.session_rate =
          sessionType && sessionType !== 'other' && rates && Object.keys(rates).length > 0 ? rates[sessionType] : '0'
      }
      // disable FN LN until valid DOB is entered
      if (get(synchronousErrors, PAYMENT_FORM.FIELDS.DATE_OF_BIRTH)) {
        disabledFields[PAYMENT_FORM.FIELDS.FIRST_NAME] = true
        disabledFields[PAYMENT_FORM.FIELDS.LAST_NAME] = true
      }

      return { initialValues, disabledFields }
    },
    [
      differentialPricingCalculationEnabled,
      differentialPricingEnabled,
      differentialPricingModifier,
      modality,
      prepopInfo,
      providerAddresses,
      rates,
      roles,
      sessionType,
      synchronousErrors,
    ],
  )

  useEffect(() => {
    const fromSubmissionIntermediate = location.state?.fromSubmissionIntermediate
    const providerCalendarNotConnected = [
      'ProviderCalendarStatus.NONE',
      'ProviderCalendarStatus.NOT_SET_UP',
      'ProviderCalendarStatus.CHOOSING_CALENDARS',
      'ProviderCalendarStatus.SETTING_UP_CONFIRMING_AVAILABILITY',
      'ProviderCalendarStatus.CHOOSING_WRITEABLE_CALENDAR',
    ].includes(providerCalendarState)
    if (
      config.smartSchedulingEnabled &&
      providerCalendarNotConnected &&
      !fromSubmissionIntermediate &&
      !isInProductCalendarEnabled
    ) {
      addAlert({
        show: true,
        contents: (
          <>
            <Link
              text='Activate Smart Scheduling'
              onPress={() => {
                track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.CALENDAR_BANNER })
                navigate(SCHEDULING.route)
              }}
              underline
              color={colors.textButton}
              size={BodyTextSize.LARGE}
            />{' '}
            by setting up your calendar to boost referrals and simplify appointment booking 🗓️
          </>
        ),
        style: 'calm',
        expires: true,
      })
    }
  }, [config.smartSchedulingEnabled, providerCalendarState, addAlert, location, navigate, colors.textButton])

  useEffect(() => {
    const copiedChargeID = queryString.parse(location.search)['copy-charge-id'] || location.state?.copy_charge_id
    if (!initialized) {
      if (copiedChargeID) {
        getChargeToCopy(copiedChargeID).then(
          (rpReturn: any) => {
            if (hasRole(roles, ROLES.PRACTICES_ADMIN) && rpReturn.value.data.provider_lyra_id) {
              getProviderRates({ id: rpReturn.value.data.provider_lyra_id })
            }
            // Call getInitialValues inside getChargeToCopy callback to make sure this.props.prepopInfo is populated
            if (Object.keys(prepopInfo).length > 1) {
              const { initialValues } = getInitialValues(guaranteedTime)
              initialize(initialValues)
              navigate(location.pathname, {}) // Clear out the state copy-charge-id once copied charge is fetched
            }
          },
          (error: any) => {
            const errorText = error.response.data.message || getErrorText(error.response.status)
            addAlert({
              show: true,
              contents: errorText,
              style: 'danger',
              expires: true,
            })
          },
        )
      } else {
        const { initialValues } = getInitialValues(guaranteedTime)
        initialize(initialValues)
      }
    }
  }, [
    addAlert,
    getChargeToCopy,
    getInitialValues,
    getProviderRates,
    guaranteedTime,
    initialize,
    initialized,
    location.pathname,
    location.search,
    location.state?.copy_charge_id,
    navigate,
    prepopInfo,
    roles,
  ])

  useEffect(() => {
    // re-initialize the form if the guaranteedTime box has been toggled. Force fields to update to the calculated initialValues
    const { initialValues } = getInitialValues(guaranteedTime)
    if (prevGuaranteedTime !== guaranteedTime || isEqual(prevPrepopInfo, prepopInfo) === false) {
      initialize({ ...prepopInfo, ...initialValues })
    }
    // updates the rates when we receive rates from backend, when user changes session_type, or when a user changing modality
    if (prevSessionType !== sessionType || !isEqual(prevRates, rates) || prevModality !== modality) {
      dispatch(change(PAYMENT_FORM.NAME, PAYMENT_FORM.FIELDS.SESSION_RATE, initialValues.session_rate))
    }
  }, [
    prevGuaranteedTime,
    guaranteedTime,
    prevPrepopInfo,
    prepopInfo,
    prevSessionType,
    sessionType,
    prevRates,
    rates,
    getInitialValues,
    initialize,
    dispatch,
    prevModality,
    modality,
  ])

  const eligibilityCheck = () => {
    // Fire checkEligibility if there are no corresponding errors for eligibilityCheckFields
    // And eligibilityCheck data is different than previous request
    if (
      isEmpty(pick(synchronousErrors, Object.keys(eligibilityCheckFields))) &&
      !isEqual(submittedEligibityCheckData, eligibilityCheckFields)
    ) {
      checkEligibility(convertToFormData(eligibilityCheckFields)).then((response: any) => {
        if (response.user_eligibility.status === false) {
          displayIneligibleClientModal()
        }
        setSubmittedEligibilityCheckData(eligibilityCheckFields)
      })
    }
  }

  const convertToFormData = (vals: any) => {
    const formData = new FormData()
    Object.keys(vals).forEach((key) => {
      let val = vals[key]
      if (
        key === PAYMENT_FORM.FIELDS.DATE_OF_BIRTH ||
        key === PAYMENT_FORM.FIELDS.VISIT_DATE ||
        key === PAYMENT_FORM.FIELDS.ELIGIBLE_MEMBER_DOB
      ) {
        val = dateUtils.getDisplayDate(val, 'YYYY-MM-DD')
      }
      if (
        key === PAYMENT_FORM.FIELDS.DIAGNOSIS ||
        key === PAYMENT_FORM.FIELDS.COMPANY ||
        key === PAYMENT_FORM.FIELDS.PRIMARY_CONDITION ||
        key === PAYMENT_FORM.FIELDS.SECONDARY_CONDITION ||
        key === PAYMENT_FORM.FIELDS.EBT_TYPE
      ) {
        val = val.map((d: any) => d.value).toString()
      }
      if (key === PAYMENT_FORM.FIELDS.LYRA_CODE) {
        val = sanitizeLyraCode(val)
      }
      if (key === PAYMENT_FORM.FIELDS.LOCATION) {
        if (Array.isArray(val)) {
          val = val[0]
        }
        // Only send over the state and zipcode of provider in-person address
        val = JSON.stringify({
          state: val.state,
          zipcode: val.zipcode,
        })
      }
      formData.append(key, val)
    })
    return formData
  }

  const providerChangeHandler = (event: ChangeEvent, providerId: string) => {
    if (hasRole(roles, ROLES.PRACTICES_ADMIN)) {
      getProviderRates({ id: providerId })
    }

    // Clear the dynamic fields since they are based on the selected provider attributes.
    dispatch(change(PAYMENT_FORM.NAME, PAYMENT_FORM.FIELDS.IS_GUARANTEED_TIME, false))

    selectProvider(providerId)
  }

  const lastSessionHandler = (e: ChangeEvent<HTMLInputElement>, value: boolean) => {
    if (e.target.name === PAYMENT_FORM.FIELDS.LAST_SESSION && value) {
      dispatch(change(PAYMENT_FORM.NAME, PAYMENT_FORM.FIELDS.LAST_SESSION_TERMINATED, false))
    } else if (e.target.name === PAYMENT_FORM.FIELDS.LAST_SESSION_TERMINATED && value) {
      dispatch(change(PAYMENT_FORM.NAME, PAYMENT_FORM.FIELDS.LAST_SESSION, false))
    }
  }

  const submitForm = (values: any) => {
    hideAlerts()

    const formData = convertToFormData(values.toJS())
    const isPaymentsAdmin = hasRole(roles, ROLES.PAYMENTS_ADMIN)

    return submitPaymentRequest(formData, false, isProgramLevelSessionLimitEnabled)
      .then((rpReturn: any) => {
        const { APPROACHING_ATTESTATION, ATTESTATION_PRIOR_AUTHORIZATION, ATTESTATION_NEEDED } =
          specialityCareAttestationStatuses
        // Coaches should not be asked to provide ICD10 and verify co-pay consent for coaching health plan sessions.
        // Default values will be used when the health plan claim is generated.
        if (
          !coachingSessionTypesList.includes(rpReturn.data.data.session_type) &&
          (rpReturn.data.health_plan_extension_eligible ||
            [APPROACHING_ATTESTATION, ATTESTATION_PRIOR_AUTHORIZATION, ATTESTATION_NEEDED].includes(
              rpReturn.data.speciality_care_attestation_status,
            ))
        ) {
          window.scrollTo(0, 0)
          saveHealthPlanCharge(rpReturn.data)
          if (isPaymentsAdmin && location.pathname === PROVIDERS_DETAILS_NEW_PAYMENT.route) {
            navigate(PROVIDERS_DETAILS_HEALTHPLAN_PAYMENT_INFO.route)
          } else if (isPaymentsAdmin && location.pathname === PRACTICES_DETAILS_NEW_PAYMENT.route) {
            navigate(PRACTICES_DETAILS_HEALTHPLAN_PAYMENT_INFO.route)
          } else {
            navigate(HEALTHPLAN_INFO.route)
          }
        } else if (
          !isPaymentsAdmin &&
          (rpReturn.data.email_required || rpReturn.data.health_plan_reminder_email_required)
        ) {
          const customer: CustomerInfo | undefined = customers.find(
            (customer) => customer.value === rpReturn.data.data.company,
          )
          navigate(CLIENT_EMAIL.route, {
            state: {
              patient_id: rpReturn.data.data.patient_lyra_id,
              initialSession: rpReturn.data.session_tracking_status === 'session_limit_initial_session',
              sessionsLeft: parseInt(rpReturn.data.data.sessions_remaining, 10),
              firstName: rpReturn.data.data.first_name,
              lastName: rpReturn.data.data.last_name,
              isHealthPlanEligible: rpReturn.data.health_plan_reminder_email_required,
              company: rpReturn.data.data.company,
              customer: customer,
            },
          })
        } else {
          // forward them along with success message
          clearLyraCodeValidationInfo()
          const { initialValues } = getInitialValues(guaranteedTime)
          initialize(initialValues)
          dispatch(change(PAYMENT_FORM.NAME, PAYMENT_FORM.FIELDS.SESSION_RATE, initialValues.session_rate))
          // Hack: Setting a new key to reset the DateInputField after submit.
          // This should go away when we move to react-final-form
          setDateInputKey(uniqueId('dateInput'))
          if (
            sessionTrackingStatus === 'session_limit_warning' ||
            sessionTrackingStatus === 'session_limit_initial_session'
          ) {
            window.scrollTo(0, 0)
            return
          }
          addAlert({
            show: true,
            contents: bannerMessages.SUBMIT_PAYMENT_SUCCESS,
            style: 'success',
            expires: true,
            autoDismissTimer: 10000,
          })
        }
      })
      .catch(() => window.scrollTo(0, 0))
  }

  const buildModals = () => {
    let showModal = false
    let modalBody = <div />
    let modalType = ''

    // prep payment info if exists
    let sessionsLeft: any
    let company: CustomerInfo | undefined
    let firstName = enteredPatient.first_name
    let lastName = enteredPatient.last_name
    if (paymentResponse) {
      firstName = paymentResponse.first_name
      lastName = paymentResponse.last_name
      company = customers.find((customer: CustomerInfo) => customer.value === paymentResponse.company)
      if (!isEmpty(paymentResponse.sessions_remaining)) {
        sessionsLeft = parseInt(paymentResponse.sessions_remaining, 10)
      }
    }

    // session tracking modal
    switch (sessionTrackingStatus) {
      case 'session_limit_warning':
        showModal = true
        if (sessionsLeft === 0) {
          modalType = 'STS_LAST'
          modalBody = <LastSessionModal firstName={firstName} lastName={lastName} company={company} />
          break
        }
        modalType = 'STS_WARNING'
        modalBody = (
          <SessionsWarningModal
            firstName={firstName}
            lastName={lastName}
            sessionsLeft={sessionsLeft}
            company={company}
          />
        )
        break
      case 'session_limit_initial_session':
        showModal = true
        modalType = 'STS_INITIAL'
        modalBody = (
          <FirstSessionModal firstName={firstName} lastName={lastName} sessionsLeft={sessionsLeft} customer={company} />
        )
        break
      // outcome reminder modal
      case 'session_limit_ok':
        if (outcomeReminderStatus.show_reminder) {
          showModal = true
          modalType = 'outcomeReminder'
          modalBody = (
            <OutcomeReminder
              firstName={firstName}
              outcomeSentDate={dateUtils.getDisplayDate(outcomeReminderStatus.outcome_sent_date, 'MMMM Do')}
              closeFunc={() => closeRequestPaymentModal(modalType)}
            />
          )
        }
    }
    if (sessionsExceeded === 'session_limit_exceeded') {
      showModal = true
      modalBody = <SessionsExceededModal firstName={firstName} lastName={lastName} company={company} />
      modalType = 'sessionsExceeded'
    }

    // duplicate payment modal
    if (showDuplicateModal) {
      showModal = true
      modalType = 'duplicatePayment'
      modalBody = <DuplicatePaymentModal closeFunc={() => closeRequestPaymentModal(modalType)} />
    }

    // exceeded cancelled sessions modal
    if (showCancelledSessionsExceededModal) {
      showModal = true
      modalBody = (
        <CancelledSessionsExceededModal
          firstName={firstName}
          lastName={lastName}
          visitDate={enteredVisitDate}
          company={company}
        />
      )
      modalType = 'cancelledSessionsExceeded'
    }

    // ineligible client modal
    if (showIneligibleClientModal) {
      showModal = true
      modalType = 'ineligibleClient'
      modalBody = <ClientIneligibleModal closeFunc={() => closeRequestPaymentModal(modalType)} />
    }

    return {
      showModal,
      modalBody,
      modalType,
    }
  }

  const shouldDisableSubmitButton = () => {
    if (!attendance) {
      return false
    } else if (
      selectedCompany?.[0]?.lyra_code_required === 'true' &&
      !hasRole(roles, ROLES.PAYMENTS_ADMIN) &&
      !guaranteedTime
    ) {
      return !eligibilityStatus || !formIsValid
    }
    return !formIsValid
  }

  if (!user.is_registered) {
    if (hasRole(roles, ROLES.PRACTICES_ADMIN)) {
      return <Navigate to={PRACTICES_REGISTER.route} />
    } else if (hasRole(roles, ROLES.INDIVIDUAL_PROVIDER)) {
      return <Navigate to={PROVIDERS_REGISTER.route} />
    }
  }

  if (loading && !provider) {
    return (
      <ContentLayout>
        <div styleName='loading-container'>
          <LoadingIndicator size={45} />
        </div>
      </ContentLayout>
    )
  }

  if (!loading && (hasRole(roles, ROLES.PRACTICES_ADMIN) || isPractice) && providersList?.length === 0) {
    return (
      <ContentLayout>
        <div styleName='no-providers-container'>
          <h1>No Providers Found</h1>
          <p className='paragraph-small'>
            No providers were found for this practice. Please align registered providers to this practice and try again.
          </p>
        </div>
      </ContentLayout>
    )
  }

  const { showModal, modalBody, modalType } = buildModals()
  const { initialValues, disabledFields } = getInitialValues(guaranteedTime)

  const showAvailabilityCard = !hasRole(roles, ROLES.PAYMENTS_ADMIN)
  return (
    <BootstrapContainer col='col-md-10 col-md-offset-1'>
      {/* @ts-expect-error TS(2741): Property 'isMinified' is missing in type '{}' but ... Remove this comment to see the full error message */}
      {showAvailabilityCard && <AvailabilityCard />}
      <div styleName='title-block'>
        <h1 styleName='title'>Request Payment</h1>
      </div>
      <div styleName='request-payment-container'>
        <PaymentRequestForm
          attendance={attendance}
          dateInputKey={dateInputKey}
          disabledFields={disabledFields}
          handleSubmit={handleSubmit}
          submitFunction={throttle(submitForm, 2000)}
          submitting={submitting}
          providers={providersList}
          showEligibleMemberDetails={showEligibleMemberDetails}
          showOutcomesMessage={providerOutcomesAgreed}
          patientOutcomesAgreed={patientOutcomesAgreed}
          sessionType={sessionType}
          showDiagnosisDescription={showDiagnosisDescription}
          clientOutcomesEligibility={clientOutcomesEligibility}
          providerChangeHandler={providerChangeHandler}
          initialCompany={initialValues.company || initialCompany}
          initialDiagnoses={initialDiagnoses}
          initialModality={differentialPricingRatesEnabled ? undefined : initialModality}
          lastSessionHandler={lastSessionHandler}
          sponsoringCompanies={customers ?? []}
          guaranteedTime={guaranteedTime}
          guaranteedTimeEligible={guaranteedTimeEligible}
          selectedCompany={selectedCompany}
          primaryButtonText='Submit Payment'
          lyraCodeValidationUser={lyraCodeValidationInfo?.user_info}
          disableSubmitButton={shouldDisableSubmitButton()}
          showLyraCode={selectedCompany?.[0]?.lyra_code_enabled === 'true' && !guaranteedTime}
          hideDependentField={selectedCompany?.[0]?.hide_dependent_field === 'true'}
          selectedProvider={selectedProvider}
          loggedInUser={user}
          config={config}
          providerAddresses={providerAddresses ?? []}
          locationChangeHandler={locationChangeHandler}
          primaryCondition={primaryCondition}
          secondaryCondition={secondaryCondition}
          ebtType={ebtType}
          rates={rates}
        />
        <BaseModal isOpen={showModal} body={modalBody} closeModal={() => closeRequestPaymentModal(modalType)} />
      </div>
    </BootstrapContainer>
  )
}

type RequestPaymentProps = {
  handleSubmit: () => void
  initialize: (initialValues: object) => void
  initialized: boolean
  submitting: boolean
  user: ProviderUser
  providersList: ProviderAdminProviderInfo[]
  showEligibleMemberDetails: boolean
  sessionType: string
  modality: string
  showDiagnosisDescription: boolean
  clientOutcomesEligibility: boolean
  enteredPatient: { first_name: string; last_name: string; dob: string }
  initialCompany: CustomerInfo[]
  initialDiagnoses: { label: string; value: string }[]
  initialModality: string
  dispatch: Dispatch<AnyAction>
  customers: CustomerInfo[]
  sessionTrackingStatus: string
  sessionsExceeded: string
  paymentResponse: Transfer
  showDuplicateModal: boolean
  showCancelledSessionsExceededModal: boolean
  enteredVisitDate: string
  guaranteedTimeEligible: boolean
  guaranteedTime: boolean
  providerOutcomesAgreed: boolean
  patientOutcomesAgreed: boolean
  rates: ProviderRates
  outcomeReminderStatus: { show_reminder: boolean; outcome_sent_date: string }
  selectedCompany: CustomerInfo[]
  config: Config
  prepopInfo: ChargeCopyPrepopInfo
  formIsValid: boolean
  activeField: string
  asyncValidate: (fieldName: string, fieldValue: string, manual: string) => void
  enteredlyraCode: string
  touch: (fieldName: string) => void
  eligibilityCheckFields: ChargeEligiblityPayload
  synchronousErrors: any
  showIneligibleClientModal: boolean
  eligibilityStatus: boolean
  lyraCodeValidationInfo: LyraCodeValidationInfo
  selectedProvider: ProviderAdminProviderInfo
  isPractice: boolean
  attendance: string
  providerAddresses: Address[]
  providerCalendarState: string
  primaryCondition: { label: string; value: string }[]
  secondaryCondition: { label: string; value: string }[]
  ebtType: { label: string; value: string }[]
  differentialPricingEnabled: boolean
  differentialPricingModifier: number
  provider: ProviderInfo
  actions: {
    getProviderCapacity: ({ id }: { id: string }) => Promise<any>
    getPracticeProviders: (id: string) => Promise<any>
    addAlert: ({
      show,
      contents,
      style,
      expires,
      autoDismissTimer,
    }: {
      show: boolean
      contents: React.ReactNode
      style: string
      expires: boolean
      autoDismissTimer?: number
    }) => void
    getProviderRates: ({ id }: { id: string }) => void
    getProvider: (providerId: string) => void
    getChargeToCopy: (chargeId: string) => Promise<any>
    selectProvider: (providerId: string) => void
    checkEligibility: (eligiblityObj: FormData) => Promise<any>
    displayIneligibleClientModal: () => void
    getPayments: ({
      status,
      page,
      limit,
      providerId,
      isHealthPlan,
      patientId,
    }: {
      status: string
      page: number
      limit: number
      providerId: string
      isHealthPlan: boolean
      patientId: string
    }) => Promise<any>
    getClient: (
      enteredPatient: { first_name: string; last_name: string; dob: string },
      cancelFunction: CancelToken,
    ) => Promise<any>
    hideAlerts: () => void
    submitPaymentRequest: (data: FormData, isBCx: boolean, isProgramLevelSessionLimitEnabled: boolean) => Promise<any>
    clearLyraCodeValidationInfo: () => void
    closeRequestPaymentModal: (modalType: string) => void
    clearRequestPaymentStore: () => void
    saveHealthPlanCharge: (HPCharge: any) => void
  }
}

const selector = formValueSelector(PAYMENT_FORM.NAME)

const mapStateToProps = (state: RootState, ownProps: RequestPaymentProps) => {
  const userData = getAuthUser(state)
  // role based fields
  let practiceID
  let provider: ProviderUser | ProviderAdminProviderInfo | undefined = userData
  let rates = getProviderDetailsRates(state)
  if (provider?.onsite) {
    rates = getRequestPaymentRates(state)
  }
  const providersList = getRequestPaymentProvidersList(state) ?? []
  if (hasRole(userData?.roles, ROLES.PRACTICES_ADMIN)) {
    provider = getRequestPaymentSelectedProvider(state)
    practiceID = userData?.practice_id
  } else if (hasRole(userData?.roles, ROLES.PAYMENTS_ADMIN)) {
    provider = getProviderDetailsData(state)
    if (ownProps.isPractice) {
      provider = getRequestPaymentSelectedProvider(state)
      practiceID = getPracticeDetailsDataId(state)
    }
    rates = provider?.rates
  }

  const attendance = selector(state, PAYMENT_FORM.FIELDS.ATTENDANCE)
  const relationship = selector(state, PAYMENT_FORM.FIELDS.RELATIONSHIP)
  const sessionType = selector(state, PAYMENT_FORM.FIELDS.SESSION_TYPE)
  const modality = selector(state, PAYMENT_FORM.FIELDS.MODALITY)
  const diagnosisArray = selector(state, PAYMENT_FORM.FIELDS.DIAGNOSIS)
  const firstName = selector(state, PAYMENT_FORM.FIELDS.FIRST_NAME)
  const lastName = selector(state, PAYMENT_FORM.FIELDS.LAST_NAME)
  const clientDOB = selector(state, PAYMENT_FORM.FIELDS.DATE_OF_BIRTH)
  const customers = getRequestPaymentCustomers(state)
  const chargeCopy = getChargeCopyPrepopInfo(getRequestPaymentCopy(state), customers)
  let selectedCompany: CustomerInfo[] = selector(state, PAYMENT_FORM.FIELDS.COMPANY)
  selectedCompany = selectedCompany?.length > 0 ? selectedCompany : chargeCopy.initialCompany
  const selectedCustomerInfo = selectedCompany?.[0]
  const visitDate = selector(state, PAYMENT_FORM.FIELDS.VISIT_DATE)
  const lyraCode = selector(state, PAYMENT_FORM.FIELDS.LYRA_CODE)
  const eligibleMemberFirstName = selector(state, PAYMENT_FORM.FIELDS.ELIGIBLE_MEMBER_FIRST_NAME)
  const eligibleMemberLastName = selector(state, PAYMENT_FORM.FIELDS.ELIGIBLE_MEMBER_LAST_NAME)
  const guaranteedTime = selector(state, PAYMENT_FORM.FIELDS.IS_GUARANTEED_TIME)
  const providerPaymentInfo = {
    provider_lyra_id: provider?.id || (provider as any)?.lyra_id,
    ...(practiceID && { practice_id: practiceID }),
  }
  const primaryCondition = selector(state, PAYMENT_FORM.FIELDS.PRIMARY_CONDITION)
  const secondaryCondition = selector(state, PAYMENT_FORM.FIELDS.SECONDARY_CONDITION)
  const ebtType = selector(state, PAYMENT_FORM.FIELDS.EBT_TYPE)

  // get patient outcomes and employer data
  const { patientOutcomesAgreed, patientEmployer } = getPatientPrepopInfo(getRequestPaymentPatient(state), customers)

  // Used for visit date field validation
  const minDate = hasRole(userData?.roles, ROLES.PAYMENTS_ADMIN) ? null : subDays(new Date(), 90)

  // Set outcomes eligibility text
  let isUnderage = false
  if (clientDOB !== undefined) {
    isUnderage = formValidationConstants.isValidDateOfBirth(clientDOB, { min: 0, max: 17 }).isValid
  }
  const isClientOutcomesEligible =
    !isUnderage && (sessionType === undefined || outcomesEligibleSessionTypes[sessionType])

  const showEligibleMemberDetails = shouldShowPaymentFormEligibleMemberDetails(
    relationship,
    isUnderage,
    selectedCustomerInfo,
  )
  const formState = (state.form as unknown as ImmutableTypedMap<typeof state.form>).toJS() as typeof state.form
  return {
    providersList,
    user: userData,
    guaranteedTime,
    guaranteedTimeEligible: provider?.guaranteed_time_eligible ?? false,
    showEligibleMemberDetails,
    sessionType,
    modality,
    rates,
    practiceID,
    prepopInfo: Object.assign({}, providerPaymentInfo, chargeCopy.prepopInfo),
    initialCompany: chargeCopy.initialCompany || patientEmployer,
    initialDiagnoses: chargeCopy.initialDiagnoses,
    initialModality: chargeCopy.prepopInfo.modality,
    showDiagnosisDescription: includes(diagnosisArray, 'Other'),
    clientOutcomesEligibility: isClientOutcomesEligible,
    providerOutcomesAgreed: provider?.outcomes_agreed ?? false,
    patientOutcomesAgreed,
    customers: customers,
    sessionTrackingStatus: getRequestPaymentDataSessionTrackingStatus(state),
    outcomeReminderStatus: getRequestPaymentDataOutcomeReminderStatus(state) ?? {},
    sessionsExceeded: getRequestPaymentResponseDataSessionTrackingStatus(state),
    showDuplicateModal: getRequestPaymentShowDuplicateModal(state),
    showCancelledSessionsExceededModal: getRequestPaymentShowCancelledSessionsExceededModal(state),
    paymentResponse: getRequestPaymentDataData(state),
    clientDOB,
    enteredVisitDate: visitDate,
    enteredPatient: {
      first_name: firstName,
      last_name: lastName,
      dob: clientDOB,
    },
    initialICD10s: Object.fromEntries(
      Object.entries(getRequestPaymentCopy(state) ?? {}).filter(
        ([key, value]: [string, string]) => [ICD10_SECONDARY_FIELD, ICD10_PRIMARY_FIELD].includes(key) && value,
      ),
    ),
    eligibilityCheckFields: {
      [PAYMENT_FORM.FIELDS.COMPANY]: selectedCompany,
      [PAYMENT_FORM.FIELDS.FIRST_NAME]: firstName,
      [PAYMENT_FORM.FIELDS.LAST_NAME]: lastName,
      ...(selectedCustomerInfo?.lyra_code_enabled === 'true' && { [PAYMENT_FORM.FIELDS.LYRA_CODE]: lyraCode }),
      ...(showEligibleMemberDetails && {
        [PAYMENT_FORM.FIELDS.ELIGIBLE_MEMBER_FIRST_NAME]: eligibleMemberFirstName,
        [PAYMENT_FORM.FIELDS.LAST_NAME]: eligibleMemberLastName,
      }),
      [PAYMENT_FORM.FIELDS.VISIT_DATE]: visitDate,
    },
    selectedCompany,
    selectedProvider: getRequestPaymentSelectedProvider(state),
    lyraCodeValidationInfo: getRequestPaymentLyraCodeValidationInfo(state) ?? {},
    synchronousErrors: getFormSyncErrors(PAYMENT_FORM.NAME)(state),
    showIneligibleClientModal: getRequestPaymentShowIneligibleClientModal(state),
    activeField: formState[PAYMENT_FORM.NAME]?.active,
    enteredlyraCode: lyraCode,
    eligibilityStatus: getRequestPaymentEligibilityStatus(state) ?? false,
    $$formMeta: getFormMeta(PAYMENT_FORM.NAME)(state),
    formIsValid: isValid(PAYMENT_FORM.NAME)(state),
    config: getAuthConfig(state),
    attendance,
    providerAddresses: getProviderDetailsAddresses(state),
    provider: getProviderDetailsData(state),
    providerCalendarState: getProviderDetailsCalendarState(state),
    minDate,
    primaryCondition,
    secondaryCondition,
    ebtType,
    differentialPricingEnabled: getProviderDetailsDiffPricingEnabled(state),
    differentialPricingModifier: getProviderDetailsDiffPricingModifier(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    actions: bindActionCreators(
      {
        ...requestPaymentActions,
        ...alertActions,
        ...clientsDataActions,
        ...providerDetailsActions,
        getProviderCapacity,
        saveHealthPlanCharge,
      },
      dispatch,
    ),
  }
}

export const RequestPaymentReduxForm = reduxForm({
  form: PAYMENT_FORM.NAME,
  validate: paymentRequestValidate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  asyncValidate: paymentRequestAsyncValidate,
  warn: requestPaymentWarnings,
  // @ts-expect-error TS(2367): This condition will always return 'false' since th... Remove this comment to see the full error message
  shouldAsyncValidate: ({ trigger }) => trigger === 'manual',
})(CSSModules(RequestPayment, styles))

export default connect(mapStateToProps, mapDispatchToProps)(RequestPaymentReduxForm)
