import { createReducer } from '@reduxjs/toolkit'

import { Assignment, AssignmentResponse, AssignmentSummary } from '@lyrahealth-inc/shared-app-logic'

import {
  BULK_UNSET_ASSIGNMENT,
  CLEAR_ASSIGNMENT,
  CLEAR_ASSIGNMENT_RESPONSE,
  CLEAR_ASSIGNMENTS,
  DECREASE_ASSIGNMENT_UNREAD_COUNT,
  DECREASE_SELECTED_ASSIGNMENT_UNREAD_COUNT,
  DELETE_ASSIGNMENT_RESPONSE,
  GET_ASSIGNMENT_SUMMARY,
  GET_ASSIGNMENTS,
  SELECT_ASSIGNMENT,
  SELECT_ASSIGNMENT_RESPONSE,
  SET_ASSIGNMENT,
  SET_ASSIGNMENT_RESPONSE,
  UNSET_ASSIGNMENT,
  UPDATE_ASSIGNMENT,
  UPDATE_ASSIGNMENT_RESPONSE,
} from '../../../common/constants/reduxConstants'

type AssignmentsState = {
  data: Assignment[]
  assignmentDetails?: Assignment
  responseDetails?: AssignmentResponse
  assignmentSummary: AssignmentSummary[]
}
const initialState = {
  data: [],
  assignmentSummary: [],
}

export default createReducer<AssignmentsState>(initialState, (builder) => {
  builder.addCase(SELECT_ASSIGNMENT, (state, action: any) => {
    state.assignmentDetails = action.data
    return state
  })
  builder.addCase(GET_ASSIGNMENTS, (state, action: any) => {
    state.data = action.data
    return state
  })
  builder.addCase(GET_ASSIGNMENT_SUMMARY, (state, action: any) => {
    const currentState = [...state.assignmentSummary]
    const exists = currentState.some((item) => item.assignment_id === action.data.assignment_id)
    if (exists) {
      const filtered = currentState.filter((item) => item.assignment_id !== action.data.assignment_id)
      state.assignmentSummary = [...filtered, action.data]
    } else {
      const updatedAssignmentSummary = [...state.assignmentSummary, action.data]
      state.assignmentSummary = updatedAssignmentSummary
    }
    return state
  })
  builder.addCase(SET_ASSIGNMENT, (state, action: any) => {
    const assignment = action.data
    state.data = [...state.data, assignment]
    state.assignmentDetails = assignment
    return state
  })
  builder.addCase(UNSET_ASSIGNMENT, (state, action: any) => {
    state.data = state.data.filter((assignment) => assignment.id !== action.data)
    state.assignmentDetails = undefined
    return state
  })
  builder.addCase(BULK_UNSET_ASSIGNMENT, (state, action: any) => {
    state.data = state.data.filter((assignment) => !action.data.includes(assignment.id))
    return state
  })
  builder.addCase(UPDATE_ASSIGNMENT, (state, action: any) => {
    const idxToUpdate = state.data.findIndex((assignment) => assignment.id === action.data.id)
    if (idxToUpdate !== -1) {
      state.data[idxToUpdate] = action.data
    }
    return state
  })
  builder.addCase(DECREASE_ASSIGNMENT_UNREAD_COUNT, (state, action: any) => {
    const assignmentIdx = state.data.findIndex((assignment) => assignment.id === action.data.assignmentId)
    if (assignmentIdx !== -1) {
      const unreadCount = state.data[assignmentIdx].unread_count
      state.data[assignmentIdx].unread_count = unreadCount - 1
    }
    return state
  })
  builder.addCase(DECREASE_SELECTED_ASSIGNMENT_UNREAD_COUNT, (state) => {
    if (!state.assignmentDetails) {
      return state
    }
    const unreadCount = state.assignmentDetails.unread_count
    state.assignmentDetails.unread_count = unreadCount - 1
    return state
  })
  builder.addCase(SET_ASSIGNMENT_RESPONSE, (state, action: any) => {
    const assignment = state.data.find((assignment) => assignment.id === action.data.assignment_id)
    let newList: AssignmentResponse[]
    if (assignment?.assignment_responses && assignment.assignment_responses.length > 0) {
      newList = assignment.assignment_responses.concat(action.data)
    } else {
      newList = [action.data]
    }

    if (assignment) {
      assignment.assignment_responses = newList
    }

    if (state.assignmentDetails) {
      state.assignmentDetails.assignment_responses = newList
    }

    return state
  })
  builder.addCase(UPDATE_ASSIGNMENT_RESPONSE, (state, action: any) => {
    const assignment = state.data.find((assignment) => assignment.id === action.data.assignment_id)
    const responsesIdx = assignment?.assignment_responses?.findIndex((response) => response.id === action.data.id)

    if (responsesIdx !== undefined && responsesIdx !== -1) {
      assignment!.assignment_responses![responsesIdx] = action.data
    }

    const selectedResponseIdx = state.assignmentDetails?.assignment_responses?.findIndex(
      (response) => response.id === action.data.id,
    )

    if (selectedResponseIdx !== undefined && selectedResponseIdx !== -1) {
      state.assignmentDetails!.assignment_responses![selectedResponseIdx] = action.data
    }
    return state
  })
  builder.addCase(SELECT_ASSIGNMENT_RESPONSE, (state, action: any) => {
    state.responseDetails = action.data
    return state
  })
  builder.addCase(CLEAR_ASSIGNMENT_RESPONSE, (state) => {
    state.responseDetails = undefined
    return state
  })
  builder.addCase(DELETE_ASSIGNMENT_RESPONSE, (state, action: any) => {
    // Get provider draft response index
    const assignment = state.data.find((assignment) => assignment.id === action.data.assignment_id)

    if (assignment) {
      assignment.assignment_responses = assignment.assignment_responses?.filter(
        (response) => response.id !== action.data.id,
      )
      const responseCount = assignment.response_count
      if (responseCount > 0) {
        assignment.response_count = responseCount - 1
      }
    }

    if (state.assignmentDetails) {
      state.assignmentDetails.assignment_responses = state.assignmentDetails.assignment_responses?.filter(
        (response) => response.id !== action.data.id,
      )
      const responseCount = state.assignmentDetails.response_count
      if (responseCount > 0) {
        state.assignmentDetails.response_count = responseCount - 1
      }
    }

    return state
  })
  builder.addCase(CLEAR_ASSIGNMENT, (state) => {
    state.responseDetails = undefined
    state.assignmentDetails = undefined
    return state
  })
  builder.addCase(CLEAR_ASSIGNMENTS, () => initialState)
})
