import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { isFuture } from 'date-fns'
import { sortBy } from 'lodash-es'

import {
  ClientListClientObject,
  ConversationObject,
  getDateFromAppointment,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'
import { ConversationFilter, ConversationTag } from '@lyrahealth-inc/ui-core-crossplatform'

import { getClientFullName } from '../../../common/utils/utils'
import { getAuthUserId } from '../../../data/auth/authSelectors'
import {
  getActiveV2Clients,
  getInactiveV2Clients,
  getInitialActiveClientsFetched,
  getInitialInactiveClientsFetched,
} from '../../../data/lyraTherapy/clientSelectors'
import { useGetAppointmentsQuery } from '../../clients/clientDetails/data/appointmentsApi'
import { useConversationsData } from '../data/hooks/useConversationsData'

type ClientListClientObjectWithExtraFields = ClientListClientObject & { active: boolean; unscheduled: boolean }

const filterByTag = (
  client: ClientListClientObjectWithExtraFields,
  filters: ConversationFilter[],
  conversations: ConversationObject[],
) => {
  if (filters.includes(ConversationFilter.ACTIVE)) {
    if (!client.active) {
      return false
    }
  }
  const conversation = conversations.find((conversation) => conversation.patient_lyra_id === client.id)
  if (filters.includes(ConversationFilter.UNREAD)) {
    if (!conversation || (conversation.conversation_attributes.unread_provider_messages_count ?? 0) === 0) {
      return false
    }
  }

  if (filters.includes(ConversationFilter.UNSCHEDULED)) {
    if (!client.active || !client.unscheduled) {
      return false
    }
  }

  return true
}

const useGetFilteredConversations = (filters: ConversationFilter[]) => {
  const { data: conversations, isLoading: isLoadingConversations } = useConversationsData()
  const activeClientsFetched = useSelector(getInitialActiveClientsFetched)
  const inactiveClientsFetched = useSelector(getInitialInactiveClientsFetched)

  const { isPreferredNameEnabled } = useFlags()
  const activeClients = useSelector(getActiveV2Clients)
  const inactiveClients = useSelector(getInactiveV2Clients)
  const userId = useSelector(getAuthUserId)
  const { data: appointments, isLoading: isLoadingAppointments } = useGetAppointmentsQuery({ providerId: userId })
  const futureAppointments = useMemo(
    () =>
      appointments?.filter((appointment) => {
        const sessionStart = getDateFromAppointment(appointment)
        return isFuture(sessionStart) && appointment.appointmentStatus !== 'canceled'
      }),
    [appointments],
  )

  const allClients = useMemo(
    () =>
      [
        ...activeClients.map((client) => ({
          ...client,
          active: true,
        })),
        ...inactiveClients.map((client) => ({
          ...client,
          active: false,
        })),
      ].map((client) => ({
        ...client,
        unscheduled: !futureAppointments?.some((appointment) => appointment.userInfo?.lyraId === client.id),
      })),
    [activeClients, futureAppointments, inactiveClients],
  )

  const mappedConversations = useMemo(() => {
    if (!conversations) {
      return []
    }
    return allClients
      .filter((client) => filterByTag(client, filters, conversations))
      .map((client) => {
        const conversation = conversations.find((conversation) => conversation.patient_lyra_id === client.id)
        return {
          clientId: client.id,
          clientName: client ? getClientFullName(client, isPreferredNameEnabled) : '',
          lastMessageTime: conversation?.conversation_last_message_sent_datetime
            ? new Date(conversation.conversation_last_message_sent_datetime)
            : null,
          hasUnreadMessage:
            !!conversation && (conversation.conversation_attributes.unread_provider_messages_count ?? 0) > 0,
          tag: client?.active
            ? client?.unscheduled
              ? ConversationTag.UNSCHEDULED
              : undefined
            : ConversationTag.INACTIVE,
        }
      })
  }, [allClients, conversations, filters, isPreferredNameEnabled])

  const sortedConversations = useMemo(
    () =>
      sortBy(mappedConversations, (conversation) =>
        conversation.lastMessageTime ? conversation.lastMessageTime.getTime() * -1 : Number.MIN_VALUE,
      ),
    [mappedConversations],
  )

  return {
    conversations: sortedConversations,
    isLoading: isLoadingConversations || isLoadingAppointments || !activeClientsFetched || !inactiveClientsFetched,
  }
}

export default useGetFilteredConversations
