import React, { FunctionComponent, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import CSSModules from 'react-css-modules'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import BottomSheetGorhom from '@gorhom/bottom-sheet'
import { addMinutes, formatISO, subMinutes } from 'date-fns'
import { fromJS } from 'immutable'
import { cloneDeep, get } from 'lodash-es'
import { bindActionCreators } from 'redux'
import styled from 'styled-components/native'

import {
  Appointment,
  ClientObject,
  Episode,
  getDateFromAppointment,
  RECORDING_STATUS,
  SESSION_TYPES,
  translateFeedbackResponse,
  USER_ROLES,
  VideoSession as VideoSessionData,
} from '@lyrahealth-inc/shared-app-logic'
import { BaseModal, SessionBanner, VideoSession } from '@lyrahealth-inc/ui-core'
import {
  FormChangeProperties,
  Modal,
  RecordConsentModal,
  VideoSessionFeedback,
  ZoomProvider,
  ZoomVideoSession,
} from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './providerSession.module.scss'
import RecordingConsent from './recordingConsent/RecordingConsent'
import { track } from '../../../../../mixpanel/mixpanelTracking'
import { axiosAuthInstance } from '../../../common/http/axiosInstance'
import { getAuthIsLoggedIn, getAuthUser, getAuthUserId } from '../../../data/auth/authSelectors'
import { getClientDetailsData, getClientsData } from '../../../data/lyraTherapy/clientSelectors'
import { RootState, useAppDispatch } from '../../../data/store'
import {
  useGetAppointmentsQuery,
  useUpdateAppointmentMutation,
} from '../../../lyraTherapy/clients/clientDetails/data/appointmentsApi'
import {
  clearSelectedClientStore,
  getLtClient,
  selectLtClient,
} from '../../../lyraTherapy/clients/clientDetails/data/ltClientDetailsAutoActions'
import { setToastContent } from '../../../lyraTherapy/data/ltToastAutoActions'
import {
  clearSessionInfo,
  getToken,
  pingLT,
  submitVideoQualityFeedback,
  toggleVideoSessionStatus,
  updateVideoSessionMetadata,
  updateVideoSessionSettings,
} from '../../../lyraTherapy/data/ltVideoAutoActions'
import { getLTVideoSessionInfo, getLTVideoSettings } from '../../../lyraTherapy/data/ltVideoSelectors'
import { getEpisode, updateEpisode } from '../../../lyraTherapy/episodes/data/episodesAutoActions'
import { getMessagesActiveLiveMsgAppt, inLiveMsgSession } from '../../../lyraTherapy/messages/data/messagesSelectors'
import { setActiveLiveMsgAppt, setLiveMsgClientIdToOpen } from '../../../lyraTherapy/messages/data/messagesSlice'
import { CLIENT_HOME } from '../../constants/routingConstants'
import { logToSumoLogic } from '../../utils/utils'
import JoinSessionBanner from '../joinSessionBanner/JoinSessionBanner'

const VideoSessionFeedbackContainer = styled.View({
  height: '100%',
})

type ProviderSessionProps = ConnectedProps<typeof connector>

const ProviderSession: FunctionComponent<ProviderSessionProps> = ({
  settings,
  sessionInfo,
  inLiveMsgSession,
  activeLiveMsgAppt,
  clientDetails,
  clients,
  actions: {
    getToken,
    pingLT,
    toggleVideoSessionStatus,
    getEpisode,
    updateVideoSessionMetadata,
    getLtClient,
    updateEpisode,
    submitVideoQualityFeedback,
    clearSessionInfo,
    selectLtClient,
    clearSelectedClientStore,
    updateVideoSessionSettings,
    setToastContent,
  },
}) => {
  const user = useSelector(getAuthUser)
  const userId = useSelector(getAuthUserId)

  const isLoggedIn = useSelector(getAuthIsLoggedIn)
  const [isRecording, setIsRecording] = useState(false)
  const [showRecordingStatus, setShowRecordingStatus] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState(<div />)
  const [modalProps, setModalProps] = useState({})
  const [hasOpenedSessionFromBanner, setHasOpenedSessionFromBanner] = useState(false)
  const [zoomAppointment, setZoomAppointment] = useState<Appointment | null>(null)
  const [appointmentIdFromSessionInfo, setAppointmentIdFromSessionInfo] = useState<string | null>(null)
  const [showRecordingModal, setShowRecordingModal] = useState(false)
  const [showCallQualityModal, setShowCallQualityModal] = useState(false)
  const refreshSessionId = useRef<number | null>(null)
  const bottomSheetRef = useRef<BottomSheetGorhom>(null)
  const lowSnapPoint = 370
  const [snapPoints, setSnapPoints] = useState<[number]>([lowSnapPoint])
  const [scrollEnabled, setScrollEnabled] = useState<boolean>(false)
  const navigate = useNavigate()
  const lastVideoSessionId = useRef<string>()
  const dispatch = useAppDispatch()
  const { data: allAppointments = [] } = useGetAppointmentsQuery(
    { providerId: userId },
    { pollingInterval: 1000 * 60 * 60 * 3 },
  )
  const [updateAppointment] = useUpdateAppointmentMutation()
  const appointments = useMemo(
    () => allAppointments.filter((session) => ['newAppt', 'rescheduled'].includes(session.appointmentStatus)),
    [allAppointments],
  )

  const openBottomSheet = useCallback(() => {
    bottomSheetRef.current?.expand()
  }, [])
  const closeBottomSheet = useCallback(() => {
    bottomSheetRef?.current?.close()
    setSnapPoints([lowSnapPoint])
    setScrollEnabled(false)
  }, [])

  const onFormChange = ({ values }: FormChangeProperties) => {
    if ((values.FeedbackStars as number) <= 4) {
      setSnapPoints([689])
      setScrollEnabled(true)
    } else {
      setSnapPoints([lowSnapPoint])
      setScrollEnabled(false)
    }
    bottomSheetRef.current?.expand()
  }

  useEffect(() => {
    return () => {
      if (refreshSessionId.current) {
        window.clearInterval(refreshSessionId.current)
      }
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])
  const getTokenForSession: (data: any) => Promise<VideoSessionData> = useCallback(
    ({ apptID, participantID }: any) => {
      const sessionNumber =
        zoomAppointment?.sessionNumber ??
        appointments.find((appt) => appt.appointmentId === parseInt(apptID))?.sessionNumber ??
        -1
      logToSumoLogic('getVideoSessionToken', user?.id, {
        stack: new Error().stack,
      })
      return getToken({
        appointment_id: apptID,
        patient_id: participantID,
        provider_id: user?.id,
        session_number: sessionNumber,
      }) as unknown as Promise<VideoSessionData>
    },
    [zoomAppointment?.sessionNumber, appointments, user?.id, getToken],
  )

  const sessionOpenedCallback = useCallback(() => {
    if (get(window, 'zE.hide')) {
      ;(window as any).zE.hide()
    }

    setHasOpenedSessionFromBanner(false)
    refreshSessionId.current = window.setInterval(() => {
      track({
        event: 'ping lyra therapy to keep token alive',
        page: 'video session',
        details: 'provider',
      })
      pingLT()
    }, 1000 * 60 * 5)
  }, [pingLT])

  const displayRecordingStatus = useCallback(() => {
    setShowRecordingStatus(true)
  }, [])

  const setAndShowModal = useCallback((modal: ReactElement, props: any) => {
    setShowModal(true)
    setModalData(modal)
    setModalProps(props)
  }, [])

  const disableRecording = useCallback(
    (sessionInfo: VideoSessionData) => {
      // this will disable the button from being clicked repeatedly
      setIsRecording(false)

      const sessionNumber =
        zoomAppointment?.sessionNumber ??
        appointments.find((appt) => appt.appointmentId === parseInt(sessionInfo.appointment_id))?.sessionNumber
      const updateObj = { ...sessionInfo, session_number: sessionNumber }
      updateObj.recording_status = RECORDING_STATUS.disabled
      ;(updateVideoSessionMetadata(updateObj) as unknown as Promise<any>).catch(() => {
        // if there is an error in sending the update call, re-enable the 'Disable recording button'
        setIsRecording(true)
      })
    },
    [appointments, updateVideoSessionMetadata, zoomAppointment?.sessionNumber],
  )

  const disableRecordingForSession = () => {
    if (!sessionInfo) {
      return
    }
    disableRecording(sessionInfo)
  }
  const disableEpisodeRecording = useCallback(
    (episode: Episode, session: VideoSessionData) => {
      if (!episode) {
        return
      }
      disableRecording(session)
      updateEpisode({ ...episode, recording_consent: false })
    },
    [disableRecording, updateEpisode],
  )

  const closeModal = useCallback(() => {
    setShowModal(false)
  }, [])

  const sessionStartedCallback = useCallback(
    (clientId: any) => {
      if (!sessionInfo) {
        return
      }

      setAppointmentIdFromSessionInfo(sessionInfo.appointment_id)

      toggleVideoSessionStatus(true)
      const sessionNumber = appointments.find(
        (appt) => appt.appointmentId === parseInt(sessionInfo?.appointment_id),
      )?.sessionNumber
      ;(getEpisode({ episodeId: sessionInfo.episode_id }) as unknown as Promise<any>).then((result: Episode) => {
        if (result.recording_consent === false) {
          ;(
            updateVideoSessionMetadata(
              Object.assign(cloneDeep(sessionInfo), {
                status: 'started',
                start_time: formatISO(new Date()),
                recording_status: RECORDING_STATUS.disabled,
                session_number: sessionNumber,
              }),
            ) as unknown as Promise<any>
          ).then(() => {
            setIsRecording(false)
          })
          displayRecordingStatus()
        } else {
          ;(
            updateVideoSessionMetadata(
              Object.assign(cloneDeep(sessionInfo), {
                status: 'started',
                start_time: formatISO(new Date()),
                recording_status: RECORDING_STATUS.not_started,
                session_number: sessionNumber,
              }),
            ) as unknown as Promise<any>
          ).then(() => {
            setIsRecording(true)
          })

          setAndShowModal(
            <RecordingConsent
              disableRecording={() => disableRecording(sessionInfo)}
              disableEpisodeRecording={() => disableEpisodeRecording(result, sessionInfo)}
              closeModal={closeModal}
              displayRecordingStatus={displayRecordingStatus}
            />,
            {
              className: 'recording-consent',
              blurable: false,
              showCloseButton: false,
            },
          )
        }
      })
      ;(getLtClient({ clientId }) as unknown as any).then(() => navigate(CLIENT_HOME.route))
    },
    [
      sessionInfo,
      toggleVideoSessionStatus,
      appointments,
      getEpisode,
      getLtClient,
      updateVideoSessionMetadata,
      displayRecordingStatus,
      setAndShowModal,
      closeModal,
      disableRecording,
      disableEpisodeRecording,
      navigate,
    ],
  )

  const submitQualityFeedback = useCallback(
    (responses: FormChangeProperties, videoSessionId?: string) => {
      submitVideoQualityFeedback({
        entity_type: 'video_session',
        entity_id: videoSessionId,
        lyra_id: user?.id,
        user_type: 'provider',
        feedback_response: translateFeedbackResponse(responses.values),
      })
      setShowModal(false)
      setShowCallQualityModal(false)
      closeBottomSheet()
    },
    [submitVideoQualityFeedback, user?.id, closeBottomSheet],
  )

  const showCallQualityFeedbackModal = useCallback(
    (videoSessionId?: string) => {
      const videoSessionFeedback = (
        <VideoSessionFeedbackContainer>
          <VideoSessionFeedback
            onLayout={openBottomSheet}
            saveForm={(values: FormChangeProperties) => {
              submitQualityFeedback(values, videoSessionId)
            }}
            scrollEnabled={scrollEnabled}
            onFormChange={onFormChange}
            scrollFieldContainerHeight={snapPoints[0]}
          />
        </VideoSessionFeedbackContainer>
      )
      setAndShowModal(videoSessionFeedback, { className: 'call-quality-feedback' })
    },
    [setAndShowModal, submitQualityFeedback, openBottomSheet, scrollEnabled, snapPoints],
  )

  const sessionEndedCallback = useCallback(() => {
    if (get(window, 'zE.show')) {
      ;(window as any).zE.show()
    }
    toggleVideoSessionStatus(false)
    setShowRecordingStatus(false)
    if (refreshSessionId.current) {
      window.clearInterval(refreshSessionId.current)
    }
    refreshSessionId.current = null
    // appointmentIdFromSessionInfo should exist from sessionStartedCallback
    const sessionNumber = appointmentIdFromSessionInfo
      ? appointments.find((appt) => appt.appointmentId === parseInt(appointmentIdFromSessionInfo))?.sessionNumber
      : -1
    const updateObj = { ...sessionInfo, session_number: sessionNumber }

    const appointment = appointments.find((appointment: any) => {
      return appointment.appointmentId === parseInt(updateObj.appointment_id as string, 10)
    })

    // only update session info if session was officially started (to account for "dismissed" sessions)
    if (updateObj.status === 'started') {
      updateObj.status = 'finished'
      updateObj.end_time = formatISO(new Date())

      showCallQualityFeedbackModal(updateObj.id)

      // update metadata for db appointment and twilio session
      ;(updateVideoSessionMetadata(updateObj) as unknown as Promise<any>).then(() => {
        updateAppointment({
          providerId: userId,
          data: {
            ...appointment,
            appointmentStatus: 'concluded',
          },
        })
          .unwrap()
          .then(() => {
            clearSessionInfo()
          })
      })
    } else {
      clearSessionInfo()
    }
  }, [
    appointmentIdFromSessionInfo,
    appointments,
    clearSessionInfo,
    sessionInfo,
    showCallQualityFeedbackModal,
    toggleVideoSessionStatus,
    updateAppointment,
    updateVideoSessionMetadata,
    userId,
  ])

  const onJoin = useCallback(() => {
    if (typeof (axiosAuthInstance as any).cancelLastRequest === 'function') {
      ;(axiosAuthInstance as any).cancelLastRequest()
    }

    const client = clients?.activeV2?.find((client: any) => client.id === activeLiveMsgAppt?.userId) // eslint-disable-line
    if (client) {
      dispatch(setLiveMsgClientIdToOpen(activeLiveMsgAppt?.userId ?? null))
      // clear the selected client if navigating from one client directly to another, Client.js does not unmount
      if (clientDetails?.id !== client.id) {
        clearSelectedClientStore()
        selectLtClient(client)
      }
      navigate(CLIENT_HOME.route)
    }
  }, [
    activeLiveMsgAppt?.userId,
    clearSelectedClientStore,
    clientDetails?.id,
    clients?.activeV2,
    dispatch,
    navigate,
    selectLtClient,
  ])

  const saveSettingsModal = useCallback(
    (settings: any) => {
      updateVideoSessionSettings(settings)
      closeModal()
    },
    [closeModal, updateVideoSessionSettings],
  )

  const onTwilioSessionJoin = useCallback(() => {
    setHasOpenedSessionFromBanner(true)
  }, [])

  const videoAppts = useMemo(
    () =>
      appointments.filter(
        (appt: any) => appt.meetingFormat === 'video' && clients?.activeV2?.some(({ id }: any) => id === appt.userId), // eslint-disable-line
      ),
    [appointments, clients],
  )
  const liveMsgAppts = useMemo(
    () =>
      appointments.filter(
        (appt: any) =>
          appt.meetingFormat === 'live_messaging' && clients?.activeV2?.some(({ id }: any) => id === appt.userId), // eslint-disable-line
      ),
    [appointments, clients],
  )

  const onZoomAppointmentJoin = useCallback((appointment: Appointment) => {
    setZoomAppointment(appointment)
  }, [])

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const THIRTY_SECONDS_IN_MS = 30000

  const calculateTimeoutForWarning = useCallback(
    (appointment: Appointment) => {
      const fiveMinutesBeforeSessionEnd = subMinutes(
        addMinutes(getDateFromAppointment(appointment), appointment.appointmentDuration),
        5,
      )

      const timeUntilWarning = fiveMinutesBeforeSessionEnd.getTime() - new Date().getTime()

      // Only set timer if there's still time left and we're more than 30 seconds from the warning
      if (timeUntilWarning > THIRTY_SECONDS_IN_MS) {
        if (timeoutRef.current !== null) {
          clearTimeout(timeoutRef.current)
        }
        timeoutRef.current = setTimeout(() => {
          setToastContent({
            text: '5 minutes left in this session',
            toastType: 'info',
            id: 'ProviderSession-end-of-session-alert',
          })
        }, timeUntilWarning)
      }
    },
    [setToastContent],
  )

  const onZoomSessionStarted = useCallback(() => {
    if (!sessionInfo || !zoomAppointment) {
      return
    }
    toggleVideoSessionStatus(true)
    calculateTimeoutForWarning(zoomAppointment)
    const sessionNumber =
      zoomAppointment.sessionNumber ||
      appointments.find((appt) => appt.appointmentId === parseInt(sessionInfo.appointment_id))?.sessionNumber
    ;(getEpisode({ episodeId: sessionInfo.episode_id }) as unknown as Promise<any>).then((result: Episode) => {
      if (result.recording_consent === false) {
        ;(
          updateVideoSessionMetadata(
            Object.assign(cloneDeep(sessionInfo), {
              status: 'started',
              start_time: formatISO(new Date()),
              recording_status: RECORDING_STATUS.disabled,
              session_number: sessionNumber,
            }),
          ) as unknown as Promise<any>
        ).then(() => {
          setIsRecording(false)
        })
        displayRecordingStatus()
      } else {
        ;(
          updateVideoSessionMetadata(
            Object.assign(cloneDeep(sessionInfo), {
              status: 'started',
              start_time: formatISO(new Date()),
              recording_status: RECORDING_STATUS.not_started,
              session_number: sessionNumber,
            }),
          ) as unknown as Promise<any>
        ).then(() => {
          setIsRecording(true)
        })

        setShowRecordingModal(true)
      }
    })
    ;(getLtClient({ clientId: zoomAppointment.userInfo!.lyraId }) as unknown as any).then(() =>
      navigate(CLIENT_HOME.route),
    )
  }, [
    appointments,
    displayRecordingStatus,
    getEpisode,
    getLtClient,
    navigate,
    sessionInfo,
    toggleVideoSessionStatus,
    updateVideoSessionMetadata,
    zoomAppointment,
    calculateTimeoutForWarning,
  ])

  const onZoomSessionEnded = useCallback(() => {
    if (!zoomAppointment || !sessionInfo) {
      return
    }
    if (get(window, 'zE.show')) {
      ;(window as any).zE.show()
    }

    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }

    toggleVideoSessionStatus(false)
    setShowRecordingStatus(false)
    if (refreshSessionId.current) {
      window.clearInterval(refreshSessionId.current)
    }
    refreshSessionId.current = null

    const sessionNumber =
      zoomAppointment.sessionNumber ||
      appointments.find((appt) => appt.appointmentId === parseInt(sessionInfo.appointment_id))?.sessionNumber
    const updateObj = { ...sessionInfo, session_number: sessionNumber }

    // only update session info if session was officially started (to account for "dismissed" sessions)
    if (updateObj.status === 'started') {
      updateObj.status = 'finished'
      updateObj.end_time = formatISO(new Date())
      lastVideoSessionId.current = sessionInfo.id
      setShowCallQualityModal(true)
      // update metadata for db appointment and twilio session
      ;(updateVideoSessionMetadata(updateObj) as unknown as Promise<any>).then(() => {
        updateAppointment({
          providerId: userId,
          data: {
            ...zoomAppointment,
            appointmentStatus: 'concluded',
          },
        })
          .unwrap()
          .then(() => {
            clearSessionInfo()
            setZoomAppointment(null)
          })
      })
    } else {
      clearSessionInfo()
      setZoomAppointment(null)
    }
  }, [
    appointments,
    clearSessionInfo,
    sessionInfo,
    toggleVideoSessionStatus,
    updateAppointment,
    updateVideoSessionMetadata,
    userId,
    zoomAppointment,
  ])

  const onZoomSessionClosed = useCallback(() => {
    if (get(window, 'zE.show')) {
      ;(window as any).zE.show()
    }
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
    toggleVideoSessionStatus(false)
    setShowRecordingStatus(false)
    if (refreshSessionId.current) {
      window.clearInterval(refreshSessionId.current)
    }
    refreshSessionId.current = null
    setZoomAppointment(null)
    clearSessionInfo()
  }, [clearSessionInfo, toggleVideoSessionStatus])

  const onDontRecordEpisodePressed = useCallback(() => {
    if (!zoomAppointment || !sessionInfo) {
      return
    }
    ;(getEpisode({ episodeId: sessionInfo.episode_id }) as unknown as Promise<any>).then((result: Episode) => {
      disableEpisodeRecording(result, sessionInfo)
      displayRecordingStatus()
    })
  }, [disableEpisodeRecording, displayRecordingStatus, getEpisode, sessionInfo, zoomAppointment])

  const onDontRecordSessionPressed = useCallback(() => {
    if (!sessionInfo) {
      return
    }
    disableRecording(sessionInfo)
    displayRecordingStatus()
  }, [disableRecording, displayRecordingStatus, sessionInfo])

  const zoomSessionParams = useMemo(
    () =>
      sessionInfo
        ? {
            token: sessionInfo.access_token,
            userName: sessionInfo.participant_identity ?? 'provider',
            sessionName: sessionInfo.room_name,
          }
        : undefined,
    [sessionInfo],
  )

  const getZoomSessionParams = useCallback(async () => {
    if (!zoomAppointment?.userInfo) {
      throw new Error('Not in zoom session')
    }

    const videoSession = await getTokenForSession({
      apptID: zoomAppointment.appointmentId,
      participantID: zoomAppointment.userInfo.lyraId,
      sessionNumber: zoomAppointment.sessionNumber,
    })

    return {
      token: videoSession.access_token,
      userName: videoSession.participant_identity ?? 'client',
      sessionName: videoSession.room_name,
    }
  }, [getTokenForSession, zoomAppointment])

  const onActiveSession = useCallback(
    ({ appt }: { appt: Appointment | null }) => {
      dispatch(setActiveLiveMsgAppt(appt))
    },
    [dispatch],
  )

  if (!isLoggedIn) {
    return <div />
  }

  return (
    <ZoomProvider isProvider>
      <BaseModal isOpen={showModal} body={modalData} closeModal={closeModal} {...modalProps} />
      {!zoomAppointment && (
        <JoinSessionBanner onTwilioSessionJoin={onTwilioSessionJoin} onZoomSessionJoin={onZoomAppointmentJoin} />
      )}
      <SessionBanner
        onJoin={onJoin}
        userRole={USER_ROLES.PROVIDER}
        appts={liveMsgAppts}
        inSession={inLiveMsgSession}
        sessionType={SESSION_TYPES.MESSAGE}
        onActiveSession={onActiveSession}
        timeZone={(liveMsgAppts as any).timeZone}
      />
      <RecordConsentModal
        show={showRecordingModal}
        onDontRecordEpisodePressed={onDontRecordEpisodePressed}
        onDontRecordSessionPressed={onDontRecordSessionPressed}
        onRecordSessionPressed={displayRecordingStatus}
        onClose={() => setShowRecordingModal(false)}
      />
      <Modal
        visible={showCallQualityModal}
        onCloseEnd={() => {
          setShowCallQualityModal(false)
        }}
        onRequestClose={() => setShowCallQualityModal(false)}
        modalContents={
          <VideoSessionFeedbackContainer>
            <VideoSessionFeedback
              onLayout={openBottomSheet}
              saveForm={(values: FormChangeProperties) => {
                submitQualityFeedback(values, lastVideoSessionId.current)
              }}
              scrollEnabled={scrollEnabled}
              onFormChange={onFormChange}
              scrollFieldContainerHeight={snapPoints[0]}
            />
          </VideoSessionFeedbackContainer>
        }
        scrollable
        scrollableModalWidth='100%'
        scrollableModalHeight='100%'
        disableBottomSheet
      />
      {zoomAppointment && zoomSessionParams && (
        <ZoomVideoSession
          appointment={zoomAppointment}
          getSessionParams={getZoomSessionParams}
          participantName={`${zoomAppointment.userInfo?.firstName} ${zoomAppointment.userInfo?.lastName}`}
          participantFirstName={zoomAppointment.userInfo?.firstName}
          onSessionOpened={sessionOpenedCallback}
          onSessionStarted={onZoomSessionStarted}
          onSessionEnded={onZoomSessionEnded}
          onSessionClosed={onZoomSessionClosed}
          showRecordingStatus={showRecordingStatus}
          isRecording={isRecording}
          addToast={setToastContent}
          onDisableRecordingPressed={disableRecordingForSession}
          disableMobileView
        />
      )}
      <VideoSession
        $$appointments={fromJS(videoAppts)}
        type='provider'
        isRecording={isRecording}
        // @ts-expect-error TS(2322): Type '() => void' is not assignable to type '() =>... Remove this comment to see the full error message
        disableRecording={disableRecordingForSession}
        showRecordingStatus={showRecordingStatus}
        settings={settings}
        // @ts-expect-error TS(2322): Type '(settings: any) => void' is not assignable t... Remove this comment to see the full error message
        saveSettingsFunction={saveSettingsModal}
        // @ts-expect-error TS(2322): Type '(modalData: any, modalProps?: {}) => void' i... Remove this comment to see the full error message
        setAndShowModal={setAndShowModal}
        // @ts-expect-error TS(2322): Type '() => void' is not assignable to type '() =>... Remove this comment to see the full error message
        closeModal={closeModal}
        getTokenFunction={getTokenForSession}
        // @ts-expect-error TS(2322): Type '(clientId: any) => void' is not assignable t... Remove this comment to see the full error message
        sessionStartedCallback={sessionStartedCallback}
        // @ts-expect-error TS(2322): Type '() => void' is not assignable to type '() =>... Remove this comment to see the full error message
        sessionEndedCallback={sessionEndedCallback}
        // @ts-expect-error TS(2322): Type '() => void' is not assignable to type '() =>... Remove this comment to see the full error message
        sessionOpenedCallback={sessionOpenedCallback}
        // @ts-expect-error TS(2322): Type '({ event, action, details, page, properties,... Remove this comment to see the full error message
        track={track}
        // @ts-expect-error TS(2322): Type '(sourceCategory: any, userId: any, data: any... Remove this comment to see the full error message
        logger={logToSumoLogic}
        disableBanner={true}
        hasOpenedSessionFromBanner={hasOpenedSessionFromBanner}
      />
    </ZoomProvider>
  )
}

type StateProps = {
  settings: Dict
  sessionInfo: VideoSessionData | undefined
  inLiveMsgSession: boolean
  activeLiveMsgAppt: Appointment | undefined
  clientDetails: ClientObject | null
  clients: any
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    settings: getLTVideoSettings(state),
    sessionInfo: getLTVideoSessionInfo(state),
    inLiveMsgSession: inLiveMsgSession(state),
    activeLiveMsgAppt: getMessagesActiveLiveMsgAppt(state),
    clientDetails: getClientDetailsData(state),
    clients: getClientsData(state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators(
      {
        getToken,
        getLtClient,
        pingLT,
        toggleVideoSessionStatus,
        updateVideoSessionSettings,
        updateVideoSessionMetadata,
        clearSessionInfo,
        selectLtClient,
        submitVideoQualityFeedback,
        updateEpisode,
        getEpisode,
        clearSelectedClientStore,
        setToastContent,
      },
      dispatch,
    ),
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(CSSModules(ProviderSession, styles))
