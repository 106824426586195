import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { ViewStyle } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'

import styled, { useTheme } from 'styled-components/native'

import { SecondaryButton, TertiaryIconButton, XIcon } from '../../atoms'
import { ClientDetailsEpisodeSideBarSection, ClientDetailsMainSideBarSection } from '../../molecules'
import { Details } from '../../ui-models'
import { tID } from '../../utils'

const PANEL_WIDTH = 320

const OuterContainer = styled(Animated.View)(({ theme }) => ({
  flexDirection: 'column',
  width: `${PANEL_WIDTH}px`,
  position: 'absolute',
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.colors.backgroundPrimary,
}))

const Header = styled.View(({ theme }) => ({
  paddingLeft: theme.spacing['16px'],
  paddingRight: theme.spacing['16px'],
  height: '75px',
  borderBottomWidth: '1px',
  borderBottomColor: theme.colors.borderDefault,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
}))

const Container = styled.View(({ theme }) => ({
  flexDirection: 'column',
  padding: theme.spacing['24px'],
  gap: theme.spacing['16px'],
  flex: 1,
  overflow: 'scroll',
}))

const DetailsContainer = styled.View(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.spacing['32px'],
  overflow: 'scroll',
}))

const CloseIconButton = styled(TertiaryIconButton)({
  alignSelf: 'center',
})

export type ClientDetailsSideBarProps = {
  details: Details | null
  isOpen: boolean
  hasSelectedClient: boolean
  style?: ViewStyle
  onClose?: () => void
  onOpenProfile?: () => void
  isLoading: boolean
}

export const ClientDetailsSideBar: React.FC<ClientDetailsSideBarProps> = ({
  details,
  isOpen,
  hasSelectedClient,
  style,
  onClose,
  onOpenProfile,
  isLoading,
}) => {
  const { colors } = useTheme()

  const translateX = useSharedValue(0)
  useEffect(() => {
    translateX.value = isOpen ? withTiming(0) : withTiming(PANEL_WIDTH)
  }, [isOpen, translateX])

  const contentStyle = useAnimatedStyle(() => ({
    transform: [{ translateX: translateX.value }],
  }))

  if (!hasSelectedClient) {
    return null
  }
  return (
    <OuterContainer testID={tID('ClientDetailsSideBar')} style={[style, contentStyle]}>
      <Header>
        {details && (
          <CloseIconButton
            testID={tID('ClientDetailsSideBar-close')}
            leftIcon={<XIcon fillColor={colors.iconDefault} size={20} />}
            onPress={() => onClose?.()}
          />
        )}
      </Header>
      {!isLoading && details && (
        <Container>
          <SecondaryButton
            fullWidth
            testID={tID('ClientDetailsSideBar-open-profile')}
            text={<FormattedMessage defaultMessage='Open full profile' description='Button to open client profile' />}
            onPress={() => onOpenProfile?.()}
          />
          <DetailsContainer>
            <ClientDetailsMainSideBarSection details={details} />
            <ClientDetailsEpisodeSideBarSection details={details} />
          </DetailsContainer>
        </Container>
      )}
    </OuterContainer>
  )
}
