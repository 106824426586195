import React, { FunctionComponent, useContext, useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { EventContentArg } from '@fullcalendar/core'
import { isNil } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { CALENDAR_SETUP_DESCRIPTION_TEXT, CALENDAR_SETUP_HEADER_TEXT } from '@lyrahealth-inc/shared-app-logic'

import { BodyText, PrimaryButton, SecondaryButton, Subhead, TertiaryButton } from '../../atoms'
import { AppContext } from '../../context'
import { ProviderBookableCalendarSlotTarget } from '../../molecules'
import {
  ConfirmationModal,
  ProviderBookableCalendar,
  ProviderBookableCalendarRef,
  ProviderCalendarDaySlotSelectionModal,
} from '../../organisms'
import './style.css'
import { SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

export type ProviderBookableCalendarSetupProps = {
  timeZone?: string
  onSave?: (events: { startTime: string; endTime: string }[]) => void
  onCancel?: () => void
  isSaving?: boolean
  isEditing?: boolean
  initialEvents?: { startTime: string; endTime: string; id: string }[]
  bookableTarget: number
  bookableMax: number
  onChange?: (events: { startTime: string; endTime: string }[]) => void
  hidePerformanceMetrics?: boolean
  hybridBookingMode?: boolean
}

const Container = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundSecondary,
  alignItems: 'center',
}))

const Header = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  padding: theme.spacing['24px'],
  justifyContent: 'space-between',
  backgroundColor: theme.colors.backgroundPrimary,
  width: '100%',
  borderBottomWidth: '1px',
  borderBottomColor: theme.colors.borderDefault,
  alignItems: 'center',
}))

const CalendarContainer = styled.View({
  overflow: 'scroll',
  width: '100%',
  height: 'calc(100vh - 114px)',
})

const HeaderText = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  gap: theme.spacing['8px'],
}))

const HeaderBodyText = styled(BodyText)(({ theme }) => ({
  maxWidth: '850px',
  color: theme.colors.textSecondary,
}))

const Row = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['8px'],
}))

const RightSection = styled(Row)(({ theme }) => ({
  gap: theme.spacing['32px'],
}))

const ButtonGroup = styled(Row)(({ theme }) => ({
  borderLeftWidth: '1px',
  borderLeftColor: theme.colors.borderDefault,
  gap: theme.spacing['16px'],
}))

export const ProviderBookableCalendarSetup: FunctionComponent<ProviderBookableCalendarSetupProps> = ({
  timeZone = 'America/Los_Angeles',
  onSave,
  onCancel,
  isSaving = false,
  isEditing = false,
  initialEvents,
  bookableTarget,
  bookableMax,
  onChange,
  hidePerformanceMetrics,
  hybridBookingMode,
}) => {
  const calendarRef = useRef<ProviderBookableCalendarRef>(null)
  const theme = useTheme() as ThemeType
  const { isInProductCalendarAddModalEnabled } = useContext(AppContext)
  const [events, setEvents] = useState<{ startTime: string; endTime: string; id?: string }[]>(initialEvents ?? [])
  const [showAddEventModal, setShowAddEventModal] = useState(false)
  const [modalAddedEvents, setModalAddedEvents] = useState<{ startTime: string; endTime: string; id?: string }[]>([])
  const [eventToDelete, setEventToDelete] = useState<EventContentArg | null>(null)
  const [eventToEdit, setEventToEdit] = useState<EventContentArg | null>(null)

  useEffect(() => {
    onChange?.(events)
  }, [events, onChange])

  const setEventTimes = (modalEvents: { startTime: string; endTime: string }[]) => {
    eventToEdit?.event.setStart(modalEvents[0].startTime, { maintainDuration: true })
    setEventToEdit(null)
  }

  return (
    <Container>
      <Header>
        <HeaderText>
          <Subhead
            size={SubheadSize.LARGE}
            text={
              <FormattedMessage
                {...(hybridBookingMode
                  ? isEditing
                    ? CALENDAR_SETUP_HEADER_TEXT.HYBRID_EDIT
                    : CALENDAR_SETUP_HEADER_TEXT.HYBRID_SETUP
                  : isEditing
                  ? CALENDAR_SETUP_HEADER_TEXT.BOOKABLE_EDIT
                  : CALENDAR_SETUP_HEADER_TEXT.BOOKABLE_SETUP)}
              />
            }
          />
          {!isEditing && (
            <HeaderBodyText
              text={
                <FormattedMessage
                  {...(hybridBookingMode
                    ? CALENDAR_SETUP_DESCRIPTION_TEXT.HYBRID
                    : CALENDAR_SETUP_DESCRIPTION_TEXT.BOOKABLE)}
                />
              }
              color={theme.colors.textSecondary}
            />
          )}
        </HeaderText>
        <RightSection>
          {!hidePerformanceMetrics && (
            <ProviderBookableCalendarSlotTarget
              target={bookableTarget}
              max={bookableMax}
              current={events.length}
              showWarningBelowTarget={isEditing}
            />
          )}
          {isInProductCalendarAddModalEnabled && (
            <SecondaryButton
              testID={tID('ProviderBookableCalendarSetup-add-button')}
              text={
                <FormattedMessage defaultMessage='Add' description='Button to add availability to calendar setup' />
              }
              onPress={() => {
                setShowAddEventModal(true)
              }}
            />
          )}
          <ButtonGroup>
            <TertiaryButton
              testID={tID('ProviderBookableCalendarSetup-cancel-button')}
              text={<FormattedMessage defaultMessage='Cancel' description='Button to cancel calendar setup' />}
              onPress={() => onCancel?.()}
            />
            <PrimaryButton
              loading={isSaving}
              testID={tID('ProviderBookableCalendarSetup-save-button')}
              text={<FormattedMessage defaultMessage='Save' description='Button to save calendar setup' />}
              onPress={() => onSave?.(events)}
            />
          </ButtonGroup>
        </RightSection>
      </Header>
      <CalendarContainer>
        <ProviderBookableCalendar
          ref={calendarRef}
          timeZone={timeZone}
          onEventsChanged={setEvents}
          initialEvents={initialEvents}
          modalAddedEvents={modalAddedEvents}
          setEventToDelete={setEventToDelete}
          setEventToEdit={(arg) => {
            setShowAddEventModal(true)
            setEventToEdit(arg)
          }}
        />
      </CalendarContainer>
      <ProviderCalendarDaySlotSelectionModal
        currentEvents={events}
        eventToEdit={eventToEdit}
        onConfirmationButtonPress={(modalEvents: { startTime: string; endTime: string }[]) => {
          eventToEdit ? setEventTimes(modalEvents) : setModalAddedEvents(modalEvents)
        }}
        onRequestClose={() => {
          setShowAddEventModal(false)
          setEventToEdit(null)
        }}
        setShowAddEventModal={setShowAddEventModal}
        showAddEventModal={showAddEventModal}
      />
      <ConfirmationModal
        visible={!isNil(eventToDelete)}
        onRequestClose={() => setEventToDelete(null)}
        onConfirmationButtonPress={() => {
          !isNil(eventToDelete) && eventToDelete.event.remove()
          setEventToDelete(null)
        }}
        modalTitle={
          <FormattedMessage
            defaultMessage='Delete availability?'
            description='Title of modal to delete recurring availability in calendar template'
          />
        }
        cancelButtonText={<FormattedMessage defaultMessage='Cancel' description='Cancel button' />}
        confirmationButtonText={<FormattedMessage defaultMessage='Delete' description='Add availability button' />}
        modalContents={
          <BodyText
            text={
              <FormattedMessage
                defaultMessage='Clients will not be able to book at this time.'
                description='Body text for delete confirmation modal'
              />
            }
          />
        }
      />
    </Container>
  )
}
