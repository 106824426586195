import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { BaseQueryFn, createApi, QueryDefinition, TypedUseQueryHookResult } from '@reduxjs/toolkit/query/react'
import { AxiosError, AxiosRequestConfig } from 'axios'

import { actionAlertHandler, actionStyles } from '../common/constants/reduxConstants'
import { axiosAuthInstance, axiosCalendarServicInstance, axiosGenericInstance } from '../common/http/axiosInstance'

export enum RTKQueryTag {
  APPOINTMENT = 'APPOINTMENT',
  CONVERSATION = 'CONVERSATION',
}

export enum AxiosInstance {
  AUTH = 'AUTH',
  GENERIC = 'GENERIC',
  CALENDAR = 'CALENDAR',
}

const getAxiosInstance = (instance: AxiosInstance) => {
  switch (instance) {
    case AxiosInstance.AUTH:
      return axiosAuthInstance
    case AxiosInstance.GENERIC:
      return axiosGenericInstance
    case AxiosInstance.CALENDAR:
      return axiosCalendarServicInstance
  }
}

const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string
      method?: AxiosRequestConfig['method']
      data?: AxiosRequestConfig['data']
      params?: AxiosRequestConfig['params']
      headers?: AxiosRequestConfig['headers']
      axiosInstanceType?: AxiosInstance
      withCredentials?: boolean
      showAlert?: boolean
    },
    unknown,
    unknown
  > =>
  async (
    {
      url,
      method,
      data,
      params,
      headers,
      axiosInstanceType = AxiosInstance.AUTH,
      withCredentials = false,
      showAlert = true,
    },
    api,
  ) => {
    try {
      const axiosInstance = getAxiosInstance(axiosInstanceType)
      const result = await axiosInstance({
        url,
        method,
        data,
        params,
        headers,
        withCredentials,
      })
      return { data: result.data }
    } catch (axiosError) {
      const error = axiosError as AxiosError
      if (showAlert) {
        actionAlertHandler({ actionStyle: actionStyles.ERROR, message: error, dispatch: api.dispatch })
      }
      return {
        error,
      }
    }
  }

export const baseQuery = axiosBaseQuery()
export const baseApi = createApi({
  baseQuery: baseQuery,
  endpoints: () => ({}),
  tagTypes: [RTKQueryTag.APPOINTMENT, RTKQueryTag.CONVERSATION],
})

export type ExtractRTKQueryArgs<T> = T extends UseQuery<QueryDefinition<infer Args, any, any, any, any>> ? Args : never
export type ExtractRTKQueryReturnType<T> = T extends UseQuery<QueryDefinition<any, any, any, infer ResultType, any>>
  ? ResultType
  : never
type ExtractRTKQueryOptions<T> = T extends (arg: any, options: infer Options) => any ? Options : never

export type RTKQueryResultType<T> = TypedUseQueryHookResult<
  ExtractRTKQueryReturnType<T>,
  ExtractRTKQueryArgs<T>,
  typeof baseQuery
>

type AutoCast<T> = T extends any ? T : never

export type RTKQueryHook<T> = (
  args?: Partial<ExtractRTKQueryArgs<T>>,
  options?: ExtractRTKQueryOptions<T>,
) => AutoCast<RTKQueryResultType<T>>
