import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { getAge } from '@lyrahealth-inc/shared-app-logic'

import { ClientDetailsSideBarSection } from './ClientDetailsSideBarSection'
import { Details } from '../../ui-models'
import { tID } from '../../utils'

export type ClientDetailsMainSideBarSectionProps = {
  details: Details
}

export const ClientDetailsMainSideBarSection: React.FC<ClientDetailsMainSideBarSectionProps> = ({ details }) => {
  const intl = useIntl()
  return (
    <ClientDetailsSideBarSection
      headerText={<FormattedMessage defaultMessage='Client details' description='Header text for client details' />}
      items={[
        {
          key: 'legal_name',
          label: <FormattedMessage defaultMessage='Legal name' description='Legal name of client' />,
          textValue: details.client.legalName,
          testID: tID('ClientDetailsMainSideBarSection-legal-name'),
        },
        {
          key: 'preferred_name',
          label: <FormattedMessage defaultMessage='Preferred name' description='Preferred name of client' />,
          textValue: details.client.preferredName,
          testID: tID('ClientDetailsMainSideBarSection-preferred-name'),
        },
        {
          key: 'time_zone',
          label: <FormattedMessage defaultMessage='Time zone' description='Time zone of client' />,
          textValue: details.client.timeZone,
          testID: tID('ClientDetailsMainSideBarSection-time-zone'),
        },
        {
          key: 'date_of_birth',
          label: <FormattedMessage defaultMessage='DOB' description='DOB of client' />,
          textValue: (
            <FormattedMessage
              defaultMessage='{dateOfBirth} ({age})'
              description='DOB of client'
              values={{
                age: getAge({ date: details.client.dateOfBirth, dateFormat: 'YYYY-MM-dd' }),
                dateOfBirth: intl.formatDate(details.client.dateOfBirth, {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  timeZone: 'UTC',
                }),
              }}
            />
          ),
          testID: tID('ClientDetailsMainSideBarSection-date-of-birth'),
        },
        {
          key: 'phone_number',
          label: <FormattedMessage defaultMessage='Phone' description='Phone number of client' />,
          textValue: details.client.phoneNumber,
          testID: tID('ClientDetailsMainSideBarSection-phone-number'),
        },
      ]}
    />
  )
}
