import React, { memo } from 'react'

import styled, { useTheme } from 'styled-components/native'

import { ConversationItemTag } from './ConversationItemTag'
import { BodyText, CircleIcon, ConversationTime, PressableOpacity } from '../../atoms'
import { Conversation } from '../../ui-models'
import { tID } from '../../utils'

const Container = styled(PressableOpacity)<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  padding: theme.spacing['12px'],
  gap: theme.spacing['8px'],
  backgroundColor: isSelected ? theme.colors.backgroundHighlightPrimary : theme.colors.backgroundPrimary,
}))

const ConversationDetails = styled.View({
  flexDirection: 'column',
  flexShrink: 1,
  flexGrow: 1,
})

const IconContainer = styled.View<{ show: boolean }>(({ show }) => ({
  opacity: show ? 1 : 0,
}))

export type ConversationItemProps = {
  conversation: Conversation
  onPress: () => void
  isSelected: boolean
}

export const ConversationItem: React.FC<ConversationItemProps> = memo(
  ({ conversation: { clientName, hasUnreadMessage, lastMessageTime, tag }, onPress, isSelected }) => {
    const { colors } = useTheme()
    return (
      <Container testID={tID('ConversationItem')} onPress={onPress} isSelected={isSelected}>
        <IconContainer testID={tID('ConversationItem-unreadIcon')} show={hasUnreadMessage}>
          <CircleIcon size={10} isFilled fillColor={colors.iconActive} />
        </IconContainer>
        <ConversationDetails>
          <BodyText text={clientName} numberOfLines={1} />
          <ConversationTime time={lastMessageTime} />
        </ConversationDetails>
        {tag && <ConversationItemTag tag={tag} />}
      </Container>
    )
  },
)
