import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'
import { connect, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { Navigate } from 'react-router-dom'

import { merge, pick } from 'lodash-es'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { css } from 'styled-components/native'

import {
  Content,
  CustomerInfo,
  getInitialICD10s,
  ICD10_PRIMARY_FIELD,
  ICD10_SECONDARY_FIELD,
} from '@lyrahealth-inc/shared-app-logic'
import { CardMedium, ContentLayout, contentSchemaUtils, DefaultButton, LoadingIndicator } from '@lyrahealth-inc/ui-core'
import { FormBody, FormButtonParams, PrimaryButton, tID, useFetcher } from '@lyrahealth-inc/ui-core-crossplatform'

import attestationFormMetadata from './attestationFormMetadata'
import getApproachingSpecialityCareMetadata from './getApproachingSpecialityCareMetadata'
import getPreAuthSpecialityCareMetadata from './getPreAuthSpecialityCareMetadata'
import ICD10sField from './ICD10sField'
import ICD10sFormMetadata from './ICD10sFormMetadata'
import { addAlert, hideAlerts } from '../../../data/alertActions'
import { getAuthRoles } from '../../../data/auth/authSelectors'
import { getHealthPlanCurrentCharge, getHealthPlanICD10s } from '../../../data/healthPlan/healthPlanSelectors'
import { clearHealthPlanCharge, getICD10s, updateCharge } from '../../../data/healthPlanAutoActions'
import { getPaymentsDataAllData } from '../../../data/payments/paymentsSelectors'
import { getRequestPaymentCustomers } from '../../../data/requestPayment/requestPaymentSelectors'
import { RootState } from '../../../data/store'
import { getPayments } from '../../../payments/dashboard/data/paymentsDashboardActions'
import { bannerMessages, ROLES, specialityCareAttestationStatuses } from '../../constants/appConstants'
import {
  CLIENT_EMAIL,
  PRACTICES_DETAILS_HEALTHPLAN_PAYMENT_INFO,
  PRACTICES_DETAILS_NEW_PAYMENT,
  PROVIDERS_DETAILS_HEALTHPLAN_PAYMENT_INFO,
  PROVIDERS_DETAILS_NEW_PAYMENT,
  REQUEST_PAYMENT,
} from '../../constants/routingConstants'
import { hasRole, prepopulateAttestation } from '../../utils/utils'

export const HealthPlanInfo = ({
  charge,
  availableCodes,
  customers = [],
  pastPayments,
  actions: { getICD10s, getPayments, hideAlerts, updateCharge, addAlert, clearHealthPlanCharge },
}: any) => {
  const intl = useIntl()
  const roles = useSelector(getAuthRoles)
  const providerLyraId = charge?.data?.provider_lyra_id
  const patientLyraId = charge?.data?.patient_lyra_id
  const [loading] = useFetcher([getICD10s, {}])
  const navigate = useNavigate()
  const location = useLocation()
  const hasClearedCharge = useRef(false)
  const initialICD10s = useMemo(() => getInitialICD10s(availableCodes, charge), [availableCodes, charge])

  useEffect(() => {
    getPayments({
      status: 'submitted',
      page: 0,
      limit: 20,
      providerId: providerLyraId,
      isHealthPlan: true,
      patientId: patientLyraId,
    })
  }, [getPayments, providerLyraId, patientLyraId])

  const goToNextScreen = useCallback(() => {
    if (charge?.email_required && !hasRole(roles, ROLES.PAYMENTS_ADMIN)) {
      navigate(CLIENT_EMAIL.route, {
        state: {
          patient_id: charge?.data?.patient_lyra_id,
          initialSession: charge?.session_tracking_status === 'session_limit_initial_session',
          sessionsLeft: parseInt(charge?.data.sessions_remaining, 10),
          firstName: charge?.data.first_name,
          lastName: charge?.data.last_name,
          company: charge?.data?.company,
          customer: customers.find((customer: CustomerInfo) => customer.value === charge?.data?.company),
        },
      })
    } else if (
      hasRole(roles, ROLES.PAYMENTS_ADMIN) &&
      location.pathname === PRACTICES_DETAILS_HEALTHPLAN_PAYMENT_INFO.route
    ) {
      navigate(PRACTICES_DETAILS_NEW_PAYMENT.route, { replace: true })
    } else if (
      hasRole(roles, ROLES.PAYMENTS_ADMIN) &&
      location.pathname === PROVIDERS_DETAILS_HEALTHPLAN_PAYMENT_INFO.route
    ) {
      navigate(PROVIDERS_DETAILS_NEW_PAYMENT.route, { replace: true, state: { provider_id: charge?.provider_lyra_id } })
    } else {
      navigate(REQUEST_PAYMENT.route, { replace: true, state: { fromSubmissionIntermediate: true } })
    }
  }, [
    charge?.data?.company,
    charge?.data?.first_name,
    charge?.data?.last_name,
    charge?.data?.patient_lyra_id,
    charge?.data?.sessions_remaining,
    charge?.email_required,
    charge?.provider_lyra_id,
    charge?.session_tracking_status,
    customers,
    location.pathname,
    navigate,
    roles,
  ])

  let submitForm = useCallback(
    (values: { values?: Dict }) => {
      hideAlerts()
      const formValues = { ...values.values }
      if (Array.isArray(formValues[ICD10_PRIMARY_FIELD])) {
        formValues[ICD10_PRIMARY_FIELD] = formValues[ICD10_PRIMARY_FIELD].map((obj: any) => obj.code).join(',')
      }
      if (Array.isArray(formValues[ICD10_SECONDARY_FIELD])) {
        formValues[ICD10_SECONDARY_FIELD] = formValues[ICD10_SECONDARY_FIELD].map((obj: any) => obj.code).join(',')
      }
      return updateCharge({ chargeID: charge?.data?.id, ...formValues }).then(() => {
        addAlert({
          show: true,
          contents: bannerMessages.SUBMIT_PAYMENT_SUCCESS,
          style: 'success',
          expires: false,
          autoDismissTimer: 10000,
        })
        hasClearedCharge.current = true
        clearHealthPlanCharge()
        goToNextScreen()
      })
    },
    [addAlert, charge?.data?.id, clearHealthPlanCharge, goToNextScreen, hideAlerts, updateCharge],
  )

  const getAttended = () => pick(charge?.data, 'attendance')

  const submitButton = useCallback(({ handleSubmit }: FormButtonParams) => {
    return <PrimaryButton text={'Submit'} onPress={handleSubmit} testID={tID('HealthPlanInfo-submit')} />
  }, [])

  if (!charge) {
    if (hasClearedCharge.current) {
      return null
    }
    return <Navigate to={REQUEST_PAYMENT.route} />
  }

  const { health_plan_extension_eligible, speciality_care_attestation_status } = charge

  let metadata: Content = {
    meta_data: { schema: { type: 'object', properties: {} }, uiSchema: { 'ui:order': [] } },
    content_type: 'form',
    group: 'exercise',
    name: 'healthPlanInfoMetadata',
    title: '',
  }
  if (health_plan_extension_eligible) {
    metadata = contentSchemaUtils.extendMetadata(metadata, ICD10sFormMetadata)
    ;(metadata.meta_data as any).initialValues = merge(getAttended(), initialICD10s)
  }
  let button
  const { APPROACHING_ATTESTATION, ATTESTATION_PRIOR_AUTHORIZATION, ATTESTATION_NEEDED } =
    specialityCareAttestationStatuses
  const { label: companyLabel } = customers.find(({ value }: any) => value === charge?.data?.company) || {}
  switch (speciality_care_attestation_status) {
    case APPROACHING_ATTESTATION:
      metadata = contentSchemaUtils.extendMetadata(
        metadata,
        getApproachingSpecialityCareMetadata(companyLabel, `${charge?.data?.first_name} ${charge?.data?.last_name}`),
      )
      if (!health_plan_extension_eligible) {
        // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type '(value... Remove this comment to see the full error message
        submitForm = undefined
        button = (
          <DefaultButton data-test-id='HealthPlanInfo-submit' onClick={goToNextScreen}>
            Submit
          </DefaultButton>
        )
      }
      break
    case ATTESTATION_PRIOR_AUTHORIZATION:
      metadata = contentSchemaUtils.extendMetadata(
        metadata,
        getPreAuthSpecialityCareMetadata(
          companyLabel,
          charge?.data?.first_name,
          `${charge?.data?.first_name} ${charge?.data?.last_name}`,
        ),
      )
      break
    case ATTESTATION_NEEDED:
      metadata = contentSchemaUtils.extendMetadata(metadata, attestationFormMetadata)
      ;(metadata.meta_data as any).initialValues = merge(
        getAttended(),
        initialICD10s,
        prepopulateAttestation(pastPayments),
      )
      break
    default:
      break
  }

  return (
    <ContentLayout>
      <CardMedium>
        {loading || !availableCodes ? (
          <div style={{ textAlign: 'center' }}>
            <LoadingIndicator size={45} />
          </div>
        ) : (
          <FormBody
            intl={intl}
            schema={metadata?.meta_data?.schema}
            uiSchema={metadata?.meta_data?.uiSchema}
            customFields={{ icd10s: (props: any) => <ICD10sField {...props} availableCodes={availableCodes} /> }}
            name={metadata?.name}
            formButton={
              health_plan_extension_eligible || speciality_care_attestation_status === ATTESTATION_PRIOR_AUTHORIZATION
                ? submitButton
                : undefined
            }
            saveForm={submitForm}
            withPageBreaks={false}
            formBodyCustomStyles={{
              submitButtonContainer: css`
                border-top-width: 0;
                padding: 0;
                width: 100%;
                box-shadow: none;
                border-bottom-left-radius: 16px;
                border-bottom-right-radius: 16px;
              `,
              submitButtonWrapper: css`
                max-width: none;
                margin-left: 40px;
                margin-bottom: 20px;
              `,
            }}
            initialValues={{
              ...(health_plan_extension_eligible && merge(getAttended(), initialICD10s)),
              ...(speciality_care_attestation_status === ATTESTATION_NEEDED && prepopulateAttestation(pastPayments)),
            }}
          >
            {button}
          </FormBody>
        )}
      </CardMedium>
    </ContentLayout>
  )
}

HealthPlanInfo.propTypes = {
  actions: PropTypes.object,
  charge: PropTypes.object,
  availableCodes: PropTypes.object,
  customers: PropTypes.array,
  pastPayments: PropTypes.array,
}

const mapStateToProps = (state: RootState) => ({
  charge: getHealthPlanCurrentCharge(state),
  availableCodes: getHealthPlanICD10s(state),
  customers: getRequestPaymentCustomers(state),
  pastPayments: getPaymentsDataAllData(state),
})

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    { updateCharge, clearHealthPlanCharge, getICD10s, getPayments, hideAlerts, addAlert },
    dispatch,
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(HealthPlanInfo)
