import React, { FunctionComponent, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { bindActionCreators } from 'redux'
import styled, { useTheme } from 'styled-components/native'

import { CheckboxButtonType, ProviderCalendarConfiguration, RadioButtonType } from '@lyrahealth-inc/shared-app-logic'
import {
  BodyText,
  Checkbox,
  Link,
  LoadingIndicator,
  PrimaryButton,
  RadioButton,
  Subhead,
  SubheadSize,
  TertiaryButton,
  tID,
  Tooltip,
  TOOLTIP_TRIGGER_ICON,
} from '@lyrahealth-inc/ui-core-crossplatform'

import {
  clearAvailableCalendars,
  getAvailableCalendars,
  putAvailableCalendars,
} from '../../../calendar/data/calendarActions'
import { getAvailableCalendarsSelector } from '../../../calendar/data/calendarSelectors'
import { getAuthUserId } from '../../../data/auth/authSelectors'
import { RootState } from '../../../data/store'
import { setToastContent } from '../../../lyraTherapy/data/ltToastAutoActions'
import { LC_CALENDAR_ACTIVATE_SETUP, SETTINGS } from '../../constants/routingConstants'

const SelectCalendars: FunctionComponent<SelectCalendarsProps> = ({
  calendars,
  isEdit = false,
  actions: { getAvailableCalendars, putAvailableCalendars, clearAvailableCalendars, setToastContent },
}) => {
  const { colors } = useTheme()
  const { formatMessage } = useIntl()
  const [incomingCalendarIds, setIncomingCalendarIds] = useState<string[]>([])
  const [outgoingCalendarId, setOutgoingCalendarId] = useState<string>('')
  const navigate = useNavigate()
  const userId = useSelector(getAuthUserId)

  useEffect(() => {
    if (userId && calendars?.length === 0) {
      getAvailableCalendars({ providerId: userId })
    }
  }, [userId, calendars, getAvailableCalendars])

  useEffect(() => {
    // this cleanup useEffect is needed to clear calendars out of state when we leave the page
    // we want to reload the calendars from CS each time we enter the page to make sure we always have the up to date list of calendars from google
    return () => clearAvailableCalendars()
  }, [clearAvailableCalendars])

  useEffect(() => {
    if (calendars) {
      const readCalendarIds: string[] = []
      let writeCalendarId: string = ''
      calendars.forEach((calendar) => {
        if (calendar.id != null && calendar.read_only) {
          readCalendarIds.push(calendar.calendar_id)
        }
        if (calendar.id != null && !calendar.read_only) {
          readCalendarIds.push(calendar.calendar_id)
          writeCalendarId = calendar.calendar_id
        }
      })
      setIncomingCalendarIds(readCalendarIds)
      setOutgoingCalendarId(writeCalendarId)
    }
  }, [calendars])

  const Header = styled.View(({ theme }) => ({
    flexDirection: 'row',
    padding: theme.spacing['24px'],
    justifyContent: 'space-between',
    backgroundColor: theme.colors.backgroundPrimary,
    width: '100%',
    borderBottomWidth: '1px',
    borderBottomColor: theme.colors.borderDefault,
    alignItems: 'center',
  }))

  const HeaderText = styled.View(({ theme }) => ({
    gap: theme.spacing['8px'],
  }))

  const Row = styled.View(({ theme }) => ({
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing['8px'],
  }))

  const RightSection = styled(Row)(({ theme }) => ({
    gap: theme.spacing['32px'],
  }))

  const ButtonGroup = styled(Row)(({ theme }) => ({
    borderLeftWidth: '1px',
    borderLeftColor: theme.colors.borderDefault,
    gap: theme.spacing['16px'],
  }))

  const PageContainer = styled.View(({ theme }) => ({
    width: 'auto',
    backgroundColor: theme.colors.backgroundSecondary,
    display: 'flex',
    alignItems: 'center',
  }))

  const SelectCalendarsContainer = styled.View({
    width: '624px',
  })

  const TitleContainer = styled(Subhead)(({ theme }) => ({
    paddingTop: theme.spacing['32px'],
  }))

  const CalendarsContainer = styled.View(({ theme }) => ({
    paddingTop: theme.spacing['32px'],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.colors.borderDefault,
  }))

  const TitleToolTipContainer = styled.View({
    display: 'flex',
    flexDirection: 'row',
  })

  const RowContainer = styled.View(({ theme }) => ({
    width: '624px',
    height: theme.spacing['56px'],
    padding: '16px 24px 16px 0px',
    display: 'flex',
    flexDirection: 'row',
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: theme.colors.borderDefault,
    backgroundColor: theme.colors.backgroundSecondary,
  }))

  const CalendarTextContainer = styled.View({
    flex: 1,
  })

  const NextButtonContainer = styled.View({
    width: '624px',
    flexDirection: 'row',
    justifyContent: 'end',
  })

  const onCheckboxChecked = (calendar_id: string) => {
    if (incomingCalendarIds.includes(calendar_id) && outgoingCalendarId !== calendar_id) {
      setIncomingCalendarIds(incomingCalendarIds.filter((id) => id !== calendar_id))
    } else {
      setIncomingCalendarIds([...incomingCalendarIds, calendar_id])
    }
  }

  const renderCheckboxItems = () => {
    return calendars?.map((calendar) => {
      return (
        <RowContainer key={calendar.calendar_id}>
          <CalendarTextContainer>
            <BodyText text={calendar.calendar_name} bold={calendar.is_primary} />
          </CalendarTextContainer>
          <Checkbox
            buttonType={CheckboxButtonType.CHECKBOX_DESCRIPTION_NO_OUTLINE}
            title=''
            checked={incomingCalendarIds.includes(calendar.calendar_id)}
            onPress={() => onCheckboxChecked(calendar.calendar_id)}
            readOnly={outgoingCalendarId === calendar.calendar_id}
            name={calendar.calendar_id}
          />
        </RowContainer>
      )
    })
  }

  const onRadioButtonChecked = (calendar_id: string) => {
    if (outgoingCalendarId === calendar_id) {
      setOutgoingCalendarId('')
    } else {
      setOutgoingCalendarId(calendar_id)
      if (!incomingCalendarIds.includes(calendar_id)) {
        setIncomingCalendarIds([...incomingCalendarIds, calendar_id])
      }
    }
  }

  const renderRadioButtonItems = () => {
    return calendars?.map((calendar) => {
      return (
        <RowContainer key={calendar.calendar_id}>
          <CalendarTextContainer>
            <BodyText text={calendar.calendar_name} bold={calendar.is_primary} />
          </CalendarTextContainer>
          <RadioButton
            title={''}
            selected={outgoingCalendarId === calendar.calendar_id}
            value={calendar.calendar_id}
            onChange={() => onRadioButtonChecked(calendar.calendar_id)}
            parentContainerWidth={0}
            gridButtonsGap={0}
            buttonType={RadioButtonType.DESCRIPTION_NO_OUTLINE}
          />
        </RowContainer>
      )
    })
  }

  const handleSubmit = () => {
    const configurations: ProviderCalendarConfiguration[] = []
    calendars?.forEach((calendar) => {
      const updated_calendar: ProviderCalendarConfiguration = { ...calendar }
      if (outgoingCalendarId === updated_calendar.calendar_id) {
        updated_calendar.read_only = false
        configurations.push(updated_calendar)
      } else if (incomingCalendarIds.includes(updated_calendar.calendar_id)) {
        updated_calendar.read_only = true
        configurations.push(updated_calendar)
      }
    })
    if (userId) {
      putAvailableCalendars({ provider_id: userId, configurations })
    }
    if (isEdit) {
      setToastContent({
        text: formatMessage({
          defaultMessage: 'Lyra calendar updated',
          description: 'A toast message letting the user know their calendar was updated',
        }),
        toastType: 'success',
        id: 'SelectCalendars-success',
      })
      navigate(SETTINGS.route)
    } else {
      navigate(LC_CALENDAR_ACTIVATE_SETUP.route)
    }
  }

  return (
    <>
      {isEdit && (
        <Header>
          <HeaderText>
            <Subhead
              size={SubheadSize.LARGE}
              text={
                <FormattedMessage
                  defaultMessage='Edit external calendars'
                  description='Header editing external calendars'
                />
              }
            />
          </HeaderText>
          <RightSection>
            <ButtonGroup>
              <TertiaryButton
                testID={tID('SelectCalendars-cancel-button')}
                text={<FormattedMessage defaultMessage='Cancel' description='Button to cancel calendar selection' />}
                onPress={() => {
                  navigate(SETTINGS.route)
                }}
              />
              <PrimaryButton
                testID={tID('SelectCalendars-save-button')}
                text={<FormattedMessage defaultMessage='Save' description='Button to save calendar selection' />}
                disabled={!outgoingCalendarId}
                onPress={() => {
                  handleSubmit()
                }}
              />
            </ButtonGroup>
          </RightSection>
        </Header>
      )}
      <PageContainer>
        <SelectCalendarsContainer>
          {!isEdit && (
            <TitleContainer
              size={SubheadSize.LARGE}
              textAlign='center'
              text={
                <FormattedMessage
                  defaultMessage='Which calendars would you like to connect from your Google account?'
                  description='Title text for external calendars'
                />
              }
            />
          )}
          {calendars?.length !== 0 ? (
            <>
              <CalendarsContainer>
                <TitleToolTipContainer>
                  <Subhead size={SubheadSize.SMALL} text='Incoming Calendars' />
                  <Tooltip
                    hoverEnabled
                    content={
                      <FormattedMessage
                        defaultMessage='Clients will not see events or details from these calendars. For your reference, they will show as “busy” on your Lyra calendar and block out any available slots at those times.'
                        description='Tooltip describing incoming calendar functionality'
                      />
                    }
                    triggerConfig={{
                      icon: TOOLTIP_TRIGGER_ICON.INFO,
                      size: 24,
                      fillColor: colors.textSecondary,
                    }}
                  />
                </TitleToolTipContainer>
                <BodyText
                  color={colors.textSecondary}
                  text={
                    <FormattedMessage
                      defaultMessage="We'll use events from these calendars to block availability on the Lyra calendar."
                      description='Body text describing how incoming calendars will be used'
                    />
                  }
                  style={{ marginBottom: '24px' }}
                />
                {renderCheckboxItems()}
              </CalendarsContainer>
              <CalendarsContainer>
                <Subhead size={SubheadSize.SMALL} text='Outgoing Calendars' />
                <BodyText
                  color={colors.textSecondary}
                  text={
                    <FormattedMessage
                      defaultMessage='We’ll save Lyra appointments to this external calendar and here in Lyra.'
                      description='Body text describing outgoing calendar functionality'
                    />
                  }
                  style={{ marginBottom: '24px' }}
                />
                {renderRadioButtonItems()}
              </CalendarsContainer>
              {!isEdit && (
                <>
                  <NextButtonContainer>
                    <PrimaryButton
                      onPress={() => handleSubmit()}
                      text={<FormattedMessage defaultMessage='Next' description='Next button' />}
                      style={{ marginTop: '32px', marginBottom: '32px' }}
                      disabled={!outgoingCalendarId}
                      testID={'SelectCalendars-next-button'}
                    />
                  </NextButtonContainer>
                  <BodyText
                    color={colors.textSecondary}
                    text={
                      <FormattedMessage
                        defaultMessage='Wrong Google Account? {contact} to disconnect this account and connect another one.'
                        description='A message telling user what to do if wrong Google account is displaying'
                        values={{
                          contact: (
                            <Link
                              onPress={() => {
                                window.open(
                                  'https://provider-support.lyrahealth.com/hc/en-us/requests/new?ticket_form_id=26162178922387',
                                )
                              }}
                              text='Contact our team'
                              underline
                            />
                          ),
                        }}
                      />
                    }
                  />
                </>
              )}
            </>
          ) : (
            <LoadingIndicator size={45} />
          )}
        </SelectCalendarsContainer>
      </PageContainer>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    calendars: getAvailableCalendarsSelector(state),
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      getAvailableCalendars: getAvailableCalendars as any,
      putAvailableCalendars: putAvailableCalendars as any,
      clearAvailableCalendars: clearAvailableCalendars as any,
      setToastContent: setToastContent as any,
    },
    dispatch,
  ),
})

type SelectCalendarsProps = {
  calendars?: ProviderCalendarConfiguration[]
  isEdit?: boolean
  actions: {
    getAvailableCalendars: (params: Parameters<typeof getAvailableCalendars>[0]) => void
    putAvailableCalendars: (params: Parameters<typeof putAvailableCalendars>[0]) => void
    clearAvailableCalendars: () => void
    setToastContent: (params: Parameters<typeof setToastContent>[0]) => Promise<any>
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SelectCalendars)
