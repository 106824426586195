import { useMemo } from 'react'

import { sortBy } from 'lodash-es'

import {
  ClientObject,
  Episode,
  getClientPreferredNameIfExists,
  getInternationallyFormattedNumber,
} from '@lyrahealth-inc/shared-app-logic'
import { Details } from '@lyrahealth-inc/ui-core-crossplatform'

import { episodeStates, programConfig, programNameTitleMap, programs } from '../../../common/constants/appConstants'
import { getGSCDuration } from '../../../common/utils/utils'

type PanelArgs = {
  client: ClientObject | null
  episodes: Episode[]
}
const useDetailsPanelData: (args: PanelArgs) => Details | null = ({ client, episodes }: PanelArgs) => {
  const episode = useMemo(() => {
    if (episodes.length === 0) {
      return null
    }

    const activeEpisode = episodes.find((episode) => episode.state === episodeStates.IN_PROGRESS)
    if (activeEpisode) {
      return activeEpisode
    }

    return sortBy(episodes, (episode) => new Date(episode.start_date).getTime() * -1)[0]
  }, [episodes])
  return useMemo(() => {
    if (!client) {
      return null
    }

    return {
      client: {
        legalName: client.full_name,
        preferredName: getClientPreferredNameIfExists(client),
        timeZone: client.time_zone ?? null,
        dateOfBirth: client.date_of_birth,
        phoneNumber: getInternationallyFormattedNumber(client.phone, client.country),
      },
      episode: {
        isActive: episode?.state === episodeStates.IN_PROGRESS,
        program: episode?.program_name
          ? programNameTitleMap[episode.program_name as keyof typeof programNameTitleMap]
          : null,
        startDate: episode?.start_date ?? null,
        nextSession: client.next_appointment?.startDate ?? null,
        sessionNumber:
          episode?.program_name === programs.SingleSessionCoaching
            ? getGSCDuration(
                client?.first_appointment?.startDate,
                programConfig[episode?.program_name]?.recommendedEpisodeLength,
              )
            : client?.session_number,
      },
    }
  }, [client, episode])
}

export default useDetailsPanelData
