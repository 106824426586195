import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import {
  BodyText,
  BodyTextSize,
  Link,
  ProviderCalendarLandingContent,
  ThemeType,
} from '@lyrahealth-inc/ui-core-crossplatform'

import CalendarSetupCard from './CalendarSetupCard'

const SetupCard = styled(CalendarSetupCard)(({ theme }) => ({
  paddingTop: theme.spacing['32px'],
}))

const OuterContainer = styled.View(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  backgroundColor: theme.colors.backgroundSecondary,
}))

const DescriptionContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['56px'],
}))

export const CalendarLandingPage: FunctionComponent = () => {
  const { colors } = useTheme() as ThemeType

  return (
    <OuterContainer>
      <SetupCard />
      <ProviderCalendarLandingContent />
      <DescriptionContainer>
        <BodyText
          color={colors.textSecondary}
          text={
            <FormattedMessage
              defaultMessage='For more information about how to connect your calendar <supportLink>click here</supportLink>. Still need help? Submit a ticket <ticketLink>here</ticketLink>. We’ll get back to you soon.'
              description='Text offer providers information about connecting their calendar as well as support if they need more help'
              values={{
                supportLink: (text: string) => (
                  <Link
                    text={text}
                    //TODO: Will eventually need Mixpanel event: DACN-1336
                    onPress={() =>
                      window.open(
                        'https://provider-support.lyrahealth.com/hc/en-us/articles/38528605874963--HOLD-New-CC-self-service-guide',
                        '_blank',
                      )
                    }
                    size={BodyTextSize.DEFAULT}
                    underline
                  />
                ),
                ticketLink: (text: string) => (
                  <Link
                    text={text}
                    //TODO: Will eventually need Mixpanel event: DACN-1336
                    onPress={() =>
                      window.open(
                        'https://provider-support.lyrahealth.com/hc/en-us/requests/new?ticket_form_id=26162178922387',
                        '_blank',
                      )
                    }
                    size={BodyTextSize.DEFAULT}
                    underline
                  />
                ),
              }}
            />
          }
        />
      </DescriptionContainer>
    </OuterContainer>
  )
}
