import { createReducer } from '@reduxjs/toolkit'

import {
  CalendarProvider,
  ProviderCalendarConfiguration,
  ProviderCalendarEvent,
  ProviderGoogleEvent,
} from '@lyrahealth-inc/shared-app-logic'

import {
  CLEAR_AVAILABLE_CALENDARS,
  GET_AVAILABLE_CALENDARS,
  GET_CALENDAR_AVAILABILITY_SLOTS,
  GET_CALENDAR_EVENTS,
  GET_CALENDAR_PROVIDER,
  GET_CALENDAR_TOKEN,
  GET_CALENDARS,
  GET_GOOGLE_EVENT,
  HIDE_CALENDAR_LIVE_MODAL,
  SHOW_CALENDAR_LIVE_MODAL,
} from '../../common/constants/reduxConstants'

type CalendarState = {
  calendarEvents: ProviderCalendarEvent[]
  token?: string
  requiresAuthorization?: boolean
  availabilitySlots?: ProviderCalendarEvent[]
  calendars?: ProviderCalendarConfiguration[]
  googleEvents: { [key: string]: ProviderGoogleEvent }
  provider?: CalendarProvider
  showCalendarLiveModal: boolean
  available_calendars?: ProviderCalendarConfiguration[]
}

const initialState = {
  isLoading: true,
  calendarEvents: [],
  googleEvents: {},
  showCalendarLiveModal: false,
  available_calendars: [],
}

export default createReducer<CalendarState>(initialState, (builder) => {
  builder.addCase(GET_CALENDAR_TOKEN, (state, action: any) => {
    state.requiresAuthorization = action.data == null
    return state
  })
  builder.addCase(GET_CALENDAR_EVENTS, (state, action: any) => {
    state.calendarEvents = action.data
    return state
  })
  builder.addCase(GET_CALENDAR_AVAILABILITY_SLOTS, (state, action: any) => {
    state.availabilitySlots = action.data
    return state
  })
  builder.addCase(GET_CALENDARS, (state, action: any) => {
    state.calendars = action.data.filter(
      (calendar: ProviderCalendarConfiguration) => !calendar.read_only && calendar.status === 'VALID',
    )
    return state
  })
  builder.addCase(GET_AVAILABLE_CALENDARS, (state, action: any) => {
    state.available_calendars = action.data
    return state
  })
  builder.addCase(GET_GOOGLE_EVENT, (state, action: any) => {
    state.googleEvents[action.data.id] = action.data
    return state
  })
  builder.addCase(GET_CALENDAR_PROVIDER, (state, action: any) => {
    state.provider = action.data
    return state
  })
  builder.addCase(SHOW_CALENDAR_LIVE_MODAL, (state) => {
    state.showCalendarLiveModal = true
    return state
  })
  builder.addCase(HIDE_CALENDAR_LIVE_MODAL, (state) => {
    state.showCalendarLiveModal = false
    return state
  })
  builder.addCase(CLEAR_AVAILABLE_CALENDARS, (state) => {
    state.available_calendars = []
    return state
  })
})
