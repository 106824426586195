import React, { FunctionComponent, useContext, useMemo, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { View } from 'react-native'
import { Popover } from 'react-native-popper'

import { format } from 'date-fns'
import styled, { useTheme } from 'styled-components/native'

import { CalendarAlert, CalendarAlertType } from '@lyrahealth-inc/shared-app-logic'

import {
  BodyText,
  ChevronIconDirection,
  ChevronV2Icon,
  GearIcon,
  PressableOpacity,
  QuestionMarkIcon,
  SecondaryButton,
  Subhead,
  TertiaryButton,
  TertiaryIconButton,
  XIcon,
} from '../../atoms'
import { AppContext } from '../../context'
import { SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'
import { ProviderCalendarMetricBadge } from '../providerCalendarMetricBadge/ProviderCalendarMetricBadge'

export type ProviderCalendarHeaderProps = {
  onAddPressed: () => void
  onNextPressed: () => void
  onPrevPressed: () => void
  onTodayPressed: () => void
  onSettingsPressed: () => void
  onOpenZendeskPressed: () => void
  rangeText: string
  scheduledSessions: number | null
  clientHours: number | null
  alerts: CalendarAlert[]
  onAlertPressed: (alert: CalendarAlert) => void
  hidePerformanceMetrics?: boolean
  hybridBookingMode?: boolean
}

const Header = styled.View(({ theme }) => ({
  flexDirection: 'column',
  position: 'sticky',
  top: 0,
  backgroundColor: theme.colors.backgroundPrimary,
  zIndex: 3,
}))

const HeaderContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: theme.spacing['16px'],
  padding: `${theme.spacing['16px']} ${theme.spacing['24px']}`,
  backgroundColor: theme.colors.backgroundPrimary,
}))

const HeaderInnerContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['16px'],
}))

const MetricsContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['8px'],
}))

const Metric = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['12px'],
  padding: `6px ${theme.spacing['8px']}`,
  border: `1px solid ${theme.colors.borderDefault}`,
  borderRadius: '8px',
}))

const Border = styled.View(({ theme }) => ({
  height: '1px',
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: '1px',
  backgroundColor: theme.colors.borderDefault,
}))

const IconButtonContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['8px'],
}))

const IconButton = styled(TertiaryIconButton)(({ theme }) => ({
  alignSelf: 'center',
  border: `1.5px solid ${theme.colors.borderDefault}`,
  height: '48px',
  width: '48px',
}))

const CenteredIconButton = styled(TertiaryIconButton)({
  alignSelf: 'center',
})

const PopoverContainer = styled.View(({ theme }) => ({
  flexDirection: 'column',
  backgroundColor: theme.colors.backgroundPrimary,
  borderRadius: '16px',
  padding: theme.spacing['16px'],
  gap: theme.spacing['16px'],
  boxShadow: `0 2px 10px ${theme.colors.shadowLow}`,
}))

const PopoverRow = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['48px'],
}))

const CloseButton = styled(PressableOpacity)({
  alignSelf: 'flex-end',
})

const TodayButton = styled(TertiaryButton)(({ theme }) => ({
  alignSelf: 'center',
  borderColor: theme.colors.borderDefault,
  borderWidth: '1.5px',
}))

export const ProviderCalendarHeader: FunctionComponent<ProviderCalendarHeaderProps> = ({
  onAddPressed,
  onNextPressed,
  onPrevPressed,
  onTodayPressed,
  onSettingsPressed,
  onOpenZendeskPressed,
  rangeText,
  scheduledSessions,
  clientHours,
  alerts,
  onAlertPressed,
  hidePerformanceMetrics,
  hybridBookingMode,
}) => {
  const theme = useTheme() as ThemeType
  const { isInProductCalendarAddModalEnabled } = useContext(AppContext)
  const [isOpen, setIsOpen] = useState(false)
  const { formatMessage } = useIntl()
  const pressableRef = useRef(null)
  const bookableAlert = useMemo(() => alerts.find((alert) => alert.type === CalendarAlertType.BOOKABLE), [alerts])
  const scheduledAlert = useMemo(() => alerts.find((alert) => alert.type === CalendarAlertType.SCHEDULED), [alerts])
  return (
    <Header>
      <HeaderContainer>
        <HeaderInnerContainer>
          <TodayButton
            aria-label={formatMessage(
              {
                defaultMessage: 'Today {actualDate}',
                description: 'Button text that moves calendar to today',
              },
              { actualDate: format(new Date(), 'PPPP') },
            )}
            customTextColor={theme.colors.textSecondary}
            testID={tID('ProviderCalendarHeader-today-button')}
            text={<FormattedMessage defaultMessage='Today' description='Button text that moves calendar to today' />}
            onPress={onTodayPressed}
          />
          <IconButtonContainer>
            <IconButton
              aria-label={formatMessage({
                defaultMessage: 'Previous week',
                description: 'Button that moves calendar to previous week',
              })}
              leftIcon={<ChevronV2Icon direction={ChevronIconDirection.LEFT} />}
              onPress={onPrevPressed}
              testID={tID('ProviderCalendarHeader-previous-button')}
            />
            <IconButton
              aria-label={formatMessage({
                defaultMessage: 'Next week',
                description: 'Button that moves calendar to next week',
              })}
              testID={tID('ProviderCalendarHeader-next-button')}
              leftIcon={<ChevronV2Icon direction={ChevronIconDirection.RIGHT} />}
              onPress={onNextPressed}
            />
          </IconButtonContainer>
          <Subhead
            aria-label={rangeText}
            tabIndex={0}
            text={rangeText}
            size={SubheadSize.LARGE}
            testID={tID('ProviderCalendarHeader-range-text')}
          />
        </HeaderInnerContainer>
        <HeaderInnerContainer>
          {!hidePerformanceMetrics && (
            <MetricsContainer testID={tID('ProviderCalendarHeader-metrics-client-hours')}>
              <Metric>
                <Subhead
                  aria-label={formatMessage(
                    {
                      defaultMessage: '{numberOfSpots} calendar spots',
                      description: 'Button text of the month and year the calendar is presenting right now',
                    },
                    { numberOfSpots: clientHours },
                  )}
                  size={SubheadSize.LARGE}
                  text={clientHours}
                  tabIndex={0}
                />
                <BodyText
                  text={
                    <FormattedMessage
                      defaultMessage='Calendar spots'
                      description='Label for calendar spots slots on lc calendar header'
                    />
                  }
                />
                {bookableAlert && (
                  <View role='alert'>
                    <ProviderCalendarMetricBadge
                      testID={tID('ProviderCalendarMetricBadge-bookable-badge')}
                      alert={bookableAlert}
                      onPress={() => onAlertPressed(bookableAlert)}
                    />
                  </View>
                )}
              </Metric>
              <Metric testID={tID('ProviderCalendarHeader-metrics-scheduled')}>
                <Subhead
                  aria-label={formatMessage(
                    {
                      defaultMessage: '{scheduledSessions} scheduled sessions',
                      description: 'Text for the number of scheduled sessions the Provider has',
                    },
                    { scheduledSessions },
                  )}
                  size={SubheadSize.LARGE}
                  text={scheduledSessions}
                  tabIndex={0}
                />
                <BodyText
                  text={
                    <FormattedMessage
                      defaultMessage='Scheduled'
                      description='Label for scheduled sessions on lc calendar header'
                    />
                  }
                />
                {scheduledAlert && (
                  <View role='alert'>
                    <ProviderCalendarMetricBadge
                      testID={tID('ProviderCalendarMetricBadge-scheduled-badge')}
                      alert={scheduledAlert}
                      onPress={() => onAlertPressed(scheduledAlert)}
                    />
                  </View>
                )}
              </Metric>
            </MetricsContainer>
          )}
          {isInProductCalendarAddModalEnabled && (
            <SecondaryButton
              testID={tID('ProviderBookableCalendarSetup-add-button')}
              text={
                <FormattedMessage defaultMessage='Add' description='Button to add availability to calendar setup' />
              }
              onPress={onAddPressed}
            />
          )}
          <CenteredIconButton
            aria-label={formatMessage({
              defaultMessage: 'Settings',
              description: 'Button text of the Settings button',
            })}
            testID={tID('ProviderCalendarHeader-settings-button')}
            leftIcon={<GearIcon color={theme.colors.iconDefault} size={20} isFilled={false} />}
            onPress={onSettingsPressed}
          />
          <View ref={pressableRef}>
            <CenteredIconButton
              aria-label={formatMessage({
                defaultMessage: 'Zendesk',
                description: 'Button text of the Zendesk help button',
              })}
              testID={tID('ProviderCalendarHeader-help-button')}
              leftIcon={<QuestionMarkIcon fillColor={theme.colors.iconDefault} width={20} />}
              onPress={() => setIsOpen(true)}
            />
          </View>
          <Popover isOpen={isOpen} onOpenChange={setIsOpen} trigger={pressableRef} placement='left top'>
            <Popover.Backdrop />
            <Popover.Content>
              <PopoverContainer>
                <PopoverRow>
                  <Subhead
                    tabIndex={0}
                    size={SubheadSize.SMALL}
                    text={
                      <FormattedMessage
                        defaultMessage='Need help with your Lyra calendar?'
                        description='Header for provider calendar help popover'
                      />
                    }
                  />
                  <CloseButton
                    aria-label={formatMessage({
                      defaultMessage: 'Close dialog',
                      description: 'Button text to close the Zendesk dialog',
                    })}
                    hitSlop={60}
                    onPress={() => setIsOpen(false)}
                  >
                    <XIcon size={24} fillColor={theme.colors.iconDefault} />
                  </CloseButton>
                </PopoverRow>
                <SecondaryButton
                  testID={tID('ProviderCalendarHeader-open-zendesk-button')}
                  onPress={onOpenZendeskPressed}
                  text={
                    hybridBookingMode ? (
                      <FormattedMessage
                        defaultMessage='Open Help Center'
                        description='Button to get help with calendar'
                      />
                    ) : (
                      <FormattedMessage defaultMessage='Open Zendesk' description='Button to get help with calendar' />
                    )
                  }
                />
              </PopoverContainer>
            </Popover.Content>
          </Popover>
        </HeaderInnerContainer>
      </HeaderContainer>
      <Border />
    </Header>
  )
}
