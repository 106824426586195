import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { useWindowDimensions, View } from 'react-native'
import ReactPlayer, { ReactPlayerProps } from 'react-player'

import styled, { useTheme } from 'styled-components/native'

import { XIcon } from '../../atoms/icons/XIcon'
import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { breakpoints as breakpointsConstants } from '../../constants'
import { Modal, type ModalProps } from '../../organisms/modal/Modal'
import { ThemeType } from '../../utils/themes/ThemeProvider'
import { tID } from '../../utils/utils'
import { calculateVideoSize } from '../../utils/videoUtils'
import { type TrackWatchedVideoCallback, VideoPlayer } from '../videoPlayer/VideoPlayer.web'

export interface VideoPlayerModalProps {
  url: string
  isVisible: boolean
  onClose: () => void
  autoPlay?: boolean
  onTrackVideoWatched?: TrackWatchedVideoCallback
  videoId?: string
  animationType?: ModalProps['animationType']
  aspectRatio?: number
  className?: string
  playerProps?: ReactPlayerProps
  videoPlayerRef?: React.MutableRefObject<ReactPlayer | null>
  videoOverlay?: React.JSX.Element
}

const CloseVideoButtonContainer = styled(PressableOpacity)<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  position: 'absolute',
  flexDirection: 'row',
  right: spacing['32px'],
  top: spacing['32px'],
  background: 'rgba(0, 0, 0, 0.6)',
  borderRadius: spacing['48px'],
  padding: spacing['12px'],
  zIndex: 10,
}))

const VideoPlayerContainer = styled(View)<{ width?: number; height?: number }>(({ width, height }) => ({
  width,
  height,
  margin: '0 auto',
}))

const StyledModal = styled(Modal)({
  padding: 0,
})

export const VideoPlayerModal: FunctionComponent<VideoPlayerModalProps> = ({
  url,
  isVisible,
  onClose,
  autoPlay = false,
  onTrackVideoWatched,
  videoId,
  animationType,
  aspectRatio = 0.5625, // 9 / 16
  className,
  playerProps,
  videoPlayerRef,
  videoOverlay,
}) => {
  const { formatMessage } = useIntl()
  const { breakpoints, colors, spacing } = useTheme()
  const { videoPlayerModal } = colors.components
  const { isMobileSized } = breakpoints
  const { width: screenWidth, height: screenHeight } = useWindowDimensions()

  const margin = isMobileSized ? 0 : 48
  const maxWidth = Math.min(screenWidth, breakpointsConstants.laptop) - margin
  const minWidth = (isMobileSized ? breakpointsConstants.mobileXxs : breakpointsConstants.tablet) - margin
  const [width, height] = calculateVideoSize(maxWidth, screenHeight, minWidth, aspectRatio)

  const content = (
    <>
      {!isMobileSized && (
        <CloseVideoButtonContainer onPress={onClose} testID={tID('Modal-XIcon')}>
          <XIcon size={24} fillColor={colors.iconInverse} />
        </CloseVideoButtonContainer>
      )}
      <VideoPlayerContainer testID={tID('VideoPlayer')} width={width} height={height}>
        <VideoPlayer
          url={url}
          width={'100%'}
          height={'100%'}
          playing={autoPlay}
          onTrackVideoWatched={onTrackVideoWatched}
          videoId={videoId}
          style={isMobileSized ? undefined : { borderRadius: spacing['8px'], overflow: 'hidden' }}
          className={className}
          videoPlayerRef={videoPlayerRef}
          {...playerProps}
        />
        {videoOverlay}
      </VideoPlayerContainer>
    </>
  )

  return (
    <StyledModal
      onRequestClose={onClose}
      onCloseEnd={onClose}
      visible={isVisible}
      modalContents={content}
      width={width.toString() + 'px'}
      data-test-id={'VideoPlayerModal'}
      disableBottomSheet
      backgroundColor={videoPlayerModal.background}
      closeIconColor={isMobileSized ? colors.iconInverse : undefined}
      mobileContentCentered
      animationType={animationType}
      isHeaderTransparent={true}
      centered={true}
      accessibilityLabel={formatMessage({
        defaultMessage: 'Video player modal',
        description: 'Accessibility label for video player modal',
      })}
    />
  )
}
