import React, { FunctionComponent, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { isAfter, sub } from 'date-fns'
import { get, isEmpty, isNil } from 'lodash-es'
import { bindActionCreators } from 'redux'
import { v4 as uuidv4 } from 'uuid'

import {
  canSeeDirectBookingLink,
  MessageAttributes,
  parseAllowedMessages,
  ProgramCustomerPropertyId,
  ProviderInfo,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'
import {
  ActivityReviewInfo,
  Chat,
  ChatHandle,
  CloseStyles,
  DirectBookingLinkInline,
  DirectBookingLinkMessageAttachment,
  MessageAttachmentType,
  MessageType,
  useFetcher,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { useSelectedConversation } from './data/hooks/useSelectedConversation'
import {
  useLazyGetMessageQuery,
  useSendMessageMutation,
  useUpdateConversationAttributesMutation,
} from './data/messagesApi'
import {
  getConversationsClient,
  getMessengerDrafts,
  getSelectedConversationId,
  inLiveMsgSession,
} from './data/messagesSelectors'
import { saveMessageDraft, toggleLiveMsgSession } from './data/messagesSlice'
import { actions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { SUPERVISOR_ROLES } from '../../common/constants/appConstants'
import { CLIENTS_ASSIGNMENT_DETAILS, CLIENTS_SESSIONS } from '../../common/constants/routingConstants'
import { getClientFullName, hasRole } from '../../common/utils/utils'
import { getAuthSupervisor, getAuthUser, getAuthUserId } from '../../data/auth/authSelectors'
import {
  getClientDetailsData,
  getClientEpisodesData,
  getSelectedEpisodeProgram,
} from '../../data/lyraTherapy/clientSelectors'
import { RootState, useAppDispatch } from '../../data/store'
import { clearSelectedAssignment, getAssignment } from '../assignments/data/assignmentsAutoActions'
import { useClientAppointmentsData } from '../clients/clientDetails/data/hooks/useClientAppointmentsData'
import { setToastContent } from '../data/ltToastAutoActions'
import { getClientEpisodes as getClientEpisodesAction } from '../episodes/data/episodesAutoActions'
import { useInfiniteMessages } from './data/hooks/useInfiniteMessages'

const messageReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ON_TYPING': {
      return { ...state, receiverTyping: action.receiverTyping }
    }
    case 'NEW_MESSAGE': {
      return { ...state, newMessageCount: state.newMessageCount + 1, receiverTyping: false }
    }
    case 'READ_MESSAGE': {
      return { ...state, newMessageCount: 0 }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

type BaseChatProps = ConnectedProps<typeof connector> & {
  closePopover?: () => void
  onUpdateMessageCount?: () => void
  isLoading?: boolean
  onMessageSent?: () => void
  onChatHandleChange?: (handle: ChatHandle | null) => void
} & Partial<React.ComponentProps<typeof Chat>>

export const BaseChat: FunctionComponent<BaseChatProps> = ({
  client,
  draft,
  conversationsClient,
  closePopover,
  inLiveMsgSession,
  selectedEpisodeProgram,
  onUpdateMessageCount,
  episodes,
  isLoading = false,
  onMessageSent,
  onChatHandleChange,
  actions: { clearSelectedAssignment, setToastContent, getClientEpisodesAction },
  ...props
}) => {
  const conversationInstanceId = useRef(uuidv4())
  const providerId = useSelector(getAuthUserId)
  const supervisor = useSelector(getAuthSupervisor)
  const provider = useSelector(getAuthUser)
  const selectedConversation = useSelectedConversation()
  const [initialValue, setInitialValue] = useState('')
  const [state, dispatch] = useReducer(messageReducer, {
    receiverTyping: false,
    newMessageCount: 0,
  })
  const { data: appointments } = useClientAppointmentsData()
  const {
    data: messagesData,
    isLoading: loadingMessages,
    isSuccess: messagesFetched,
    loadMore: loadMoreMessages,
    isLoadingNext: isLoadingNextPage,
  } = useInfiniteMessages()
  const messages = useMemo(() => (selectedConversation ? messagesData : []), [selectedConversation, messagesData])

  const [getMessage] = useLazyGetMessageQuery()
  const [createMessage] = useSendMessageMutation()
  const [updateConversationAttributes] = useUpdateConversationAttributesMutation()
  const appDispatch = useAppDispatch()

  const isSupervisor = supervisor && !isEmpty(supervisor.roles) && hasRole(supervisor.roles, SUPERVISOR_ROLES)
  const { receiverTyping, newMessageCount } = state
  const { isPreferredNameEnabled, supervisorsThatCanMarkConversationsAsRead } = useFlags()
  const [addedDBLAttachment, setAddedDBLAttachment] = useState(false)
  const inputRef = useRef('')

  useEffect(() => {
    inputRef.current = draft
    setInitialValue(draft)
  }, [draft])

  const markMessagesAsRead = useCallback(async () => {
    const unreadMessageCount = selectedConversation?.conversation_attributes?.unread_provider_messages_count ?? 0
    if (unreadMessageCount > 0 && isEmpty(supervisor) && selectedConversation?.conversation_id) {
      dispatch({ type: 'READ_MESSAGE' })
      try {
        await updateConversationAttributes({
          conversationId: selectedConversation?.conversation_id,
          unreadProviderMessagesCount: 0,
        }).unwrap()
      } catch (e) {
        console.error(`Unable to update unread message - ${e}`)
      }
    }
  }, [
    selectedConversation?.conversation_attributes?.unread_provider_messages_count,
    selectedConversation?.conversation_id,
    supervisor,
    updateConversationAttributes,
  ])
  const markMessagesAsUnread = useCallback(async () => {
    if (!selectedConversation?.conversation_id) {
      return
    }
    try {
      track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.MARK_MESSAGE_UNREAD })
      await updateConversationAttributes({
        conversationId: selectedConversation?.conversation_id,
        unreadProviderMessagesCount: 1,
      })
      setToastContent({
        text: 'Done! Successfully marked message as unread',
        id: 'MessagesPopover-Toast-Success',
        toastType: 'success',
      })
    } catch (e) {
      console.error(`Unable to update unread message - ${e}`)
    } finally {
      onUpdateMessageCount && onUpdateMessageCount()
    }
  }, [onUpdateMessageCount, selectedConversation?.conversation_id, setToastContent, updateConversationAttributes])

  // tell the conversation that we are typing
  const onTyping = useCallback(async () => {
    if (conversationsClient && selectedConversation?.conversation_id) {
      const activeConversation = await conversationsClient.getConversationBySid(selectedConversation?.conversation_id)
      if (conversationsClient.connectionState !== 'connected') {
        return
      }
      activeConversation.typing().catch(() => {})
    }
  }, [conversationsClient, selectedConversation])

  const setInputValue = useCallback((value) => (inputRef.current = value ?? ''), [])

  const setInputValueAndStartTypingIndicator = (value: string) => {
    setInputValue(value)
    onTyping()
  }

  const onReceiverTyping = useCallback(
    (member) => {
      // ensure that the typing started event comes from the selected conversation
      if (
        member.conversation.sid === selectedConversation?.conversation_id &&
        member.identity !== conversationsClient?.user.identity
      ) {
        dispatch({ type: 'ON_TYPING', receiverTyping: member.isTyping })
      }
    },
    [conversationsClient?.user.identity, selectedConversation?.conversation_id],
  )

  const onNewMessage = useCallback(
    async (message) => {
      if (
        !selectedConversation?.conversation_id ||
        message.conversation.sid !== selectedConversation?.conversation_id
      ) {
        return
      }

      const isClientMessage = get(message, 'state.attributes.from') !== providerId
      const appendMessage =
        isClientMessage || message.state.attributes.metadata?.conversationInstanceId !== conversationInstanceId.current
      await getMessage({
        conversationId: selectedConversation?.conversation_id,
        messageId: message.sid,
        append: appendMessage,
      })
      if (isClientMessage) {
        dispatch({ type: 'NEW_MESSAGE' })
      }
    },
    [selectedConversation, getMessage, providerId],
  )

  // save draft if the component unmounts
  useEffect(
    () => () => {
      if (selectedConversation?.conversation_id) {
        appDispatch(
          saveMessageDraft({ content: inputRef.current, conversationId: selectedConversation?.conversation_id }),
        )
      }
      inputRef.current = ''
      // clear live messaging in session if component is unmounted anywhere
      if (inLiveMsgSession) {
        appDispatch(toggleLiveMsgSession(false))
      }
    },
    [appDispatch, inLiveMsgSession, selectedConversation?.conversation_id],
  )

  // Clear unread messages when component is mounted and messages have already been fetched
  const hasValidSupervisor = useMemo(() => {
    return isEmpty(supervisor) || supervisorsThatCanMarkConversationsAsRead?.includes(supervisor?.id)
  }, [supervisor, supervisorsThatCanMarkConversationsAsRead])

  useEffect(() => {
    const updateMessage = async () => {
      if (!selectedConversation?.conversation_id) {
        return
      }
      try {
        await updateConversationAttributes({
          conversationId: selectedConversation?.conversation_id,
          unreadProviderMessagesCount: 0,
        })
      } catch (e) {
        console.error(`Unable to update unread message - ${e}`)
      }
    }
    if (messagesFetched && selectedConversation?.conversation_id && hasValidSupervisor) {
      updateMessage()
    }
  }, [messagesFetched, selectedConversation?.conversation_id, hasValidSupervisor, updateConversationAttributes])

  useEffect(() => {
    if (conversationsClient) {
      conversationsClient.on('messageAdded', onNewMessage)
      conversationsClient.on('typingStarted', onReceiverTyping)
      conversationsClient.on('typingEnded', onReceiverTyping)
    }
    return () => {
      if (conversationsClient) {
        conversationsClient.off('messageAdded', onNewMessage)
        conversationsClient.off('typingStarted', onReceiverTyping)
        conversationsClient.off('typingEnded', onReceiverTyping)
      }
    }
  }, [conversationsClient, onNewMessage, onReceiverTyping])

  const isProgramBookingLink = (message: string) =>
    message.includes('directPathBooking?directPath=true') &&
    message.includes('directLinkIntent=DIRECT_LINK_CONCURRENT_CARE_REFERRAL') &&
    message.includes('directLinkSource=provider_message')
  const getProgramBookingLinkType = (message: string) => {
    if (message.includes('treatment=Therapy') && message.includes('offering=Default')) {
      return ProgramCustomerPropertyId.considerLyraTherapy
    } else if (message.includes('treatment=Therapy') && message.includes('offering=AlcoholUseDisorder')) {
      return ProgramCustomerPropertyId.alcoholUseDisorderTherapy
    } else if (message.includes('treatment=Coaching') && message.includes('offering=Default')) {
      return ProgramCustomerPropertyId.stressManagement
    } else if (message.includes('treatment=Coaching') && message.includes('offering=Parenting')) {
      return ProgramCustomerPropertyId.coachingForParents
    } else if (message.includes('treatment=Coaching') && message.includes('offering=SingleSession')) {
      return ProgramCustomerPropertyId.guidedSelfCareEnabled
    } else if (message.includes('treatment=Assessment') && message.includes('offering=ClinicalLeave')) {
      return ProgramCustomerPropertyId.clinicalLeaveEvaluation
    } else if (message.includes('treatment=MedicationManagement') && message.includes('offering=Default')) {
      return ProgramCustomerPropertyId.blendedCareMeds
    }
    return ''
  }
  const submitNewMessage = useCallback(
    (value) => {
      if (!selectedConversation?.conversation_id) {
        return
      }
      if (selectedConversation?.patient_lyra_id === client?.id) {
        const metadata: Record<string, any> = {
          conversationInstanceId: conversationInstanceId.current,
        }
        if (addedDBLAttachment) {
          metadata.attachments = [
            {
              key: uuidv4(),
              type: MessageAttachmentType.DBL,
            },
          ]
        }
        if (isProgramBookingLink(value)) {
          metadata.attachments = [
            {
              key: uuidv4(),
              type: MessageAttachmentType.PBL,
              program: getProgramBookingLinkType(value),
            },
          ]
        }

        createMessage({
          conversationId: selectedConversation?.conversation_id,
          data: {
            message_body: value,
            message_type: 'direct',
            message_author_id: providerId,
            message_author_type: 'provider',
            metadata,
          },
        })
        setInitialValue('')
        inputRef.current = ''
        appDispatch(saveMessageDraft({ content: '', conversationId: selectedConversation?.conversation_id }))
        setAddedDBLAttachment(false)
        track({ event: 'SEND_MESSAGE', details: { program: selectedEpisodeProgram } })
        onMessageSent?.()
      }
    },
    [
      selectedConversation?.conversation_id,
      selectedConversation?.patient_lyra_id,
      client?.id,
      addedDBLAttachment,
      createMessage,
      providerId,
      appDispatch,
      selectedEpisodeProgram,
      onMessageSent,
    ],
  )

  const navigate = useNavigate()

  const handleLinkClickNew = useCallback(
    (_: string, __: boolean, activityInfo?: ActivityReviewInfo) => {
      if (!activityInfo) {
        return
      }
      const { id, responseId, activityType } = activityInfo
      switch (activityType) {
        case 'feedback':
          clearSelectedAssignment()
          navigate(CLIENTS_ASSIGNMENT_DETAILS.route, {
            state: { assignmentId: id, responseId: responseId },
          })
          break
        case 'session_cancellation':
        case 'request_session_time':
          navigate(CLIENTS_SESSIONS.route)
          break
      }
      closePopover?.()
    },
    [clearSelectedAssignment, closePopover, navigate],
  )

  useFetcher([[getClientEpisodesAction, { provider_id: provider?.id, patient_id: client?.id }, episodes.length > 0]])

  const canAttachDBL = useMemo(
    () => canSeeDirectBookingLink({ provider: provider as unknown as ProviderInfo, episodes, appointments }),
    [appointments, episodes, provider],
  )

  const attachDBLDisabled = !canAttachDBL || addedDBLAttachment

  const getMessageType = (attributes: MessageAttributes) => {
    const linkText = attributes?.metadata?.linkText
    const activityResponseId = attributes?.metadata?.activity_response_id
    const activityId = attributes?.metadata?.activity_id
    const isDeletedMessage =
      !isNil(attributes?.retention_status) && attributes?.retention_status === 'DELETED_LIVE_MESSAGE'
    const isActivity = Boolean(linkText) || (Boolean(activityResponseId) && Boolean(activityId))

    if (isDeletedMessage) {
      return MessageType.DELETED
    } else if (isActivity) {
      return MessageType.ACTIVITY
    } else {
      return MessageType.CHAT_BUBBLE
    }
  }

  const memoizedMessages = useMemo(() => {
    if (!messages) return []
    return parseAllowedMessages(messages).map((m, index) => {
      const attributes = JSON.parse(m?.attributes)
      const isPatient = attributes.author_type === 'patient'
      const previousMessage = messages[index - 1]
      let showTime = true
      const dateCreated = new Date(m.date_created)
      // we only want to show the time stamp if 10 minutes have passed
      showTime =
        index === 0 ? true : isAfter(sub(dateCreated, { minutes: 10 }), new Date(previousMessage?.date_created))
      return {
        authorType: attributes.author_type,
        messageId: m.message_id,
        receiver: isPatient,
        dateCreated: m?.date_created,
        message: m.body,
        isLatestMessage: index === messages.length - 1,
        // if the message has no status, it has been sent
        sendStatus: m.sendStatus === undefined ? 'sent' : m.sendStatus,
        showTime,
        linkText: attributes.metadata?.linkText,
        activityResponseId: attributes.metadata?.activity_response_id,
        activityId: attributes.metadata?.activity_id,
        submitDate: attributes.metadata?.submit_date,
        activityTitle: attributes.metadata?.title,
        timeZone: (provider as any).time_zone,
        type: getMessageType(attributes),
        messageType: attributes.message_type,
        showAvatar: false,
        attachments: attributes.metadata?.attachments,
      }
    })
  }, [messages, provider])

  return (
    <Chat
      ref={(handle) => {
        onChatHandleChange?.(handle)
      }}
      chatHeaderTitle=''
      liveSessionBadge=''
      displayName={client ? getClientFullName(client, isPreferredNameEnabled) : ''}
      onBack={closePopover}
      onSendMessage={submitNewMessage}
      onInputChange={setInputValueAndStartTypingIndicator}
      messages={memoizedMessages}
      receiverTyping={receiverTyping}
      receiverMsgable={true}
      unreadMsgCount={newMessageCount}
      scrollInfo={{ scrollToEnd: false, animatedScroll: false }}
      showNewMsgIndicator={newMessageCount > 0}
      onNewMsgPressed={markMessagesAsRead}
      onChatBubblePressed={handleLinkClickNew}
      closeStyle={CloseStyles.BackArrow}
      showChevron={true}
      inputValue={initialValue}
      conversationDateCreated={selectedConversation?.conversation_date_created}
      isLoading={loadingMessages || isLoading}
      showClose={false}
      markMessagesAsUnread={markMessagesAsUnread}
      showMenu={!isSupervisor}
      shouldInvert={true}
      inputActions={[
        <DirectBookingLinkInline
          key='DBL'
          onPress={() => setAddedDBLAttachment(true)}
          disabled={attachDBLDisabled}
          disabledTooltipContent={
            !canAttachDBL ? (
              <FormattedMessage
                defaultMessage='Unable to send direct booking link to this client'
                description='Tooltip on DBL attachment button when provider cannot add attachment'
              />
            ) : (
              <FormattedMessage
                defaultMessage='A direct booking link is already 
                    inserted into the message'
                description='Tooltip on DBL attachment button when attachment already added'
              />
            )
          }
        />,
      ]}
      inputAttachments={
        addedDBLAttachment
          ? [
              <DirectBookingLinkMessageAttachment
                key='DBLAttachment'
                onPress={() => {}}
                onClosePress={() => setAddedDBLAttachment(false)}
                showClose
              />,
            ]
          : []
      }
      onReachedMessagesEnd={loadMoreMessages}
      isLoadingNextPage={isLoadingNextPage}
      {...props}
    />
  )
}

const mapStateToProps = (state: RootState) => {
  const selectedConversationId = getSelectedConversationId(state)
  const draft = getMessengerDrafts(state).find((draft) => draft.conversationId === selectedConversationId)
  return {
    client: getClientDetailsData(state),
    conversationsClient: getConversationsClient(state),
    draft: draft?.content ?? '',
    inLiveMsgSession: inLiveMsgSession(state),
    selectedEpisodeProgram: getSelectedEpisodeProgram(state),
    episodes: getClientEpisodesData(state),
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      getAssignment,
      clearSelectedAssignment,
      setToastContent,
      getClientEpisodesAction,
    },
    dispatch,
  ),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(BaseChat)
