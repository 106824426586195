import { AnyAction, Dispatch } from 'redux'

import { BookingMode, ProviderCalendarConfiguration, ProviderCalendarEvent } from '@lyrahealth-inc/shared-app-logic'

import {
  CLEAR_AVAILABLE_CALENDARS,
  CREATE_CALENDAR_EVENTS,
  GET_AVAILABLE_CALENDARS,
  GET_CALENDAR_AVAILABILITY_SLOTS,
  GET_CALENDAR_EVENTS,
  GET_CALENDAR_OAUTH_URL,
  GET_CALENDAR_PROVIDER,
  GET_CALENDAR_TOKEN,
  GET_CALENDARS,
  GET_GOOGLE_EVENT,
  HIDE_CALENDAR_LIVE_MODAL,
  PATCH_CALENDAR_EVENT,
  PATCH_CALENDAR_PROVIDER,
  PUT_AVAILABLE_CALENDARS,
  SHOW_CALENDAR_LIVE_MODAL,
} from '../../common/constants/reduxConstants'
import { axiosCalendarServicInstance } from '../../common/http/axiosInstance'

type OAuthURLRequest = {
  providerEmail: string
  providerId: string
  timeZone: string
}

export const getOAuthURL = (params: OAuthURLRequest) => async (dispatch: Dispatch<AnyAction>) => {
  const { data } = await axiosCalendarServicInstance.get('/oauth2/google', {
    params: {
      provider_email: params.providerEmail,
      provider_id: params.providerId,
      timezone: params.timeZone,
    },
  })

  return dispatch({
    type: GET_CALENDAR_OAUTH_URL,
    data: data.authorization_uri,
  })
}

export const getCalendarToken =
  ({ providerId }: { providerId: string }) =>
  async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await axiosCalendarServicInstance.get(`/api/v1/auth/${providerId}`)
    return dispatch({
      type: GET_CALENDAR_TOKEN,
      data: data.access_token,
    })
  }

export const getCalendarEvents =
  ({ providerId, startDate, endDate }: { providerId: string; startDate: string; endDate: string }) =>
  async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await axiosCalendarServicInstance.get(`/api/v1/events`, {
      params: {
        start_date: startDate,
        end_date: endDate,
        provider_id: providerId,
      },
    })
    return dispatch({
      type: GET_CALENDAR_EVENTS,
      data,
    })
  }

export const getCalendarAvailabilitySlots =
  ({ providerId }: { providerId: string }) =>
  async (dispatch: Dispatch<AnyAction>) => {
    let data: ProviderCalendarEvent[]
    try {
      data = (
        await axiosCalendarServicInstance.get(`/api/v1/events`, {
          params: {
            event_type: 'bookable_recurring',
            provider_id: providerId,
          },
        })
      ).data
    } catch {
      data = []
    }
    return dispatch({
      type: GET_CALENDAR_AVAILABILITY_SLOTS,
      data,
    })
  }

type PostCalendarEventsTemplateRequest = {
  provider_calendar_configuration_id: string
  events: {
    title: string
    start: string
    end: string
    timezone: string
    event_type: string
    recurrence?: string[]
  }[]
}
export const postCalendarEventsTemplate =
  (request: PostCalendarEventsTemplateRequest) => async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await axiosCalendarServicInstance.post('/api/v1/events/template', request)
    return dispatch({
      type: CREATE_CALENDAR_EVENTS,
      data,
    })
  }

type PutCalendarEventsTemplateRequest = {
  provider_calendar_configuration_id: string
  event_ids: string[]
}
export const putCalendarEventsTemplateCancel =
  (request: PutCalendarEventsTemplateRequest) => async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await axiosCalendarServicInstance.put('/api/v1/events/template/cancel', request)
    return dispatch({
      type: CREATE_CALENDAR_EVENTS,
      data,
    })
  }

export const getCalendars =
  ({ providerId }: { providerId: string }) =>
  async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await axiosCalendarServicInstance.get('/api/v1/calendar/configuration', {
      params: { provider_id: providerId },
    })
    return dispatch({
      type: GET_CALENDARS,
      data: data,
    })
  }

export const getAvailableCalendars =
  ({ providerId }: { providerId: string }) =>
  async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await axiosCalendarServicInstance.get('/api/v1/calendar/configuration/available', {
      params: { provider_id: providerId },
    })
    return dispatch({
      type: GET_AVAILABLE_CALENDARS,
      data,
    })
  }

type PutAvailableCalendarsRequest = {
  provider_id: string
  configurations: ProviderCalendarConfiguration[]
}

export const putAvailableCalendars =
  (request: PutAvailableCalendarsRequest) => async (dispatch: Dispatch<AnyAction>) => {
    const { provider_id, configurations } = request
    const { data } = await axiosCalendarServicInstance.put(`api/v1/calendar/configuration/provider/${provider_id}`, {
      configurations,
    })
    return dispatch({
      type: PUT_AVAILABLE_CALENDARS,
      data,
    })
  }

type PatchCalendarEventRequest = {
  external_id: string
  body: {
    provider_calendar_configuration_id: string
    event: {
      start?: string
      end?: string
      status?: string
    }
  }
}
export const patchCalendarEvent = (request: PatchCalendarEventRequest) => async (dispatch: Dispatch<AnyAction>) => {
  const { data } = await axiosCalendarServicInstance.patch(`/api/v1/events/${request.external_id}`, request.body)
  return dispatch({
    type: PATCH_CALENDAR_EVENT,
    data,
  })
}

type PostCalendarEventsRequest = {
  provider_calendar_configuration_id: string
  events: {
    title: string
    start: string
    end: string
    timezone: string
    event_type: string
  }[]
}

export const postCalendarEvents = (request: PostCalendarEventsRequest) => async (dispatch: Dispatch<AnyAction>) => {
  const { data } = await axiosCalendarServicInstance.post('/api/v1/events', request)
  return dispatch({
    type: CREATE_CALENDAR_EVENTS,
    data,
  })
}

type GetGoogleEventRequest = {
  external_id: string
  provider_calendar_configuration_id: string
}
export const getGoogleEvent = (request: GetGoogleEventRequest) => async (dispatch: Dispatch<AnyAction>) => {
  const { data } = await axiosCalendarServicInstance.get(`/api/v1/events/${request.external_id}`, {
    params: {
      provider_calendar_configuration_id: request.provider_calendar_configuration_id,
    },
  })
  return dispatch({
    type: GET_GOOGLE_EVENT,
    data,
  })
}

type GetCalendarProviderRequest = {
  providerId: string
}
export const getCalendarProvider = (request: GetCalendarProviderRequest) => async (dispatch: Dispatch<AnyAction>) => {
  const { data } = await axiosCalendarServicInstance.get(`/api/v1/provider/${request.providerId}`)
  return dispatch({
    type: GET_CALENDAR_PROVIDER,
    data,
  })
}

type PatchCalendarProviderRequest = {
  providerId: string
  body: {
    booking_mode: BookingMode
  }
}
export const patchCalendarProvider =
  (request: PatchCalendarProviderRequest) => async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await axiosCalendarServicInstance.patch(`/api/v1/provider/${request.providerId}`, request.body)
    return dispatch({
      type: PATCH_CALENDAR_PROVIDER,
      data,
    })
  }

export const showCalendarLiveModal = () => (dispatch: Dispatch<AnyAction>) => {
  return dispatch({
    type: SHOW_CALENDAR_LIVE_MODAL,
  })
}

export const hideCalendarLiveModal = () => (dispatch: Dispatch<AnyAction>) => {
  return dispatch({
    type: HIDE_CALENDAR_LIVE_MODAL,
  })
}

export const clearAvailableCalendars = () => (dispatch: Dispatch<AnyAction>) => {
  return dispatch({
    type: CLEAR_AVAILABLE_CALENDARS,
  })
}
