import { useCallback, useMemo } from 'react'

import { skipToken } from '@reduxjs/toolkit/query'

import { useGetMessagesQuery, useLazyGetMessagesQuery } from '../messagesApi'
import { useSelectedConversation } from './useSelectedConversation'

export const useInfiniteMessages = () => {
  const selectedConversation = useSelectedConversation()

  const { data, isFetching, isLoading, isSuccess } = useGetMessagesQuery(
    selectedConversation?.conversation_id
      ? { conversationId: selectedConversation.conversation_id, cursor: undefined }
      : skipToken,
    {
      refetchOnMountOrArgChange: false,
    },
  )

  const [trigger] = useLazyGetMessagesQuery()

  const loadMore = useCallback(() => {
    if (!isFetching && data?.next_page_token && selectedConversation?.conversation_id) {
      trigger({
        conversationId: selectedConversation.conversation_id,
        cursor: data.next_page_token,
      })
    }
  }, [data?.next_page_token, isFetching, selectedConversation?.conversation_id, trigger])

  const messages = useMemo(() => {
    if (!data?.conversation_messages) {
      return []
    }
    return [...data.conversation_messages].reverse()
  }, [data?.conversation_messages])

  return {
    data: messages,
    loadMore,
    hasMore: !!data?.next_page_token && !!selectedConversation?.conversation_id,
    isLoadingNext: isFetching && isSuccess,
    isLoading,
    isSuccess,
  }
}
