import React, { useRef, useState } from 'react'
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl'
import { View } from 'react-native'
import { Popover } from 'react-native-popper'

import styled from 'styled-components/native'

import { CheckboxGroup, ComposeIcon, FilterIcon, Headline, SecondaryButton, TertiaryIconButton } from '../../atoms'
import { ButtonSize } from '../../atoms/baseButton/BaseButton'
import { HeadlineSize } from '../../styles'
import { ConversationFilter } from '../../ui-models'
import { tID } from '../../utils'

const Container = styled.View(({ theme }) => ({
  flexDirection: 'row',
  height: '75px',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing['12px'],
  borderBottomWidth: '1px',
  borderBottomColor: theme.colors.borderDefault,
}))
const IconContainer = styled.View(({ theme }) => ({
  padding: theme.spacing['12px'],
}))

const PopoverContainer = styled.View(({ theme }) => ({
  flexDirection: 'column',
  backgroundColor: theme.colors.backgroundPrimary,
  borderRadius: '16px',
  padding: theme.spacing['16px'],
  gap: theme.spacing['16px'],
  boxShadow: `0 2px 10px ${theme.colors.shadowLow}`,
}))

const ComposeButton = styled(SecondaryButton)({
  // accounts for border sizing causing 1.5px shift
  // can't use box-sizing due to RN not supporting it
  marginTop: '1.5px',
})

export type ChatSideBarHeaderProps = {
  onComposePressed: () => void
  filters: ConversationFilter[]
  onFiltersChanged: (filters: ConversationFilter[]) => void
}

export const filterNames: Record<ConversationFilter, MessageDescriptor> = defineMessages({
  [ConversationFilter.ACTIVE]: {
    defaultMessage: 'Active',
    description: 'Label for filtering conversations by active',
  },
  [ConversationFilter.UNREAD]: {
    defaultMessage: 'Unread',
    description: 'Label for filtering conversations by unread',
  },
  [ConversationFilter.UNSCHEDULED]: {
    defaultMessage: 'Unscheduled',
    description: 'Label for filtering conversations by unscheduled',
  },
})
export const ChatSideBarHeader: React.FC<ChatSideBarHeaderProps> = ({
  onComposePressed,
  filters,
  onFiltersChanged,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const ref = useRef(null)
  const { formatMessage } = useIntl()
  return (
    <>
      <Container>
        <View ref={ref}>
          <TertiaryIconButton
            testID={tID('ChatSideBarHeader-filter-button')}
            size={ButtonSize.UNSIZED}
            leftIcon={
              <IconContainer>
                <FilterIcon size={24} />
              </IconContainer>
            }
            onPress={() => setIsPopoverOpen((prev) => !prev)}
            accessibilityLabel={formatMessage({
              defaultMessage: 'Filter conversations',
              description: 'Accessibility label for button to filter chat conversations',
            })}
          />
        </View>
        <ComposeButton
          testID={tID('ChatSideBarHeader-compose-button')}
          leftIcon={<ComposeIcon size={24} />}
          text={<FormattedMessage defaultMessage='Compose' description='Button to start a conversation' />}
          onPress={onComposePressed}
        />
      </Container>
      <Popover
        trigger={ref}
        isOpen={isPopoverOpen}
        onOpenChange={setIsPopoverOpen}
        placement='bottom left'
        offset={8}
        animated={!window.Cypress}
      >
        <Popover.Content>
          <PopoverContainer>
            <Headline
              size={HeadlineSize.EYEBROW}
              text={<FormattedMessage defaultMessage='Filter by' description='Filter conversation popover' />}
            />
            <CheckboxGroup
              baseInputStyle={{ marginBottom: 0 }}
              options={Object.values(ConversationFilter).map((filter) => ({
                id: filter,
                text: <FormattedMessage {...filterNames[filter]} />,
              }))}
              name='filters'
              value={filters}
              onChange={(selectedFilters) => onFiltersChanged(selectedFilters as ConversationFilter[])}
            />
          </PopoverContainer>
        </Popover.Content>
      </Popover>
    </>
  )
}
