import React from 'react'
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl'

import { useTheme } from 'styled-components/native'

import { ClientDetailsEpisodeTags } from './ClientDetailsEpisodeTags'
import { ClientDetailsSideBarSection } from './ClientDetailsSideBarSection'
import { BodyText } from '../../atoms'
import { Details } from '../../ui-models'
import { tID } from '../../utils'

export type ClientDetailsEpisodeSideBarSectionProps = {
  details: Details
}

export const ClientDetailsEpisodeSideBarSection: React.FC<ClientDetailsEpisodeSideBarSectionProps> = ({ details }) => {
  const { colors } = useTheme()
  return (
    <ClientDetailsSideBarSection
      headerText={<FormattedMessage defaultMessage='Episode details' description='Header text for episode details' />}
      items={[
        {
          key: 'episode',
          label: <FormattedMessage defaultMessage='Episode' description='Tags related to episode like program name' />,
          value: <ClientDetailsEpisodeTags programName={details.episode.program} isActive={details.episode.isActive} />,
          testID: tID('ClientDetailsEpisodeSideBarSection-episode'),
        },
        {
          key: 'start_date',
          label: <FormattedMessage defaultMessage='Start date' description='Start date of episode' />,
          textValue: details.episode.startDate ? (
            <FormattedDate
              value={details.episode.startDate}
              year='numeric'
              month='short'
              weekday='short'
              day='numeric'
            />
          ) : null,
          testID: tID('ClientDetailsEpisodeSideBarSection-start-date'),
        },
        {
          key: 'next_session',
          label: <FormattedMessage defaultMessage='Next session' description='Session number of episode' />,
          textValue: (
            <FormattedMessage
              defaultMessage='Session {sessionNumber}'
              description='Client session number of episode'
              values={{ sessionNumber: (details.episode.sessionNumber ?? 0) + 1 }}
            />
          ),
          testID: tID('ClientDetailsEpisodeSideBarSection-next-session'),
        },
        {
          key: 'next_session_date',
          label: <FormattedMessage defaultMessage='Scheduled date' description='Next session date of episode' />,
          textValue: details.episode.nextSession ? (
            <FormattedDate
              value={details.episode.nextSession}
              year='numeric'
              month='short'
              weekday='short'
              day='numeric'
            />
          ) : (
            <BodyText
              color={colors.textWarning}
              text={<FormattedMessage defaultMessage='Needs scheduling' description='Next session needs scheduling' />}
            />
          ),
          testID: tID('ClientDetailsEpisodeSideBarSection-next-session-date'),
        },
        {
          key: 'next_session_time',
          label: <FormattedMessage defaultMessage='Scheduled time' description='Next session time of episode' />,
          textValue: details.episode.nextSession ? (
            <FormattedTime value={details.episode.nextSession} hour='numeric' minute='numeric' timeZoneName='short' />
          ) : (
            '—'
          ),
          testID: tID('ClientDetailsEpisodeSideBarSection-next-session-time'),
        },
      ]}
    />
  )
}
