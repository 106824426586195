import React from 'react'
import { Outlet } from 'react-router-dom'

import { useConversationsData } from '../../messages/data/hooks/useConversationsData'

export const ClientsContainer = () => {
  useConversationsData(undefined, { refetchOnMountOrArgChange: true })

  return <Outlet />
}

export default ClientsContainer
