import React from 'react'

import { useTheme } from 'styled-components/native'

import { AIDraftStatus, noteSummaryStatusText, summaryStatusText } from '@lyrahealth-inc/shared-app-logic'

import { BodyText, Size as BodyTextSize } from '../bodyText/BodyText'
import { Corners, IconBadge } from '../iconBadge/IconBadge'
import { SparkleSingleIcon } from '../icons'
import { SparklesIcon } from '../icons/SparklesIcon'

export type AIStatusBadgeProps = {
  customText?: string
  digestType?: DigestType
  hideText?: boolean
  status?: AIDraftStatus
}

export enum DigestType {
  DRAFT = 'DRAFT',
  SUMMARY = 'SUMMARY',
}

export const AIStatusBadge = ({
  customText,
  digestType = DigestType.DRAFT,
  hideText = false,
  status = AIDraftStatus.COMPLETE,
}: AIStatusBadgeProps) => {
  const { colors } = useTheme()
  const { aiStatusBadge } = colors.components

  const styleAttibutes = {
    [AIDraftStatus.COMPLETE]: {
      fill: aiStatusBadge.available.background,
      text: aiStatusBadge.available.text,
    },
    [AIDraftStatus.IN_PROGRESS]: {
      fill: aiStatusBadge.processing.background,
      text: aiStatusBadge.processing.text,
    },
    [AIDraftStatus.NOT_STARTED]: {
      fill: aiStatusBadge.processing.background,
      text: aiStatusBadge.processing.text,
    },
    [AIDraftStatus.ERROR]: {
      fill: aiStatusBadge.unavailable.background,
      text: aiStatusBadge.unavailable.text,
    },
    [AIDraftStatus.NOT_FOUND]: {
      fill: aiStatusBadge.unavailable.background,
      text: aiStatusBadge.unavailable.text,
    },
  }

  const summaryStyleAttibutes = {
    [AIDraftStatus.COMPLETE]: {
      fill: colors.components.alertBanner.success.background,
      text: colors.iconSuccess,
    },
    [AIDraftStatus.IN_PROGRESS]: {
      fill: colors.components.avatar.goldTheme.background,
      text: colors.components.avatar.goldTheme.text,
    },
    [AIDraftStatus.NOT_STARTED]: {
      fill: aiStatusBadge.processing.background,
      text: aiStatusBadge.processing.text,
    },
    [AIDraftStatus.ERROR]: {
      fill: aiStatusBadge.unavailable.background,
      text: aiStatusBadge.unavailable.text,
    },
    [AIDraftStatus.NOT_FOUND]: {
      fill: aiStatusBadge.unavailable.background,
      text: aiStatusBadge.unavailable.text,
    },
  }

  const summaryText = digestType === DigestType.DRAFT ? noteSummaryStatusText[status] : summaryStatusText[status]
  const color = digestType === DigestType.DRAFT ? styleAttibutes[status].fill : summaryStyleAttibutes[status].fill
  const fontIcon =
    digestType === DigestType.DRAFT ? (
      <SparklesIcon
        size={16}
        color={digestType === DigestType.DRAFT ? styleAttibutes[status].text : summaryStyleAttibutes[status].text}
      />
    ) : (
      <SparkleSingleIcon size={16} color={summaryStyleAttibutes[status].text} />
    )
  return (
    <IconBadge
      text={
        hideText ? (
          ''
        ) : (
          <BodyText
            size={BodyTextSize.CAPTION}
            text={customText ? customText : summaryText}
            color={digestType === DigestType.DRAFT ? styleAttibutes[status].text : summaryStyleAttibutes[status].text}
            bold={digestType === DigestType.DRAFT ? false : true}
          />
        )
      }
      color={color}
      frontIcon={fontIcon}
      cornerType={Corners.CURVED}
      hideText={hideText}
      testId={`AIStatusBadge-${status}`}
    />
  )
}
