// This is the base modal component that is used by ProviderCalendarDaySlotSelectionModal
// and ProviderCalendarDateSlotSelectionModal

import React, { FunctionComponent, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { EventContentArg } from '@fullcalendar/core'
import { addMinutes, parse } from 'date-fns'
import { format } from 'date-fns-tz'
import { isEmpty, isNil } from 'lodash-es'
import styled from 'styled-components/native'

import { ProviderCalendarTimeSlotSelector } from './ProviderCalendarTimeSlotSelector'
import { BodyText, PlusIcon, TextButton } from '../../atoms'
import { InlineWarningBanner } from '../../molecules'
import { ThemeType } from '../../utils'
import { tID } from '../../utils/utils'
import { ConfirmationModal } from '../confirmationModal/ConfirmationModal'

export type ProviderCalendarBaseSlotSelectionModalProps = {
  allAvailableTimeSlots: { label: string; value: string }[]
  blockedRelativeStartTimes: number[]
  blockedTimeSlotValues: string[]
  cancelButtonText?: string
  confirmButtonDisabled?: boolean
  confirmationButtonText?: string
  dayDateSelector?: React.ReactElement
  eventToEdit?: EventContentArg | null
  handleAnimationEnd?: () => void
  holdDurationMinutes?: number
  modalTitle?: string
  onConfirmationButtonPress: () => void
  onRequestClose: () => void
  selectedStartTimes: string[]
  sessionDuration?: number
  setSelectedStartTimes: (startTimes: string[]) => void
  visible: boolean
}

const TimeSelectorsTitleContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['8px'],
}))

export const ProviderCalendarBaseSlotSelectionModal: FunctionComponent<ProviderCalendarBaseSlotSelectionModalProps> = ({
  allAvailableTimeSlots,
  blockedRelativeStartTimes,
  blockedTimeSlotValues,
  cancelButtonText = 'Cancel',
  confirmButtonDisabled = false,
  confirmationButtonText = 'Add',
  eventToEdit,
  dayDateSelector,
  handleAnimationEnd = () => {},
  holdDurationMinutes = 60,
  modalTitle = 'Add availability',
  onConfirmationButtonPress,
  onRequestClose,
  selectedStartTimes,
  sessionDuration = 50,
  setSelectedStartTimes,
  visible,
}) => {
  const { formatMessage } = useIntl()

  const remainingAvailableTimeslots = useMemo(() => {
    return allAvailableTimeSlots.filter((timeSlot) => !blockedTimeSlotValues.includes(timeSlot.value))
  }, [allAvailableTimeSlots, blockedTimeSlotValues])

  const getNextTimeSlot = () => {
    const previousLatestTimeSlot = selectedStartTimes[selectedStartTimes.length - 1]
    if (isEmpty(previousLatestTimeSlot)) {
      return ''
    }
    const nextTimeSlot = format(
      addMinutes(parse(previousLatestTimeSlot, 'hh:mm a', new Date()), holdDurationMinutes),
      'hh:mm a',
    )
    return remainingAvailableTimeslots.map((timeslot) => timeslot.value).includes(nextTimeSlot) &&
      !selectedStartTimes.includes(nextTimeSlot)
      ? nextTimeSlot
      : ''
  }

  const StartTimeSelectors = () => {
    return (
      <>
        {selectedStartTimes.map((selectedStartTime, index) => (
          <>
            <ProviderCalendarTimeSlotSelector
              allOptions={allAvailableTimeSlots}
              blockedRelativeStartTimes={blockedRelativeStartTimes}
              blockedValues={blockedTimeSlotValues}
              index={index}
              selectedOption={selectedStartTime}
              selectedOptionList={selectedStartTimes}
              setSelectedOptionList={setSelectedStartTimes}
              showDeleteIcon={index !== 0}
              singleTimeSelector={selectedStartTimes.length === 1}
            />
          </>
        ))}
      </>
    )
  }

  return (
    <ConfirmationModal
      visible={visible}
      onRequestClose={onRequestClose}
      handleAnimationEnd={handleAnimationEnd}
      onConfirmationButtonPress={onConfirmationButtonPress}
      modalTitle={modalTitle}
      cancelButtonText={cancelButtonText}
      confirmationButtonText={confirmationButtonText}
      confirmButtonDisabled={confirmButtonDisabled}
      modalContents={
        <>
          {allAvailableTimeSlots.length === 0 && (
            <InlineWarningBanner
              testId={tID('ProviderCalendarBaseSlotSelectionModal-noAvailabilityBanner')}
              text={formatMessage({
                defaultMessage: 'No availability today. Choose another day',
                description:
                  "Message to indicate that there are no timeslots that are bookable for the chosen day in the provider's calendar.",
              })}
            />
          )}
          {dayDateSelector}
          <TimeSelectorsTitleContainer>
            <BodyText
              text={
                <FormattedMessage
                  defaultMessage='Time ({sessionDuration}min sessions • {holdDurationHours} hour holds)'
                  description='Title for selectors where providers select times to mark as available for sessions'
                  values={{ sessionDuration, holdDurationHours: holdDurationMinutes / 60 }}
                />
              }
            />
          </TimeSelectorsTitleContainer>
          <StartTimeSelectors />
          {isNil(eventToEdit) && remainingAvailableTimeslots.length > 0 && (
            <TextButton
              text={
                <FormattedMessage
                  defaultMessage='Add another time'
                  description='Button label for provider to add another time slot selection'
                />
              }
              leftIcon={<PlusIcon />}
              onPress={() => {
                const prev = selectedStartTimes
                setSelectedStartTimes([...prev].concat(getNextTimeSlot()))
              }}
              testID={tID('ProviderCalenderSlotSelectionModal-addSlotButton')}
            />
          )}
        </>
      }
    />
  )
}
