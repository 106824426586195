import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'

import styled from 'styled-components/native'

import { NewWindowIcon, SideBarIcon, Subhead, TertiaryIconButton } from '../../atoms'
import { ButtonSize } from '../../atoms/baseButton/BaseButton'
import { ProviderComposeTypeahead } from '../../molecules'
import { SubheadSize } from '../../styles'
import { tID } from '../../utils'

export type ChatSideBarProps = {
  clientName?: string
  onOpenDetailsPanel: () => void
  isDetailsPanelOpen: boolean
  isComposing: boolean
  clients: { id: string; name: string }[]
  onClientSelected: (clientId: string | null) => void
  onOpenNewWindow?: () => void
}

const Container = styled.View(({ theme }) => ({
  flexDirection: 'column',
  backgroundColor: theme.colors.backgroundPrimary,
  zIndex: 'unset',
}))

const HeaderRow = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  borderBottomColor: theme.colors.borderDefault,
  borderBottomWidth: '1px',
  padding: theme.spacing['16px'],
  height: '75px',
  justifyContent: 'space-between',
}))

const HeaderIcons = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
}))

const IconContainer = styled.View(({ theme }) => ({
  padding: theme.spacing['12px'],
}))

const NewWindowContainer = styled(Animated.View)({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  zIndex: 1,
})

const NEW_WINDOW_ICON_OFFSET = 52

export const ProviderChatHeader: React.FC<ChatSideBarProps> = ({
  clientName,
  onOpenDetailsPanel,
  isDetailsPanelOpen,
  isComposing,
  clients,
  onClientSelected,
  onOpenNewWindow,
}) => {
  const sideBarIconOpacity = useSharedValue(0)
  const newWindowIconRight = useSharedValue(0)
  useEffect(() => {
    const hideSideBarIcon = isDetailsPanelOpen || (isComposing && !clientName)
    sideBarIconOpacity.value = hideSideBarIcon ? withTiming(0) : withTiming(1)
    newWindowIconRight.value = hideSideBarIcon ? withTiming(0) : withTiming(NEW_WINDOW_ICON_OFFSET)
  }, [clientName, isComposing, isDetailsPanelOpen, newWindowIconRight, sideBarIconOpacity])

  const sideBarButtonStyle = useAnimatedStyle(() => ({
    opacity: sideBarIconOpacity.value,
  }))

  const newWindowButtonStyle = useAnimatedStyle(() => ({
    right: newWindowIconRight.value,
  }))

  return (
    <Container testID={tID('ProviderChatHeader')}>
      <HeaderRow>
        {(!!clientName || isComposing) && (
          <>
            <Subhead
              size={SubheadSize.MEDIUM}
              text={
                isComposing ? (
                  <FormattedMessage
                    defaultMessage='New message'
                    description='Header for page when composing a new message'
                  />
                ) : (
                  clientName
                )
              }
            />
            <HeaderIcons>
              {onOpenNewWindow && (
                <NewWindowContainer style={newWindowButtonStyle}>
                  <TertiaryIconButton
                    testID={tID('ProviderChatHeader-new-window-button')}
                    size={ButtonSize.UNSIZED}
                    leftIcon={
                      <IconContainer>
                        <NewWindowIcon />
                      </IconContainer>
                    }
                    onPress={onOpenNewWindow}
                  />
                </NewWindowContainer>
              )}
              <Animated.View style={sideBarButtonStyle}>
                <TertiaryIconButton
                  testID={tID('ProviderChatHeader-side-bar-button')}
                  pointerEvents={isDetailsPanelOpen ? 'none' : undefined}
                  size={ButtonSize.UNSIZED}
                  leftIcon={
                    <IconContainer>
                      <SideBarIcon />
                    </IconContainer>
                  }
                  onPress={onOpenDetailsPanel}
                />
              </Animated.View>
            </HeaderIcons>
          </>
        )}
      </HeaderRow>
      {isComposing && <ProviderComposeTypeahead clients={clients} onClientSelected={onClientSelected} />}
    </Container>
  )
}
