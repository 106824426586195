/* eslint-disable formatjs/no-literal-string-in-jsx */

import React, { FunctionComponent, ReactElement, useState } from 'react'

import styled from 'styled-components/native'

import { BodyText } from '../../atoms'
import { BodyTextSize } from '../../styles'
import { ThemeType } from '../../utils'
import { tID } from '../../utils/utils'

export type TableOfContentsProps = {
  containerPadding?: number
  headers: { elementId: string; title: string; badges?: ReactElement[] }[]
  testID?: string
}

const Container = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  gap: spacing['16px'],
  display: 'flex',
  flexDirection: 'column',
}))

const SectionItemContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: spacing['4px'],
  alignItems: 'center',
}))

const NavItemButtonActive = styled.View<{ theme: ThemeType; containerPadding?: number }>(
  ({ theme, containerPadding = 16 }) => ({
    width: '4px',
    height: '100%',
    backgroundColor: theme.colors.backgroundActive,
    position: 'absolute',
    borderTopRightRadius: theme.spacing['16px'],
    borderBottomRightRadius: theme.spacing['16px'],
    left: `-${containerPadding}px`,
    right: 0,
    bottom: '0',
    marginTop: 'auto',
    marginBottom: 'auto',
  }),
)

const SectionText = styled(BodyText)<{ theme: ThemeType; active: boolean }>(({ theme, active }) => ({
  color: active ? theme.colors.textActive : theme.colors.textSecondary,
}))

const BadgesContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  marginLeft: spacing['8px'],
  gap: spacing['8px'],
  display: 'flex',
  flexDirection: 'row',
}))

export const TableOfContents: FunctionComponent<TableOfContentsProps> = ({
  containerPadding,
  headers,
  testID = 'TableOfContents',
}) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const sectionClicked = (i: number, headerName: string) => {
    setActiveIndex(i)
    document?.getElementById(headerName)?.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' })
  }

  return (
    <Container testID={tID(`${testID}`)}>
      {headers.map((header, i) => {
        return (
          <SectionItemContainer testID={tID(`${testID}-${header.elementId}`)} key={header.elementId}>
            {activeIndex === i && (
              <NavItemButtonActive containerPadding={containerPadding} testID={tID(`Header-active`)} />
            )}
            <SectionText
              size={BodyTextSize.SMALL}
              text={header.title}
              active={activeIndex === i}
              onPress={() => sectionClicked(i, header.elementId)}
              testID={tID(`${testID}-${header.elementId}-text`)}
            />
            <BadgesContainer testID={tID(`${testID}-${header.elementId}-badgeContainer`)}>
              {header.badges}
            </BadgesContainer>
          </SectionItemContainer>
        )
      })}
    </Container>
  )
}
