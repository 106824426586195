import { FunctionComponent, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { OAUTH_SUCCESS_RESPONSE } from './constants'
import { useSetupRoute } from './hooks/useSetupRoute'
import { getAuthConfig } from '../data/auth/authSelectors'

const CalendarCallback: FunctionComponent = () => {
  const navigate = useNavigate()
  const config = useSelector(getAuthConfig)
  const setupRoute = useSetupRoute(config)
  useEffect(() => {
    if (window.opener) {
      window.opener.postMessage(OAUTH_SUCCESS_RESPONSE)
      window.opener.focus()
      window.close()
    } else {
      navigate(setupRoute)
    }
  }, [setupRoute, navigate])
  return null
}

export default CalendarCallback
