import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useConversationsData } from './useConversationsData'
import { getSelectedConversationId } from '../messagesSelectors'

export const useSelectedConversation = () => {
  const { data: conversations } = useConversationsData()
  const selectedConversationId = useSelector(getSelectedConversationId)
  return useMemo(
    () => conversations?.find((conversation) => conversation.conversation_id === selectedConversationId) ?? null,
    [conversations, selectedConversationId],
  )
}
