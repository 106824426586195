import { clearLTAppointments } from './appointmentsSlice'
import {
  CLEAR_ASSIGNMENTS,
  CLEAR_CLIENT_PRESCRIPTIONS,
  CLEAR_CURRICULUM,
  CLEAR_EPISODES,
  CLEAR_LT_CLIENT_DATA,
  LT_SELECTED_CLIENT,
} from '../../../../common/constants/reduxConstants'
import { clearSelectedConversation } from '../../../messages/data/messagesSlice'

export const getLtClient = ({ clientId, cancelToken, providerId }: any) => ({
  action: LT_SELECTED_CLIENT,
  request: {
    method: 'get',
    url: `/v1/clients/${clientId}`,
    cancelToken,
    params: { providerId, is_bcx: true },
  },
})

export const getClientId = ({ relationshipId }: any) => ({
  request: {
    method: 'get',
    url: `/lt/v1/provider_patient/${relationshipId}`,
  },
})

export const selectLtClient = (data: any) => ({
  type: LT_SELECTED_CLIENT,
  data,
})

export const clearSelectedClientData = () => ({
  type: CLEAR_LT_CLIENT_DATA,
})

export const clearSelectedClientStore = () => {
  return (dispatch: any) => {
    dispatch({ type: CLEAR_LT_CLIENT_DATA })
    dispatch(clearLTAppointments())
    dispatch({ type: CLEAR_CURRICULUM })
    dispatch({ type: CLEAR_ASSIGNMENTS })
    dispatch(clearSelectedConversation())
    dispatch({ type: CLEAR_EPISODES })
    dispatch({ type: CLEAR_CLIENT_PRESCRIPTIONS })
  }
}
