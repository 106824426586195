import React, { Dispatch, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'

import { AnyAction, bindActionCreators } from 'redux'

import { PROGRAM_TAXONOMY } from '@lyrahealth-inc/shared-app-logic'
import { CardMedium, ContentLayout, LoadingIndicator } from '@lyrahealth-inc/ui-core'

import * as myProviderInfoActions from './data/myProviderInfoActions'
import DisplayProviderInfo from './DisplayProviderInfo'
import styles from './myProviderInfo.module.scss'
import { SET_MY_INFO_DATA } from '../../common/constants/reduxConstants'
import { hasErrorForAction } from '../../common/utils/utils'
import { getAlertsState } from '../../data/alertsSelectors'
import { Alert } from '../../data/alertTypes'
import { getAuthUserId } from '../../data/auth/authSelectors'
import { getMyInfoData } from '../data/myInfoSelectors'

// sub views

type MyProviderInfoProps = {
  actions: { [key: string]: any; returnPracticeInfo: (id: string) => void }
}

const MyProviderInfo = ({ actions: { returnProviderInfo } }: MyProviderInfoProps) => {
  const id = useSelector(getAuthUserId)
  const myProviderInfo = useSelector(getMyInfoData)
  const alerts: Alert[] = useSelector(getAlertsState)

  useEffect(() => {
    returnProviderInfo(id)
  }, [id, returnProviderInfo])

  if (!myProviderInfo) {
    if (hasErrorForAction(alerts, SET_MY_INFO_DATA)) {
      return null // custom workflow or display could render here
    } else {
      return (
        <div className={styles['loading-container']}>
          <LoadingIndicator size={45} />
        </div>
      )
    }
  }
  const taxonomies = myProviderInfo.program_taxonomies ?? []
  return (
    <ContentLayout>
      <CardMedium>
        <div>
          <h1 className={styles.title}>My Info</h1>
          <DisplayProviderInfo
            $$data={myProviderInfo}
            showSummaryAndSpecialties={
              taxonomies && taxonomies.length > 0 && taxonomies[0].partner === PROGRAM_TAXONOMY.partner.directAccess
            }
          />
        </div>
      </CardMedium>
    </ContentLayout>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    // @ts-expect-error TS(2769): No overload matches this call.
    actions: bindActionCreators({ ...myProviderInfoActions }, dispatch),
  }
}

// @ts-expect-error TS(2345): Argument of type '(wrappedComponentProps: MyProvid... Remove this comment to see the full error message
export default connect(null, mapDispatchToProps)(MyProviderInfo)
