import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Client } from '@twilio/conversations'

import { Appointment } from '@lyrahealth-inc/shared-app-logic'

type MessagesState = {
  selectedConversationId: string | null
  conversationsClient?: Client
  inLiveMsgSession: boolean
  activeLiveMsgAppt?: Appointment
  liveMsgClientIdToOpen: string | null
  drafts: { content: string; conversationId: string }[]
}

const initialState: MessagesState = {
  selectedConversationId: null,
  drafts: [],
  inLiveMsgSession: false,
  liveMsgClientIdToOpen: null,
}

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setConversationsClient: (state, action: PayloadAction<Client>) => {
      // @ts-ignore
      state.conversationsClient = action.payload
    },
    setSelectedConversation: (state, action: PayloadAction<string>) => {
      state.selectedConversationId = action.payload
    },
    toggleLiveMsgSession: (state, action: PayloadAction<boolean>) => {
      state.inLiveMsgSession = action.payload
    },
    setActiveLiveMsgAppt: (state, action: PayloadAction<Appointment | null>) => {
      state.activeLiveMsgAppt = action.payload ?? undefined
    },
    setLiveMsgClientIdToOpen: (state, action: PayloadAction<string | null>) => {
      state.liveMsgClientIdToOpen = action.payload
    },
    saveMessageDraft: (state, action: PayloadAction<{ content: string; conversationId: string }>) => {
      const draftIdx = state.drafts.findIndex((draft) => draft.conversationId === action.payload.conversationId)
      if (draftIdx !== -1) {
        state.drafts[draftIdx] = action.payload
      } else {
        state.drafts.push(action.payload)
      }
    },
    clearSelectedConversation: (state) => {
      state.selectedConversationId = null
    },
    clearLiveMsgSession: (state) => {
      state.inLiveMsgSession = false
      state.activeLiveMsgAppt = undefined
      state.liveMsgClientIdToOpen = null
    },
    clearLiveMsgClientIdToOpen: (state) => {
      state.liveMsgClientIdToOpen = null
    },
    clearMessages: () => {
      return initialState
    },
    updateSelectedConversationID: (
      state,
      action: PayloadAction<{ oldConversationId: string; newConversationId: string }>,
    ) => {
      if (state.selectedConversationId === action.payload.oldConversationId) {
        state.selectedConversationId = action.payload.newConversationId
      }
    },
  },
})

export const {
  setConversationsClient,
  setSelectedConversation,
  clearSelectedConversation,
  clearMessages,
  clearLiveMsgSession,
  clearLiveMsgClientIdToOpen,
  saveMessageDraft,
  toggleLiveMsgSession,
  setActiveLiveMsgAppt,
  setLiveMsgClientIdToOpen,
  updateSelectedConversationID,
} = messagesSlice.actions
export default messagesSlice.reducer
