import React, { ReactElement, useCallback, useMemo, useRef, useState } from 'react'
import { View, ViewStyle } from 'react-native'

import BottomSheetGorhom from '@gorhom/bottom-sheet'
import { noop } from 'lodash-es'
import { CSSObject } from 'styled-components'
import styled from 'styled-components/native'
import { v4 as uuidv4 } from 'uuid'

import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { BottomSheet } from '../../molecules/bottomSheet/BottomSheet'
import { BodyTextSize } from '../../styles'
import { ThemeType, tID } from '../../utils'
import { BaseButton, ButtonSize, ButtonType } from '../baseButton/BaseButton'
import { BodyText } from '../bodyText/BodyText'
import { DropdownMenu } from '../formElements/typeAhead/DropdownMenu'
import { TypeAheadCustomOptionsConfig } from '../formElements/typeAhead/TypeAheadCustomOptionsConfig'
import { ChevronIcon, ChevronIconDirection } from '../icons'

export interface DropdownMenuItem {
  text: string
  testId?: string
  icon?: React.ReactNode
  id?: string
  dropdownOptionText?: string
  selectHandler: () => void
}

export type DropdownButtonProps<T> = {
  buttonTextWidth?: string
  buttonType?: ButtonType
  buttonSize?: ButtonSize
  customButtonText?: string
  customTextSize?: BodyTextSize
  customTextColor?: string
  dropdownWidth?: string | number
  dropdownItems: DropdownMenuItem[]
  testID?: string
  style?: ViewStyle
  dropdownStyle?: ViewStyle
  preselectedValue?: string
  fullWidth?: boolean
  leftIcon?: ReactElement | null
  iconAtEnd?: boolean
  iconColor?: string
  buttonPlaceholderText?: string
  label?: React.ReactNode
  buttonFooter?: React.ReactNode
  focusBorderStyle?: CSSObject
  containerStyle?: ViewStyle
  customOptionsConfig?: TypeAheadCustomOptionsConfig<T>
  disableSelectedValueHighlight?: boolean
  disableCenteredContent?: boolean
  showBottomSheet?: boolean
  bottomSheetProps?: {
    menuItemHeight: number
    topPadding: number
    bottomPadding: number
    headerComponent?: React.ReactNode
    headerComponentHeight: number
  }
  onPress?: (isDropdownOpen?: boolean) => void
  dropdownMenuProps?: { showsVerticalScrollIndicator?: boolean; scrollEnabled?: boolean }
}

const ButtonTextContainer = styled.View<{ width?: string }>(({ width }) => ({
  ...(width && { width: width }),
}))

const DropdownContainer = styled.View<{ theme: ThemeType; width?: string | number }>(({ theme, width }) => ({
  position: 'absolute',
  backgroundColor: theme.colors.backgroundPrimary,
  padding: `${theme.spacing['16px']} 0`,
  borderRadius: theme.spacing['8px'],
  boxShadow: `0 2px 16px ${theme.colors.shadowLow}`,
  ...(width && { width: width }),
  top: '100%',
}))

const BottomSheetMenuContainer = styled.View<{
  theme: ThemeType
  height: number
  paddingTop: number
  paddingBottom: number
}>(({ theme, height, paddingTop, paddingBottom }) => ({
  padding: `${paddingTop}px ${theme.spacing['16px']} ${paddingBottom}px ${theme.spacing['16px']}`,
  height: `${height}px`,
}))

export const DropdownButton = <T,>({
  buttonTextWidth,
  buttonType = ButtonType.SECONDARY,
  buttonSize,
  customButtonText,
  dropdownWidth,
  dropdownItems,
  customTextSize,
  customTextColor,
  style,
  dropdownStyle,
  testID = 'DropdownButton',
  preselectedValue,
  fullWidth = false,
  leftIcon,
  iconAtEnd = false,
  iconColor,
  buttonPlaceholderText,
  label,
  buttonFooter,
  focusBorderStyle,
  containerStyle,
  customOptionsConfig,
  disableSelectedValueHighlight = false,
  disableCenteredContent = false,
  showBottomSheet = false,
  bottomSheetProps,
  onPress = noop,
  dropdownMenuProps,
}: DropdownButtonProps<T>) => {
  const dropdownContainerRef = useRef(null)

  const defaultSelectedDropdownItem = useMemo(
    () =>
      (preselectedValue
        ? dropdownItems.find(
            (option) => option.text === preselectedValue || option.dropdownOptionText === preselectedValue,
          )
        : dropdownItems[0]) as DropdownMenuItem,
    [dropdownItems, preselectedValue],
  )
  const [selectedValue, setSelectedValue] = useState(defaultSelectedDropdownItem)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const [isDropdownTouched, setIsDropdownTouched] = useState(false)

  const dropdownContainerRefID = 'DropDownContainer'
  const triggerButtonRefID = 'TriggerButton-' + uuidv4().substring(0, 8)

  useOnClickOutside(
    dropdownContainerRefID,
    dropdownContainerRef,
    () => {
      setIsDropdownOpen(false)
    },
    triggerButtonRefID,
  )

  const dropdownOptions = dropdownItems.map((option) => option.dropdownOptionText ?? option.text)
  let dropdownOptionProps
  if (customOptionsConfig) {
    dropdownOptionProps = { options: dropdownOptions, customOptions: dropdownItems as T[] }
  } else {
    dropdownOptionProps = { options: dropdownOptions }
  }

  const bottomSheetRef = useRef<BottomSheetGorhom>(null)

  const dropdownItemsBottomSheetMenuHeight = bottomSheetProps
    ? dropdownItems.length * bottomSheetProps.menuItemHeight +
      bottomSheetProps.topPadding +
      bottomSheetProps.bottomPadding +
      bottomSheetProps.headerComponentHeight
    : 0

  const closeBottomSheet = useCallback(() => {
    bottomSheetRef?.current?.close()
    setIsDropdownOpen(false)
  }, [])

  const dropdown = (
    <DropdownMenu
      {...dropdownOptionProps}
      onOptionPress={(selected) => {
        setIsDropdownTouched(true)
        const selectedDropdownItem = dropdownItems.find(
          (option) => option.text === selected || option.dropdownOptionText === selected,
        )
        !customButtonText && setSelectedValue(selectedDropdownItem ?? defaultSelectedDropdownItem)
        selectedDropdownItem?.selectHandler()
        setIsDropdownOpen((prev) => !prev)
      }}
      value={
        selectedValue && !disableSelectedValueHighlight
          ? [selectedValue?.dropdownOptionText ?? selectedValue.text]
          : ['']
      }
      customOptionsConfig={customOptionsConfig}
      showsVerticalScrollIndicator={dropdownMenuProps?.showsVerticalScrollIndicator}
      scrollEnabled={dropdownMenuProps?.scrollEnabled}
    />
  )

  return (
    <View testID={tID(testID)} style={containerStyle}>
      <View nativeID={triggerButtonRefID}>
        {label && <BodyText text={label} size={BodyTextSize.CAPTION} />}
        <BaseButton
          buttonType={buttonType}
          rightIcon={<ChevronIcon direction={isDropdownOpen ? ChevronIconDirection.UP : ChevronIconDirection.DOWN} />}
          style={style}
          size={buttonSize}
          fullWidth={fullWidth}
          leftIcon={leftIcon}
          iconAtEnd={iconAtEnd}
          iconColor={iconColor}
          text={
            buttonTextWidth ? (
              <ButtonTextContainer width={buttonTextWidth}>
                <BodyText
                  text={
                    !isDropdownTouched && buttonPlaceholderText
                      ? buttonPlaceholderText
                      : customButtonText ?? selectedValue.text
                  }
                  color={customTextColor}
                />
              </ButtonTextContainer>
            ) : (
              <BodyText
                text={
                  !isDropdownTouched && buttonPlaceholderText
                    ? buttonPlaceholderText
                    : customButtonText ?? selectedValue.text
                }
                color={customTextColor}
              />
            )
          }
          onPress={() => {
            onPress(!isDropdownOpen)
            setIsDropdownOpen((prev) => !prev)
          }}
          customTextSize={customTextSize}
          focusBorderStyle={focusBorderStyle}
          disableCenteredContent={disableCenteredContent}
        />
        {buttonFooter}
      </View>
      {isDropdownOpen &&
        (showBottomSheet && bottomSheetProps ? (
          <BottomSheet
            ref={bottomSheetRef}
            onCloseEnd={() => {
              closeBottomSheet()
            }}
            snapPoints={[dropdownItemsBottomSheetMenuHeight]}
            showCloseIcon
          >
            <BottomSheetMenuContainer
              height={dropdownItemsBottomSheetMenuHeight}
              paddingTop={bottomSheetProps.topPadding}
              paddingBottom={bottomSheetProps.bottomPadding}
            >
              {bottomSheetProps.headerComponent}
              {dropdown}
            </BottomSheetMenuContainer>
          </BottomSheet>
        ) : (
          <DropdownContainer
            testID={tID('DropdownButton-dropdown')}
            width={dropdownWidth}
            ref={dropdownContainerRef}
            nativeID={dropdownContainerRefID}
            style={dropdownStyle}
          >
            {dropdown}
          </DropdownContainer>
        ))}
    </View>
  )
}
