import React, { useCallback, useMemo } from 'react'
import { ListRenderItem } from 'react-native'
import Animated, { LinearTransition } from 'react-native-reanimated'

import styled from 'styled-components/native'

import { ConversationItem } from '../../molecules'
import { Conversation } from '../../ui-models'

const ItemSeparatorComponent = styled.View(({ theme }) => ({
  height: '1px',
  backgroundColor: theme.colors.borderDefault,
}))

export type ConversationListProps = {
  conversations: Conversation[]
  onConversationPressed: (clientId: string) => void
  selectedClientId: string | null
}

const APPROX_ITEM_HEIGHT = 68

export const ConversationList: React.FC<ConversationListProps> = ({
  conversations,
  onConversationPressed,
  selectedClientId,
}) => {
  const renderItem: ListRenderItem<Conversation> = useCallback(
    ({ item }) => (
      <ConversationItem
        conversation={item}
        onPress={() => onConversationPressed(item.clientId)}
        isSelected={item.clientId === selectedClientId}
      />
    ),
    [onConversationPressed, selectedClientId],
  )
  const keyExtractor = (item: Conversation) => item.clientId
  const extraData = useMemo(
    () => ({ onConversationPressed, selectedClientId }),
    [onConversationPressed, selectedClientId],
  )
  return (
    <Animated.FlatList
      data={conversations}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      ItemSeparatorComponent={ItemSeparatorComponent}
      scrollEnabled={true}
      extraData={extraData}
      getItemLayout={(_, index) => ({
        length: APPROX_ITEM_HEIGHT,
        offset: APPROX_ITEM_HEIGHT * index,
        index,
      })}
      windowSize={5}
      initialNumToRender={20}
      itemLayoutAnimation={LinearTransition.springify()}
    />
  )
}
