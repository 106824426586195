// This is a modal component used in the calendar template view to add a time(s)
// for a certain day of the week and the selected available time(s) recurs every week

import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

import { EventContentArg } from '@fullcalendar/core'
import { addDays, addMinutes, getDay, isBefore, isEqual, parse, parseISO, startOfWeek, subHours } from 'date-fns'
import { format } from 'date-fns-tz'
import { isEmpty } from 'lodash-es'

import { DAYS_OF_WEEK_LONG_LABELS } from '@lyrahealth-inc/shared-app-logic'

import { ProviderCalendarBaseSlotSelectionModal } from './ProviderCalendarBaseSlotSelectionModal'
import { SelectField } from '../../atoms'

export type ProviderCalendarDaySlotSelectionModalProps = {
  currentEvents?: { startTime: string; endTime: string }[]
  eventToEdit?: EventContentArg | null
  onConfirmationButtonPress: (events: { startTime: string; endTime: string }[]) => void
  onRequestClose: () => void
  sessionDuration?: number
  setShowAddEventModal: (showModal: boolean) => void
  showAddEventModal: boolean
  slotMaxTime?: string
  slotMinTime?: string
}

export const ProviderCalendarDaySlotSelectionModal: FunctionComponent<ProviderCalendarDaySlotSelectionModalProps> = ({
  currentEvents = [],
  eventToEdit,
  onConfirmationButtonPress,
  onRequestClose,
  sessionDuration = 50,
  setShowAddEventModal,
  showAddEventModal,
  slotMaxTime = '22:00:00',
  slotMinTime = '07:00:00',
}) => {
  const { formatMessage } = useIntl()

  const formatTimeToLocale = (time: Date) => {
    return new Intl.DateTimeFormat(Intl.DateTimeFormat().resolvedOptions().locale, {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }).format(time)
  }

  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState<number>(0)
  const [selectedStartTimes, setSelectedStartTimes] = useState<string[]>([''])
  const [initialTime, setInitialTime] = useState<string | undefined>(undefined)
  const startDate = parse(slotMinTime, 'HH:mm:ss', new Date())
  const endDate = parse(slotMaxTime, 'HH:mm:ss', new Date())

  useEffect(() => {
    if (eventToEdit) {
      const initialDate = parse(eventToEdit.event.startStr.replace('Z', ''), "yyyy-MM-dd'T'HH:mm:ss", new Date())
      setSelectedDayOfWeek(getDay(initialDate))
      setSelectedStartTimes([format(initialDate, 'hh:mm a')])
      setInitialTime(format(initialDate, 'hh:mm a'))
    }
  }, [eventToEdit])

  const blockedRelativeStartTimes = useMemo(() => {
    const adjacentBlockHalfHourCount = Math.ceil(sessionDuration / 30) - 1
    const blockedRelativeStartTimes: number[] = []
    for (let startTime = -adjacentBlockHalfHourCount; startTime <= adjacentBlockHalfHourCount; startTime++) {
      blockedRelativeStartTimes.push(startTime * 30)
    }
    return blockedRelativeStartTimes
  }, [sessionDuration])

  const holdDurationMinutes = Math.ceil(sessionDuration / 30) * 30

  const allAvailableTimeSlots = useMemo(() => {
    const timeSlots: { label: string; value: string }[] = []
    let currentTime = startDate

    while (isBefore(currentTime, subHours(endDate, 1)) || isEqual(currentTime, subHours(endDate, 1))) {
      timeSlots.push({
        label: `${formatTimeToLocale(currentTime)} - ${formatTimeToLocale(
          addMinutes(currentTime, holdDurationMinutes),
        )}`,
        value: format(currentTime, 'hh:mm a'),
      })
      currentTime = addMinutes(currentTime, 30)
    }

    const selectedDay = addDays(startOfWeek(new Date()), Number(selectedDayOfWeek))

    const selectedDayExistingEvents: { startTime: string; endTime: string }[] | undefined =
      selectedDay &&
      currentEvents
        .filter(
          (currentEvent) => format(new Date(currentEvent.startTime), 'MM-dd-yy') === format(selectedDay, 'MM-dd-yy'),
        )
        .filter(({ startTime }: { startTime: string }) => {
          return format(parseISO(startTime), 'hh:mm a') !== initialTime
        })
        .map(({ startTime, endTime }: { startTime: string; endTime: string }) => {
          return {
            startTime: format(parseISO(startTime), 'hh:mm a'),
            endTime: format(parseISO(endTime), 'hh:mm a'),
          }
        })

    const blockedStartTimes = selectedDayExistingEvents
      ? [
          ...new Set(
            selectedDayExistingEvents
              .map((selectedDateExistingEvent) => selectedDateExistingEvent.startTime)
              .reduce((allStartTimes: string[], existingEventTime) => {
                const blockedTimes = blockedRelativeStartTimes.map((blockedRelativeStartTime) =>
                  format(
                    addMinutes(parse(existingEventTime, 'hh:mm a', new Date()), blockedRelativeStartTime),
                    'hh:mm a',
                  ),
                )
                return allStartTimes.concat(blockedTimes)
              }, []),
          ),
        ]
      : []

    return timeSlots.filter((timeSlot) => !blockedStartTimes.includes(timeSlot.value))
  }, [
    blockedRelativeStartTimes,
    currentEvents,
    endDate,
    holdDurationMinutes,
    initialTime,
    selectedDayOfWeek,
    startDate,
  ])

  const blockedTimeSlotValues = useMemo(() => {
    return [
      ...new Set(
        selectedStartTimes.reduce(
          (allStartTimes: string[], selectedStartTime) =>
            isEmpty(selectedStartTime)
              ? allStartTimes
              : allStartTimes.concat(
                  blockedRelativeStartTimes.map((blockedRelativeStartTime) =>
                    format(
                      addMinutes(parse(selectedStartTime, 'hh:mm a', new Date()), blockedRelativeStartTime),
                      'hh:mm a',
                    ),
                  ),
                ),
          [],
        ),
      ),
    ]
  }, [blockedRelativeStartTimes, selectedStartTimes])

  const hasValidTimeSelections = useMemo(() => {
    return selectedStartTimes.filter((selectedStartTime) => !isEmpty(selectedStartTime)).length > 0
  }, [selectedStartTimes])

  const confirmSelection = () => {
    setShowAddEventModal(false)
    const startAndEndTimes = selectedStartTimes
      .filter((selectedStartTime) => !isEmpty(selectedStartTime))
      .map((selectedStartTime) => {
        const startTime = parse(
          selectedStartTime,
          'hh:mm a',
          addDays(startOfWeek(new Date()), Number(selectedDayOfWeek)),
        )
        return {
          startTime: format(startTime, "yyyy-MM-dd'T'HH:mm:ss.SSSX", { timeZone: 'UTC' }),
          endTime: format(addMinutes(startTime, holdDurationMinutes), "yyyy-MM-dd'T'HH:mm:ss.SSSX", {
            timeZone: 'UTC',
          }),
        }
      })
    onConfirmationButtonPress(startAndEndTimes)
  }

  const handleAnimationEnd = () => {
    setSelectedDayOfWeek(0)
    setSelectedStartTimes([''])
  }

  return (
    <ProviderCalendarBaseSlotSelectionModal
      allAvailableTimeSlots={allAvailableTimeSlots}
      blockedRelativeStartTimes={blockedRelativeStartTimes}
      blockedTimeSlotValues={blockedTimeSlotValues}
      cancelButtonText={formatMessage({
        defaultMessage: 'Cancel',
        description: 'Button to cancel creating or editing event time and date',
      })}
      confirmationButtonText={
        eventToEdit
          ? formatMessage({
              defaultMessage: 'Save',
              description: 'Button to save new event time and date',
            })
          : formatMessage({
              defaultMessage: 'Add',
              description: 'Button to add new event time and date',
            })
      }
      confirmButtonDisabled={!hasValidTimeSelections}
      dayDateSelector={
        <SelectField
          label={formatMessage({
            defaultMessage: 'Day of the week',
            description: 'Label for field where providers select the day of the week they are adding availability for',
          })}
          value={selectedDayOfWeek}
          name='selectedDayOfWeek'
          onChange={(e: number) => setSelectedDayOfWeek(e)}
          disablePlaceholder
          options={[
            {
              label: formatMessage(DAYS_OF_WEEK_LONG_LABELS.Sunday),
              value: 0,
            },
            {
              label: formatMessage(DAYS_OF_WEEK_LONG_LABELS.Monday),
              value: 1,
            },
            {
              label: formatMessage(DAYS_OF_WEEK_LONG_LABELS.Tuesday),
              value: 2,
            },
            {
              label: formatMessage(DAYS_OF_WEEK_LONG_LABELS.Wednesday),
              value: 3,
            },
            {
              label: formatMessage(DAYS_OF_WEEK_LONG_LABELS.Thursday),
              value: 4,
            },
            {
              label: formatMessage(DAYS_OF_WEEK_LONG_LABELS.Friday),
              value: 5,
            },
            {
              label: formatMessage(DAYS_OF_WEEK_LONG_LABELS.Saturday),
              value: 6,
            },
          ]}
        />
      }
      eventToEdit={eventToEdit}
      handleAnimationEnd={handleAnimationEnd}
      onConfirmationButtonPress={confirmSelection}
      onRequestClose={onRequestClose}
      modalTitle={
        eventToEdit
          ? formatMessage({
              defaultMessage: 'Edit availability',
              description: 'Title of modal to update an availabilty',
            })
          : formatMessage({
              defaultMessage: 'Add availability',
              description: 'Title of modal to add an availabilty',
            })
      }
      selectedStartTimes={selectedStartTimes}
      sessionDuration={sessionDuration}
      setSelectedStartTimes={setSelectedStartTimes}
      visible={showAddEventModal}
    />
  )
}
