import React from 'react'
import { FormattedMessage, MessageDescriptor } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { CALENDAR_LANDING_INFO, CALENDAR_LANDING_TITLES, LANDING_PAGE_INFO } from '@lyrahealth-inc/shared-app-logic'

import { BodyText, Image, Subhead } from '../../atoms'
import { BodyTextSize, SubheadSize } from '../../styles'
import { ThemeType } from '../../utils'

const DescriptionContainer = styled.View(() => ({
  maxWidth: '254px',
}))

const InformationContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  display: 'flex',
  width: '100%',
  maxWidth: '944px',
  paddingTop: theme.spacing['32px'],
  paddingBottom: theme.spacing['48px'],
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

const LandingItem = styled.View(() => ({
  maxWidth: '254px',
}))

const SubheadContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['8px'],
  marginTop: theme.spacing['16px'],
}))

const HYBRID_BOOKING_LANDING_ITEMS = [
  {
    imageSource: require('../../assets/referrals.png'),
    title: CALENDAR_LANDING_TITLES[LANDING_PAGE_INFO.REFERRALS],
    description: CALENDAR_LANDING_INFO[LANDING_PAGE_INFO.REFERRALS],
  },
  {
    imageSource: require('../../assets/confirmed.png'),
    title: CALENDAR_LANDING_TITLES[LANDING_PAGE_INFO.FASTER_BOOKING],
    description: CALENDAR_LANDING_INFO[LANDING_PAGE_INFO.FASTER_BOOKING],
  },
  {
    imageSource: require('../../assets/schedule.png'),
    title: CALENDAR_LANDING_TITLES[LANDING_PAGE_INFO.EASIER_MANAGEMENT],
    description: CALENDAR_LANDING_INFO[LANDING_PAGE_INFO.EASIER_MANAGEMENT],
  },
]

export const ProviderCalendarLandingContent: React.FC = () => {
  const { colors } = useTheme() as ThemeType

  const generateSchedulingInfo = (
    landingElement: {
      imageSource: any
      title: MessageDescriptor
      description: MessageDescriptor
    },
    index: number,
  ) => {
    return (
      <LandingItem key={index}>
        <Image source={landingElement.imageSource} style={{ width: 67, height: 67 }} accessibilityIgnoresInvertColors />
        <SubheadContainer>
          <Subhead
            level='4'
            color={colors.textSecondary}
            size={SubheadSize.XSMALL}
            text={<FormattedMessage {...landingElement.title} />}
          />
        </SubheadContainer>
        <DescriptionContainer>
          <BodyText
            color={colors.textSecondary}
            size={BodyTextSize.SMALL}
            text={<FormattedMessage {...landingElement.description} />}
          />
        </DescriptionContainer>
      </LandingItem>
    )
  }

  const schedulingInfoList = HYBRID_BOOKING_LANDING_ITEMS.map(
    (landingElement: { imageSource: any; title: MessageDescriptor; description: MessageDescriptor }, index: number) =>
      generateSchedulingInfo(landingElement, index),
  )
  return <InformationContainer>{schedulingInfoList}</InformationContainer>
}
