import React from 'react'
import { useIntl } from 'react-intl'

import { isToday, isWithinInterval, isYesterday, subDays } from 'date-fns'
import styled, { useTheme } from 'styled-components/native'

import { BodyTextSize } from '../../styles'
import { BodyText } from '../bodyText/BodyText'

export type ConversationTimeProps = {
  time: Date | null
}

const ConversationText = styled(BodyText)<{ hide: boolean }>(({ hide }) => ({
  opacity: hide ? 0 : 1,
}))
export const ConversationTime: React.FC<ConversationTimeProps> = ({ time }) => {
  const intl = useIntl()
  const now = new Date()
  const { colors } = useTheme()

  let formatted
  if (!time) {
    formatted = 'empty'
  } else if (isToday(time)) {
    const todayTime = intl.formatTime(time, {
      hour: 'numeric',
      minute: 'numeric',
    })
    formatted = intl.formatMessage({ defaultMessage: 'Today, {todayTime}', description: 'Today' }, { todayTime })
  } else if (isYesterday(time)) {
    formatted = intl.formatMessage({ defaultMessage: 'Yesterday', description: 'Yesterday' })
  } else if (isWithinInterval(time, { start: subDays(now, 7), end: now })) {
    formatted = intl.formatDate(time, { weekday: 'long' })
  } else {
    formatted = intl.formatDate(time, { month: 'numeric', day: 'numeric', year: 'numeric' })
  }

  return (
    <ConversationText
      hide={!time}
      text={formatted}
      size={BodyTextSize.SMALL}
      color={colors.textSecondary}
      numberOfLines={1}
    />
  )
}
