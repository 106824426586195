import React from 'react'

import {
  AssessmentIcon,
  AssignmentsIcon,
  GraduationIcon,
  InfoSheetIcon,
  LessonIcon,
  PastSessionsIcon,
  SessionsIcon,
} from '../../atoms/illustrations'

export const AssignmentIconSelector: React.FC<AssignmentIconSelectorProps> = ({ type, size = 40 }) => {
  switch (type) {
    case 'lesson':
      return <LessonIcon size={size} />
    case 'exercise':
      return <AssignmentsIcon size={size} />
    case 'assessment':
      return <AssessmentIcon size={size} />
    case 'session':
      return <SessionsIcon width={size} />
    case 'infosheet':
      return <InfoSheetIcon size={size} />
    case 'track':
      return <GraduationIcon width={size} />
    default:
      return <PastSessionsIcon width={size} />
  }
}

type AssignmentIconSelectorProps = {
  type: string | undefined
  size?: number
}
