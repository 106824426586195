import React, { createRef, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

import classNames from 'classnames'
import { includes, isEmpty, isInteger, isNil, isNumber, sortBy, startCase } from 'lodash-es'
import moment from 'moment-timezone'
import { Bar, BarChart, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import {
  AssessmentType,
  convertUTCToISO8601format,
  Outcome,
  QuestionResponse,
  useWindowSize,
} from '@lyrahealth-inc/shared-app-logic'

import CustomDot from './CustomDot'
import CustomTick from './CustomTick'
import styles from './outcomeChart.module.scss'
import TextButton from '../../atoms/buttons/textButton/TextButton'
import LyraPopover, { LyraPopoverHandle } from '../../atoms/popover/LyraPopover'
import { outcomeChartConstants } from '../../constants/outcomeConstants'
import outcomeUtils from '../../utils/outcomeUtils'

export interface OutcomeChartResponse {
  clientName: string
  title: string | undefined
  dateSubmitted: string
  assessmentType: string
  score: number | string | null | undefined
  answers: QuestionResponse[] | undefined
}

type OutcomeChartProps = {
  outcomesData: Outcome[]
  assessmentType: string
  answerModalAction?: (response: OutcomeChartResponse) => void
  clientName: string
  track: (event: { event: string; action: string }) => void
}

export const OutcomeChart: React.FC<OutcomeChartProps> = ({
  answerModalAction,
  assessmentType,
  clientName,
  outcomesData,
  track,
}) => {
  const [toolTipActive, setToolTipActive] = useState(false)
  const [activeX, setActiveX] = useState(0)
  const [activeY, setActiveY] = useState(0)
  const [toolTipContent, setToolTipContent] = useState<(Outcome & { title: string }) | object>({})
  const [overTooltip, setOverTooltip] = useState(false)
  const [tooltipIndex, setTooltipIndex] = useState(-1)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [isAlreadyConvertedToUTC, setIsAlreadyConvertedToUTC] = useState(false)
  const windowSize = useWindowSize()
  const chartContainerRef = useRef<HTMLDivElement | null>(null)
  const lineRef = createRef<Line>()
  const popoverRef = useRef<LyraPopoverHandle>(null)
  const { formatDate } = useIntl()
  const chartHeight = 440
  const minDataPointWidth = 75

  const assessment = outcomeChartConstants.find((obj) => obj.name === assessmentType)
  const isMobile = window.innerWidth < 768
  const XAxisPadding = isMobile ? { left: 70, right: 45 } : { left: 115, right: 60 }
  const ranges = assessment?.ranges

  useEffect(() => {
    // Set initial scroll position on load
    const chartContainer = chartContainerRef?.current
    if (chartContainer && windowSize) {
      setScrollPosition(chartContainer.scrollWidth - chartContainer.clientWidth)
    }
  }, [windowSize])

  useEffect(() => {
    // For mobile: Close tooltip when touch outside
    const hideTooltip = (event: MouseEvent) => {
      if (
        toolTipActive &&
        chartContainerRef.current &&
        !chartContainerRef.current.contains(event.target as HTMLElement)
      ) {
        setToolTipActive(false)
        setTooltipIndex(-1)
      }
    }
    // @ts-expect-error TS(2769): No overload matches this call.
    document.addEventListener('touchstart', hideTooltip)
    return () => {
      // @ts-expect-error TS(2769): No overload matches this call.
      document.removeEventListener('touchstart', hideTooltip)
    }
  }, [toolTipActive])

  const onChartMouseMove = (chart: any) => {
    popoverRef?.current?.forceClose()
    if (tooltipIndex !== chart.activeTooltipIndex) {
      if (chart.isTooltipActive) {
        setToolTipActive(true)
        const activePoint: any = {
          payload: {},
          ...(lineRef?.current?.props?.points ? lineRef.current.props.points[chart?.activeTooltipIndex] : undefined),
        }
        if (activePoint) {
          setActiveX(activePoint.x ?? 0)
          setActiveY(activePoint.y ?? 0)
          setToolTipContent(activePoint?.payload)
          setTooltipIndex(chart.activeTooltipIndex)
        }
      }
    }
  }

  const onChartMouseLeave = () => {
    setTimeout(() => {
      if (!overTooltip) {
        setToolTipActive(false)
        setTooltipIndex(-1)
        // Mainly for Bar graphs
        setIsAlreadyConvertedToUTC(false)
      }
    }, 3000) // To prevent tooltip flicker
  }

  const onPopoverOpen = () => {
    track({ event: 'BUTTON_PRESS', action: `OUTCOMES_${assessmentType}_INFO` })
    setToolTipActive(false)
    setTooltipIndex(-1)
  }

  const getFormattedDateToShow = (): string => {
    const outcome = toolTipContent as Outcome & { title?: string; dateRange?: string }

    // If the date has already been preprocessed(bar chart does), return the precomputed dateRange
    if (isAlreadyConvertedToUTC && outcome.dateRange) {
      return outcome.dateRange // e.g., "9/8/19 - 9/14/19"
    }

    // Otherwise, fallback to formatting the raw UTC response_date into a localized short date
    return formatDate(convertUTCToISO8601format(outcome.response_date), {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
  }

  const handleAnswersClick = () => {
    track({ event: 'BUTTON_PRESS', action: `OUTCOMES_${assessmentType}_TOOLTIP` })
    if (!('response_date' in toolTipContent)) return
    const answers = toolTipContent.response_details
    const assessment = outcomeChartConstants.find((obj) => obj.name === assessmentType)
    const dateToShow = getFormattedDateToShow()

    const data = {
      clientName: clientName,
      title: assessment?.fullName,
      dateSubmitted: dateToShow,
      assessmentType: assessmentType,
      score: toolTipContent.scores[assessmentType],
      answers: answers?.filter((a) => includes(a.groups, assessmentType)),
    }
    answerModalAction && answerModalAction(data)
  }

  const renderCustomTooltip = () => {
    if (!('response_date' in toolTipContent)) return <></>
    const toolTipWidth = 140
    const title = toolTipContent.title
    let score = toolTipContent.scores[assessmentType]
    let selfHarm = false

    if (assessmentType === 'TFM') {
      score = toolTipContent.scores.actualTFM
    }

    const scoreDescription = outcomeUtils
      .convertOutcomeScoreToDescription(assessmentType, score)
      .replace(assessment?.condition || '', '')

    if (
      assessmentType === 'PHQ' &&
      'PHQ-SELF-HARM' in toolTipContent.scores &&
      toolTipContent.scores['PHQ-SELF-HARM'] &&
      toolTipContent.scores['PHQ-SELF-HARM'] > 0
    ) {
      selfHarm = true
    }

    const dateToShow = getFormattedDateToShow()

    return (
      <div
        data-test-id='OutcomeChart-customTooltip'
        onMouseOver={() => setOverTooltip(true)}
        onFocus={() => setOverTooltip(true)}
        onMouseOut={() => setOverTooltip(false)}
        onBlur={() => setOverTooltip(false)}
        className={styles['tooltip-container']}
        style={{
          left: activeX - toolTipWidth / 2 - scrollPosition,
          bottom: chartHeight - activeY + 30,
          width: toolTipWidth,
        }}
      >
        <div className={styles['tooltip-inner']}>
          {assessmentType === 'TLFB-OTHER-SUBSTANCES' ? (
            <div dir='ltr' className={title ? styles['labeled-score-number'] : styles['score-number']}>
              <div>{title}</div>
              {`${score} ${score === 1 ? 'day' : 'days'} of use`}
            </div>
          ) : (
            <div className={styles['score-number']}>{score}</div>
          )}
          <div className={styles['score-description']}>{scoreDescription}</div>
          <div className={styles['score-date']}>{dateToShow}</div>
          {selfHarm ? <p className={styles['self-harm']}>Thoughts of self harm or suicide</p> : ''}
          {answerModalAction && toolTipContent.response_details && (
            <TextButton
              text='view answers'
              data-test-id='OutcomeChart-viewAnswers'
              onClick={handleAnswersClick}
              isSmall
              isPrimary
            />
          )}
        </div>
      </div>
    )
  }

  const renderCustomYaxisLabels = () => {
    return (
      <div
        className={classNames(styles['custom-yaxis'], {
          [styles.mobile]: isMobile,
        })}
      >
        {ranges?.map(({ upper, lower, description }, index) => {
          const totalRange = ranges[ranges.length - 1].upper
          const isLast = index === ranges?.length - 1
          const labelOfTypeRange = !isNil(lower)
          const lowerValue = labelOfTypeRange ? lower || 0 : assessment?.ticks[index] || 0
          const upperValue = labelOfTypeRange && !isLast ? upper + 1 : upper
          const rangeSize = upperValue - lowerValue
          const rangePercentage = (rangeSize / totalRange) * 100
          const showZeroLabel = !labelOfTypeRange && index === 0
          return (
            <div
              key={description}
              className={classNames(
                styles['yaxis-label'],
                !labelOfTypeRange ? styles.point : '',
                showZeroLabel ? styles.zero : '',
              )}
              style={{ flexBasis: `${rangePercentage}%` }}
            >
              <span>{description.replace(assessment?.condition ?? '', '')}</span>
              {showZeroLabel && <span>0</span>}
            </div>
          )
        })}
      </div>
    )
  }

  // Extracted helper to format a date based on screen size
  const formatDisplayDate = (date: moment.Moment): string => {
    return formatDate(date.toDate(), {
      day: 'numeric',
      month: 'numeric',
      ...(windowSize[0] > 768 && { year: '2-digit' }),
    })
  }

  const timelineFollowbackMethodData = (data: Outcome[]): Outcome[] => {
    const chronological = data[data.length - 1]?.response_date > data[0]?.response_date
    data = !chronological ? data.reverse() : data
    const dataValues = data.reduce((entries, response) => {
      // Define start (Monday) and end (Sunday) of the previous week
      const pastMonday = moment(response.response_date, 'YYYY-MM-DDThh:mm:ss').subtract(1, 'weeks').startOf('isoWeek')
      const pastSunday = moment(response.response_date, 'YYYY-MM-DDThh:mm:ss').subtract(1, 'weeks').endOf('isoWeek')
      for (let day = pastMonday; day.isSameOrBefore(pastSunday); day.add(1, 'day')) {
        const newDay = day.format('dddd') // e.g., 'Monday'
        const response_date = day.format('YYYY-MM-DD')

        // Convert response_date to formatted string (e.g., 9/7/19)
        const transposedDate = formatDisplayDate(day)

        // Find score for the current day from response details
        const tFMScore = response.response_details?.find((response) => response.question === newDay)?.score

        entries[response_date] = {
          scores: {
            TFM: tFMScore && tFMScore > 7 ? 7 : tFMScore,
            actualTFM: tFMScore,
          },
          response_details: [response.response_details?.find((response) => response.question === newDay)],
          response_date: transposedDate,
        }
      }
      return entries
    }, {})

    // Identify range of existing data
    const dataOrder = Object.keys(dataValues).sort()
    const startDate = dataOrder[0]
    const endDate = dataOrder[dataOrder.length - 1]

    // Fill missing days between start and end date with null values
    for (
      const date = moment(startDate, 'YYYY-MM-DD');
      date.isSameOrBefore(moment(endDate, 'YYYY-MM-DD'));
      date.add(1, 'day')
    ) {
      const dateKey = date.format('YYYY-MM-DD')
      if (!(dateKey in dataValues)) {
        dataValues[dateKey] = {
          scores: {
            TFM: null,
            actualTFM: null,
          },
          response_details: null,
          response_date: formatDisplayDate(date),
        }
      }
    }

    // Sort final output by descending date so most recent is first
    const newResponseData: Outcome[] = sortBy(
      Object.values(dataValues) as (Outcome & { response_date: string })[],
      (entry) => moment(entry.response_date, 'M/D/YY').valueOf(),
    ).reverse()

    return newResponseData
  }

  // Convert day lists like "['monday', 'tuesday']" → "Monday, Tuesday"
  const formatDayList = (text: string): string => {
    try {
      const replaced = text.replace(/'/g, '"') // Convert single quotes to double quotes for valid JSON
      const parsed = JSON.parse(replaced) // Parse JSON string into an array
      if (Array.isArray(parsed) && parsed.length > 0) {
        return parsed.map((day) => startCase(day)).join(', ') // Convert to "Monday, Tuesday"
      }
    } catch {
      return text
    }
    return text
  }

  const timelineFollowbackMethodDataOtherSubstances = (
    data: Outcome[],
  ): (Outcome & { title?: string; dateRange?: string })[] => {
    const formatAnswerText = (text: string): string => startCase(text) // Convert camelCase to Title Case

    // Sort responses by submission date in descending order (newest first)
    const sortedData = [...data].sort((a, b) => moment(b.response_date).diff(moment(a.response_date)))

    // Map to store the latest entry for each (answer-text + cutoff-week) combination
    const latestEntries = new Map<string, Outcome & { title?: string; dateRange?: string }>()

    /*
    ┌───────────────┬──────────────────────────────┬───────────────────────────┬───────────────────────┐
    │ Submission    │ Previous Sunday (Cutoff)     │ Full Week Range           │ Answer-Text           │
    ├───────────────┼──────────────────────────────┼───────────────────────────┼───────────────────────┤
    │ 3/02/25 (Sun) │ 2/23/25                      │ 2/23/25 - 3/1/25          │ Tobacco Products      │
    │ 3/06/25 (Thu) │ 2/23/25                      │ 2/23/25 - 3/1/25          │ Tobacco Products      │ (Overwrites 3/2)  
    │ 3/13/25 (Thu) │ 3/02/25                      │ 3/2/25 - 3/8/25           │ Other Substance Use   │
    │ 3/18/25 (Tue) │ 3/09/25                      │ 3/9/25 - 3/15/25          │ Addictive Behavior    │
    │ 3/23/25 (Sun) │ 3/16/25                      │ 3/16/25 - 3/22/25         │ Marijuana Use         │
    │ 3/29/25 (Sat) │ 3/23/25                      │ 3/23/25 - 3/29/25         │ Caffeine Consumption  │
    │ 4/02/25 (Wed) │ 3/30/25                      │ 3/30/25 - 4/5/25          │ Tobacco Products      │
    │ 4/08/25 (Tue) │ 4/06/25                      │ 4/6/25 - 4/12/25          │ Other Substance Use   │
    └───────────────┴──────────────────────────────┴───────────────────────────┴───────────────────────┘

    //
    // **Grouping Logic:**
    // 1) **Previous Sunday (Cutoff Date)** → Groups responses into **weekly periods** based on the previous Sunday.
    // 2) **Substance Type (Main Question Answer)** → Separates responses **within the same week** based on the type of substance tracked.
    // 3) **Most Recent Entry Wins** → If there are multiple entries for the same cutoff week and same substance type,
    //    only the most recent one (by submission date) is kept.
    //
    // **Edge Case Consideration:**
    // - Typically, users do **not** change their selected substance type from one assessment to another within the same week.
    // - However, if multiple entries exist for the same cutoff week with **different substance types**, they will be kept as separate entries.
    // - This is considered an **edge case** but is handled to ensure accurate tracking.
    */

    sortedData.forEach((outcome) => {
      const responseDetails = outcome.response_details ?? []

      // Extract all TLFB-OTHER-SUBSTANCES-related details
      let tlfbDetails = responseDetails.filter((detail) => detail.groups.includes('TLFB-OTHER-SUBSTANCES'))

      // Locate the main question used to categorize the type of substance or behavior
      // This question is required on the client side, so it should always be present in response_details
      const mainQuestionDetail = tlfbDetails.find((detail) =>
        detail.question?.toLowerCase().includes('select the main type of substance use or behavior'),
      )

      let dayCount = 0
      // Count number of days from JSON-parsed answer-text field (if applicable)
      const daysDetail = tlfbDetails.find((detail) => detail['answer-text']?.includes('['))
      if (daysDetail?.['answer-text']) {
        try {
          const replaced = daysDetail['answer-text'].replace(/'/g, '"') // Convert single quotes to double quotes
          const parsed = JSON.parse(replaced) // Parse JSON string into an array
          if (Array.isArray(parsed)) dayCount = parsed.length // Count the number of days listed
        } catch {
          console.warn('Failed to parse days list:', daysDetail['answer-text'])
        }
      }

      // Format answer-text: convert day lists into readable format and other text to Title Case
      tlfbDetails = tlfbDetails.map((detail) => ({
        ...detail,
        'answer-text': detail['answer-text']?.includes('[')
          ? formatDayList(detail['answer-text']) // Convert day lists into "Monday, Tuesday"
          : formatAnswerText(detail['answer-text'] ?? ''), // Convert text to Title Case
      }))

      // Convert UTC submission date to moment instance
      const responseMoment = moment.utc(outcome.response_date)

      // Use previous Sunday (in UTC) as cutoff date for week grouping
      const previousSunday = responseMoment.clone().subtract(1, 'weeks').startOf('week')
      const endOfWeek = previousSunday.clone().add(6, 'days')

      // Format the response date shown on the bottom X-axis of the bar graph
      // Uses the previous Sunday and screen size to determine formatting (adds year on desktop)
      const formattedResponseDate = formatDate(previousSunday.toDate(), {
        day: 'numeric',
        month: 'numeric',
        ...(windowSize[0] > 768 && { year: '2-digit' }),
        timeZone: 'UTC',
      })

      // Full date range to display for the week (e.g., 9/8/19 - 9/14/19) shown in tooltip and modal
      const dateRange = `${formatDate(previousSunday.toDate(), {
        ...assessment?.toolTipFormat,
        timeZone: 'UTC',
      })}-${formatDate(endOfWeek.toDate(), {
        ...assessment?.toolTipFormat,
        timeZone: 'UTC',
      })}`

      const formattedEntry: Outcome & { title?: string; dateRange?: string } = {
        scores: { 'TLFB-OTHER-SUBSTANCES': dayCount },
        response_details: tlfbDetails,
        response_date: formattedResponseDate,
        title: formatAnswerText(mainQuestionDetail?.['answer-text'] ?? ''),
        dateRange,
      }

      const key = `${formattedResponseDate}||${formattedEntry.title}` // separate groups by both week and substance type

      // Always keep the latest (since data is sorted desc), overwrite if already exists
      latestEntries.set(key, formattedEntry)
    })

    // Group by week to determine how to handle title visibility
    const weekGroups = new Map<string, (Outcome & { title?: string; dateRange?: string })[]>()
    for (const [key, entry] of latestEntries.entries()) {
      const [week] = key.split('||')
      if (!weekGroups.has(week)) weekGroups.set(week, [])
      weekGroups.get(week)!.push(entry)
    }

    const finalEntries: (Outcome & { title?: string; dateRange?: string })[] = []
    weekGroups.forEach((entries) => {
      const uniqueTitles = new Set(entries.map((e) => e.title))
      if (entries.length === 1 || uniqueTitles.size === 1) {
        // Only one distinct dataset – remove title to avoid unnecessary tooltip clutter
        entries.forEach(({ title: _unusedTitle, ...entryWithoutTitle }) => {
          finalEntries.push(entryWithoutTitle)
        })
      } else {
        // Multiple distinct datasets – keep titles for clarity in tooltip
        finalEntries.push(...entries)
      }
    })

    return finalEntries
  }

  const bingeDrinkingDays = (data: Outcome[]) => {
    const chronological = data[data.length - 1]?.response_date > data[0]?.response_date
    const startDate = chronological ? data[0].response_date : data[data.length - 1].response_date
    const endDate = chronological ? data[data.length - 1].response_date : data[0].response_date
    const uniqueMonthsData = sortBy(data, ['response_date']).reduce((cumulative, response) => {
      cumulative[moment(response.response_date).format('YYYY-MM')] = response
      return cumulative
    }, {})
    const currentMonths = Object.keys(uniqueMonthsData)
    const drinkingDaysData: Outcome[] = Object.values(uniqueMonthsData)
    for (
      const date = moment(startDate, 'YYYY-MM-DDThh:mm:ss');
      date.isBefore(moment(endDate, 'YYYY-MM-DDThh:mm:ss'));
      date.add(1, 'month')
    ) {
      if (!currentMonths.includes(date.format('YYYY-MM'))) {
        drinkingDaysData.push({
          scores: {
            BDD: null,
          },
          response_details: null,
          response_date: date.format('YYYY-MM-DDThh:mm:ss'),
        })
      }
    }
    chronological
      ? drinkingDaysData.sort((entryA, entryB) => (entryA.response_date > entryB.response_date ? 1 : -1))
      : drinkingDaysData.sort((entryA, entryB) => (entryA.response_date < entryB.response_date ? 1 : -1))
    return drinkingDaysData
  }

  let data: Outcome[] = outcomesData.filter((outcome) => isInteger(outcome.scores[assessmentType]))
  if (assessmentType === AssessmentType.TFM) data = timelineFollowbackMethodData(data)
  if (assessmentType === AssessmentType.TLFB_OTHER_SUBSTANCES) data = timelineFollowbackMethodDataOtherSubstances(data)
  else if (assessmentType === AssessmentType.BDD && data.length > 0) data = bingeDrinkingDays(data)
  else if (assessmentType === AssessmentType.CGSQ || assessmentType === AssessmentType.PARENTING_TASK)
    data = outcomesData.filter((outcome) => isNumber(outcome.scores[assessmentType]))
  if (isEmpty(data)) return null
  const barGraphAssessmentTypes = [AssessmentType.TFM, AssessmentType.TLFB_OTHER_SUBSTANCES]
  return (
    <div data-test-id={`OutcomeChart-container-${assessment?.title}`}>
      <p className={styles.title}>
        {assessment?.title}
        <LyraPopover
          onOpen={onPopoverOpen}
          content={<p>{assessment?.description}</p>}
          iconId='assessment-description'
          iconSize={16}
          openOnHover
          ref={popoverRef}
        />
      </p>
      <div data-test-id={`OutcomeChart-chart-${assessmentType}`} className={classNames(styles.container)}>
        {renderCustomYaxisLabels()}
        <div
          className={classNames(styles['chart-container'])}
          ref={chartContainerRef}
          onScroll={({ currentTarget: { scrollLeft, scrollWidth, clientWidth } }) => {
            setScrollPosition(scrollWidth - clientWidth + scrollLeft)
          }}
        >
          <ResponsiveContainer height={chartHeight} minWidth={data.length * minDataPointWidth}>
            {!barGraphAssessmentTypes.includes(assessmentType as AssessmentType) ? (
              <LineChart
                data={data}
                margin={{ top: 5, right: 5, bottom: 25, left: 5 }}
                onClick={() => {}}
                onMouseMove={onChartMouseMove}
                onMouseLeave={onChartMouseLeave}
              >
                <CartesianGrid vertical={false} />
                <Line
                  ref={lineRef}
                  type='linear'
                  isAnimationActive={false}
                  dataKey={`scores.${assessmentType}`}
                  stroke={assessment?.lineColor}
                  strokeWidth={2}
                  dot={<CustomDot tooltipIndex={tooltipIndex} fillNoValueEntries={assessment?.fillNoValueEntries} />}
                  activeDot={
                    <CustomDot
                      isActive
                      tooltipIndex={tooltipIndex}
                      fillNoValueEntries={assessment?.fillNoValueEntries}
                    />
                  }
                />
                <XAxis
                  reversed
                  dataKey='response_date'
                  axisLine={false}
                  tickLine={false}
                  tick={<CustomTick data={data} />}
                  ticks={[data[data.length - 1].response_date, data[0].response_date]}
                  tickCount={2}
                  padding={XAxisPadding}
                />
                <YAxis
                  dataKey={`scores.${assessmentType}`}
                  axisLine={false}
                  tickLine={false}
                  width={0}
                  domain={[
                    ranges ? ranges[0]?.lower ?? 0 : 0,
                    ranges ? ranges[ranges.length - 1]?.upper ?? ranges[ranges.length - 1].lower ?? 0 : 0,
                  ]}
                  tick={{ marginBottom: 50 }}
                  ticks={assessment?.ticks}
                />
                <Tooltip wrapperStyle={{ display: 'none' }} />
              </LineChart>
            ) : (
              <BarChart margin={{ top: 5, right: 5, bottom: 25, left: 5 }} data={data}>
                <CartesianGrid vertical={false} />
                <Bar
                  dataKey={`scores.${assessmentType}`}
                  fill={assessment?.lineColor}
                  maxBarSize={215} // Max column width of individual bars
                  radius={[10, 10, 0, 0]} // Corner radius of individual bars
                  onMouseEnter={(barData) => {
                    // barData.x represents the X position of the column in the graph
                    // barData.width is the width of the column
                    // We divide barData.width by 2 to center the tooltip in the column
                    setActiveX(barData.x + barData.width / 2)
                    setActiveY(barData.y)
                    setToolTipContent(barData.payload) // Store tooltip data from the hovered column
                    setToolTipActive(true)

                    // This marks that the date was already pre-formatted as UTC in `timelineFollowbackMethodDataOtherSubstances`
                    // So we skip any further conversion in the tooltip display logic
                    setIsAlreadyConvertedToUTC(true)
                  }}
                  onMouseLeave={onChartMouseLeave}
                />
                <XAxis reversed dataKey='response_date' />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  domain={[
                    ranges ? ranges[0]?.lower ?? 0 : 0,
                    ranges ? ranges[ranges.length - 1]?.upper ?? ranges[ranges.length - 1].lower ?? 0 : 0,
                  ]}
                  tick={{ dx: -40 }}
                  ticks={assessment?.ticks}
                />
                <Tooltip wrapperStyle={{ display: 'none' }} />
              </BarChart>
            )}
          </ResponsiveContainer>
          {toolTipActive && renderCustomTooltip()}
        </div>
      </div>
    </div>
  )
}
