import { useIntl } from 'react-intl'

import { FieldSchema, FormMetadata, ProviderAccessField } from '@lyrahealth-inc/shared-app-logic'

export const useGetProviderAccessFormMetadata: () => FormMetadata = () => {
  const { formatMessage } = useIntl()
  const providerLastName: FieldSchema = {
    name: ProviderAccessField.PROVIDER_LAST_NAME,
    title: formatMessage({ defaultMessage: 'Your last name', description: "The provider's family name/surname" }),
    type: 'string',
    validation: {
      isRequired: true,
    },
  }

  const accessCode: FieldSchema = {
    name: ProviderAccessField.ACCESS_CODE,
    title: formatMessage({
      defaultMessage: 'Access Code',
      description: 'An alphanumeric access code required to access patient outcomes',
    }),
    type: 'string',
    validation: {
      isRequired: true,
    },
  }

  const clientLastName: FieldSchema = {
    name: ProviderAccessField.CLIENT_LAST_NAME,
    title: formatMessage({ defaultMessage: "Client's last name", description: "The client's family name/surname" }),
    type: 'string',
    validation: {
      isRequired: true,
    },
  }

  const clientDateOfBirth: FieldSchema = {
    name: ProviderAccessField.CLIENT_DATE_OF_BIRTH,
    title: formatMessage({ defaultMessage: "Client's date of birth", description: "The client's date of birth" }),
    type: 'string',
    validation: {
      isRequired: true,
    },
    widgetProps: {
      appendFormat: false,
    },
  }

  return {
    schema: {
      type: 'object',
      properties: {
        [ProviderAccessField.HEADER]: {
          name: ProviderAccessField.HEADER,
          content: formatMessage({ defaultMessage: '### Welcome to Lyra Health', description: 'Title of the page' }),
        },
        [ProviderAccessField.SUBHEADER]: {
          name: ProviderAccessField.SUBHEADER,
          content: formatMessage({
            defaultMessage: "Enter your information to review your client's assessment results.",
            description: 'Subtitle of the page',
          }),
        },
        [ProviderAccessField.ERROR_BANNER]: {
          name: ProviderAccessField.ERROR_BANNER,
          type: 'string',
        },
        [ProviderAccessField.FIRST_ROW]: {
          type: 'array',
          inline: true,
          spaceBetweenFields: 32,
          items: [providerLastName, accessCode],
        },
        [ProviderAccessField.SECOND_ROW]: {
          type: 'array',
          inline: true,
          spaceBetweenFields: 32,
          items: [clientLastName, clientDateOfBirth],
        },
      },
    },
    uiSchema: {
      'ui:order': [
        ProviderAccessField.HEADER,
        ProviderAccessField.SUBHEADER,
        ProviderAccessField.ERROR_BANNER,
        ProviderAccessField.FIRST_ROW,
        ProviderAccessField.PROVIDER_LAST_NAME,
        ProviderAccessField.ACCESS_CODE,
        ProviderAccessField.SECOND_ROW,
        ProviderAccessField.CLIENT_LAST_NAME,
        ProviderAccessField.CLIENT_DATE_OF_BIRTH,
      ],
      [ProviderAccessField.HEADER]: {
        'ui:options': {
          center: true,
        },
      },
      [ProviderAccessField.ERROR_BANNER]: { 'ui:widget': 'inlineErrorBanner' },
      [ProviderAccessField.SECOND_ROW]: {
        items: [{}, { 'ui:widget': 'date' }],
      },
    },
  }
}
