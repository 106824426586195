import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { getClientEpisodesData } from '../../../data/lyraTherapy/clientSelectors'

const useGetRelId = () => {
  const episodes = useSelector(getClientEpisodesData)
  return useCallback(
    (clientId: string | null) =>
      clientId ? episodes.find((episode) => episode.patient_id === clientId)?.provider_patient_id ?? null : null,
    [episodes],
  )
}

export default useGetRelId
