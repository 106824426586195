import React, { useEffect, useMemo, useState } from 'react'
import { connect, ConnectedProps, useSelector } from 'react-redux'

import { skipToken } from '@reduxjs/toolkit/query'
import { bindActionCreators } from 'redux'
import styled from 'styled-components/native'

import { useFlags } from '@lyrahealth-inc/shared-app-logic'
import { ClientDetailsSideBar, useFetcher } from '@lyrahealth-inc/ui-core-crossplatform'

import MessengerChat from './MessengerChat'
import { getAuthUserId } from '../../../data/auth/authSelectors'
import { getClientDetailsData, getClientEpisodesData } from '../../../data/lyraTherapy/clientSelectors'
import { useAppDispatch } from '../../../data/store'
import { getLtClient } from '../../clients/clientDetails/data/ltClientDetailsAutoActions'
import { getClientEpisodes } from '../../episodes/data/episodesAutoActions'
import { useConversationsData } from '../data/hooks/useConversationsData'
import { useGetConversationQuery } from '../data/messagesApi'
import { clearSelectedConversation, setSelectedConversation } from '../data/messagesSlice'
import useDetailsPanelData from '../data/useDetailsPanelData'
import useGetFilteredConversations from '../hooks/useGetFilteredConversations'

const Container = styled.View({
  flexDirection: 'row',
  flex: 1,
  overflow: 'hidden',
})

const ClientDetailsSideBarContainer = styled(ClientDetailsSideBar)(({ theme }) => ({
  borderLeftWidth: '1px',
  borderLeftColor: theme.colors.borderDefault,
}))

type BaseMessengerProps = ConnectedProps<typeof connector> & {
  selectedClientId: string | null
  isComposing: boolean
  onSelectedClientIdChanged: (clientId: string | null) => void
  showEmptyView: boolean
  onIsComposingChanged: (isComposing: boolean) => void
  onOpenNewWindow?: () => void
  onOpenClientProfile: () => void
  initialDetailPanelOpen: boolean
  isLoadingClient?: boolean
}

const BaseMessenger: React.FC<BaseMessengerProps> = ({
  selectedClientId,
  onSelectedClientIdChanged: setSelectedClientId,
  isComposing,
  children,
  showEmptyView,
  onIsComposingChanged: setIsComposing,
  onOpenNewWindow,
  onOpenClientProfile,
  initialDetailPanelOpen,
  isLoadingClient = false,
  actions: { getLtClient, getClientEpisodes },
}) => {
  const { isMessagingCenterEnabled } = useFlags()
  const dispatch = useAppDispatch()
  const [isDetailsPanelOpen, setIsDetailsPanelOpen] = useState(initialDetailPanelOpen)

  const selectedClientDetails = useSelector(getClientDetailsData)
  const episodes = useSelector(getClientEpisodesData)
  const userId = useSelector(getAuthUserId)

  const { data: conversations } = useConversationsData()
  const selectedConversation = useMemo(
    () => conversations?.find((conversation) => conversation.patient_lyra_id === selectedClientId),
    [conversations, selectedClientId],
  )
  useGetConversationQuery(
    selectedClientDetails
      ? {
          providerId: userId,
          patientId: selectedClientDetails?.id,
        }
      : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  )

  const [isLoadingClientDetails] = useFetcher(
    [
      [getLtClient, { clientId: selectedClientId, providerId: userId }, selectedClientId, false],
      [getClientEpisodes, { provider_id: userId, patient_id: selectedClientId }, selectedClientId, false],
    ],
    [selectedClientId, userId],
  )

  const clientDetails = useDetailsPanelData({
    client: selectedClientDetails,
    episodes,
  })

  useEffect(() => {
    if (selectedConversation?.conversation_id) {
      dispatch(setSelectedConversation(selectedConversation?.conversation_id))
    } else {
      dispatch(clearSelectedConversation())
    }
  }, [dispatch, selectedConversation?.conversation_id])

  const { conversations: conversationsMapped } = useGetFilteredConversations([])
  const clientOptions = useMemo(
    () => conversationsMapped.map((client) => ({ id: client.clientId, name: client.clientName })),
    [conversationsMapped],
  )
  const selectedClient = useMemo(
    () => clientOptions.find((client) => client.id === selectedClientId),
    [clientOptions, selectedClientId],
  )

  if (!isMessagingCenterEnabled) {
    return null
  }
  const isLoadingConversation = !selectedConversation && selectedClientId != null
  return (
    <Container>
      {children}
      <MessengerChat
        clientName={selectedClient?.name}
        isLoading={isLoadingClientDetails || isLoadingClient || isLoadingConversation}
        showEmptyView={showEmptyView && !isLoadingClient && !isComposing}
        isDetailsPanelOpen={isDetailsPanelOpen}
        onOpenDetailsPanel={() => setIsDetailsPanelOpen(true)}
        isComposing={isComposing}
        clients={clientOptions}
        onClientSelected={setSelectedClientId}
        onMessageSent={() => setIsComposing(false)}
        onOpenNewWindow={onOpenNewWindow}
      />
      <ClientDetailsSideBarContainer
        isLoading={isLoadingClientDetails}
        hasSelectedClient={selectedClientId != null}
        details={clientDetails}
        isOpen={isDetailsPanelOpen}
        onClose={() => setIsDetailsPanelOpen(false)}
        onOpenProfile={onOpenClientProfile}
      />
    </Container>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      getLtClient,
      getClientEpisodes,
    },
    dispatch,
  ),
})

const connector = connect(null, mapDispatchToProps)
export default connector(BaseMessenger)
