import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { BodyText, EditIconBoxed, PrimaryButton, Subhead } from '../../atoms'
import { ProviderBookableCalendarSlotTarget } from '../../molecules'
import { SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

export type ProviderBookableCalendarSettingsProps = {
  availabilityText?: string
  events?: { startTime: string; endTime: string }[]
  onEditPressed?: () => void
  bookableTarget: number
  bookableMax: number
}

const Container = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  border: `1px solid ${theme.colors.borderDefault}`,
  padding: theme.spacing['24px'],
  borderRadius: '16px',
  gap: theme.spacing['24px'],
}))

const Header = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  gap: theme.spacing['16px'],
}))

const HeaderInner = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderBottomWidth: '1px',
  borderBottomColor: theme.colors.borderDefault,
  paddingBottom: theme.spacing['24px'],
  alignItems: 'center',
}))

const Row = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['24px'],
}))

export const ProviderBookableCalendarSettings: FunctionComponent<ProviderBookableCalendarSettingsProps> = ({
  availabilityText = '',
  events = [],
  onEditPressed,
  bookableTarget,
  bookableMax,
}) => {
  const theme = useTheme() as ThemeType

  return (
    <Container testID={tID('ProviderBookableCalendarSettings')}>
      <Header>
        <HeaderInner>
          <Subhead
            size={SubheadSize.MEDIUM}
            text={
              <FormattedMessage
                defaultMessage='Calendar spots template'
                description='Header for setting calendar availability'
              />
            }
          />
          <PrimaryButton
            testID={tID('ProviderBookableCalendarSettings-edit-button')}
            text={<FormattedMessage defaultMessage='Edit' description='Button to edit calendar' />}
            onPress={() => onEditPressed?.()}
            leftIcon={<EditIconBoxed size={24} />}
          />
        </HeaderInner>
      </Header>
      <Row>
        <ProviderBookableCalendarSlotTarget
          target={bookableTarget}
          current={events.length}
          max={bookableMax}
          showWarningBelowTarget={true}
        />
        <BodyText
          color={theme.colors.textSecondary}
          text={
            <FormattedMessage
              defaultMessage='Availability on {availabilityText}'
              description='Text describing which days of the week the provider is available'
              values={{ availabilityText }}
            />
          }
        />
      </Row>
    </Container>
  )
}
