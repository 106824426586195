import React, { FunctionComponent, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Pressable, View } from 'react-native'

import { get, isEmpty } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { AssignmentResponse, AssignmentStatuses, Contentmetadata } from '@lyrahealth-inc/shared-app-logic'
import { Link } from '@lyrahealth-inc/ui-core-crossplatform'

import { BodyText, Image, PressableOpacity, TruncatedText } from '../../atoms'
import { BodyTextSize, colors as deprecatedColors } from '../../styles'
import { ThemeType, tID } from '../../utils'

export interface ReportListProps {
  data: AssignmentResponse[]
  rowClickHandler: (response: AssignmentResponse) => void
  metaData?: Contentmetadata
}

const DateContainer = styled.View({
  flexDirection: 'row',
  alignItems: 'baseline',
})

const DateProvider = styled.View({
  flexDirection: 'row',
  alignItems: 'baseline',
  color: deprecatedColors.charcoal4,
})

const ListItemBadge = styled(View)<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  height: '12px',
  width: '12px',
  backgroundColor: deprecatedColors.navy4,
  borderRadius: '6px',
  marginLeft: spacing['12px'],
}))

const ListItem = styled(PressableOpacity)<{ firstItem: boolean; lastItem: boolean; theme: ThemeType }>(
  ({ firstItem, lastItem, theme: { spacing } }) => ({
    height: '80px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing['24px'],
    ...(lastItem && {
      borderBottomLeftRadius: spacing['12px'],
      borderBottomRightRadius: spacing['12px'],
    }),
    ...(firstItem && {
      borderTopLeftRadius: spacing['12px'],
      borderTopRightRadius: spacing['12px'],
    }),
    ...(!lastItem && {
      borderBottomColor: deprecatedColors.charcoal2,
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
    }),
  }),
)

const ResponseHeaderContainer = styled(View)<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  marginBottom: spacing['12px'],
  padding: `0 ${spacing['24px']}`,
}))

const ResponseFilterItemPressableAll = styled(Pressable)<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  marginRight: spacing['12px'],
}))

const ResponseListContainer = styled(View)<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  border: `1px solid ${deprecatedColors.charcoal2}`,
  borderRadius: '10px',
  marginBottom: spacing['32px'],
}))

const ResponseListEmptyContainer = styled(View)<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: `${spacing['32px']} ${spacing['24px']}`,
  backgroundColor: deprecatedColors.charcoal1,
  borderRadius: '10px',
}))

const ResponseFilterContainer = styled.View({
  flexDirection: 'row',
})

const ResponseFilterHelpMsgContainer = styled(View)<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  padding: `${spacing['12px']} ${spacing['24px']}`,
}))

const ImageContainer = styled(Image)(({ theme: { spacing } }) => ({
  width: '320px',
  height: '192px',
  marginTop: spacing['12px'],
}))

const ContentContainer = styled(View)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexShrink: 1,
  flexGrow: 1,
})

const ResponseContainer = styled(View)({
  flexShrink: 1,
})

export const ReportList: FunctionComponent<ReportListProps> = ({ data = [], rowClickHandler, metaData }) => {
  const [filterReadResponses, setFilterReadResponses] = useState(false)
  const { formatMessage, formatDate } = useIntl()
  const { colors } = useTheme()

  const fieldsToDisplay = [...new Set([...(get(metaData, 'report.fieldsToDisplay') || [])])]

  const renderFieldsToDisplay = (response: AssignmentResponse) => {
    const fields = fieldsToDisplay
      .map((field: string) => response.response[field] || '')
      .filter((field: string) => field.length > 0)

    return <BodyText text={fields.join(' - ')} />
  }

  const renderListItem = (response: AssignmentResponse, index: number, length: number) => {
    const unread = !get(response, 'viewed')
    const date = get(response, 'submit_date') || get(response, 'update_date')

    return (
      <ListItem
        firstItem={index === 0}
        lastItem={index === length - 1}
        onPress={() => rowClickHandler(response)}
        key={response.id}
        testID={tID(`ReportList-${index}`)}
        hoveredBackgroundColor={deprecatedColors.ui_oatmeal1}
        pressedBackgroundColor={deprecatedColors.ui_oatmeal1}
      >
        <ContentContainer>
          <ResponseContainer>
            <DateContainer>
              <DateProvider testID={tID('ReportList-date-provider')}>
                <BodyText
                  text={formatDate(date, {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  })}
                  color={colors.textInactive}
                  size={BodyTextSize.SMALL}
                />
              </DateProvider>
              {unread ? <ListItemBadge /> : null}
            </DateContainer>
            {fieldsToDisplay.length > 0 && (
              <TruncatedText maxNumberOfLines={1} text={renderFieldsToDisplay(response)} />
            )}
          </ResponseContainer>
          {unread ? (
            <BodyText
              color={deprecatedColors.navy6}
              size={BodyTextSize.DEFAULT}
              text={<FormattedMessage defaultMessage='Review' description='Text to review the entry.' />}
            />
          ) : null}
        </ContentContainer>
      </ListItem>
    )
  }

  const renderResponses = (data: AssignmentResponse[]) => {
    if (isEmpty(data)) return <></>
    const unreadResponses = data.filter((response: AssignmentResponse) => !get(response, 'viewed'))
    const listData = filterReadResponses ? unreadResponses : data
    const sortedListData = listData.sort((a: AssignmentResponse, b: AssignmentResponse) => {
      if (!b.submit_date) return 1
      if (!a.submit_date) return -1
      return b.submit_date > a.submit_date ? 1 : b.submit_date < a.submit_date ? -1 : 0
    })
    return (
      <View>
        <ResponseHeaderContainer>
          {renderFilter(unreadResponses)}
          <BodyText
            size={BodyTextSize.DEFAULT}
            text={
              <FormattedMessage
                defaultMessage='{count, plural,
                  one {1 entry}
                  other {{count} entries}
                }'
                description='Show the number of entries listed.'
                values={{ count: data.length }}
              />
            }
          />
        </ResponseHeaderContainer>
        <ResponseListContainer>
          {isEmpty(sortedListData) ? (
            <ResponseListEmptyContainer>
              <BodyText
                size={BodyTextSize.DEFAULT}
                text={
                  <FormattedMessage
                    defaultMessage="You're all caught up! No unread entries."
                    description='There are no unread entries to view.'
                  />
                }
                color={deprecatedColors.charcoal6}
              />
            </ResponseListEmptyContainer>
          ) : null}
          {sortedListData.map((activityResponse: AssignmentResponse, index: number) =>
            renderListItem(activityResponse, index, listData.length),
          )}
        </ResponseListContainer>
      </View>
    )
  }

  const renderFilter = (unreadResponses: AssignmentResponse[]) => {
    return (
      <ResponseFilterContainer>
        <ResponseFilterItemPressableAll onPress={() => setFilterReadResponses(false)}>
          <BodyText
            size={BodyTextSize.DEFAULT}
            text={<FormattedMessage defaultMessage='All' description='Remove filter so all entries are shown.' />}
            color={!filterReadResponses ? deprecatedColors.teal5 : ''}
          />
        </ResponseFilterItemPressableAll>
        <Pressable onPress={() => setFilterReadResponses(true)}>
          <BodyText
            size={BodyTextSize.DEFAULT}
            text={
              <FormattedMessage
                defaultMessage='Unread ({count})'
                description='Filter to show only the unread items'
                values={{ count: unreadResponses.length }}
              />
            }
            color={filterReadResponses ? deprecatedColors.teal5 : ''}
          />
        </Pressable>
      </ResponseFilterContainer>
    )
  }

  const completedSubmissions = data.filter((response) => response.status === AssignmentStatuses.completed)
  if (isEmpty(completedSubmissions)) {
    return (
      <ResponseListEmptyContainer>
        <BodyText
          size={BodyTextSize.DEFAULT}
          text={
            <FormattedMessage
              defaultMessage='Submitted entries will show up here'
              description='Any new entries will be displayed here.'
            />
          }
          color={deprecatedColors.charcoal6}
        />
        <ImageContainer
          source={require('./../../assets/Activity_Empty_Draft_State.png')}
          accessibilityLabel={formatMessage({
            defaultMessage: 'Draft list is empty',
            description: 'There are no drafts.',
          })}
          aria-label={formatMessage({
            defaultMessage: 'Draft list is empty',
            description: 'There are no drafts.',
          })}
        />
      </ResponseListEmptyContainer>
    )
  }

  return (
    <View>
      {renderResponses(completedSubmissions)}
      {filterReadResponses && (
        <>
          <ResponseFilterHelpMsgContainer>
            <BodyText
              color={deprecatedColors.charcoal4}
              size={BodyTextSize.SMALL}
              text={
                <FormattedMessage
                  defaultMessage='Not finding what you’re looking for? <link>View all entries</link>'
                  description='Inform the user that if they are not seeing the correct information, they can click the link to view all entries.'
                  values={{
                    link: (chunks: string) => (
                      <Link
                        text={chunks}
                        underline={true}
                        size={BodyTextSize.SMALL}
                        style={{ fontWeight: '700' }}
                        onPress={() => setFilterReadResponses(false)}
                      />
                    ),
                  }}
                />
              }
            />
          </ResponseFilterHelpMsgContainer>
        </>
      )}
    </View>
  )
}
