import React, { ReactElement, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

import { find, findIndex, noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import {
  AssignmentResponse,
  Content,
  Contentmetadata,
  getCTATitle,
  getThumbnail,
  LessonContent,
  Review,
  useWindowSize,
  VideoLesson,
  VideoStatuses,
} from '@lyrahealth-inc/shared-app-logic'

import { XIcon } from '../../atoms/icons/XIcon'
import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { LessonVideoPlayer } from '../../molecules/lessonVideoPlayer/LessonVideoPlayer'
import { FullScreenOverlay } from '../../organisms/fullScreenOverlay/FullScreenOverlay'
import { Modal } from '../../organisms/modal/Modal'
import { LessonOverview } from '../../pages/lessonOverview/LessonOverview'
import { LessonReviewEntry } from '../../pages/lessonReviewEntry/LessonReviewEntry'
import { LessonReviewSubmitted } from '../../pages/lessonReviewSubmitted/LessonReviewSubmitted'
import { colors } from '../../styles/colors'
import { ThemeType } from '../../utils/themes/ThemeProvider'
import { tID } from '../../utils/utils'

export interface LessonInterface {
  onBackPress: () => void
  contentMetaData: Contentmetadata
  content: Content
  assignmentResponses?: AssignmentResponse[]
  assignmentId?: string
  instructions?: string | null
}

type LessonContentType = 'video' | 'entry' | 'review'

const LessonContainer = styled.View({
  background: colors.ui_oatmeal1,
  height: '100%',
})

const Header = styled.View<{
  theme: ThemeType
}>(({ theme: { spacing } }) => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  backgroundColor: 'transparent',
  position: 'absolute',
  width: '100%',
  height: '48px',
  padding: spacing['16px'],
  zIndex: 1,
}))

// This is a self-contained lesson overview.
// Only this version contains basic functions such as viewing videos and entries.
export const Lesson = ({
  onBackPress,
  contentMetaData,
  content,
  assignmentResponses,
  assignmentId,
  instructions,
}: LessonInterface): ReactElement => {
  // Use State
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [selectedLessonContentIndex, setSelectedLessonContentIndex] = useState<number>(0)
  const [lessonContent, setLessonContent] = useState<LessonContentType>('video')

  // Hooks
  const { formatMessage } = useIntl()
  const [size] = useWindowSize()
  const { colors } = useTheme() as ThemeType

  // Assignment object
  const { contents: lessonContents } = contentMetaData || {}
  const { title, id } = content || {}

  // Memoed objects
  const reviewForm = useMemo(() => find(lessonContents, { content_type: 'form' }), [lessonContents])
  const videolist = useMemo(
    () => lessonContents?.filter((content: LessonContent) => content.content_type === 'video'),
    [lessonContents],
  )

  const getVideosAndReviews = (): {
    videos: VideoLesson[]
    review: Review | null
  } => {
    const videos: VideoLesson[] = []
    let review: Review | null = null

    lessonContents?.forEach((item: LessonContent, index: number) => {
      const { content_type, meta_data, name, title } = item

      // For videos
      if (content_type === 'video') {
        // Find video response
        const assignmentResponse = assignmentResponses?.find(
          (res: AssignmentResponse) => res.response && res.response[name],
        )
        const response = assignmentResponse?.response[name]

        // Build a video lesson object
        const completionStatus = (response?.status ?? VideoStatuses.new) as VideoStatuses
        const videoProgress = response?.progress ?? 0

        videos.push({
          assignmentId: assignmentId || '',
          thumbnail: meta_data?.thumbnail || '',
          videoPropertyName: name,
          videoTitle: title,
          timeFormatted: undefined,
          completionStatus,
          url: meta_data?.url || '',
          videoId: String(index),
          videoProgress,
          time: null,
          videoIndex: index,
          description: item.description || '',
          content_type: content_type,
        })

        // For entry/reviews
      } else if (content_type === 'form') {
        const reviewTime = item.estimatedCompletionTimeMinutes ?? 2
        const reviewPropertyName = name

        // Find entry/review response
        const assignmentResponse = assignmentResponses?.find(
          (res: AssignmentResponse) => res.response && res.response[reviewPropertyName],
        )
        const response = assignmentResponse?.response[reviewPropertyName]
        const reviewCompletionStatus = (response?.status ?? VideoStatuses.new) as VideoStatuses

        review = {
          reviewTime,
          reviewPropertyName,
          reviewCompletionStatus,
        }
      }
    })

    return { videos, review }
  }

  const { videos, review } = getVideosAndReviews()
  const thumbnail = getThumbnail(videolist!)
  const response = assignmentResponses?.filter((response) => response.status !== 'in_progress')?.[0]
  const selectedContent = lessonContents![selectedLessonContentIndex]

  const onVideoPressed = (_id: string, videoPropertyName?: string): void => {
    // Getting the index of the video so that the selectedContent would be updated
    const videoIndex = findIndex(lessonContents, { name: videoPropertyName })

    setIsModalOpen(true)
    setLessonContent('video')
    setSelectedLessonContentIndex(videoIndex)
  }

  const openEntryReview = (state: 'entry' | 'review'): void => {
    setLessonContent(state)
    setIsModalOpen(true)
  }

  const renderLessonContent = (): ReactElement => {
    switch (lessonContent) {
      case 'video':
        return (
          <>
            <Header>
              <PressableOpacity testID={tID('Lesson-CloseIcon')} onPress={() => setIsModalOpen(false)}>
                <XIcon size={24} fillColor={colors.iconDefault} />
              </PressableOpacity>
            </Header>
            <LessonVideoPlayer
              url={selectedContent.meta_data.url || ''}
              videoPropertyName={selectedContent.name}
              assignmentResponse={response!}
              useModal={false}
            />
          </>
        )
      case 'entry':
        return (
          <LessonReviewEntry
            content={reviewForm!.meta_data}
            name={reviewForm!.name}
            title={title}
            onClosePress={() => setIsModalOpen(false)}
            saveForm={noop}
            ctaDisabled
          />
        )
      case 'review':
        return (
          <>
            {!!reviewForm && !!response && (
              <LessonReviewSubmitted
                title={title}
                content={reviewForm.meta_data}
                status={review?.reviewCompletionStatus}
                name={reviewForm.name}
                response={response.response[reviewForm.name]?.data || response.response[reviewForm.name]?.values}
                onClosePress={() => setIsModalOpen(false)}
                saveForm={() => setIsModalOpen(false)}
                date={response.submit_date}
              />
            )}
          </>
        )
      default:
        return <></>
    }
  }

  const getModalResponsiveWidth = useMemo(() => {
    if (size >= 1200) return 1170
    if (size >= 1024) return 970
    if (size >= 768) return 750
    return '100%'
  }, [size])

  return (
    <LessonContainer testID={tID('Lesson-Container')}>
      <LessonOverview
        id={id!}
        lessonName={title}
        thumbnail={thumbnail}
        instructions={instructions}
        onBackPress={onBackPress}
        review={review}
        ctaTitle={getCTATitle(formatMessage, videos)}
        onVideoPressed={onVideoPressed}
        videos={videos}
        reviewAvailable
        isProvider
        onReviewPressed={(propertyName: string) => {
          const reviewIndex = findIndex(lessonContents, { name: propertyName })
          setSelectedLessonContentIndex(reviewIndex)
          review?.reviewCompletionStatus !== 'completed' ? openEntryReview('entry') : openEntryReview('review')
        }}
      />
      {/* entry and overview content */}
      {lessonContent !== 'video' ? (
        <FullScreenOverlay isFirst={false} isOpen={isModalOpen}>
          {renderLessonContent()}
        </FullScreenOverlay>
      ) : (
        // for video content
        <Modal
          disableFullScreenModal
          disableBottomSheet
          visible={isModalOpen}
          onCloseEnd={() => setIsModalOpen(false)}
          onRequestClose={() => setIsModalOpen(false)}
          modalContents={renderLessonContent()}
          style={{ width: getModalResponsiveWidth, padding: 0 }}
        />
      )}
    </LessonContainer>
  )
}
