export const prepopulationFieldsMap = {
  audInitialNote: {
    /* LHS=audClientIntake RHS=audInitialNote */
    audClientIntake: {
      preferredName: 'preferredName',
      preferredPronoun: 'clientPronouns',
      pronounOther: 'pronounsOtherSpecify',
      homeAddressStreet: 'street',
      homeAddressCity: 'city',
      homeAddressState: 'state',
      homeAddressZipCode: 'zipCode',
      primaryPhone: 'clientPhoneNumber',
      leaveVoicemailsPersonal: 'leaveVoicemails',
      gender: 'gender',
      specifyGender: 'genderOtherSpecify',
      raceEthnicityIdentity: 'ethnicity',
      ethnicityOther: 'clientRaceEthnicityOther',
      considerSpiritualReligious: 'religiousSpiritual',
      describeFaithBelief: 'religiousSpiritualDescription',
      relationshipStatus: 'maritalStatus',
      relationshipStatusOther: 'maritalStatusOther',
      relationshipDescription: 'relationshipDescription',
      widowHow: 'widowHow',
      divorce: 'divorce',
      gunAccess: 'firearmsAccess',
      bringsToProgram: 'chiefComplaint',
      alcoholDrinkQuantity: 'howManyDrinks',
      bingeDrinkingFrequency: 'howOftenMoreThan6Drinks',
      tobaccoUseFrequency: 'tobaccoFrequency',
      oftenECigaretteVape: 'vapeFrequency',
      oftenUseCannabis: 'cannabis',
      oftenUseIllegal: 'otherDrugs',
      oftenUsePrescription: 'prescriptionDrugs',
      symptomsExperienced: 'additionalSymptoms',
      previousPsychiatricDiagnoses: 'previousPsychiatricDiagnoses',
      previousPsychiatricDiagnosesList: 'previousPsychiatricDiagnosesList',
      currentlySeeingMentalHealthProfessional: 'currentMentalHealthServices',
      mentalHealthProfessionalInfo: 'currentProviders',
      previousSubstanceUseTreatment: 'substanceUseServicesHistory',
      previousMentalHealthServices: 'historyNonSUDMentalHealthServices',
      describePreviousTreatments: 'historyMentalHealthServicesDetails',
      attemptedSuicide: 'suicidalBehaviorHistory',
      attemptedSuicideWhen: 'suicidalBehaviorInfo',
      purposelySelfInjured: 'selfHarmingHistory',
      purposelySelfInjuredWhen: 'selfHarmingInfo',
      committedAssault: 'historyViolentBehaviors',
      committedAssaultWhen: 'violentBehaviorInfo',
      hospitalizedPsychiatricEmotionalProblems: 'historyPsychiatricHospitalization',
      hospitalizedPsychiatricEmotionalProblemsWhen: 'historyPsychiatricHospitalizationWhen',
      hospitalizedSubstanceAlcohol: 'historySubstanceHospitalization',
      hospitalizedSubstanceAlcoholWhen: 'historySubstanceHospitalizationWhen',
      experiencedDiscriminationOppression: 'discriminationOppressionHistory',
      medicalConditions: 'priorMedicalConditionsInfo',
      listMedications: 'currentMedications',
      familyPsychiatricHistory: 'psychiatricProblemHistory',
      familyPsychiatricHistoryDescription: 'psychiatricProblemInfo',
      highestLevelEducation: 'highestEducation',
      occupation: 'educationEmploymentHistory',
      currentlyInvolvedLegal: 'legalInvolvement',
      currentlyInvolvedLegalDescribe: 'legalInvolvementInfo',
      listSupportSystem: 'socialSupports',
      initialAlcoholUseGoals: 'initialGoals',
      longTermAlcoholUseGoals: 'longTermGoals',
      initialAlcoholUseGoalsOther: 'initialGoalsOther',
      sexualOrientation: 'sexualOrientation',
      specifySexualOrientation: 'sexualOrientationSpecify',
      alcoholDrinkFrequency: 'alcoholFrequency',
      otherTherapyGoals: 'otherTherapyGoals',
    },
  },
  audSessionNote: {
    audSessionNote: {
      preferredName: 'preferredName',
      clientPronouns: 'clientPronouns',
      pronounsOtherSpecify: 'pronounsOtherSpecify',
      street: 'street',
      city: 'city',
      state: 'state',
      zipCode: 'zipCode',
      usedInterventions: 'usedInterventions',
      clientPhoneNumber: 'clientPhoneNumber',
      leaveVoicemails: 'leaveVoicemails',
      currentMedications: 'currentMedications',
      substanceAbuseDiagnosis: 'substanceAbuseDiagnosis',
      primaryDiagnosis: 'primaryDiagnosis',
      secondaryDiagnosis: 'secondaryDiagnosis',
      otherDiagnosis: 'otherDiagnosis',
      initialGoals: 'initialGoals',
      initialGoalsOther: 'initialGoalsOther',
      longTermGoals: 'longTermGoals',
      longTermGoalsOther: 'longTermGoalsOther',
      otherTherapyGoals: 'otherTherapyGoals',
      presentingConcern_2: 'presentingConcern_2',
      presentingConcernDescription_2: 'presentingConcernDescription_2',
      presentingConcernObjective_2: 'presentingConcernObjective_2',
      interventionPlan_2: 'interventionPlan_2',
      presentingConcern_3: 'presentingConcern_3',
      presentingConcernDescription_3: 'presentingConcernDescription_3',
      presentingConcernObjective_3: 'presentingConcernObjective_3',
      interventionPlan_3: 'interventionPlan_3',
      presentingConcern_4: 'presentingConcern_4',
      presentingConcernDescription_4: 'presentingConcernDescription_4',
      presentingConcernObjective_4: 'presentingConcernObjective_4',
      interventionPlan_4: 'interventionPlan_4',
      otherPresentingConcerns: 'otherPresentingConcerns',
      treamentGoalsProgress: 'treamentGoalsProgress',
      clientTreatmentFeedbackOngoing: 'clientTreatmentFeedbackOngoing',
      recommendedTreatmentLength: 'recommendedTreatmentLength',
      groupPlan: 'groupPlan',
      presentingIssues: 'presentingIssues',
      predisposingFactors: 'predisposingFactors',
      precipitatingFactors: 'precipitatingFactors',
      perpetuatingFactors: 'perpetuatingFactors',
      protectiveFactorsPersonalResources: 'protectiveFactorsPersonalResources',
      otherRecommendations: 'otherRecommendations',
    },
    audInitialNote: {
      preferredName: 'preferredName',
      clientPronouns: 'clientPronouns',
      pronounsOtherSpecify: 'pronounsOtherSpecify',
      street: 'street',
      city: 'city',
      state: 'state',
      zipCode: 'zipCode',
      usedInterventions: 'usedInterventions',
      clientPhoneNumber: 'clientPhoneNumber',
      leaveVoicemails: 'leaveVoicemails',
      currentMedications: 'currentMedications',
      substanceAbuseDiagnosis: 'substanceAbuseDiagnosis',
      primaryDiagnosis: 'primaryDiagnosis',
      secondaryDiagnosis: 'secondaryDiagnosis',
      otherDiagnosis: 'otherDiagnosis',
      initialGoals: 'initialGoals',
      initialGoalsOther: 'initialGoalsOther',
      longTermGoals: 'longTermGoals',
      longTermGoalsOther: 'longTermGoalsOther',
      otherTherapyGoals: 'otherTherapyGoals',
      presentingConcern_2: 'presentingConcern_2',
      presentingConcernDescription_2: 'presentingConcernDescription_2',
      presentingConcernObjective_2: 'presentingConcernObjective_2',
      interventionPlan_2: 'interventionPlan_2',
      presentingConcern_3: 'presentingConcern_3',
      presentingConcernDescription_3: 'presentingConcernDescription_3',
      presentingConcernObjective_3: 'presentingConcernObjective_3',
      interventionPlan_3: 'interventionPlan_3',
      presentingConcern_4: 'presentingConcern_4',
      presentingConcernDescription_4: 'presentingConcernDescription_4',
      presentingConcernObjective_4: 'presentingConcernObjective_4',
      interventionPlan_4: 'interventionPlan_4',
      otherPresentingConcerns: 'otherPresentingConcerns',
      recommendedTreatmentLength: 'recommendedTreatmentLength',
      groupPlan: 'groupPlan',
      presentingIssues: 'presentingIssues',
      predisposingFactors: 'predisposingFactors',
      precipitatingFactors: 'precipitatingFactors',
      perpetuatingFactors: 'perpetuatingFactors',
      protectiveFactorsPersonalResources: 'protectiveFactorsPersonalResources',
      otherRecommendations: 'otherRecommendations',
    },
    audClientIntake: {
      preferredName: 'preferredName',
      preferredPronoun: 'clientPronouns',
      pronounOther: 'pronounsOtherSpecify',
      homeAddressStreet: 'street',
      homeAddressCity: 'city',
      homeAddressState: 'state',
      homeAddressZipCode: 'zipCode',
      primaryPhone: 'clientPhoneNumber',
      leaveVoicemailsPersonal: 'leaveVoicemails',
      listMedications: 'currentMedications',
    },
  },
  audSudExpansionInitialNote: {
    audSudExpansionClientIntake: {
      preferredName: 'preferredName',
      preferredPronoun: 'clientPronouns',
      pronounOther: 'pronounsOtherSpecify',
      homeAddressStreet: 'street',
      homeAddressCity: 'city',
      homeAddressState: 'state',
      homeAddressZipCode: 'zipCode',
      primaryPhone: 'clientPhoneNumber',
      leaveVoicemailsPersonal: 'leaveVoicemails',
      listMedications: 'currentMedications',
      gender: 'gender',
      specifyGender: 'genderOtherSpecify',
      raceEthnicityIdentity: 'ethnicity',
      ethnicityOther: 'clientRaceEthnicityOther',
      considerSpiritualReligious: 'religiousSpiritual',
      describeFaithBelief: 'religiousSpiritualDescription',
      relationshipStatus: 'maritalStatus',
      relationshipStatusOther: 'maritalStatusOther',
      relationshipDescription: 'relationshipDescription',
      widowHow: 'widowHow',
      divorce: 'divorce',
      gunAccess: 'firearmsAccess',
      bringsToProgram: 'chiefComplaint',
      alcoholDrinkQuantity: 'howManyDrinks',
      bingeDrinkingFrequency: 'howOftenMoreThan6Drinks',
      tobaccoUseFrequency: 'tobaccoFrequency',
      oftenECigaretteVape: 'vapeFrequency',
      oftenUseCannabis: 'cannabis',
      oftenUseIllegal: 'otherDrugs',
      oftenUsePrescription: 'prescriptionDrugs',
      symptomsExperienced: 'additionalSymptoms',
      previousPsychiatricDiagnoses: 'previousPsychiatricDiagnoses',
      previousPsychiatricDiagnosesList: 'previousPsychiatricDiagnosesList',
      currentlySeeingMentalHealthProfessional: 'currentMentalHealthServices',
      mentalHealthProfessionalInfo: 'currentProviders',
      previousSubstanceUseTreatment: 'substanceUseServicesHistory',
      previousMentalHealthServices: 'historyNonSUDMentalHealthServices',
      describePreviousTreatments: 'historyMentalHealthServicesDetails',
      attemptedSuicide: 'suicidalBehaviorHistory',
      attemptedSuicideWhen: 'suicidalBehaviorInfo',
      purposelySelfInjured: 'selfHarmingHistory',
      purposelySelfInjuredWhen: 'selfHarmingInfo',
      committedAssault: 'historyViolentBehaviors',
      committedAssaultWhen: 'violentBehaviorInfo',
      hospitalizedPsychiatricEmotionalProblems: 'historyPsychiatricHospitalization',
      hospitalizedPsychiatricEmotionalProblemsWhen: 'historyPsychiatricHospitalizationWhen',
      hospitalizedSubstanceAlcohol: 'historySubstanceHospitalization',
      hospitalizedSubstanceAlcoholWhen: 'historySubstanceHospitalizationWhen',
      experiencedDiscriminationOppression: 'discriminationOppressionHistory',
      medicalConditions: 'priorMedicalConditionsInfo',
      familyPsychiatricHistory: 'psychiatricProblemHistory',
      familyPsychiatricHistoryDescription: 'psychiatricProblemInfo',
      highestLevelEducation: 'highestEducation',
      occupation: 'educationEmploymentHistory',
      currentlyInvolvedLegal: 'legalInvolvement',
      currentlyInvolvedLegalDescribe: 'legalInvolvementInfo',
      listSupportSystem: 'socialSupports',
      initialAlcoholUseGoals: 'initialGoals',
      longTermAlcoholUseGoals: 'longTermGoals',
      initialAlcoholUseGoalsOther: 'initialGoalsOther',
      sexualOrientation: 'sexualOrientation',
      specifySexualOrientation: 'sexualOrientationSpecify',
      alcoholDrinkFrequency: 'alcoholFrequency',
      otherTherapyGoals: 'otherTherapyGoals',
      medicationForSUD: 'medicationForOpiodUse',
      naloxoneUsage: 'opiodReveralAgentAccess',
      audOrSudCare: 'alcoholOrSubstance',
      primaryCareReasons: 'primaryEnrollmentReason',
      primaryCareReasonsOther: 'primaryEnrollmentReasonOther',
      secondaryCareReasons: 'otherEnrollmentReason',
      secondaryCareReasonsOther: 'otherEnrollmentReasonOther',
    },
  },
  audSudExpansionSessionNote: {
    audSudExpansionSessionNote: {
      preferredName: 'preferredName',
      clientPronouns: 'clientPronouns',
      pronounsOtherSpecify: 'pronounsOtherSpecify',
      street: 'street',
      city: 'city',
      state: 'state',
      zipCode: 'zipCode',
      clientPhoneNumber: 'clientPhoneNumber',
      leaveVoicemails: 'leaveVoicemails',
      currentMedications: 'currentMedications',
      substanceAbuseDiagnosis: 'substanceAbuseDiagnosis',
      primaryDiagnosis: 'primaryDiagnosis',
      secondaryDiagnosis: 'secondaryDiagnosis',
      otherDiagnosis: 'otherDiagnosis',
      initialGoals: 'initialGoals',
      initialGoalsOther: 'initialGoalsOther',
      longTermGoals: 'longTermGoals',
      longTermGoalsOther: 'longTermGoalsOther',
      otherTherapyGoals: 'otherTherapyGoals',
      presentingConcern_2: 'presentingConcern_2',
      presentingConcernDescription_2: 'presentingConcernDescription_2',
      presentingConcernObjective_2: 'presentingConcernObjective_2',
      sudInterventionPlan: 'sudInterventionPlan',
      interventionPlan_2: 'interventionPlan_2',
      presentingConcern_3: 'presentingConcern_3',
      presentingConcernDescription_3: 'presentingConcernDescription_3',
      presentingConcernObjective_3: 'presentingConcernObjective_3',
      interventionPlan_3: 'interventionPlan_3',
      presentingConcern_4: 'presentingConcern_4',
      presentingConcernDescription_4: 'presentingConcernDescription_4',
      presentingConcernObjective_4: 'presentingConcernObjective_4',
      interventionPlan_4: 'interventionPlan_4',
      otherPresentingConcerns: 'otherPresentingConcerns',
      treamentGoalsProgress: 'treamentGoalsProgress',
      clientTreatmentFeedbackOngoing: 'clientTreatmentFeedbackOngoing',
      recommendedTreatmentLength: 'recommendedTreatmentLength',
      groupPlan: 'groupPlan',
      presentingIssues: 'presentingIssues',
      predisposingFactors: 'predisposingFactors',
      precipitatingFactors: 'precipitatingFactors',
      perpetuatingFactors: 'perpetuatingFactors',
      protectiveFactorsPersonalResources: 'protectiveFactorsPersonalResources',
      otherRecommendations: 'otherRecommendations',
      otherDetoxificationNeeds: 'otherDetoxificationNeeds',
    },
    audSudExpansionInitialNote: {
      preferredName: 'preferredName',
      clientPronouns: 'clientPronouns',
      pronounsOtherSpecify: 'pronounsOtherSpecify',
      street: 'street',
      city: 'city',
      state: 'state',
      zipCode: 'zipCode',
      clientPhoneNumber: 'clientPhoneNumber',
      leaveVoicemails: 'leaveVoicemails',
      currentMedications: 'currentMedications',
      substanceAbuseDiagnosis: 'substanceAbuseDiagnosis',
      primaryDiagnosis: 'primaryDiagnosis',
      secondaryDiagnosis: 'secondaryDiagnosis',
      otherDiagnosis: 'otherDiagnosis',
      initialGoals: 'initialGoals',
      initialGoalsOther: 'initialGoalsOther',
      longTermGoals: 'longTermGoals',
      longTermGoalsOther: 'longTermGoalsOther',
      otherTherapyGoals: 'otherTherapyGoals',
      presentingConcern_2: 'presentingConcern_2',
      presentingConcernDescription_2: 'presentingConcernDescription_2',
      presentingConcernObjective_2: 'presentingConcernObjective_2',
      interventionPlan_2: 'interventionPlan_2',
      presentingConcern_3: 'presentingConcern_3',
      presentingConcernDescription_3: 'presentingConcernDescription_3',
      presentingConcernObjective_3: 'presentingConcernObjective_3',
      interventionPlan_3: 'interventionPlan_3',
      presentingConcern_4: 'presentingConcern_4',
      presentingConcernDescription_4: 'presentingConcernDescription_4',
      presentingConcernObjective_4: 'presentingConcernObjective_4',
      interventionPlan_4: 'interventionPlan_4',
      otherPresentingConcerns: 'otherPresentingConcerns',
      recommendedTreatmentLength: 'recommendedTreatmentLength',
      groupPlan: 'groupPlan',
      presentingIssues: 'presentingIssues',
      predisposingFactors: 'predisposingFactors',
      precipitatingFactors: 'precipitatingFactors',
      perpetuatingFactors: 'perpetuatingFactors',
      protectiveFactorsPersonalResources: 'protectiveFactorsPersonalResources',
      otherRecommendations: 'otherRecommendations',
      otherDetoxificationNeeds: 'otherDetoxificationNeeds',
    },
    audSudExpansionClientIntake: {
      preferredName: 'preferredName',
      preferredPronoun: 'clientPronouns',
      pronounOther: 'pronounsOtherSpecify',
      homeAddressStreet: 'street',
      homeAddressCity: 'city',
      homeAddressState: 'state',
      homeAddressZipCode: 'zipCode',
      primaryPhone: 'clientPhoneNumber',
      leaveVoicemailsPersonal: 'leaveVoicemails',
      listMedications: 'currentMedications',
      medicationForSUD: 'medicationForOpiodUse',
      naloxoneUsage: 'opiodReveralAgentAccess',
    },
  },
  medsInitialNote: {
    medsClientIntake: {
      goalsWorkingPrescriber_48: 'treatmentGoals_303',
      problemBroughtSee_41: 'chiefComplaint_5',
      depressedMood_1: 'depressedMood_12',
      difficultySleepingIncludes_28: 'sleep_14',
      lossInterest_2: 'interest_16',
      beingExcessivelyAgitated_6: 'psychomotorAgitationRetardation_18',
      beingPhysicallySlowed_7: 'psychomotorAgitationRetardation_18_2',
      excessiveFatigue_8: 'energy_22',
      excessiveGuilt_10: 'guiltWorthlessness_20',
      feelingWorthless_9: 'guiltWorthlessness_20_2',
      excessiveFatigue_49: 'energy_22',
      difficultyThinkingConcentrating_26: ['concentration_24', 'concentration_61'],
      increasedAppetite_8: 'appetite_26',
      poorAppetiteUnintended_3: 'appetite_26_2',
      thoughtsWantingHurt_11: 'si_28',
      thoughtsWantingHurt_12: 'hi_30',
      feelingExcessivelyElevated_13: 'mood_33',
      irritability_27: ['irritability_35', 'irritability_59'],
      decreasedSleepSeveral_14: 'noSleep_37',
      talkativePressureKeep_15: 'speech_39',
      feelingInvincibleSpecial_25: 'grandiosity_41',
      racingThoughts_16: 'racingThoughts_43',
      riskyBehavior_18: 'goalOrientedRisky_45',
      distractibility_17: 'distractibilityConcentration_47',
      hearingVoicesOther_19: 'ah_50',
      seeingOtherPeople_20: 'vh_52',
      paranoiaExampleFeeling_21: 'paranoia_54',
      difficultySleepingIncludes_46: 'sleep_63',
      socialAnxiety_32: 'socialAnxiety_67',
      excessiveWorryAffects_33: 'worry_69',
      agoraphobiaInvolveFear_34: 'agoraphobia_71',
      panicAttacksInvolved_35: 'panicAttacks_73',
      compulsiveBehaviorsExcessive_40: 'compulsiveBehaviors_85',
      difficultyControllingImpulses_45: 'impulseControl_87',
      restrictingEating_41: 'restrictingEating_90',
      intenseFearGaining_42: 'intenseFearGaining_92',
      bingeEatingEating_43: 'bingeEating_94',
      purgingThrowingUp_44: 'bulimia_96',
      attemptedSuicide_72: 'suicideAttempts_101',
      attemptedSuicide_73: 'suicideAttempts_102',
      purposelySelfInjured_74: 'selfInjury_104',
      purposelySelfInjured_75: 'selfInjury_105',
      assaultedCommittedAct_76: 'violenceHistory_107',
      assaultedCommittedAct_77: 'violenceHistory_108',
      hospitalizedInpatientPartial_78: 'psychiatricHospitalizations_110',
      hospitalizedInpatientPartial_79: 'psychiatricHospitalizations_111',
      anxietyDisorder_1: 'anxietyDisorder_1',
      generalizedAnxietyDisorder_2: 'generalizedAnxietyDisorder_2',
      adjustmentDisorder_3: 'adjustmentDisorder_3',
      attentionDeficitHyperactivity_4: 'attentionDeficitHyperactivity_4',
      majorDepressiveDisorder_5: 'majorDepressiveDisorder_5',
      bipolarDisorder: 'bipolarDisorder',
      socialPhobia_6: 'socialPhobia_6',
      panicDisorder_7: 'panicDisorder_7',
      postTraumaticStress_8: 'postTraumaticStress_8',
      autisticDisorder_9: 'autisticDisorder_9',
      problemsRelationshipSpouse_10: 'problemsRelationshipSpouse_10',
      alcoholUseDisorder_11: 'alcoholUseDisorder_11',
      cannabisUseDisorder_12: 'cannabisUseDisorder_12',
      hallucinogenUseDisorder_13: 'hallucinogenUseDisorder_13',
      inhalantUseDisorder_14: 'inhalantUseDisorder_14',
      opioidUseDisorder_15: 'opioidUseDisorder_15',
      sedativeHypnoticAnxiolytic_16: 'sedativeHypnoticAnxiolytic_16',
      stimulantUseDisorder_17: 'stimulantUseDisorder_17',
      stimulantUseDisorder_18: 'stimulantUseDisorder_18',
      tobaccoUseDisorder_19: 'tobaccoUseDisorder_19',
      other_20: 'other_20',
      previousPsychiatricDiagnoses_47: 'previousPsychiatricDiagnoses_47',
      psychiatricMedicationsTaken_53: 'pastPsychiatricMedications_115',
      currentlySeeingMental_50: 'currentPreviousProviders_117',
      significantChildhoodAdolescence_29: 'detailsAboutChildhood_120',
      relationshipStatus_20: 'relationshipStatus_122',
      togetherPartnerSpouse_21: 'relationshipStatus_123',
      relationshipStatus_23: 'relationshipStatus_124',
      relationshipStatus_24: 'relationshipStatus_125',
      childrenGenderAge_25: 'children_126',
      numberPersonsLiving_26: 'livingSituation_127',
      anyoneParticularSupport_30: 'familySocialSupports_128',
      considerSpiritualReligious_18: 'considerSpiritualReligious_130',
      faithBelief_19: 'considerSpiritualReligious_131',
      highestLevelEducation_35: 'education_132',
      workingNow_36: 'workingNow_134',
      workingNow_37: 'workingNow_135',
      currentlyInvolvedLegal_94: 'currentlyInvolvedLegal_137',
      currentlyInvolvedLegal_95: 'currentlyInvolvedLegal_138',
      accessGun_80: 'accessGun_139',
      oftenDrinkContaining_86: 'clientDrinks_141',
      oftenDrinkContaining_85: 'clientDrinks_142',
      oftenDrinkContaining_87: 'clientBinges_143',
      oftenUseTobacco_88: 'clientUsesTobacco_144',
      oftenUsePrescription_89: 'clientUsesPrescription_145',
      oftenUseIllegal_90: 'clientUsesIllicit_146',
      oftenUseCannabis_91: 'clientUsesCannabis_147',
      historyPsychiatricEmotional_31: 'historyPsychiatricEmotional_152',
      historyPsychiatricEmotional_32: 'historyPsychiatricEmotional_153',
      levelPainToday_67: 'painLevel_159',
      levelPainToday_68: 'painLevel_160',
      pregnantPlanningBecome_66: 'pregnancy_162',
      currentlyTakingPrescription_60: 'currentlyTakingPrescription_164',
      currentlyTakingPrescription_62: 'currentMedications_165',
      medicationAllergiesAll_63: 'allergies_166',
      homeAddressStreet: 'clientLocationStreet',
      homeAddressCity: 'clientLocationCity',
      homeAddressState: 'clientLocationState',
      homeAddressZipCode: 'clientLocationZipCode',
      obsessiveThoughtsRecurrent_39: 'obsessiveThoughts_83',
      asthma_1: 'asthma_1',
      copd_2: 'copd_2',
      diabetes_3: 'diabetes_3',
      obesity_4: 'obesity_4',
      hypertension_5: 'hypertension_5',
      heartDisease_6: 'heartDisease_6',
      highCholesterol_7: 'highCholesterol_7',
      osteoporosis_8: 'osteoporosis_8',
      arthritis_9: 'arthritis_9',
      noChronicConditions_10: 'noChronicConditions_10',
      other_11: 'other_11',
      otherChronicMedicalConditions: 'medicalConditionsOther_47',
    },
    bcmClientIntake: {
      goalsWorkingPrescriber_48: 'treatmentGoals_303',
      problemBroughtSee_41: 'chiefComplaint_5',
      depressedMood_1: 'depressedMood_12',
      difficultySleepingIncludes_28: 'sleep_14',
      lossInterest_2: 'interest_16',
      beingExcessivelyAgitated_6: 'psychomotorAgitationRetardation_18',
      beingPhysicallySlowed_7: 'psychomotorAgitationRetardation_18_2',
      excessiveFatigue_8: 'energy_22',
      excessiveGuilt_10: 'guiltWorthlessness_20',
      feelingWorthless_9: 'guiltWorthlessness_20_2',
      excessiveFatigue_49: 'energy_22',
      difficultyThinkingConcentrating_26: ['concentration_24', 'concentration_61'],
      increasedAppetite_8: 'appetite_26',
      poorAppetiteUnintended_3: 'appetite_26_2',
      thoughtsWantingHurt_11: 'si_28',
      thoughtsWantingHurt_12: 'hi_30',
      feelingExcessivelyElevated_13: 'mood_33',
      irritability_27: ['irritability_35', 'irritability_59'],
      decreasedSleepSeveral_14: 'noSleep_37',
      talkativePressureKeep_15: 'speech_39',
      feelingInvincibleSpecial_25: 'grandiosity_41',
      racingThoughts_16: 'racingThoughts_43',
      riskyBehavior_18: 'goalOrientedRisky_45',
      distractibility_17: 'distractibilityConcentration_47',
      hearingVoicesOther_19: 'ah_50',
      seeingOtherPeople_20: 'vh_52',
      paranoiaExampleFeeling_21: 'paranoia_54',
      difficultySleepingIncludes_46: 'sleep_63',
      socialAnxiety_32: 'socialAnxiety_67',
      excessiveWorryAffects_33: 'worry_69',
      agoraphobiaInvolveFear_34: 'agoraphobia_71',
      panicAttacksInvolved_35: 'panicAttacks_73',
      compulsiveBehaviorsExcessive_40: 'compulsiveBehaviors_85',
      difficultyControllingImpulses_45: 'impulseControl_87',
      restrictingEating_41: 'restrictingEating_90',
      intenseFearGaining_42: 'intenseFearGaining_92',
      bingeEatingEating_43: 'bingeEating_94',
      purgingThrowingUp_44: 'bulimia_96',
      attemptedSuicide_72: 'suicideAttempts_101',
      attemptedSuicide_73: 'suicideAttempts_102',
      purposelySelfInjured_74: 'selfInjury_104',
      purposelySelfInjured_75: 'selfInjury_105',
      assaultedCommittedAct_76: 'violenceHistory_107',
      assaultedCommittedAct_77: 'violenceHistory_108',
      hospitalizedInpatientPartial_78: 'psychiatricHospitalizations_110',
      hospitalizedInpatientPartial_79: 'psychiatricHospitalizations_111',
      anxietyDisorder_1: 'anxietyDisorder_1',
      generalizedAnxietyDisorder_2: 'generalizedAnxietyDisorder_2',
      adjustmentDisorder_3: 'adjustmentDisorder_3',
      attentionDeficitHyperactivity_4: 'attentionDeficitHyperactivity_4',
      majorDepressiveDisorder_5: 'majorDepressiveDisorder_5',
      bipolarDisorder: 'bipolarDisorder',
      socialPhobia_6: 'socialPhobia_6',
      panicDisorder_7: 'panicDisorder_7',
      postTraumaticStress_8: 'postTraumaticStress_8',
      autisticDisorder_9: 'autisticDisorder_9',
      problemsRelationshipSpouse_10: 'problemsRelationshipSpouse_10',
      alcoholUseDisorder_11: 'alcoholUseDisorder_11',
      cannabisUseDisorder_12: 'cannabisUseDisorder_12',
      hallucinogenUseDisorder_13: 'hallucinogenUseDisorder_13',
      inhalantUseDisorder_14: 'inhalantUseDisorder_14',
      opioidUseDisorder_15: 'opioidUseDisorder_15',
      sedativeHypnoticAnxiolytic_16: 'sedativeHypnoticAnxiolytic_16',
      stimulantUseDisorder_17: 'stimulantUseDisorder_17',
      stimulantUseDisorder_18: 'stimulantUseDisorder_18',
      tobaccoUseDisorder_19: 'tobaccoUseDisorder_19',
      other_20: 'other_20',
      previousPsychiatricDiagnoses_47: 'previousPsychiatricDiagnoses_47',
      psychiatricMedicationsTaken_53: 'pastPsychiatricMedications_115',
      currentlySeeingMental_50: 'currentPreviousProviders_117',
      significantChildhoodAdolescence_29: 'detailsAboutChildhood_120',
      relationshipStatus_20: 'relationshipStatus_122',
      togetherPartnerSpouse_21: 'relationshipStatus_123',
      relationshipStatus_23: 'relationshipStatus_124',
      relationshipStatus_24: 'relationshipStatus_125',
      numberPersonsLiving_26: 'livingSituation_127',
      anyoneParticularSupport_30: 'familySocialSupports_128',
      considerSpiritualReligious_18: 'considerSpiritualReligious_130',
      faithBelief_19: 'considerSpiritualReligious_131',
      highestLevelEducation_35: 'education_132',
      workingNow_36: 'workingNow_134',
      occupation: 'workingNow_135',
      currentlyInvolvedLegal_94: 'currentlyInvolvedLegal_137',
      currentlyInvolvedLegal_95: 'currentlyInvolvedLegal_138',
      accessGun_80: 'accessGun_139',
      oftenDrinkContaining_86: 'clientDrinks_141',
      oftenDrinkContaining_85: 'clientDrinks_142',
      oftenDrinkContaining_87: 'clientBinges_143',
      oftenUseTobacco_88: 'clientUsesTobacco_144',
      oftenUsePrescription_89: 'clientUsesPrescription_145',
      oftenUseIllegal_90: 'clientUsesIllicit_146',
      oftenUseCannabis_91: 'clientUsesCannabis_147',
      historyPsychiatricEmotional_31: 'historyPsychiatricEmotional_152',
      historyPsychiatricEmotional_32: 'historyPsychiatricEmotional_153',
      levelPainToday_67: 'painLevel_159',
      levelPainToday_68: 'painLevel_160',
      pregnantPlanningBecome_66: 'pregnancy_162',
      currentlyTakingPrescription_60: 'currentlyTakingPrescription_164',
      currentlyTakingPrescription_62: 'currentMedications_165',
      medicationAllergiesAll_63: 'allergies_166',
      homeAddressStreet: 'clientLocationStreet',
      homeAddressCity: 'clientLocationCity',
      homeAddressState: 'clientLocationState',
      homeAddressZipCode: 'clientLocationZipCode',
      obsessiveThoughtsRecurrent_39: 'obsessiveThoughts_83',
      asthma_1: 'asthma_1',
      copd_2: 'copd_2',
      diabetes_3: 'diabetes_3',
      obesity_4: 'obesity_4',
      hypertension_5: 'hypertension_5',
      heartDisease_6: 'heartDisease_6',
      highCholesterol_7: 'highCholesterol_7',
      osteoporosis_8: 'osteoporosis_8',
      arthritis_9: 'arthritis_9',
      noChronicConditions_10: 'noChronicConditions_10',
      other_11: 'other_11',
      otherChronicMedicalConditions: 'medicalConditionsOther_47',
    },
  },
  bcmInitialNote: {
    medsClientIntake: {
      goalsWorkingPrescriber_48: 'treatmentGoals_303',
      problemBroughtSee_41: 'chiefComplaint_5',
      depressedMood_1: 'depressedMood_12',
      difficultySleepingIncludes_28: 'sleep_14',
      lossInterest_2: 'interest_16',
      beingExcessivelyAgitated_6: 'psychomotorAgitationRetardation_18',
      beingPhysicallySlowed_7: 'psychomotorAgitationRetardation_18_2',
      excessiveFatigue_8: 'energy_22',
      excessiveGuilt_10: 'guiltWorthlessness_20',
      feelingWorthless_9: 'guiltWorthlessness_20_2',
      excessiveFatigue_49: 'energy_22',
      difficultyThinkingConcentrating_26: ['concentration_24', 'concentration_61'],
      increasedAppetite_8: 'appetite_26',
      poorAppetiteUnintended_3: 'appetite_26_2',
      thoughtsWantingHurt_11: 'si_28',
      thoughtsWantingHurt_12: 'hi_30',
      feelingExcessivelyElevated_13: 'mood_33',
      irritability_27: ['irritability_35', 'irritability_59'],
      decreasedSleepSeveral_14: 'noSleep_37',
      talkativePressureKeep_15: 'speech_39',
      feelingInvincibleSpecial_25: 'grandiosity_41',
      racingThoughts_16: 'racingThoughts_43',
      riskyBehavior_18: 'goalOrientedRisky_45',
      distractibility_17: 'distractibilityConcentration_47',
      hearingVoicesOther_19: 'ah_50',
      seeingOtherPeople_20: 'vh_52',
      paranoiaExampleFeeling_21: 'paranoia_54',
      difficultySleepingIncludes_46: 'sleep_63',
      socialAnxiety_32: 'socialAnxiety_67',
      excessiveWorryAffects_33: 'worry_69',
      agoraphobiaInvolveFear_34: 'agoraphobia_71',
      panicAttacksInvolved_35: 'panicAttacks_73',
      compulsiveBehaviorsExcessive_40: 'compulsiveBehaviors_85',
      difficultyControllingImpulses_45: 'impulseControl_87',
      restrictingEating_41: 'restrictingEating_90',
      intenseFearGaining_42: 'intenseFearGaining_92',
      bingeEatingEating_43: 'bingeEating_94',
      purgingThrowingUp_44: 'bulimia_96',
      attemptedSuicide_72: 'suicideAttempts_101',
      attemptedSuicide_73: 'suicideAttempts_102',
      purposelySelfInjured_74: 'selfInjury_104',
      purposelySelfInjured_75: 'selfInjury_105',
      assaultedCommittedAct_76: 'violenceHistory_107',
      assaultedCommittedAct_77: 'violenceHistory_108',
      hospitalizedInpatientPartial_78: 'psychiatricHospitalizations_110',
      hospitalizedInpatientPartial_79: 'psychiatricHospitalizations_111',
      anxietyDisorder_1: 'anxietyDisorder_1',
      generalizedAnxietyDisorder_2: 'generalizedAnxietyDisorder_2',
      adjustmentDisorder_3: 'adjustmentDisorder_3',
      attentionDeficitHyperactivity_4: 'attentionDeficitHyperactivity_4',
      majorDepressiveDisorder_5: 'majorDepressiveDisorder_5',
      bipolarDisorder: 'bipolarDisorder',
      socialPhobia_6: 'socialPhobia_6',
      panicDisorder_7: 'panicDisorder_7',
      postTraumaticStress_8: 'postTraumaticStress_8',
      autisticDisorder_9: 'autisticDisorder_9',
      problemsRelationshipSpouse_10: 'problemsRelationshipSpouse_10',
      alcoholUseDisorder_11: 'alcoholUseDisorder_11',
      cannabisUseDisorder_12: 'cannabisUseDisorder_12',
      hallucinogenUseDisorder_13: 'hallucinogenUseDisorder_13',
      inhalantUseDisorder_14: 'inhalantUseDisorder_14',
      opioidUseDisorder_15: 'opioidUseDisorder_15',
      sedativeHypnoticAnxiolytic_16: 'sedativeHypnoticAnxiolytic_16',
      stimulantUseDisorder_17: 'stimulantUseDisorder_17',
      stimulantUseDisorder_18: 'stimulantUseDisorder_18',
      tobaccoUseDisorder_19: 'tobaccoUseDisorder_19',
      other_20: 'other_20',
      previousPsychiatricDiagnoses_47: 'previousPsychiatricDiagnoses_47',
      psychiatricMedicationsTaken_53: 'pastPsychiatricMedications_115',
      currentlySeeingMental_50: 'currentPreviousProviders_117',
      significantChildhoodAdolescence_29: 'detailsAboutChildhood_120',
      relationshipStatus_20: 'relationshipStatus_122',
      togetherPartnerSpouse_21: 'relationshipStatus_123',
      relationshipStatus_23: 'relationshipStatus_124',
      relationshipStatus_24: 'relationshipStatus_125',
      numberPersonsLiving_26: 'livingSituation_127',
      anyoneParticularSupport_30: 'familySocialSupports_128',
      considerSpiritualReligious_18: 'considerSpiritualReligious_130',
      faithBelief_19: 'considerSpiritualReligious_131',
      highestLevelEducation_35: 'education_132',
      workingNow_36: 'workingNow_134',
      workingNow_37: 'workingNow_135',
      currentlyInvolvedLegal_94: 'currentlyInvolvedLegal_137',
      currentlyInvolvedLegal_95: 'currentlyInvolvedLegal_138',
      accessGun_80: 'accessGun_139',
      oftenDrinkContaining_86: 'clientDrinks_141',
      oftenDrinkContaining_85: 'clientDrinks_142',
      oftenDrinkContaining_87: 'clientBinges_143',
      oftenUseTobacco_88: 'clientUsesTobacco_144',
      oftenUsePrescription_89: 'clientUsesPrescription_145',
      oftenUseIllegal_90: 'clientUsesIllicit_146',
      oftenUseCannabis_91: 'clientUsesCannabis_147',
      historyPsychiatricEmotional_31: 'historyPsychiatricEmotional_152',
      historyPsychiatricEmotional_32: 'historyPsychiatricEmotional_153',
      levelPainToday_67: 'painLevel_159',
      levelPainToday_68: 'painLevel_160',
      pregnantPlanningBecome_66: 'pregnancy_162',
      currentlyTakingPrescription_60: 'currentlyTakingPrescription_164',
      currentlyTakingPrescription_62: 'currentMedications_165',
      medicationAllergiesAll_63: 'allergies_166',
      homeAddressStreet: 'clientLocationStreet',
      homeAddressCity: 'clientLocationCity',
      homeAddressState: 'clientLocationState',
      homeAddressZipCode: 'clientLocationZipCode',
      obsessiveThoughtsRecurrent_39: 'obsessiveThoughts_83',
      asthma_1: 'asthma_1',
      copd_2: 'copd_2',
      diabetes_3: 'diabetes_3',
      obesity_4: 'obesity_4',
      hypertension_5: 'hypertension_5',
      heartDisease_6: 'heartDisease_6',
      highCholesterol_7: 'highCholesterol_7',
      osteoporosis_8: 'osteoporosis_8',
      arthritis_9: 'arthritis_9',
      noChronicConditions_10: 'noChronicConditions_10',
      other_11: 'other_11',
      otherChronicMedicalConditions: 'medicalConditionsOther_47',
    },
    bcmClientIntake: {
      preferredName_15: 'preferredName',
      preferredPronoun: 'preferredPronoun',
      pronounOther: 'pronounOther',
      homeAddressStreet: 'clientLocationStreet',
      homeAddressCity: 'clientLocationCity',
      homeAddressState: 'clientLocationState',
      homeAddressZipCode: 'clientLocationZipCode',
      primaryPhone: 'phoneNumber',
      leaveVoicemailsPersonal_8: 'permissionToLeaveVoicemails',
      gender_81: 'gender',
      raceEthnicityCheck_16: 'ethnicity',
      relationshipStatus_20: 'relationshipStatus',
      workingNow_36: 'employedStatus',
      occupation: 'occupation',
      seekingMentalHealthLeave: 'seekingLeave',
      alreadyMentalHealthLeave: 'initiatedLeave',
      symptoms_function: 'symptomsPreventFunctioning',
      primaryCareDoctor: 'havePrimaryCareDoctor',
      lastTimePrimaryCareDoctor: 'sawPrimaryCare_352',
      goalsForRecovery: 'goalsForRecovery',
      goalsWorkingPrescriber_48: 'treatmentGoals_303',
      problemBroughtSee_41: 'chiefComplaint_5',
      depressedMood_1: 'depressedMood_12',
      difficultySleepingIncludes_28: 'sleep_14',
      lossInterest_2: 'interest_16',
      beingExcessivelyAgitated_6: 'psychomotorAgitationRetardation_18',
      beingPhysicallySlowed_7: 'psychomotorAgitationRetardation_18_2',
      excessiveFatigue_8: 'energy_22',
      excessiveGuilt_10: 'guiltWorthlessness_20',
      feelingWorthless_9: 'guiltWorthlessness_20_2',
      excessiveFatigue_49: 'energy_22',
      difficultyThinkingConcentrating_26: ['concentration_24', 'concentration_61'],
      increasedAppetite_8: 'appetite_26',
      poorAppetiteUnintended_3: 'appetite_26_2',
      feelingExcessivelyElevated_13: 'mood_33',
      irritability_27: ['irritability_35', 'irritability_59'],
      decreasedSleepSeveral_14: 'noSleep_37',
      talkativePressureKeep_15: 'speech_39',
      feelingInvincibleSpecial_25: 'grandiosity_41',
      racingThoughts_16: 'racingThoughts_43',
      riskyBehavior_18: 'goalOrientedRisky_45',
      distractibility_17: 'distractibilityConcentration_47',
      hearingVoicesOther_19: 'ah_50',
      seeingOtherPeople_20: 'vh_52',
      paranoiaExampleFeeling_21: 'paranoia_54',
      difficultySleepingIncludes_46: 'sleep_63',
      socialAnxiety_32: 'socialAnxiety_67',
      excessiveWorryAffects_33: 'worry_69',
      agoraphobiaInvolveFear_34: 'agoraphobia_71',
      panicAttacksInvolved_35: 'panicAttacks_73',
      compulsiveBehaviorsExcessive_40: 'compulsiveBehaviors_85',
      difficultyControllingImpulses_45: 'impulseControl_87',
      restrictingEating_41: 'restrictingEating_90',
      intenseFearGaining_42: 'intenseFearGaining_92',
      bingeEatingEating_43: 'bingeEating_94',
      purgingThrowingUp_44: 'bulimia_96',
      attemptedSuicide_72: 'suicideAttempts_101',
      attemptedSuicide_73: 'suicideAttempts_102',
      purposelySelfInjured_74: 'selfInjury_104',
      purposelySelfInjured_75: 'selfInjury_105',
      assaultedCommittedAct_76: 'violenceHistory_107',
      assaultedCommittedAct_77: 'violenceHistory_108',
      hospitalizedInpatientPartial_78: 'psychiatricHospitalizations_110',
      hospitalizedInpatientPartial_79: 'psychiatricHospitalizations_111',
      previousPsychiatricDiagnoses_46: 'previousPsychiatricDiagnoses_46',
      previousPsychiatricDiagnoses_47: 'previousPsychiatricDiagnoses_47',
      currentlySeeingMental_50: 'currentPreviousProviders_117',
      numberPersonsLiving_26: 'livingSituation_127',
      anyoneParticularSupport_30: 'familySocialSupports_128',
      considerSpiritualReligious_18: 'considerSpiritualReligious_130',
      faithBelief_19: 'considerSpiritualReligious_131',
      highestLevelEducation_35: 'education_132',
      currentlyInvolvedLegal_94: 'currentlyInvolvedLegal_137',
      currentlyInvolvedLegal_95: 'currentlyInvolvedLegal_138',
      accessGun_80: 'accessGun_139',
      oftenDrinkContaining_85: 'clientDrinks_142',
      oftenDrinkContaining_86: 'clientDrinks_141',
      oftenDrinkContaining_87: 'clientBinges_143',
      oftenUseTobacco_88: 'clientUsesTobacco_144',
      oftenUsePrescription_89: 'clientUsesPrescription_145',
      oftenUseIllegal_90: 'clientUsesIllicit_146',
      oftenUseCannabis_91: 'clientUsesCannabis_147',
      historyPsychiatricEmotional_31: 'historyPsychiatricEmotional_152',
      historyPsychiatricEmotional_32: 'historyPsychiatricEmotional_153',
      levelPainToday_67: 'painLevel_159',
      levelPainToday_68: 'painLevel_160',
      pregnantPlanningBecome_66: 'pregnancy_162',
      currentlyTakingPrescription_60: 'currentlyTakingPrescription_164',
      currentlyTakingPrescription_62: 'currentMedications_165',
      medicationAllergiesAll_63: 'allergies_166',
      obsessiveThoughtsRecurrent_39: 'obsessiveThoughts_83',
      chronicOngoingMedical_64: 'chronicOngoingMedical_156',
      otherChronicMedicalConditions: 'medicalConditionsOther_47',
      sideEffectsAffected_54: 'sideEffectConcerns_167',
      functions_incapacity: 'areasOfFunctioningIncapacity',
    },
  },
  medsSessionNote: {
    medsSessionNote: {
      treatmentGoals_151: 'treatmentGoals_151',
      depression_159: 'depression_159',
      depression_160: 'depression_160',
      depression_161: 'depression_161',
      depression_162: 'depression_162',
      depression_163: 'depression_163',
      depression_164: 'depression_164',
      depression_165: 'depression_165',
      anxiety_166: 'anxiety_166',
      anxiety_167: 'anxiety_167',
      anxiety_168: 'anxiety_168',
      anxiety_169: 'anxiety_169',
      anxiety_170: 'anxiety_170',
      anxiety_171: 'anxiety_171',
      anxiety_172: 'anxiety_172',
      otherDiagnoses_173: 'otherDiagnoses_173',
      otherDiagnoses_174: 'otherDiagnoses_174',
      otherDiagnoses_175: 'otherDiagnoses_175',
      otherDiagnoses_176: 'otherDiagnoses_176',
      otherDiagnoses_177: 'otherDiagnoses_177',
      otherDiagnoses_178: 'otherDiagnoses_178',
      sleepIssues_179: 'sleepIssues_179',
      sleepIssues_180: 'sleepIssues_180',
      sleepIssues_181: 'sleepIssues_181',
      sleepIssues_182: 'sleepIssues_182',
      sleepIssues_183: 'sleepIssues_183',
      sleepIssues_184: 'sleepIssues_184',
      sleepIssues_186: 'sleepIssues_186',
      currentLevelsUse_188: 'currentLevelsUse_188',
      recommendationsSubstances_189: 'recommendationsSubstances_189',
      labs_191: 'labs_191',
      acuteMedicalConditions_192: 'acuteMedicalConditions_192',
      chronicMedicationConditions_193: 'chronicMedicationConditions_193',
      sawPrimaryCare_194: 'sawPrimaryCare_194',
      therapy_196: 'therapy_196',
      roi_197: 'roi_197',
      bio_145: 'bio_145',
      psycho_146: 'psycho_146',
      social_147: 'social_147',
      summary_148: 'summary_148',
      labsImaging_140: 'labsImaging_140',
      identifyingInformation_3: 'identifyingInformation_3',
      phq9Gad7Pcl_142: 'phq9Gad7Pcl_142',
      disabilityPaperwork: 'disabilityPaperwork',
      previousTherapistCommunication: 'previousTherapistCommunication',
      receivedROI: 'receivedROI',
      clinicalChange: 'clinicalChange',
    },
    medsInitialNote: {
      treatmentGoals_303: 'treatmentGoals_151',
      depression_317: 'depression_159',
      depression_318: 'depression_160',
      depression_319: 'depression_161',
      depression_320: 'depression_162',
      depression_321: 'depression_163',
      depression_322: 'depression_164',
      depression_323: 'depression_165',
      anxiety_324: 'anxiety_166',
      anxiety_325: 'anxiety_167',
      anxiety_326: 'anxiety_168',
      anxiety_327: 'anxiety_169',
      anxiety_328: 'anxiety_170',
      anxiety_329: 'anxiety_171',
      anxiety_330: 'anxiety_172',
      otherDiagnoses_331: 'otherDiagnoses_173',
      otherDiagnoses_332: 'otherDiagnoses_174',
      otherDiagnoses_333: 'otherDiagnoses_175',
      otherDiagnoses_334: 'otherDiagnoses_176',
      otherDiagnoses_335: 'otherDiagnoses_177',
      otherDiagnoses_336: 'otherDiagnoses_178',
      sleepIssues_337: 'sleepIssues_179',
      sleepIssues_338: 'sleepIssues_180',
      sleepIssues_339: 'sleepIssues_181',
      sleepIssues_340: 'sleepIssues_182',
      sleepIssues_341: 'sleepIssues_183',
      sleepIssues_342: 'sleepIssues_184',
      sleepIssues_344: 'sleepIssues_186',
      substanceUse_346: 'currentLevelsUse_188',
      recommendationsSubstances_347: 'recommendationsSubstances_189',
      labs_349: 'labs_191',
      generalHealthInformation_350: 'acuteMedicalConditions_192',
      chronicMedicationConditions_351: 'chronicMedicationConditions_193',
      sawPrimaryCare_352: 'sawPrimaryCare_194',
      therapy_354: 'therapy_196',
      roi_355: 'roi_197',
      bioGeneticDevelopmental_298: 'bio_145',
      psychoCognitiveStyle_299: 'psycho_146',
      socialRelationshipsFamily_300: 'social_147',
      summary_301: 'summary_148',
      labsImaging_290: 'labsImaging_140',
      identifyingInformation_3: 'identifyingInformation_3',
    },
    medsClientIntake: {
      problemBroughtSee_41: 'ccFromIntake_6',
      medicationAllergiesAll_63: 'allergies_17',
      bioGeneticDevelopmental_298: 'bio_145',
      psychoCognitiveStyle_299: 'psycho_146',
      socialRelationshipsFamily_300: 'social_147',
      summary_301: 'summary_148',
      treatmentGoals_303: 'treatmentGoals_151',
    },
    bcmSessionNote: {
      treatmentGoals_151: 'treatmentGoals_151',
      depression_159: 'depression_159',
      depression_160: 'depression_160',
      depression_161: 'depression_161',
      depression_162: 'depression_162',
      depression_163: 'depression_163',
      depression_164: 'depression_164',
      anxiety_166: 'anxiety_166',
      anxiety_167: 'anxiety_167',
      anxiety_168: 'anxiety_168',
      anxiety_169: 'anxiety_169',
      anxiety_170: 'anxiety_170',
      anxiety_171: 'anxiety_171',
      anxiety_172: 'anxiety_172',
      otherDiagnoses_173: 'otherDiagnoses_173',
      otherDiagnoses_174: 'otherDiagnoses_174',
      otherDiagnoses_175: 'otherDiagnoses_175',
      otherDiagnoses_176: 'otherDiagnoses_176',
      otherDiagnoses_177: 'otherDiagnoses_177',
      otherDiagnoses_178: 'otherDiagnoses_178',
      sleepIssues_179: 'sleepIssues_179',
      sleepIssues_180: 'sleepIssues_180',
      sleepIssues_181: 'sleepIssues_181',
      sleepIssues_182: 'sleepIssues_182',
      sleepIssues_183: 'sleepIssues_183',
      sleepIssues_184: 'sleepIssues_184',
      sleepIssues_186: 'sleepIssues_186',
      currentLevelsUse_188: 'currentLevelsUse_188',
      recommendationsSubstances_189: 'recommendationsSubstances_189',
      labs_191: 'labs_191',
      acuteMedicalConditions_192: 'acuteMedicalConditions_192',
      chronicMedicationConditions_193: 'chronicMedicationConditions_193',
      sawPrimaryCare_194: 'sawPrimaryCare_194',
      roi_197: 'roi_197',
      bio_145: 'bio_145',
      psycho_146: 'psycho_146',
      social_147: 'social_147',
      summary_148: 'summary_148',
      labsImaging_140: 'labsImaging_140',
      identifyingInformation_3: 'identifyingInformation_3',
      phq9Gad7Pcl_142: 'phq9Gad7Pcl_142',
      disabilityPaperwork: 'disabilityPaperwork',
      previousTherapistCommunication: 'previousTherapistCommunication',
      receivedROI: 'receivedROI',
      clinicalChange: 'clinicalChange',
    },
    bcmInitialNote: {
      treatmentGoals_303: 'treatmentGoals_151',
      depression_317: 'depression_159',
      depression_318: 'depression_160',
      depression_319: 'depression_161',
      depression_320: 'depression_162',
      depression_321: 'depression_163',
      depression_322: 'depression_164',
      anxiety_324: 'anxiety_166',
      anxiety_325: 'anxiety_167',
      anxiety_326: 'anxiety_168',
      anxiety_327: 'anxiety_169',
      anxiety_328: 'anxiety_170',
      anxiety_329: 'anxiety_171',
      anxiety_330: 'anxiety_172',
      otherDiagnoses_331: 'otherDiagnoses_173',
      otherDiagnoses_332: 'otherDiagnoses_174',
      otherDiagnoses_333: 'otherDiagnoses_175',
      otherDiagnoses_334: 'otherDiagnoses_176',
      otherDiagnoses_335: 'otherDiagnoses_177',
      otherDiagnoses_336: 'otherDiagnoses_178',
      sleepIssues_337: 'sleepIssues_179',
      sleepIssues_338: 'sleepIssues_180',
      sleepIssues_339: 'sleepIssues_181',
      sleepIssues_340: 'sleepIssues_182',
      sleepIssues_341: 'sleepIssues_183',
      sleepIssues_342: 'sleepIssues_184',
      sleepIssues_344: 'sleepIssues_186',
      substanceUse_346: 'currentLevelsUse_188',
      recommendationsSubstances_347: 'recommendationsSubstances_189',
      labs_349: 'labs_191',
      generalHealthInformation_350: 'acuteMedicalConditions_192',
      chronicMedicationConditions_351: 'chronicMedicationConditions_193',
      sawPrimaryCare_352: 'sawPrimaryCare_194',
      therapy_354: 'therapy_196',
      roi_355: 'roi_197',
      bioGeneticDevelopmental_298: 'bio_145',
      psychoCognitiveStyle_299: 'psycho_146',
      socialRelationshipsFamily_300: 'social_147',
      summary_301: 'summary_148',
      labsImaging_290: 'labsImaging_140',
      identifyingInformation_3: 'identifyingInformation_3',
    },
    bcmClientIntake: {
      problemBroughtSee_41: 'ccFromIntake_6',
      medicationAllergiesAll_63: 'allergies_17',
      bioGeneticDevelopmental_298: 'bio_145',
      psychoCognitiveStyle_299: 'psycho_146',
      socialRelationshipsFamily_300: 'social_147',
      summary_301: 'summary_148',
      treatmentGoals_303: 'treatmentGoals_151',
    },
  },
  bcmSessionNote: {
    medsSessionNote: {
      treatmentGoals_151: 'treatmentGoals_151',
      depression_159: 'depression_159',
      depression_160: 'depression_160',
      depression_161: 'depression_161',
      depression_162: 'depression_162',
      depression_163: 'depression_163',
      depression_164: 'depression_164',
      anxiety_166: 'anxiety_166',
      anxiety_167: 'anxiety_167',
      anxiety_168: 'anxiety_168',
      anxiety_169: 'anxiety_169',
      anxiety_170: 'anxiety_170',
      anxiety_171: 'anxiety_171',
      anxiety_172: 'anxiety_172',
      otherDiagnoses_173: 'otherDiagnoses_173',
      otherDiagnoses_174: 'otherDiagnoses_174',
      otherDiagnoses_175: 'otherDiagnoses_175',
      otherDiagnoses_176: 'otherDiagnoses_176',
      otherDiagnoses_177: 'otherDiagnoses_177',
      otherDiagnoses_178: 'otherDiagnoses_178',
      sleepIssues_179: 'sleepIssues_179',
      sleepIssues_180: 'sleepIssues_180',
      sleepIssues_181: 'sleepIssues_181',
      sleepIssues_182: 'sleepIssues_182',
      sleepIssues_183: 'sleepIssues_183',
      sleepIssues_184: 'sleepIssues_184',
      sleepIssues_186: 'sleepIssues_186',
      currentLevelsUse_188: 'currentLevelsUse_188',
      recommendationsSubstances_189: 'recommendationsSubstances_189',
      labs_191: 'labs_191',
      acuteMedicalConditions_192: 'acuteMedicalConditions_192',
      chronicMedicationConditions_193: 'chronicMedicationConditions_193',
      sawPrimaryCare_194: 'sawPrimaryCare_194',
      roi_197: 'roi_197',
      bio_145: 'bio_145',
      psycho_146: 'psycho_146',
      social_147: 'social_147',
      summary_148: 'summary_148',
      labsImaging_140: 'labsImaging_140',
      identifyingInformation_3: 'identifyingInformation_3',
      phq9Gad7Pcl_142: 'phq9Gad7Pcl_142',
      disabilityPaperwork: 'disabilityPaperwork',
      previousTherapistCommunication: 'previousTherapistCommunication',
      receivedROI: 'receivedROI',
      clinicalChange: 'clinicalChange',
    },
    medsInitialNote: {
      treatmentGoals_303: 'treatmentGoals_151',
      depression_317: 'depression_159',
      depression_318: 'depression_160',
      depression_319: 'depression_161',
      depression_320: 'depression_162',
      depression_321: 'depression_163',
      depression_322: 'depression_164',
      anxiety_324: 'anxiety_166',
      anxiety_325: 'anxiety_167',
      anxiety_326: 'anxiety_168',
      anxiety_327: 'anxiety_169',
      anxiety_328: 'anxiety_170',
      anxiety_329: 'anxiety_171',
      anxiety_330: 'anxiety_172',
      otherDiagnoses_331: 'otherDiagnoses_173',
      otherDiagnoses_332: 'otherDiagnoses_174',
      otherDiagnoses_333: 'otherDiagnoses_175',
      otherDiagnoses_334: 'otherDiagnoses_176',
      otherDiagnoses_335: 'otherDiagnoses_177',
      otherDiagnoses_336: 'otherDiagnoses_178',
      sleepIssues_337: 'sleepIssues_179',
      sleepIssues_338: 'sleepIssues_180',
      sleepIssues_339: 'sleepIssues_181',
      sleepIssues_340: 'sleepIssues_182',
      sleepIssues_341: 'sleepIssues_183',
      sleepIssues_342: 'sleepIssues_184',
      sleepIssues_344: 'sleepIssues_186',
      substanceUse_346: 'currentLevelsUse_188',
      recommendationsSubstances_347: 'recommendationsSubstances_189',
      labs_349: 'labs_191',
      generalHealthInformation_350: 'acuteMedicalConditions_192',
      chronicMedicationConditions_351: 'chronicMedicationConditions_193',
      sawPrimaryCare_352: 'sawPrimaryCare_194',
      roi_355: 'roi_197',
      bioGeneticDevelopmental_298: 'bio_145',
      psychoCognitiveStyle_299: 'psycho_146',
      socialRelationshipsFamily_300: 'social_147',
      summary_301: 'summary_148',
      labsImaging_290: 'labsImaging_140',
      identifyingInformation_3: 'identifyingInformation_3',
    },
    medsClientIntake: {
      problemBroughtSee_41: 'ccFromIntake_6',
      medicationAllergiesAll_63: 'allergies_17',
      bioGeneticDevelopmental_298: 'bio_145',
      psychoCognitiveStyle_299: 'psycho_146',
      socialRelationshipsFamily_300: 'social_147',
      summary_301: 'summary_148',
      treatmentGoals_303: 'treatmentGoals_151',
    },
    bcmSessionNote: {
      treatmentGoals_151: 'treatmentGoals_151',
      depression_159: 'depression_159',
      depression_160: 'depression_160',
      depression_161: 'depression_161',
      depression_162: 'depression_162',
      depression_163: 'depression_163',
      depression_164: 'depression_164',
      anxiety_166: 'anxiety_166',
      anxiety_167: 'anxiety_167',
      anxiety_168: 'anxiety_168',
      anxiety_169: 'anxiety_169',
      anxiety_170: 'anxiety_170',
      anxiety_171: 'anxiety_171',
      anxiety_172: 'anxiety_172',
      otherDiagnoses_173: 'otherDiagnoses_173',
      otherDiagnoses_174: 'otherDiagnoses_174',
      otherDiagnoses_175: 'otherDiagnoses_175',
      otherDiagnoses_176: 'otherDiagnoses_176',
      otherDiagnoses_177: 'otherDiagnoses_177',
      otherDiagnoses_178: 'otherDiagnoses_178',
      sleepIssues_179: 'sleepIssues_179',
      sleepIssues_180: 'sleepIssues_180',
      sleepIssues_181: 'sleepIssues_181',
      sleepIssues_182: 'sleepIssues_182',
      sleepIssues_183: 'sleepIssues_183',
      sleepIssues_184: 'sleepIssues_184',
      sleepIssues_186: 'sleepIssues_186',
      currentLevelsUse_188: 'currentLevelsUse_188',
      recommendationsSubstances_189: 'recommendationsSubstances_189',
      labs_191: 'labs_191',
      acuteMedicalConditions_192: 'acuteMedicalConditions_192',
      chronicMedicationConditions_193: 'chronicMedicationConditions_193',
      doTheyHavePrimaryCareDoctor: 'doTheyHavePrimaryCareDoctor',
      sawPrimaryCare_194: 'sawPrimaryCare_194',
      roi_197: 'roi_197',
      bio_145: 'bio_145',
      psycho_146: 'psycho_146',
      social_147: 'social_147',
      summary_148: 'summary_148',
      labsImaging_140: 'labsImaging_140',
      identifyingInformation_3: 'identifyingInformation_3',
      phq9Gad7Pcl_142: 'phq9Gad7Pcl_142',
      disabilityPaperwork: 'disabilityPaperwork',
      previousTherapistCommunication: 'previousTherapistCommunication',
      receivedROI: 'receivedROI',
      clinicalChange: 'clinicalChange',
      allergies_17: 'allergies_17',
      currentMedications_16: 'currentMedications_16',
      primaryDiagnosis_153: 'primaryDiagnosis_153',
      secondaryDiagnosis_154: 'secondaryDiagnosis_154',
      coOccurringSubstance_155: 'coOccurringSubstance_155',
      street: 'street',
      city: 'city',
      state: 'state',
      zip: 'zip',
      differentialConsiderations_156: 'differentialConsiderations_156',
      clinicalLeaveEvaluation: 'clinicalLeaveEvaluation',
      symptomsAffectingFunctioning: 'symptomsAffectingFunctioning',
      areasOfFunctioningAffected: 'areasOfFunctioningAffected',
      goalsForRecovery: 'goalsForRecovery',
      moreDetailsAroundCarePlan: 'moreDetailsAroundCarePlan',
      leaveStartDate: 'leaveStartDate',
      moreDetailsAroundLeave: 'moreDetailsAroundLeave',
    },
    bcmInitialNote: {
      treatmentGoals_303: 'treatmentGoals_151',
      depression_317: 'depression_159',
      depression_318: 'depression_160',
      depression_319: 'depression_161',
      depression_320: 'depression_162',
      depression_321: 'depression_163',
      depression_322: 'depression_164',
      anxiety_324: 'anxiety_166',
      anxiety_325: 'anxiety_167',
      anxiety_326: 'anxiety_168',
      anxiety_327: 'anxiety_169',
      anxiety_328: 'anxiety_170',
      anxiety_329: 'anxiety_171',
      anxiety_330: 'anxiety_172',
      otherDiagnoses_331: 'otherDiagnoses_173',
      otherDiagnoses_332: 'otherDiagnoses_174',
      otherDiagnoses_333: 'otherDiagnoses_175',
      otherDiagnoses_334: 'otherDiagnoses_176',
      otherDiagnoses_335: 'otherDiagnoses_177',
      otherDiagnoses_336: 'otherDiagnoses_178',
      sleepIssues_337: 'sleepIssues_179',
      sleepIssues_338: 'sleepIssues_180',
      sleepIssues_339: 'sleepIssues_181',
      sleepIssues_340: 'sleepIssues_182',
      sleepIssues_341: 'sleepIssues_183',
      sleepIssues_342: 'sleepIssues_184',
      sleepIssues_344: 'sleepIssues_186',
      substanceUse_346: 'currentLevelsUse_188',
      recommendationsSubstances_347: 'recommendationsSubstances_189',
      labs_349: 'labs_191',
      generalHealthInformation_350: 'acuteMedicalConditions_192',
      chronicMedicationConditions_351: 'chronicMedicationConditions_193',
      havePrimaryCareDoctor: 'doTheyHavePrimaryCareDoctor',
      sawPrimaryCare_352: 'sawPrimaryCare_194',
      roi_355: 'roi_197',
      bioGeneticDevelopmental_298: 'bio_145',
      psychoCognitiveStyle_299: 'psycho_146',
      socialRelationshipsFamily_300: 'social_147',
      summary_301: 'summary_148',
      labsImaging_290: 'labsImaging_140',
      identifyingInformation_3: 'identifyingInformation_3',
      currentMedications_165: 'currentMedications_16',
      primaryDiagnosis_305: 'primaryDiagnosis_153',
      secondaryDiagnosis_308: 'secondaryDiagnosis_154',
      coOccurringSubstance_311: 'coOccurringSubstance_155',
      clientLocationStreet: 'street',
      clientLocationCity: 'city',
      clientLocationState: 'state',
      clientLocationZipCode: 'zip',
      otherDsmDiagnoses_315: 'differentialConsiderations_156',
      clinicalLeaveEvaluation: 'clinicalLeaveEvaluation',
      symptomsAffectingFunctioning: 'symptomsAffectingFunctioning',
      areasOfFunctioningAffected: 'areasOfFunctioningAffected',
      goalsForRecovery: 'goalsForRecovery',
      moreDetailsAroundCarePlan: 'moreDetailsAroundCarePlan',
      leaveStartDate: 'leaveStartDate',
      moreDetailsAroundLeave: 'moreDetailsAroundLeave',
    },
    bcmClientIntake: {
      problemBroughtSee_41: 'ccFromIntake_6',
      medicationAllergiesAll_63: 'allergies_17',
      bioGeneticDevelopmental_298: 'bio_145',
      psychoCognitiveStyle_299: 'psycho_146',
      socialRelationshipsFamily_300: 'social_147',
      summary_301: 'summary_148',
      treatmentGoals_303: 'treatmentGoals_151',
    },
  },
  initialNote: {
    clientIntake: {
      employed: 'employed',
      maritalStatus: 'maritalStatus',
      problemDescription: 'precipitantsPresentingProblem',
      ethnicity: 'ethnicity',
      suicide: 'priorSuicideAttempts',
      selfInjury: 'priorHistorySelfHarm',
      accessToGun: 'accessToLethalMeans',
      medicalConcerns: 'recentDiagnosisOfLifeThreateningIllness',
      previousPsychiatricDiagnoses: 'currentPsychiatricDisorders',
      previousPsychiatricDiagnosesList: 'priorDiagnoses',
      priorMentalHealthWhen: 'historyMentalHealthServices',
      prescriptionList: 'currentMedications',
      familyPsychIssues: 'familyPsychiatricHistory',
      childhoodHistory: 'developmentalHistory',
      therapyGoal: 'goal1',
    },
    bctClientIntake: {
      employed: 'employed',
      maritalStatus: 'maritalStatus',
      problemDescription: 'precipitantsPresentingProblem',
      ethnicity: 'ethnicity',
      suicide: 'priorSuicideAttempts',
      selfInjury: 'priorHistorySelfHarm',
      accessToGun: 'accessToLethalMeans',
      medicalConcerns: 'recentDiagnosisOfLifeThreateningIllness',
      previousPsychiatricDiagnoses: 'currentPsychiatricDisorders',
      previousPsychiatricDiagnosesList: 'priorDiagnoses',
      priorMentalHealthWhen: 'historyMentalHealthServices',
      prescriptionList: 'currentMedications',
      familyPsychIssues: 'familyPsychiatricHistory',
      childhoodHistory: 'developmentalHistory',
      therapyGoal: 'goal1',
    },
  },
  bctInitialNote: {
    clientIntake: {
      employed: 'employed',
      maritalStatus: 'maritalStatus',
      problemDescription: 'precipitantsPresentingProblem',
      ethnicity: 'ethnicity',
      suicide: 'priorSuicideAttempts',
      selfInjury: 'priorHistorySelfHarm',
      accessToGun: 'accessToLethalMeans',
      medicalConcerns: 'recentDiagnosisOfLifeThreateningIllness',
      previousPsychiatricDiagnoses: 'currentPsychiatricDisorders',
      previousPsychiatricDiagnosesList: 'priorDiagnoses',
      priorMentalHealthWhen: 'historyMentalHealthServices',
      prescriptionList: 'currentMedications',
      familyPsychIssues: 'familyPsychiatricHistory',
      childhoodHistory: 'developmentalHistory',
      therapyGoal: 'goal1',
    },
    // Populate BCT Initial note from BCT Intake Note
    // bctClientIntake: bctInitialNote
    bctClientIntake: {
      preferredFirstName_6: 'preferredName',
      pronounsTherapistUse_7: 'clientPronouns',
      pronounsTherapistUse_8: 'pronounsOtherSpecify',
      street: 'street',
      city: 'city',
      state: 'state',
      zipCode: 'zipCode',
      primaryPhoneNumber_22: 'clientPhoneNumber',
      canLeaveVoicemail: 'leaveVoicemails',
      gender_81: 'gender',
      gender_specify_81: 'genderOtherSpecify',
      ethnicity: 'ethnicity',
      ethnicityOther: 'clientRaceEthnicityOther',
      isReligious: 'religiousSpiritual',
      religionDescription: 'religiousSpiritualDescription',
      sexualOrientation: 'sexualOrientation',
      sexualOrientation_specify: 'sexualOrientationSpecify',
      maritalStatus: 'maritalStatus',
      relationshipDescription: 'maritalStatusRelationship',
      widowHow: 'maritalStatusWidowed',
      divorce: 'maritalStatusDivorced',
      maritalStatusOther: 'maritalStatusOther',
      children: 'children',
      household: 'currentLivingSituation',
      occupation: 'financialHistory',
      parentsOrCaregiversAge: 'familyInformation',
      prescriptionList: 'currentMedications',
      medicalConcerns: 'medicalConditionsHistory',
      alcoholFrequency: 'clientDrinks',
      previousPsychiatricDiagnoses: 'priorDiagnoses',
      previousPsychiatricDiagnosesList: 'priorDiagnosesInfo',
      otherMentalHealth: 'currentMentalHealthServices',
      otherProviderName: 'currentProviders',
      previousMentalHealthServices: 'historyMentalHealthServices',
      priorMentalHealthWhen: 'whenWhyTreatment',
      priorMentalHealthWhatKind: 'mentalHealthServicesType',
      priorMentalHealthMostUseful: 'mostHelpfulTreatment',
      priorMentalHealthLeastUseful: 'leastHelpfulTreatment',
      suicide: 'suicidalBehaviorHistory',
      suicideWhen: 'suicidalBehaviorInfo',
      selfInjury: 'selfHarmingHistory',
      selfInjuryWhen: 'selfHarmingInfo',
      committedViolence: 'historyViolentBehaviors',
      committedViolenceWhen: 'violentBehaviorInfo',
      hospitalized: 'psychiatricHospitalizationHistory',
      hospitalizedWhen: 'psychiatricHospitalizationInfo',
      familyPsychHistory: 'psychiatricProblemHistory',
      familyPsychIssues: 'psychiatricProblemInfo',
      childhoodHistory: 'developmentalSocialHistory',
      experiencedDiscrimination: 'discriminationOppressionHistory',
      educationYears: 'highestEducation',
      financialStatus: 'financialStatus',
      inLegalProceedings: 'legalInvolvement',
      inLegalProceedingsExplain: 'legalInvolvementInfo',
      therapyGoal: 'clientStatedGoals',
      problemDescription: 'chiefComplaint',
      // @ts-expect-error TS(1117): An object literal cannot have multiple properties ... Remove this comment to see the full error message
      childhoodHistory: 'predisposingFactors',
      supportSystem: 'protectiveFactorsPersonalResources',
    },
  },
  lcttInitialNote: {
    // Populate LCTT Initial note from LCTT Intake Note
    // lcttClientIntake: lcttInitialNote
    lcttClientIntake: {
      preferredFirstName_6: 'preferredName',
      pronounsTherapistUse_7: 'clientPronouns',
      pronounsTherapistUse_8: 'pronounsOtherSpecify',
      street: 'street',
      city: 'city',
      state: 'state',
      zipCode: 'zipCode',
      primaryPhoneNumber_22: 'clientPhoneNumber',
      canLeaveVoicemail: 'leaveVoicemails',
      gender_81: 'gender',
      gender_specify_81: 'genderOtherSpecify',
      ethnicity: 'ethnicity',
      ethnicityOther: 'clientRaceEthnicityOther',
      isReligious: 'religiousSpiritual',
      religionDescription: 'religiousSpiritualDescription',
      sexualOrientation: 'sexualOrientation',
      sexualOrientation_specify: 'sexualOrientationSpecify',
      whoLivesRelationship: 'currentLivingSituation',
      parentsOrCaregiversAge: 'familyInformation',
      prescriptionList: 'currentMedications',
      medicalConcerns: 'medicalConditionsHistory',
      alcoholFrequency: 'clientDrinks',
      previousPsychiatricDiagnoses: 'priorDiagnoses',
      previousPsychiatricDiagnosesList: 'priorDiagnosesInfo',
      otherMentalHealth: 'currentMentalHealthServices',
      otherProviderName: 'currentProviders',
      previousMentalHealthServices: 'historyMentalHealthServices',
      priorMentalHealthWhen: 'whenWhyTreatment',
      priorMentalHealthWhatKind: 'mentalHealthServicesType',
      priorMentalHealthMostUseful: 'mostHelpfulTreatment',
      priorMentalHealthLeastUseful: 'leastHelpfulTreatment',
      suicide: 'suicidalBehaviorHistory',
      suicideWhen: 'suicidalBehaviorInfo',
      selfInjury: 'selfHarmingHistory',
      selfInjuryWhen: 'selfHarmingInfo',
      committedViolence: 'historyViolentBehaviors',
      committedViolenceWhen: 'violentBehaviorInfo',
      hospitalized: 'psychiatricHospitalizationHistory',
      hospitalizedWhen: 'psychiatricHospitalizationInfo',
      familyPsychHistory: 'psychiatricProblemHistory',
      familyPsychIssues: 'psychiatricProblemInfo',
      experiencedDiscrimination: 'discriminationOppressionHistory',
      therapyGoal: 'clientStatedGoals',
      problemDescription: 'chiefComplaint',
      supportSystem: 'protectiveFactorsPersonalResources',
      inSchool: 'childIsInSchool',
      schoolGrade: 'currentSchoolGrade',
      repeatOrSkipGrade: 'repeatOrSkipAGrade',
      repeatOrSkipGradeExplain: 'repeatOrSkipAGradeIfYes',
      specialEducationServices: 'receiveSpecialEducationServices',
      specialEducationServicesExplain: 'receiveSpecialEducationServicesIfYes',
      challengesSchool: 'describeChallengesInSchool',
      grades: 'childCurrentGrades',
      job: 'childHaveJob',
      whereWorkRole: 'whereChildWork',
      greatestStrengths: 'childStrengths',
      mostDifficult: 'childChallenges',
    },
  },
  lcttSessionNote: {
    // populate from lcttClientIntake field => lcttSessionNote field
    lcttClientIntake: {
      preferredFirstName_6: 'preferredName',
      pronounsTherapistUse_7: 'clientPronouns',
      pronounsTherapistUse_8: 'pronounsOtherSpecify',
      street: 'street',
      city: 'city',
      state: 'state',
      zipCode: 'zipCode',
      primaryPhoneNumber_22: 'clientPhoneNumber',
      canLeaveVoicemail: 'leaveVoicemails',
      prescriptionList: 'currentMedications',
      childhoodHistory: 'predisposingFactors',
      supportSystem: 'protectiveFactorsPersonalResources',
    },
    lcttSessionNote: {
      preferredName: 'preferredName',
      clientPronouns: 'clientPronouns',
      pronounsOtherSpecify: 'pronounsOtherSpecify',
      street: 'street',
      city: 'city',
      state: 'state',
      zipCode: 'zipCode',
      clientPhoneNumber: 'clientPhoneNumber',
      leaveVoicemails: 'leaveVoicemails',
      generalAppearance: 'generalAppearance',
      cognitiveFunctioning: 'cognitiveFunctioning',
      mood: 'mood',
      affect: 'affect',
      interpersonal: 'interpersonal',
      judgment: 'judgment',
      functionalStatus: 'functionalStatus',
      currentMedications: 'currentMedications',
      primaryDiagnosis: 'primaryDiagnosis',
      secondaryDiagnosis: 'secondaryDiagnosis',
      otherDiagnosis: 'otherDiagnosis',
      substanceAbuseDiagnosis: 'substanceAbuseDiagnosis',
      problem_1: 'problem_1',
      goal1: 'goal1',
      problem_2: 'problem_2',
      goal2: 'goal2',
      addGoal3: 'addGoal3',
      problem_3: 'problem_3',
      goal3: 'goal3',
      interventionPlan_2: 'interventionPlan_2',
      otherInterventions: 'otherInterventions',
      treamentGoalsProgress: 'treamentGoalsProgress',
      recommendedTreatmentLength: 'recommendedTreatmentLength',
      treatmentGoalsProgress: 'treatmentGoalsProgress',
      presentingIssues: 'presentingIssues',
      predisposingFactors: 'predisposingFactors',
      precipitatingFactors: 'precipitatingFactors',
      perpetuatingFactors: 'perpetuatingFactors',
      protectiveFactorsPersonalResources: 'protectiveFactorsPersonalResources',
    },
    lcttInitialNote: {
      preferredName: 'preferredName',
      clientPronouns: 'clientPronouns',
      street: 'street',
      city: 'city',
      state: 'state',
      zipCode: 'zipCode',
      clientPhoneNumber: 'clientPhoneNumber',
      leaveVoicemails: 'leaveVoicemails',
      generalAppearance: 'generalAppearance',
      cognitiveFunctioning: 'cognitiveFunctioning',
      mood: 'mood',
      affect: 'affect',
      interpersonal: 'interpersonal',
      judgment: 'judgment',
      functionalStatus: 'functionalStatus',
      additionalMentalStatus: 'additionalMentalStatus',
      currentMedications: 'currentMedications',
      primaryDiagnosis: 'primaryDiagnosis',
      secondaryDiagnosis: 'secondaryDiagnosis',
      otherDiagnosis: 'otherDiagnosis',
      substanceAbuseDiagnosis: 'substanceAbuseDiagnosis',
      problem_1: 'problem_1',
      goal1: 'goal1',
      problem_2: 'problem_2',
      goal2: 'goal2',
      addGoal3: 'addGoal3',
      problem_3: 'problem_3',
      goal3: 'goal3',
      interventionPlan_2: 'interventionPlan_2',
      otherInterventions: 'otherInterventions',
      recommendedTreatmentLength: 'recommendedTreatmentLength',
      presentingIssues: 'presentingIssues',
      predisposingFactors: 'predisposingFactors',
      precipitatingFactors: 'precipitatingFactors',
      perpetuatingFactors: 'perpetuatingFactors',
      protectiveFactorsPersonalResources: 'protectiveFactorsPersonalResources',
    },
  },
  sessionNote: {
    sessionNote: {
      primaryDiagnosis: 'primaryDiagnosis',
      secondaryDiagnosis: 'secondaryDiagnosis',
      otherDiagnosis: 'otherDiagnosis',
      substanceAbuseDiagnosis: 'substanceAbuseDiagnosis',
      problemPrecipitants: 'problemPrecipitants',
      crossSectionalSymptoms: 'crossSectionalSymptoms',
      longitudinalSymptoms: 'longitudinalSymptoms',
      workingHypothesis: 'workingHypothesis',
      problemOne: 'problemOne',
      problemTwo: 'problemTwo',
      problemThree: 'problemThree',
      otherProblems: 'otherProblems',
      treatmentGoalOne: 'treatmentGoalOne',
      treatmentGoalTwo: 'treatmentGoalTwo',
      treatmentGoalThree: 'treatmentGoalThree',
      otherTreatmentGoals: 'otherTreatmentGoals',
      symptomDescription: 'symptomDescription',
      treatmentPlan: 'treatmentPlan',
    },
    initialNote: {
      primaryDiagnosis: 'primaryDiagnosis',
      secondaryDiagnosis: 'secondaryDiagnosis',
      substanceAbuseDiagnosis: 'substanceAbuseDiagnosis',
      precipitantsPresentingProblem: 'problemPrecipitants',
      crossSectionalCognitions: 'crossSectionalSymptoms',
      longitudinalViewCognitions: 'longitudinalSymptoms',
      workingHypothesis: 'workingHypothesis',
      problem1: 'problemOne',
      problem2: 'problemTwo',
      problem3: 'problemThree',
      otherProblems: 'otherProblems',
      goal1: 'treatmentGoalOne',
      goal2: 'treatmentGoalTwo',
      goal3: 'treatmentGoalThree',
      otherGoals: 'otherTreatmentGoals',
      treatmentPlan: 'treatmentPlan',
    },
    bctSessionNote: {
      primaryDiagnosis: 'primaryDiagnosis',
      secondaryDiagnosis: 'secondaryDiagnosis',
      otherDiagnosis: 'otherDiagnosis',
      substanceAbuseDiagnosis: 'substanceAbuseDiagnosis',
      problemPrecipitants: 'problemPrecipitants',
      crossSectionalSymptoms: 'crossSectionalSymptoms',
      longitudinalSymptoms: 'longitudinalSymptoms',
      workingHypothesis: 'workingHypothesis',
      problemOne: 'problemOne',
      problemTwo: 'problemTwo',
      problemThree: 'problemThree',
      otherProblems: 'otherProblems',
      treatmentGoalOne: 'treatmentGoalOne',
      treatmentGoalTwo: 'treatmentGoalTwo',
      treatmentGoalThree: 'treatmentGoalThree',
      otherTreatmentGoals: 'otherTreatmentGoals',
      symptomDescription: 'symptomDescription',
      treatmentPlan: 'treatmentPlan',
    },
    bctInitialNote: {
      primaryDiagnosis: 'primaryDiagnosis',
      secondaryDiagnosis: 'secondaryDiagnosis',
      substanceAbuseDiagnosis: 'substanceAbuseDiagnosis',
      precipitantsPresentingProblem: 'problemPrecipitants',
      crossSectionalCognitions: 'crossSectionalSymptoms',
      longitudinalViewCognitions: 'longitudinalSymptoms',
      workingHypothesis: 'workingHypothesis',
      problem1: 'problemOne',
      problem2: 'problemTwo',
      problem3: 'problemThree',
      otherProblems: 'otherProblems',
      goal1: 'treatmentGoalOne',
      goal2: 'treatmentGoalTwo',
      goal3: 'treatmentGoalThree',
      otherGoals: 'otherTreatmentGoals',
      treatmentPlan: 'treatmentPlan',
    },
  },
  bctSessionNote: {
    sessionNote: {
      primaryDiagnosis: 'primaryDiagnosis',
      secondaryDiagnosis: 'secondaryDiagnosis',
      otherDiagnosis: 'otherDiagnosis',
      substanceAbuseDiagnosis: 'substanceAbuseDiagnosis',
      problemPrecipitants: 'problemPrecipitants',
      crossSectionalSymptoms: 'crossSectionalSymptoms',
      longitudinalSymptoms: 'longitudinalSymptoms',
      workingHypothesis: 'workingHypothesis',
      problemOne: 'problemOne',
      problemTwo: 'problemTwo',
      problemThree: 'problemThree',
      otherProblems: 'otherProblems',
      treatmentGoalOne: 'treatmentGoalOne',
      treatmentGoalTwo: 'treatmentGoalTwo',
      treatmentGoalThree: 'treatmentGoalThree',
      otherTreatmentGoals: 'otherTreatmentGoals',
      symptomDescription: 'symptomDescription',
      treatmentPlan: 'treatmentPlan',
    },
    initialNote: {
      primaryDiagnosis: 'primaryDiagnosis',
      secondaryDiagnosis: 'secondaryDiagnosis',
      substanceAbuseDiagnosis: 'substanceAbuseDiagnosis',
      precipitantsPresentingProblem: 'problemPrecipitants',
      crossSectionalCognitions: 'crossSectionalSymptoms',
      longitudinalViewCognitions: 'longitudinalSymptoms',
      workingHypothesis: 'workingHypothesis',
      problem1: 'problemOne',
      problem2: 'problemTwo',
      problem3: 'problemThree',
      otherProblems: 'otherProblems',
      goal1: 'treatmentGoalOne',
      goal2: 'treatmentGoalTwo',
      goal3: 'treatmentGoalThree',
      otherGoals: 'otherTreatmentGoals',
      treatmentPlan: 'treatmentPlan',
    },
    // populate from bctClientIntake field => bctSessionNote field
    bctClientIntake: {
      preferredFirstName_6: 'preferredName',
      pronounsTherapistUse_7: 'clientPronouns',
      pronounsTherapistUse_8: 'pronounsOtherSpecify',
      street: 'street',
      city: 'city',
      state: 'state',
      zipCode: 'zipCode',
      primaryPhoneNumber_22: 'clientPhoneNumber',
      canLeaveVoicemail: 'leaveVoicemails',
      prescriptionList: 'currentMedications',
      childhoodHistory: 'predisposingFactors',
      supportSystem: 'protectiveFactorsPersonalResources',
    },
    bctSessionNote: {
      preferredName: 'preferredName',
      clientPronouns: 'clientPronouns',
      pronounsOtherSpecify: 'pronounsOtherSpecify',
      street: 'street',
      city: 'city',
      state: 'state',
      zipCode: 'zipCode',
      clientPhoneNumber: 'clientPhoneNumber',
      leaveVoicemails: 'leaveVoicemails',
      generalAppearance: 'generalAppearance',
      cognitiveFunctioning: 'cognitiveFunctioning',
      mood: 'mood',
      affect: 'affect',
      interpersonal: 'interpersonal',
      judgment: 'judgment',
      functionalStatus: 'functionalStatus',
      currentMedications: 'currentMedications',
      primaryDiagnosis: 'primaryDiagnosis',
      secondaryDiagnosis: 'secondaryDiagnosis',
      otherDiagnosis: 'otherDiagnosis',
      substanceAbuseDiagnosis: 'substanceAbuseDiagnosis',
      problem_1: 'problem_1',
      goal1: 'goal1',
      problem_2: 'problem_2',
      goal2: 'goal2',
      addGoal3: 'addGoal3',
      problem_3: 'problem_3',
      goal3: 'goal3',
      interventionPlan_2: 'interventionPlan_2',
      otherInterventions: 'otherInterventions',
      treamentGoalsProgress: 'treamentGoalsProgress',
      recommendedTreatmentLength: 'recommendedTreatmentLength',
      treatmentGoalsProgress: 'treatmentGoalsProgress',
      presentingIssues: 'presentingIssues',
      predisposingFactors: 'predisposingFactors',
      precipitatingFactors: 'precipitatingFactors',
      perpetuatingFactors: 'perpetuatingFactors',
      protectiveFactorsPersonalResources: 'protectiveFactorsPersonalResources',
    },
    bctInitialNote: {
      preferredName: 'preferredName',
      clientPronouns: 'clientPronouns',
      street: 'street',
      city: 'city',
      state: 'state',
      zipCode: 'zipCode',
      clientPhoneNumber: 'clientPhoneNumber',
      leaveVoicemails: 'leaveVoicemails',
      generalAppearance: 'generalAppearance',
      cognitiveFunctioning: 'cognitiveFunctioning',
      mood: 'mood',
      affect: 'affect',
      interpersonal: 'interpersonal',
      judgment: 'judgment',
      functionalStatus: 'functionalStatus',
      additionalMentalStatus: 'additionalMentalStatus',
      currentMedications: 'currentMedications',
      primaryDiagnosis: 'primaryDiagnosis',
      secondaryDiagnosis: 'secondaryDiagnosis',
      otherDiagnosis: 'otherDiagnosis',
      substanceAbuseDiagnosis: 'substanceAbuseDiagnosis',
      problem_1: 'problem_1',
      goal1: 'goal1',
      problem_2: 'problem_2',
      goal2: 'goal2',
      addGoal3: 'addGoal3',
      problem_3: 'problem_3',
      goal3: 'goal3',
      interventionPlan_2: 'interventionPlan_2',
      otherInterventions: 'otherInterventions',
      recommendedTreatmentLength: 'recommendedTreatmentLength',
      presentingIssues: 'presentingIssues',
      predisposingFactors: 'predisposingFactors',
      precipitatingFactors: 'precipitatingFactors',
      perpetuatingFactors: 'perpetuatingFactors',
      protectiveFactorsPersonalResources: 'protectiveFactorsPersonalResources',
    },
  },
  coachingInitialNote: {
    // Populate Initial note from intake
    coachingClientIntake: {
      bringsCoaching_30: 'broughtClientCoaching',
      goalsWorkingCoach_31: 'bclientCoachingGoals',
      workStressE_1: 'workStress',
      feelingStuckFinding_2: 'feelingStuckFinding',
      romanticRelationships_3: 'romanticRelationships',
      endRelationshipE_4: 'endRelationship',
      friendships_5: 'friendships',
      familyRelationships_6: 'familyRelationships',
      parenting_7: 'parenting',
      lifeTransition_8: 'lifeTransition',
      makingDifficultDecisions_9: 'makingDifficultDecisions',
      anxiety_10: 'anxiety',
      feelingDownSad_11: 'feelingDownSad',
      crossCulturalDifferences_12: 'crossCulturalDifferences',
      challengesRelatedCultural_13: 'challengesRelatedCultural',
      other_14: 'otherFocusTopic',
      selectPrimaryConcerns_33: 'otherFocusTopicDetails',
    },
    bccClientIntake: {
      bringsCoaching_30: 'broughtClientCoaching',
      goalsWorkingCoach_31: 'bclientCoachingGoals',
      workStressE_1: 'workStress',
      feelingStuckFinding_2: 'feelingStuckFinding',
      romanticRelationships_3: 'romanticRelationships',
      endRelationshipE_4: 'endRelationship',
      friendships_5: 'friendships',
      familyRelationships_6: 'familyRelationships',
      parenting_7: 'parenting',
      lifeTransition_8: 'lifeTransition',
      makingDifficultDecisions_9: 'makingDifficultDecisions',
      anxiety_10: 'anxiety',
      feelingDownSad_11: 'feelingDownSad',
      crossCulturalDifferences_12: 'crossCulturalDifferences',
      challengesRelatedCultural_13: 'challengesRelatedCultural',
      other_14: 'otherFocusTopic',
      selectPrimaryConcerns_33: 'otherFocusTopicDetails',
    },
  },
  bccInitialNote: {
    // Populate Initial note from intake
    coachingClientIntake: {
      bringsCoaching_30: 'broughtClientCoaching',
      goalsWorkingCoach_31: 'goal1',
      workStressE_1: 'workStress',
      feelingStuckFinding_2: 'feelingStuckFinding',
      romanticRelationships_3: 'romanticRelationships',
      endRelationshipE_4: 'endRelationship',
      friendships_5: 'friendships',
      familyRelationships_6: 'familyRelationships',
      parenting_7: 'parenting',
      lifeTransition_8: 'lifeTransition',
      makingDifficultDecisions_9: 'makingDifficultDecisions',
      anxiety_10: 'anxiety',
      feelingDownSad_11: 'feelingDownSad',
      crossCulturalDifferences_12: 'crossCulturalDifferences',
      challengesRelatedCultural_13: 'challengesRelatedCultural',
      other_14: 'otherFocusTopic',
      selectPrimaryConcerns_33: 'otherFocusTopicDetails',
    },
    // Populates Session 1 note (aka, Initial note) with Client Intake
    //  bccClientIntake Field => bccInitialNote Field
    bccClientIntake: {
      bringsCoaching_30: 'broughtClientCoaching',
      goalsWorkingCoach_31: 'goal1',
      pronounsCoachUse_7: 'clientPronouns',
      pronounsCoachUse_8: 'pronounsOtherSpecify',
      street_12: 'street',
      city_13: 'city',
      state_14: 'state',
      zipCode_15: 'zipCode',
      primaryPhoneNumber_22: 'primaryPhone',
      leaveVoicemailsPersonal_23: 'leaveVoicemails',
      selectPrimaryConcerns_32: 'focusTopics',
      selectPrimaryConcerns_33: 'otherFocusTopicDetails',
      preferredFirstName_6: ['preferredName', 'preferredNameInternational'],
      preferredPronouns_45: 'clientPronounsInternational',
      address1_10: 'address1International',
      address2_11: 'address2International',
      primaryPhoneNumber_16: 'primaryPhoneInternational',
      leaveVoicemailsPersonal_17: 'leaveVoicemailsInternational',
      bringsCoaching_26: 'broughtClientCoachingInternational',
      goalsWorkingCoach_29: 'goal1',
      selectPrimaryConcerns_30: 'focusTopicsInternational',
      otherPrimaryConcerns_31: 'otherFocusTopicDetailsInternational',
    },
    // Populates Session 1 note (aka, Initial note) with International Intake
    //  bccClientIntakeNonUSField: 'bccInitialNoteField
    bccClientIntakeNonUS: {
      preferredFirstName_6: 'preferredNameInternational',
      preferredPronouns_45: 'clientPronounsInternational',
      address1_10: 'address1International',
      address2_11: 'address2International',
      primaryPhoneNumber_16: 'primaryPhoneInternational',
      leaveVoicemailsPersonal_17: 'leaveVoicemailsInternational',
      bringsCoaching_26: 'broughtClientCoachingInternational',
      goalsWorkingCoach_29: 'goal1',
      selectPrimaryConcerns_30: 'focusTopicsInternational',
      otherPrimaryConcerns_31: 'otherFocusTopicDetailsInternational',
    },
  },
  coachingSessionNote: {
    // Populate Session note from initial note
    coachingInitialNote: {
      workStress: 'workStress',
      feelingStuckFinding: 'feelingStuckFinding',
      romanticRelationships: 'romanticRelationships',
      endRelationship: 'endRelationship',
      friendships: 'friendships',
      familyRelationships: 'familyRelationships',
      parenting: 'parenting',
      lifeTransition: 'lifeTransition',
      makingDifficultDecisions: 'makingDifficultDecisions',
      anxiety: 'anxiety',
      feelingDownSad: 'feelingDownSad',
      crossCulturalDifferences: 'crossCulturalDifferences',
      challengesRelatedCultural: 'challengesRelatedCultural',
      otherFocusTopic: 'otherFocusTopic',
      coachingTopicFocusSpecify: 'coachingTopicFocusSpecify',
      additionalClientInfo: 'additionalClientInfo_25',
      sessionSummary: 'sessionSummary',
      bclientCoachingGoals: 'clientGoals',
      anticipatedAreasFocus: 'anticipatedAreasFocus',
      betweenSessionPractice: 'practiceCompletedFrom',
    },
    // Populate Session note from last session note
    coachingSessionNote: {
      workStress: 'workStress',
      feelingStuckFinding: 'feelingStuckFinding',
      romanticRelationships: 'romanticRelationships',
      endRelationship: 'endRelationship',
      friendships: 'friendships',
      familyRelationships: 'familyRelationships',
      parenting: 'parenting',
      lifeTransition: 'lifeTransition',
      makingDifficultDecisions: 'makingDifficultDecisions',
      anxiety: 'anxiety',
      feelingDownSad: 'feelingDownSad',
      crossCulturalDifferences: 'crossCulturalDifferences',
      challengesRelatedCultural: 'challengesRelatedCultural',
      otherFocusTopic: 'otherFocusTopic',
      coachingTopicFocusSpecify: 'coachingTopicFocusSpecify',
      additionalClientInfo_25: 'additionalClientInfo_25',
      sessionSummary: 'sessionSummary',
      clientGoals: 'clientGoals',
      anticipatedAreasFocus: 'anticipatedAreasFocus',
      betweenSessionPractice: 'practiceCompletedFrom',
    },
    bccInitialNote: {
      workStress: 'workStress',
      feelingStuckFinding: 'feelingStuckFinding',
      romanticRelationships: 'romanticRelationships',
      endRelationship: 'endRelationship',
      friendships: 'friendships',
      familyRelationships: 'familyRelationships',
      parenting: 'parenting',
      lifeTransition: 'lifeTransition',
      makingDifficultDecisions: 'makingDifficultDecisions',
      anxiety: 'anxiety',
      feelingDownSad: 'feelingDownSad',
      crossCulturalDifferences: 'crossCulturalDifferences',
      challengesRelatedCultural: 'challengesRelatedCultural',
      otherFocusTopic: 'otherFocusTopic',
      coachingTopicFocusSpecify: 'coachingTopicFocusSpecify',
      additionalClientInfo: 'additionalClientInfo_25',
      sessionSummary: 'sessionSummary',
      goal1: 'clientGoals',
      anticipatedAreasFocus: 'anticipatedAreasFocus',
      betweenSessionPractice: 'practiceCompletedFrom',
    },
    bccSessionNote: {
      workStress: 'workStress',
      feelingStuckFinding: 'feelingStuckFinding',
      romanticRelationships: 'romanticRelationships',
      endRelationship: 'endRelationship',
      friendships: 'friendships',
      familyRelationships: 'familyRelationships',
      parenting: 'parenting',
      lifeTransition: 'lifeTransition',
      makingDifficultDecisions: 'makingDifficultDecisions',
      anxiety: 'anxiety',
      feelingDownSad: 'feelingDownSad',
      crossCulturalDifferences: 'crossCulturalDifferences',
      challengesRelatedCultural: 'challengesRelatedCultural',
      otherFocusTopic: 'otherFocusTopic',
      coachingTopicFocusSpecify: 'coachingTopicFocusSpecify',
      additionalClientInfo_25: 'additionalClientInfo_25',
      sessionSummary: 'sessionSummary',
      goal1: 'clientGoals',
      anticipatedAreasFocus: 'anticipatedAreasFocus',
      betweenSessionPractice: 'practiceCompletedFrom',
    },
  },
  bccSessionNote: {
    // Populate Session note from initial note
    coachingInitialNote: {
      workStress: 'workStress',
      feelingStuckFinding: 'feelingStuckFinding',
      romanticRelationships: 'romanticRelationships',
      endRelationship: 'endRelationship',
      friendships: 'friendships',
      familyRelationships: 'familyRelationships',
      parenting: 'parenting',
      lifeTransition: 'lifeTransition',
      makingDifficultDecisions: 'makingDifficultDecisions',
      anxiety: 'anxiety',
      feelingDownSad: 'feelingDownSad',
      crossCulturalDifferences: 'crossCulturalDifferences',
      challengesRelatedCultural: 'challengesRelatedCultural',
      otherFocusTopic: 'otherFocusTopic',
      coachingTopicFocusSpecify: 'coachingTopicFocusSpecify',
      additionalClientInfo: 'additionalClientInfo_25',
      sessionSummary: 'sessionSummary',
      bclientCoachingGoals: 'goal1',
      anticipatedAreasFocus: 'anticipatedAreasFocus',
      betweenSessionPractice: 'practiceCompletedFrom',
    },
    // Populate Session note from last session note
    coachingSessionNote: {
      workStress: 'workStress',
      feelingStuckFinding: 'feelingStuckFinding',
      romanticRelationships: 'romanticRelationships',
      endRelationship: 'endRelationship',
      friendships: 'friendships',
      familyRelationships: 'familyRelationships',
      parenting: 'parenting',
      lifeTransition: 'lifeTransition',
      makingDifficultDecisions: 'makingDifficultDecisions',
      anxiety: 'anxiety',
      feelingDownSad: 'feelingDownSad',
      crossCulturalDifferences: 'crossCulturalDifferences',
      challengesRelatedCultural: 'challengesRelatedCultural',
      otherFocusTopic: 'otherFocusTopic',
      coachingTopicFocusSpecify: 'coachingTopicFocusSpecify',
      additionalClientInfo_25: 'additionalClientInfo_25',
      sessionSummary: 'sessionSummary',
      clientGoals: 'goal1',
      anticipatedAreasFocus: 'anticipatedAreasFocus',
      betweenSessionPractice: 'practiceCompletedFrom',
    },
    // Populate Coaching subsequent Session note from Session 1 Note
    // bccInitialNoteField: bccSessionNoteField
    bccInitialNote: {
      isBasedInUS: 'isBasedInUS',
      isCoachingForParentsEpisode: 'isCoachingForParentsEpisode',
      focusTopics: 'coachingTopicFocus',
      otherFocusTopicDetails: 'coachingTopicFocusSpecify',
      additionalClientInfo: 'additionalClientInfo_25',
      sessionSummary: 'sessionSummary',
      goal1: 'goal1',
      goal2: 'goal2',
      goal3: 'goal3',
      anticipatedAreasFocus: 'anticipatedAreasFocus',
      betweenSessionPractice: 'practiceCompletedFrom',
      preferredName: 'preferredName',
      clientPronouns: 'clientPronouns',
      pronounsOtherSpecify: 'pronounsOtherSpecify',
      street: 'street',
      city: 'city',
      state: 'state',
      zipCode: 'zipCode',
      primaryPhone: 'primaryPhone',
      leaveVoicemails: 'leaveVoicemails',
      preferredNameInternational: 'preferredNameInternational',
      clientPronounsInternational: 'clientPronounsInternational',
      address1International: 'address1International',
      address2International: 'address2International',
      cityInternational: 'cityInternational',
      primaryPhoneInternational: 'primaryPhoneInternational',
      leaveVoicemailsInternational: 'leaveVoicemailsInternational',
      focusTopicsInternational: 'coachingTopicFocusInternational',
      otherFocusTopicDetailsInternational: 'coachingTopicFocusSpecifyInternational',
    },
    bccSessionNote: {
      isBasedInUS: 'isBasedInUS',
      coachingTopicFocus: 'coachingTopicFocus',
      coachingTopicFocusSpecify: 'coachingTopicFocusSpecify',
      additionalClientInfo_25: 'additionalClientInfo_25',
      sessionSummary: 'sessionSummary',
      goal1: 'goal1',
      goal2: 'goal2',
      goal3: 'goal3',
      anticipatedAreasFocus: 'anticipatedAreasFocus',
      betweenSessionPractice: 'practiceCompletedFrom',
      preferredName: 'preferredName',
      clientPronouns: 'clientPronouns',
      pronounsOtherSpecify: 'pronounsOtherSpecify',
      street: 'street',
      city: 'city',
      state: 'state',
      zipCode: 'zipCode',
      primaryPhone: 'primaryPhone',
      leaveVoicemails: 'leaveVoicemails',
      preferredNameInternational: 'preferredNameInternational',
      clientPronounsInternational: 'clientPronounsInternational',
      address1International: 'address1International',
      address2International: 'address2International',
      cityInternational: 'cityInternational',
      primaryPhoneInternational: 'primaryPhoneInternational',
      leaveVoicemailsInternational: 'leaveVoicemailsInternational',
      coachingTopicFocusInternational: 'coachingTopicFocusInternational',
      coachingTopicFocusSpecifyInternational: 'coachingTopicFocusSpecifyInternational',
      isCoachingForParentsEpisode: 'isCoachingForParentsEpisode',
    },
  },
  singleSessionCoachingInitialNote: {
    // Populate Initial note from intake
    singleSessionCoachingClientIntake: {
      bringsCoaching_30: 'SSCGoals',
      primaryConcern_30_0: 'primaryConcerns',
      whoIsImpacted_30_0_1: 'mostImpactedPerson',
      whatHaveYouTried_30_0_2: 'previousAttempts',
      selectPrimaryConcerns_32: 'focusTopics',
      selectPrimaryConcerns_33: 'otherFocusTopicDetails',
    },
    sscClientIntake: {
      bringsCoaching_30: 'SSCGoals',
      primaryConcern_30_0: 'primaryConcerns',
      whoIsImpacted_30_0_1: 'mostImpactedPerson',
      whatHaveYouTried_30_0_2: 'previousAttempts',
      selectPrimaryConcerns_32: 'focusTopics',
      selectPrimaryConcerns_33: 'otherFocusTopicDetails',
    },
  },
  sscInitialNote: {
    // Populate Initial note from intake
    singleSessionCoachingClientIntake: {
      bringsCoaching_30: 'goal1',
      primaryConcern_30_0: 'primaryConcerns',
      whoIsImpacted_30_0_1: 'mostImpactedPerson',
      whatHaveYouTried_30_0_2: 'previousAttempts',
      selectPrimaryConcerns_32: 'focusTopics',
      selectPrimaryConcerns_33: 'otherFocusTopicDetails',
    },
    sscClientIntake: {
      bringsCoaching_30: 'goal1',
      primaryConcern_30_0: 'primaryConcerns',
      whoIsImpacted_30_0_1: 'mostImpactedPerson',
      whatHaveYouTried_30_0_2: 'previousAttempts',
      selectPrimaryConcerns_32: 'focusTopics',
      selectPrimaryConcerns_33: 'otherFocusTopicDetails',
      preferredFirstName_6: 'preferredName',
      pronounsCoachUse_7: 'clientPronouns',
      pronounsCoachUse_8: 'pronounsOtherSpecify',
      street_12: 'street',
      city_13: 'city',
      state_14: 'state',
      zipCode_15: 'zipCode',
      primaryPhoneNumber_22: 'primaryPhone',
      leaveVoicemailsPersonal_23: 'leaveVoicemails',
    },
  },
  singleSessionCoachingSessionNote: {
    singleSessionCoachingInitialNote: {
      additionalClientInfo: 'additionalClientInfo',
      sessionSummary: 'sessionSummary',
    },
    sscInitialNote: {
      additionalClientInfo: 'additionalClientInfo',
      sessionSummary: 'sessionSummary',
    },
    singleSessionCoachingSessionNote: {
      additionalClientInfo: 'additionalClientInfo',
      sessionSummary: 'sessionSummary',
    },
    sscSessionNote: {
      additionalClientInfo: 'additionalClientInfo',
      sessionSummary: 'sessionSummary',
    },
  },
  sscSessionNote: {
    singleSessionCoachingInitialNote: {
      additionalClientInfo: 'additionalClientInfo',
      sessionSummary: 'sessionSummary',
    },
    sscInitialNote: {
      preferredName: 'preferredName',
      clientPronouns: 'clientPronouns',
      pronounsOtherSpecify: 'pronounsOtherSpecify',
      street: 'street',
      city: 'city',
      state: 'state',
      zipCode: 'zipCode',
      primaryPhone: 'primaryPhone',
      leaveVoicemails: 'leaveVoicemails',
      additionalClientInfo: 'additionalClientInfo',
      sessionSummary: 'sessionSummary',
      goal1: 'goal1',
      goal2: 'goal2',
      goal3: 'goal3',
    },
    singleSessionCoachingSessionNote: {
      additionalClientInfo: 'additionalClientInfo',
      sessionSummary: 'sessionSummary',
    },
    sscSessionNote: {
      preferredName: 'preferredName',
      clientPronouns: 'clientPronouns',
      pronounsOtherSpecify: 'pronounsOtherSpecify',
      street: 'street',
      city: 'city',
      state: 'state',
      zipCode: 'zipCode',
      primaryPhone: 'primaryPhone',
      leaveVoicemails: 'leaveVoicemails',
      additionalClientInfo: 'additionalClientInfo',
      sessionSummary: 'sessionSummary',
      goal1: 'goal1',
      goal2: 'goal2',
      goal3: 'goal3',
    },
  },
  cleInitialNote: {
    cleClientIntake: {
      preferredName_15: 'preferredName',
      preferredPronoun: 'preferredPronoun',
      pronounOther: 'pronounOther',
      homeAddressStreet: 'clientLocationStreet',
      homeAddressCity: 'clientLocationCity',
      homeAddressState: 'clientLocationState',
      homeAddressZipCode: 'clientLocationZipCode',
      primaryPhone: 'phoneNumber',
      leaveVoicemailsPersonal_8: 'permissionToLeaveVoicemails',
      gender_81: 'gender',
      raceEthnicityCheck_16: 'ethnicity',
      relationshipStatus_20: 'relationshipStatus_122',
      workingNow_36: 'employedStatus',
      workingNow_38: 'alreadyInitiatedLeave',
      problemBroughtSee_41: 'chiefComplaint_5',
      problemsStart_42: 'whenDidProblemsStart',
      describeSymptomsAffected: 'incapacityAffectsDuties',
      attemptedSuicide_72: 'suicideAttempts_101',
      attemptedSuicide_73: 'suicideAttempts_102',
      purposelySelfInjured_74: 'selfInjury_104',
      purposelySelfInjured_75: 'selfInjury_105',
      assaultedCommittedAct_76: 'violenceHistory_107',
      assaultedCommittedAct_77: 'violenceHistory_108',
      hospitalizedInpatientPartial_78: 'psychiatricHospitalizations_110',
      hospitalizedInpatientPartial_79: 'psychiatricHospitalizations_111',
      previousPsychiatricDiagnoses_46: 'previousPsychiatricDiagnoses_46',
      previousPsychiatricDiagnoses_47: 'previousPsychiatricDiagnoses_47',
      currentlySeeingMental_50: 'currentPreviousProviders_117',
      // @ts-expect-error TS(1117): An object literal cannot have multiple properties ... Remove this comment to see the full error message
      relationshipStatus_20: 'relationshipStatus',
      highestLevelEducation_35: 'education_132',
      // @ts-expect-error TS(1117): An object literal cannot have multiple properties ... Remove this comment to see the full error message
      workingNow_36: 'workingNow_134',
      workingNow_37: 'workingNow_135',
      currentlyInvolvedLegal_94: 'currentlyInvolvedLegal_137',
      currentlyInvolvedLegal_95: 'currentlyInvolvedLegal_138',
      oftenDrinkContaining_85: 'clientDrinks_142',
      oftenDrinkContaining_86: 'clientDrinks_141',
      oftenDrinkContaining_87: 'clientBinges_143',
      oftenUseTobacco_88: 'clientUsesTobacco_144',
      oftenUsePrescription_89: 'clientUsesPrescription_145',
      oftenUseIllegal_90: 'clientUsesIllicit_146',
      oftenUseCannabis_91: 'clientUsesCannabis_147',
      historyPsychiatricEmotional_31: 'historyPsychiatricEmotional_152',
      historyPsychiatricEmotional_32: 'historyPsychiatricEmotional_153',
      levelPainToday_67: 'painLevel_159',
      levelPainToday_68: 'painLevel_160',
      pregnantPlanningBecome_66: 'pregnancy_162',
      currentlyTakingPrescription_60: 'currentlyTakingPrescription_164',
      currentlyTakingPrescription_62: 'currentMedications_165',
      goalsWorkingPrescriber_48_CLE: 'goalsForRecovery_CLE',
      goalsWorkingPrescriber_48_SAP: 'goalsForRecovery_SAP',
      receivedOutpatientTreatment: 'oupatientSubstanceServiceHistory',
      areasOfFunctioning: 'areasOfFunctioning',
      checkSymptomsExperienced_81: 'symptomsEndorsedByClientIntake',
      chronicOngoingMedical_64: 'chronicOngoingMedical_156',
    },
  },
  cleSessionNote: {
    cleInitialNote: {
      caseFormulation: 'caseFormulation',
      summary_301: 'summary_148',
      primaryDiagnosis_305: 'primaryDiagnosis_153',
      secondaryDiagnosis_308: 'secondaryDiagnosis_154',
      isMentalLeaveIndicated: 'isMentalLeaveIndicated',
      paperworkYouComplete: 'paperworkYouComplete',
      componentsOfCarePlan: 'componentsOfCarePlan',
      moreDetailsCarePlan: 'moreDetailsCarePlan',
      leaveStartDate: 'leaveStartDate',
      labsImaging_290: 'labsImaging_139',
      assessments_291: 'phq9Gad7Pcl_142_CLE',
    },
    cleSessionNote: {
      summary_148: 'summary_148',
      primaryDiagnosis_153: 'primaryDiagnosis_153',
      secondaryDiagnosis_154: 'secondaryDiagnosis_154',
      isMentalLeaveIndicated: 'isMentalLeaveIndicated',
      paperworkYouComplete: 'paperworkYouComplete',
      componentsOfCarePlan: 'componentsOfCarePlan',
      moreDetailsCarePlan: 'moreDetailsCarePlan',
      leaveStartDate: 'leaveStartDate',
      labsImaging_139: 'labsImaging_139',
      phq9Gad7Pcl_142_CLE: 'phq9Gad7Pcl_142_CLE',
      phq9Gad7Pcl_142_SAP: 'phq9Gad7Pcl_142_SAP',
    },
    cleClientIntake: {
      describeSymptomsAffected: 'describeSymptomsAffected',
    },
  },
}
