import { defineMessages } from 'react-intl'

import { LANDING_PAGE_INFO } from './types'

export const CALENDAR_LANDING_TITLES = defineMessages({
  [LANDING_PAGE_INFO.REFERRALS]: {
    defaultMessage: 'More referrals',
    description: 'Title for providers receiving more referrals ',
  },
  [LANDING_PAGE_INFO.FASTER_BOOKING]: {
    defaultMessage: 'Faster confirmed appointments',
    description: 'Title for providers getting appointments booked quicker',
  },
  [LANDING_PAGE_INFO.EASIER_MANAGEMENT]: {
    defaultMessage: 'Easier calendar management',
    description: 'Title for provider calendar management with ease',
  },
})

export const CALENDAR_LANDING_INFO = defineMessages({
  [LANDING_PAGE_INFO.REFERRALS]: {
    defaultMessage: 'Providers receive twice as many referrals when clients can book directly.',
    description: 'Explanation on better referrals for providers with calendars connected',
  },
  [LANDING_PAGE_INFO.FASTER_BOOKING]: {
    defaultMessage: 'Lyra members can book a session with you that fits both your schedule and theirs.',
    description: 'Explanation on ease of members booking with providers with calendars connected',
  },
  [LANDING_PAGE_INFO.EASIER_MANAGEMENT]: {
    defaultMessage:
      'We’ll only show Lyra members available spots you set up. Easily set recurring and one-time availability.',
    description: 'Explanation on calendar functionality and ease of use',
  },
})

export const UNSAVED_CHANGES_TITLE = defineMessages({
  HYBRID_SETUP: {
    defaultMessage: 'Do you want to exit Lyra calendar setup?',
    description: 'Title of modal for provider exiting calendar setup',
  },
  DEFAULT: {
    defaultMessage: 'You have unsaved changes',
    description: 'Title of modal where provider has unsaved changes',
  },
})

export const UNSAVED_CHANGES_CANCEL_TEXT = defineMessages({
  HYBRID_SETUP: {
    defaultMessage: 'Exit',
    description: 'Discard changes and exit calendar setup',
  },
  DEFAULT: {
    defaultMessage: 'Discard changes',
    description: 'Discard changes and navigate back',
  },
})

export const UNSAVED_CHANGES_CONFIRMATION_TEXT = defineMessages({
  HYBRID_SETUP: {
    defaultMessage: 'Continue setup',
    description: 'Stay on current page and continue setup',
  },
  DEFAULT: {
    defaultMessage: 'Continue editing',
    description: 'Stay on current page and continue editing template',
  },
})

export const UNSAVED_CHANGES_BODY_TEXT = defineMessages({
  HYBRID_SETUP: {
    defaultMessage: 'Your Lyra calendar won’t be live but your Google account will remain connected.',
    description: 'Body of modal where provider has unsaved changes and attempts to exit',
  },
  BOOKABLE_SETUP: {
    defaultMessage:
      'Changes will not be saved and your Lyra calendar will not be set up. You can return later and restart the setup.',
    description: 'Body of modal where provider has unsaved changes',
  },
  HYBRID_EDIT: {
    defaultMessage: 'Any changes you’ve made to your recurring availability will not be saved',
    description: 'Body of modal where provider has unsaved changes for their availability',
  },
  BOOKABLE_EDIT: {
    defaultMessage: 'Any changes you’ve made to your calendar spots template will not be saved',
    description: 'Body of modal where provider has unsaved changes',
  },
})

export const SAVE_CONFIRMATION_TITLE = defineMessages({
  HYBRID_SETUP: {
    defaultMessage: 'Ready to go live?',
    description: 'Title of modal to confirm provider calendar going live',
  },
  HYBRID_EDIT: {
    defaultMessage: 'Save recurring availability?',
    description: 'Title of modal to confirm update to recurring availability',
  },
  BOOKABLE: {
    defaultMessage: 'Save your calendar spots template?',
    description: 'Title of modal where provider is attempting to save availability',
  },
})

export const SAVE_CONFIRMATION_BUTTON_TEXT = defineMessages({
  HYBRID_SETUP: {
    defaultMessage: 'Continue',
    description: 'Button to save availability and go live',
  },
  DEFAULT: {
    defaultMessage: 'Save',
    description: 'Button to save availability',
  },
})

export const SAVE_CONFIRMATION_BODY_TEXT = defineMessages({
  HYBRID_SETUP: {
    defaultMessage:
      'We’ll remove any availability that conflicts with events from your external calendars. Once your calendar is live, clients will be able to see and book your open availability. You’ll be able to make changes on the next page once your calendar loads',
    description:
      'Modal body text explaining how availability and external calendar choices effect the Lyra calendar experience once live',
  },
  BOOKABLE_SETUP: {
    defaultMessage:
      'Once saved, clients will be able to book during these times. You will also be able to adjust individual weekly availability as needed.',
    description: 'Body of modal where provider is attempting to save availability',
  },
  EDIT: {
    defaultMessage: 'Once saved, clients will be able to book during these times, starting with the current week.',
    description: 'Body of modal where provider is attempting to save availability',
  },
})

export const CALENDAR_SETUP_HEADER_TEXT = defineMessages({
  HYBRID_SETUP: {
    defaultMessage: 'Select recurring times you are available to see new clients',
    description: 'Header for calendar recurring availability setup',
  },
  BOOKABLE_SETUP: {
    defaultMessage: 'Set up your calendar spots template',
    description: 'Header for calendar setup',
  },
  HYBRID_EDIT: {
    defaultMessage: 'Edit recurring availability',
    description: 'Header for editing availability',
  },
  BOOKABLE_EDIT: {
    defaultMessage: 'Edit your calendar spots template',
    description: 'Header for calendar setup',
  },
})

export const CALENDAR_SETUP_DESCRIPTION_TEXT = defineMessages({
  HYBRID: {
    defaultMessage:
      'You can adjust your availability later for specific weeks as needed. We’ll remove availability on your Lyra calendar that conflicts with your events from Google calendars you selected in the previous step.',
    description: 'Subhead for calendar recurring avialability setup',
  },
  BOOKABLE: {
    defaultMessage:
      'Clients can see and book these default times. You can adjust availability for specific weeks as needed.',
    description: 'Subhead for calendar setup',
  },
})
