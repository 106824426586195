import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { useTheme } from 'styled-components/native'

import { BodyText, IconBadge } from '../../atoms'
import { Corners } from '../../atoms/iconBadge/IconBadge'
import { BodyTextSize } from '../../styles'
import { ConversationTag } from '../../ui-models'
import { tID } from '../../utils'

export type ConversationItemTagProps = {
  tag: ConversationTag
}

export const ConversationItemTag: React.FC<ConversationItemTagProps> = ({ tag }) => {
  const { colors } = useTheme()
  const intl = useIntl()

  const badgeData = useMemo(() => {
    switch (tag) {
      case ConversationTag.INACTIVE:
        return {
          backgroundColor: colors.backgroundInactive,
          textColor: colors.textInactive,
          text: intl.formatMessage({
            defaultMessage: 'Inactive',
            description: 'Badge for an inactive client',
          }),
        }
      case ConversationTag.UNSCHEDULED:
        return {
          backgroundColor: colors.backgroundWarning,
          textColor: colors.textWarning,
          text: intl.formatMessage({
            defaultMessage: 'Unscheduled',
            description: 'Badge for an unscheduled client',
          }),
        }
    }
  }, [colors.backgroundInactive, colors.backgroundWarning, colors.textInactive, colors.textWarning, intl, tag])

  return (
    <IconBadge
      testId={tID('ConversationItemTag')}
      text={<BodyText text={badgeData.text} size={BodyTextSize.BADGE} color={badgeData.textColor} />}
      color={badgeData.backgroundColor}
      cornerType={Corners.CURVED}
    />
  )
}
