import React, { FunctionComponent } from 'react'
import { View } from 'react-native'

import { LinearGradient } from 'expo-linear-gradient'
import styled, { useTheme } from 'styled-components/native'

import { ThemeType } from '../../utils/themes/ThemeProvider'
import { tID } from '../../utils/utils'

export interface IconBadgeProps {
  text?: React.ReactNode
  color?: string
  gradientFill?: boolean
  textColor?: string
  frontIcon?: React.ReactNode
  backIcon?: React.ReactNode
  testId?: string
  cornerType?: Corners
  hideText?: boolean
  noPadding?: boolean
}

const CustomContainer = ({
  gradientFill = false,
  testID,
  children,
  ...props
}: {
  gradientFill?: boolean
  testID?: string
  children: React.ReactNode
}) => {
  const { colors } = useTheme() as ThemeType

  return gradientFill ? (
    <LinearGradient
      testID={testID}
      colors={[colors.gradientBoxStart, colors.gradientBoxEnd]}
      start={[0, 0]}
      end={[1, 1]}
      locations={[0.3316, 0.7844]}
      {...props}
    >
      {children}
    </LinearGradient>
  ) : (
    <View testID={testID} {...props}>
      {children}
    </View>
  )
}

const Container = styled(CustomContainer)<{
  theme: ThemeType
  color?: string
  cornerType: Corners
  hideText?: boolean
  noPadding?: boolean
}>(({ theme, color, cornerType, hideText, noPadding }) => {
  return {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: noPadding ? '0px' : `0 ${theme.spacing['8px']}`,
    backgroundColor: color ?? theme.colors.primaryButtonBackgroundDefault,
    minWidth: '39px',
    minHeight: '24px',
    borderRadius: cornerType === Corners.ROUND ? '20px' : cornerType === Corners.CURVED ? '4px' : '0px',
    ...(hideText && {
      minWidth: '24px',
      padding: 0,
    }),
  }
})

const TextContainer = styled(View)<{
  theme: ThemeType
  hasFrontIcon: boolean
  hasBackIcon: boolean
  textColor?: string
}>(({ theme, hasFrontIcon, hasBackIcon, textColor }) => {
  return {
    flexShrink: 1,
    paddingLeft: hasFrontIcon ? theme.spacing['4px'] : 0,
    paddingRight: hasBackIcon ? theme.spacing['4px'] : 0,
    ...(textColor && { color: textColor }),
  }
})

export enum Corners {
  ROUND = 'round',
  CURVED = 'curved',
  SHARP = 'sharp',
}

export const IconBadge: FunctionComponent<IconBadgeProps> = ({
  text,
  color,
  gradientFill,
  textColor,
  frontIcon,
  backIcon,
  testId = 'IconBadge',
  cornerType = Corners.ROUND,
  hideText = false,
  noPadding = false,
  ...props
}) => {
  return (
    <Container
      gradientFill={gradientFill}
      testID={tID(testId)}
      color={color}
      cornerType={cornerType}
      hideText={hideText}
      noPadding={noPadding}
      {...props}
    >
      <View>{frontIcon}</View>
      {!hideText && text && (
        <TextContainer hasFrontIcon={Boolean(frontIcon)} hasBackIcon={Boolean(backIcon)} textColor={textColor}>
          {text}
        </TextContainer>
      )}
    </Container>
  )
}
