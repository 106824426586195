import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { isEmpty, isNil } from 'lodash-es'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import styled, { useTheme } from 'styled-components/native'

import { AssessmentSet, Episode } from '@lyrahealth-inc/shared-app-logic'
import {
  BodyText,
  BodyTextSize,
  LoadingIndicator,
  PrimaryButton,
  Subhead,
  SubheadSize,
  ThemeType,
  tID,
  TypeAhead,
  useFetcher,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { bannerMessages } from '../../common/constants/appConstants'
import { addAlert } from '../../data/alertActions'
import { getAssessmentTracksByProgram, updateAssessmentTrack } from '../episodes/data/episodesAutoActions'

export type AssessmentTrackSettingsModalProps = {
  actions: any
  closeModal: () => void
  selectedEpisode: Episode
  isProviderLTCoach: boolean
}

const BodyTextContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  margin: `${spacing['32px']} 0 28px`,
}))

export const AssessmentTrackSettingsModal: React.FC<AssessmentTrackSettingsModalProps> = ({
  selectedEpisode,
  closeModal,
  actions: { getAssessmentTracksByProgram, updateAssessmentTrack, addAlert },
  isProviderLTCoach,
}) => {
  const [focused, setFocused] = useState<boolean>(false)
  const [savingAssessmentTrackSelection, setSavingAssessmentTrackSelection] = useState<boolean>(false)
  const [selectedAssessmentTrack, setSelectedAssessmentTrack] = useState<AssessmentSet | null>(null)
  const [assessmentTrackDisplayName, setAssessmentTrackDisplayName] = useState<string>('')
  const { colors } = useTheme()

  // Load up the assessment_sets / tracks we will display in the dropdown
  const [loadingAssessmentTracks, assessmentTracks = []] = useFetcher([
    getAssessmentTracksByProgram,
    { programId: selectedEpisode.program_id },
  ])

  // Set the current episode's assessment_set
  useEffect(() => {
    const selectedAssessmentSet = (assessmentTracks as AssessmentSet[])
      .filter((assessmentSet) => assessmentSet.display_name)
      .find((assessmentSet: AssessmentSet) => selectedEpisode.assessment_set_id === assessmentSet.id)

    setSelectedAssessmentTrack(selectedAssessmentSet ?? null)
  }, [assessmentTracks, selectedEpisode])

  // Ensure `assessmentTrackDisplayName` is updated when `selectedAssessmentTrack` changes
  useEffect(() => {
    if (selectedAssessmentTrack) {
      const newDisplayName = `${selectedAssessmentTrack.display_name}${
        selectedAssessmentTrack.display_name_subtext ? ' · ' + selectedAssessmentTrack.display_name_subtext : ''
      }`
      setAssessmentTrackDisplayName(newDisplayName)
    } else {
      setAssessmentTrackDisplayName('') // Reset when there's no selection
    }
  }, [selectedAssessmentTrack])

  // sorted dropdown items. Top items starting with A going down to Z
  const dropdownItems = useMemo(
    () =>
      (assessmentTracks as AssessmentSet[])
        .filter((assessmentSet) => !isNil(assessmentSet.display_name) && assessmentSet.name !== 'unset')
        .map((assessmentSet) => ({
          id: assessmentSet.id,
          text: `${assessmentSet.display_name}${
            assessmentSet.display_name_subtext ? ' · ' + assessmentSet.display_name_subtext : ''
          }`,
        }))
        .sort((a, b) => a.text.localeCompare(b.text)),
    [assessmentTracks],
  )

  const dropddownItemsLabels = useMemo(() => {
    return dropdownItems.map(({ text }) => text).filter((text) => text.trim() !== '')
  }, [dropdownItems])

  const changeAssessmentTrack = useCallback(
    (value: string) => {
      const selectedDropdownItem = dropdownItems.find((dropdown) => dropdown.text === value)
      const selectedAssessmentTrack = (assessmentTracks as AssessmentSet[]).find(
        (assessmentTrack) => assessmentTrack.id === selectedDropdownItem?.id,
      )
      setSelectedAssessmentTrack(selectedAssessmentTrack ?? null)
    },
    [dropdownItems, assessmentTracks],
  )

  const onUpdateAssessmentTrack = () => {
    setSavingAssessmentTrackSelection(true)
    updateAssessmentTrack({
      id: selectedEpisode.id,
      assessment_set_id: selectedAssessmentTrack?.id,
    })
      .then(() => {
        addAlert({
          show: true,
          contents: bannerMessages.ASSESSMENT_TRACK_SUCCESS,
          style: 'success',
          expires: false,
          autoDismissTimer: 10000,
        })
      })
      .finally(() => {
        closeModal()
        setSavingAssessmentTrackSelection(false)
      })
  }

  return (
    <>
      <Subhead
        text={
          <FormattedMessage
            defaultMessage='Select assessment track'
            description='header for modal to select assessment track'
          />
        }
        size={SubheadSize.LARGE}
      />
      <BodyTextContainer>
        <BodyText
          size={BodyTextSize.DEFAULT}
          text={
            isProviderLTCoach ? (
              <FormattedMessage
                defaultMessage='Do not change this setting if you are not part of the Coaching for Parents program.'
                description='Label for select assessment track indicating not to change this setting if you are not part of the Coaching for Parents program'
              />
            ) : (
              <FormattedMessage
                defaultMessage='Do not change this setting if you have not yet been trained on proper usage for Lyra clients.'
                description='Label for select assessment track indicating not to change this setting if you have not yet been trained on proper usage for Lyra clients.'
              />
            )
          }
          color={colors.textWarning}
        />
      </BodyTextContainer>
      {loadingAssessmentTracks ? (
        <LoadingIndicator />
      ) : (
        <TypeAhead
          key={assessmentTrackDisplayName}
          value={assessmentTrackDisplayName}
          name='displayAssessmentTracks'
          inputName='displayAssessmentTracks-input'
          onChange={changeAssessmentTrack}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          accessibilityLabel={dropddownItemsLabels[0] ?? ''}
          fixedDropdownMenuPosition
          multiSelect={false}
          isFocused={focused}
          options={isEmpty(dropddownItemsLabels) ? [''] : dropddownItemsLabels}
        />
      )}
      <PrimaryButton
        onPress={onUpdateAssessmentTrack}
        text={<FormattedMessage defaultMessage='Save' description='save button label' />}
        testID={tID('AssessmentTrackSettingsModal-save')}
        loading={savingAssessmentTrackSelection}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators({ getAssessmentTracksByProgram, updateAssessmentTrack, addAlert }, dispatch),
})

export default connect(null, mapDispatchToProps)(AssessmentTrackSettingsModal)
