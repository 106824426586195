import React, { FunctionComponent, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'

import { addMinutes, isValid, parse } from 'date-fns'
import { format, zonedTimeToUtc } from 'date-fns-tz'
import { isEmpty, range, toNumber } from 'lodash-es'
import styled from 'styled-components/native'

import { Appointment, thisFieldIsRequiredErrorMessage } from '@lyrahealth-inc/shared-app-logic'

import { FormContainer } from '../../atoms/formElements/formUtils'
import { SelectFieldRFF } from '../../atoms/formElements/SelectField'
import { Headline } from '../../atoms/headline/Headline'
import { PrimaryButton } from '../../atoms/primaryButton/PrimaryButton'
import { SecondaryButton } from '../../atoms/secondaryButton/SecondaryButton'
import { Subhead } from '../../atoms/subhead/Subhead'
import { useMediaQuerySize } from '../../hooks/useMediaQuerySize'
import { HeadlineSize, SubheadSize } from '../../styles'
import { ThemeType } from '../../utils'
import { tID } from '../../utils/utils'

export enum BookingSteps {
  SCHEDULE = 'schedule',
}

export interface SessionDetailsProps {
  appointment?: Appointment
  appointmentDurationOptions?: number[]
  appointmentType: string
  displayAppointmentDuration: boolean
  displayRepeatingFields: boolean
  initialValues?: SessionDetailsFormValues
  meetingFormats?: { value: string; text: string }[]
  nextStep?: BookingSteps | null
  onCancel: () => void
  onSubmitForm: (values: SessionDetailsFormValues) => void
  partyName: string
  sessionFrequencyOptions?: { label: string; value: number }[]
  submitting?: boolean
  timeZone?: string
}

export type SessionDetailsFormValues = {
  appointmentDuration?: string
  meetingFormat?: string
  sessionFrequency?: string
  repeatFor?: string
  appointmentStatus?: string
}

const FieldsContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: spacing['32px'],
}))

const TitleContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  paddingHorizontal: spacing['16px'],
}))

const AppointmentInfoContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  marginTop: spacing['32px'],
  paddingHorizontal: spacing['16px'],
}))

const AppointmentDurationContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  marginTop: spacing['24px'],
}))

const FieldContainer = styled.View<{ theme: ThemeType; isMinWidthTablet: boolean }>(
  ({ theme: { spacing }, isMinWidthTablet }) => ({
    width: isMinWidthTablet ? '50%' : '100%',
    paddingHorizontal: spacing['16px'],
  }),
)

const ButtonContainer = styled.View<{ theme: ThemeType; isMinWidthTablet: boolean }>(
  ({ theme: { spacing }, isMinWidthTablet }) => ({
    paddingHorizontal: spacing['16px'],
    display: 'flex',
    flexDirection: isMinWidthTablet ? 'row' : 'column',
    gap: spacing['16px'],
  }),
)

export const SessionDetails: FunctionComponent<SessionDetailsProps> = ({
  appointment,
  appointmentDurationOptions = [25, 50, 80],
  appointmentType,
  displayAppointmentDuration,
  displayRepeatingFields,
  initialValues = {
    appointmentDuration: '50',
    meetingFormat: 'video',
    sessionFrequency: '0',
  },
  meetingFormats = [],
  nextStep,
  onCancel,
  onSubmitForm,
  partyName,
  sessionFrequencyOptions = [
    { label: 'Does not repeat', value: 0 },
    { label: 'Repeat every two weeks', value: 14 },
  ],
  submitting,
  timeZone,
}) => {
  const { formatDate, formatTime, formatMessage } = useIntl()
  const { isMinWidthTablet } = useMediaQuerySize()
  const [sessionFrequency, setSessionFrequency] = useState<string | undefined>(initialValues.sessionFrequency)
  const [repeatFor, setRepeatFor] = useState<string | undefined>(undefined)
  const [isRepeatForDisabled, setIsRepeatForDisabled] = useState(true)
  const userTimeZone =
    timeZone ?? String(format(new Date(), 'zzz', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }))
  let appointmentStartInLocalTime: Date, appointmentDuration: string

  if (!isEmpty(appointment) && !isEmpty(appointment.startDate)) {
    appointmentStartInLocalTime = zonedTimeToUtc(
      parse(`${appointment.startDate} ${appointment.startTime}`, 'yyyy-MM-dd HH:mm:ss', new Date()),
      appointment.timeZone,
    )
    const appointmentEndInLocalTime = addMinutes(appointmentStartInLocalTime, 60)
    appointmentDuration = displayAppointmentDuration
      ? formatTime(appointmentStartInLocalTime, {
          hour: 'numeric',
          minute: 'numeric',
          ...(timeZone && { timeZone }),
        }) + ` ${userTimeZone}`
      : formatTime(appointmentStartInLocalTime, {
          hour: 'numeric',
          minute: 'numeric',
          ...(timeZone && { timeZone }),
        }) +
        ' - ' +
        formatTime(appointmentEndInLocalTime, {
          hour: 'numeric',
          minute: 'numeric',
          ...(timeZone && { timeZone }),
        }) +
        ` ${userTimeZone}`
  }

  const validateForm = (values: SessionDetailsFormValues) => {
    const errors: {
      appointmentDuration?: string
      meetingFormat?: string
      sessionFrequency?: string
      repeatFor?: string
    } = {}
    if (displayAppointmentDuration && !values.appointmentDuration) {
      errors.appointmentDuration = formatMessage(thisFieldIsRequiredErrorMessage)
    }
    if (meetingFormats.length && !values.meetingFormat) {
      errors.meetingFormat = formatMessage(thisFieldIsRequiredErrorMessage)
    }
    if (displayRepeatingFields) {
      if (!sessionFrequency) {
        errors.sessionFrequency = formatMessage(thisFieldIsRequiredErrorMessage)
      } else if (toNumber(sessionFrequency) >= 2 && !repeatFor) {
        errors.repeatFor = formatMessage(thisFieldIsRequiredErrorMessage)
      }
    }

    return errors
  }

  const onSubmit = (values: SessionDetailsFormValues) => {
    const payload = {
      appointmentDuration: values.appointmentDuration,
      ...(initialValues.appointmentStatus && { appointmentStatus: initialValues.appointmentStatus }),
      meetingFormat: values.meetingFormat,
      sessionFrequency,
      repeatFor: isRepeatForDisabled ? '0' : repeatFor,
    }
    onSubmitForm(payload)
  }

  const onFrequencyChange = (e: string) => {
    setSessionFrequency(e)
    const newSessionFrequencyDays = e ? parseInt(e) : 0
    if (newSessionFrequencyDays > 0) {
      setRepeatFor('2')
      setIsRepeatForDisabled(false)
    } else {
      setRepeatFor('')
      setIsRepeatForDisabled(true)
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validateForm}
      render={({ handleSubmit }) => {
        return (
          <FormContainer handleSubmit={handleSubmit}>
            <TitleContainer testID={tID('SessionDetails-titleContainer')}>
              <Headline
                size={HeadlineSize.SMALL}
                text={
                  isEmpty(appointment) ? (
                    <FormattedMessage
                      defaultMessage='Create {appointmentType, select,
                        new {new}
                        rescheduled {rescheduled}
                        other {new}
                      } session with {partyName}'
                      description='Header when scheduling an appointment'
                      values={{
                        appointmentType,
                        partyName,
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage='Confirm {appointmentType, select,
                        new {new}
                        rescheduled {rescheduled}
                        other {new}
                      } session with {partyName}'
                      description='Header when confirming an appointment'
                      values={{
                        appointmentType,
                        partyName,
                      }}
                    />
                  )
                }
              />
            </TitleContainer>
            {!isEmpty(appointment) && isValid(appointmentStartInLocalTime) && (
              <AppointmentInfoContainer>
                <Subhead
                  size={SubheadSize.MEDIUM}
                  text={formatDate(appointmentStartInLocalTime, {
                    weekday: 'long',
                    month: 'long',
                    day: '2-digit',
                    timeZone,
                  })}
                />
                <AppointmentDurationContainer>
                  <Subhead size={SubheadSize.MEDIUM} text={appointmentDuration} />
                </AppointmentDurationContainer>
              </AppointmentInfoContainer>
            )}
            <FieldsContainer>
              {displayAppointmentDuration && (
                <FieldContainer isMinWidthTablet={isMinWidthTablet}>
                  <Field
                    name='appointmentDuration'
                    component={SelectFieldRFF}
                    label={formatMessage({
                      defaultMessage: 'Duration',
                      description: 'label for duration selection field',
                    })}
                    options={appointmentDurationOptions.map((number: number) => {
                      return { label: `${number} minutes`, value: number }
                    })}
                  />
                </FieldContainer>
              )}
              {meetingFormats.length > 0 && (
                <FieldContainer isMinWidthTablet={isMinWidthTablet}>
                  <Field
                    name='meetingFormat'
                    component={SelectFieldRFF}
                    label={formatMessage({
                      defaultMessage: 'Session Type',
                      description: 'label for session type selection field',
                    })}
                    options={meetingFormats.map((option: { value: string; text: string }) => {
                      return { label: option.text, value: option.value }
                    })}
                  />
                </FieldContainer>
              )}
              {displayRepeatingFields && (
                <>
                  <FieldContainer isMinWidthTablet={isMinWidthTablet}>
                    <Field
                      name='sessionFrequency'
                      component={SelectFieldRFF}
                      label={formatMessage({
                        defaultMessage: 'Frequency',
                        description: 'label for session frequency selection field',
                      })}
                      options={sessionFrequencyOptions}
                      input={{
                        onChange: onFrequencyChange,
                      }}
                      disablePlaceholder
                    />
                  </FieldContainer>
                  <FieldContainer isMinWidthTablet={isMinWidthTablet}>
                    <Field
                      name='repeatFor'
                      component={SelectFieldRFF}
                      input={{
                        onChange: (e: string) => setRepeatFor(e),
                        value: repeatFor,
                      }}
                      disabled={isRepeatForDisabled}
                      label={formatMessage({
                        defaultMessage: 'Repeat For',
                        description: 'label for number of session repeats',
                      })}
                      options={range(2, 9).map((number: number) => {
                        return { label: `${number} sessions`, value: number }
                      })}
                    />
                  </FieldContainer>
                </>
              )}
              <ButtonContainer isMinWidthTablet={isMinWidthTablet}>
                <PrimaryButton
                  text={
                    nextStep === BookingSteps.SCHEDULE
                      ? formatMessage({
                          defaultMessage: 'Select timeslot',
                          description: 'button label to select a session timeslot',
                        })
                      : formatMessage({
                          defaultMessage: 'Confirm Session',
                          description: 'button label to confirm a session timeslot',
                        })
                  }
                  disabled={submitting}
                  loading={submitting}
                  onPress={handleSubmit}
                  fullWidth={!isMinWidthTablet}
                  testID={tID('SessionDetails-confirm-session')}
                />
                {nextStep !== BookingSteps.SCHEDULE && (
                  <SecondaryButton
                    text={formatMessage({
                      defaultMessage: 'Cancel Session',
                      description: 'button label to cancel selected session',
                    })}
                    onPress={onCancel}
                    fullWidth={!isMinWidthTablet}
                    testID={tID('SessionDetails-cancel-session')}
                  />
                )}
              </ButtonContainer>
            </FieldsContainer>
          </FormContainer>
        )
      }}
    />
  )
}
