import React, { FunctionComponent, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { View } from 'react-native'
import { Popover } from 'react-native-popper'

import styled, { useTheme } from 'styled-components/native'

import {
  BodyText,
  ButtonModifier,
  EditIcon,
  IconBadge,
  IconButton,
  PressableOpacity,
  QuestionMarkIcon,
  SecondaryButton,
  Subhead,
  XIcon,
} from '../../atoms'
import { BodyTextSize, SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

export type ProviderHybridCalendarSettingsProps = {
  availabilityText?: string
  onAvailabilityEditPressed?: () => void
  subCalendarSelection?: boolean
  onSubCalendarEditPressed?: () => void
  onOpenHelpCenterPressed?: () => void
}

const Container = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  border: `1px solid ${theme.colors.borderDefault}`,
  padding: theme.spacing['24px'],
  borderRadius: '16px',
  gap: theme.spacing['24px'],
}))

const Row = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const RowText = styled.View({
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'start',
})

const RowDivider = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  paddingBottom: theme.spacing['24px'],
  borderBottomWidth: '1px',
  borderBottomColor: theme.colors.borderDefault,
}))

const BadgeContainer = styled.View({
  width: '93px',
  height: '26px',
  alignContent: 'flex-start',
  alignSelf: 'center',
})

const PopoverSection = styled.View({
  alignContent: 'flex-start',
  flexDirection: 'row',
})

const PopoverContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'column',
  backgroundColor: theme.colors.backgroundPrimary,
  borderRadius: '16px',
  padding: theme.spacing['16px'],
  gap: theme.spacing['16px'],
  width: '392px',
  boxShadow: `0 2px 10px ${theme.colors.shadowLow}`,
}))

const PopoverRow = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const PopoverText = styled(BodyText)({
  maxWidth: '292px',
})

const CloseButton = styled(PressableOpacity)({
  alignSelf: 'flex-end',
})

const PencilIconButton = styled(IconButton)({
  alignSelf: 'center',
})

export const ProviderHybridCalendarSettings: FunctionComponent<ProviderHybridCalendarSettingsProps> = ({
  availabilityText = '',
  onAvailabilityEditPressed,
  subCalendarSelection,
  onSubCalendarEditPressed,
  onOpenHelpCenterPressed,
}) => {
  const theme = useTheme() as ThemeType
  const { formatMessage } = useIntl()
  const [isOpen, setIsOpen] = useState(false)
  const pressableRef = useRef(null)

  return (
    <Container testID={tID('ProviderHybridCalendarSettings')}>
      <RowDivider>
        <Row>
          <PopoverSection>
            <Subhead
              size={SubheadSize.XSMALL}
              text={
                <FormattedMessage
                  defaultMessage='Google Calendar'
                  description='Subheader for Google Calendar information'
                />
              }
            />
            <View ref={pressableRef}>
              <IconButton
                onPress={() => setIsOpen(true)}
                leftIcon={<QuestionMarkIcon fillColor={theme.colors.iconDefault} width={16} />}
                iconColor={theme.colors.iconDefault}
                modifier={ButtonModifier.UNSTYLED}
                accessibilityLabel={
                  <FormattedMessage
                    defaultMessage='Google Calendar account information tooltip'
                    description='Button to open Google Calendar account tooltip popover'
                  />
                }
                testID={tID('ProviderHybridCalendarSettings-google-calendar-info-button')}
              />
            </View>
            <Popover isOpen={isOpen} onOpenChange={setIsOpen} trigger={pressableRef} placement='top left'>
              <Popover.Backdrop />
              <Popover.Content>
                <PopoverContainer>
                  <PopoverRow>
                    <Subhead
                      tabIndex={0}
                      size={SubheadSize.SMALL}
                      text={
                        <FormattedMessage
                          defaultMessage='Need to update your Google Account?'
                          description='Header for provider Google account help popover'
                        />
                      }
                    />
                    <CloseButton
                      aria-label={formatMessage({
                        defaultMessage: 'Close dialog',
                        description: 'Button text to close the Zendesk dialog',
                      })}
                      hitSlop={60}
                      onPress={() => setIsOpen(false)}
                    >
                      <XIcon size={24} fillColor={theme.colors.iconDefault} />
                    </CloseButton>
                  </PopoverRow>
                  <PopoverText
                    color={theme.colors.textSecondary}
                    text={
                      <FormattedMessage
                        defaultMessage='Submit a tech ticket to update your Google Account with Lyra.'
                        description='Text describing how a provider can update their Google Account information'
                      />
                    }
                  />
                  <SecondaryButton
                    testID={tID('ProviderHybridCalendarSettings-open-help-center-button')}
                    onPress={() => onOpenHelpCenterPressed?.()}
                    text={
                      <FormattedMessage
                        defaultMessage='Open Help Center'
                        description='Button to get open help center resource for updating Google account'
                      />
                    }
                  />
                </PopoverContainer>
              </Popover.Content>
            </Popover>
          </PopoverSection>
          <BadgeContainer>
            <IconBadge
              text={
                <BodyText
                  color={theme.colors.textSuccess}
                  size={BodyTextSize.BADGE}
                  text={
                    <FormattedMessage
                      defaultMessage='Connected'
                      description='Badge text showing that the calendar is connected'
                    />
                  }
                />
              }
              color={theme.colors.backgroundSuccess}
            />
          </BadgeContainer>
        </Row>
      </RowDivider>
      {subCalendarSelection && (
        <RowDivider>
          <Row>
            <Subhead
              size={SubheadSize.XSMALL}
              text={
                <FormattedMessage
                  defaultMessage='External calendars'
                  description='Subheader for external calendar settings'
                />
              }
            />
            <PencilIconButton
              onPress={() => onSubCalendarEditPressed?.()}
              leftIcon={<EditIcon size={24} isFilled={false} />}
              iconColor={theme.colors.iconActive}
              modifier={ButtonModifier.UNSTYLED}
              accessibilityLabel={
                <FormattedMessage
                  defaultMessage='Edit external calendars'
                  description='Button to edit external calendar usage'
                />
              }
              testID={tID('ProviderHybridCalendarSettings-edit-external-calendars-button')}
            />
          </Row>
        </RowDivider>
      )}
      <Row>
        <RowText>
          <Subhead
            size={SubheadSize.XSMALL}
            text={
              <FormattedMessage
                defaultMessage='Recurring availability'
                description='Subheader for recurring calendar availability settings'
              />
            }
          />
          <BodyText
            color={theme.colors.textSecondary}
            text={
              <FormattedMessage
                defaultMessage='Availability on {availabilityText}'
                description='Text describing which days of the week the provider is available'
                values={{ availabilityText }}
              />
            }
          />
        </RowText>
        <PencilIconButton
          onPress={() => onAvailabilityEditPressed?.()}
          leftIcon={<EditIcon size={24} isFilled={false} />}
          iconColor={theme.colors.iconActive}
          modifier={ButtonModifier.UNSTYLED}
          accessibilityLabel={
            <FormattedMessage defaultMessage='Edit Availability' description='Button to edit calendar availability' />
          }
          testID={tID('ProviderHybridCalendarSettings-edit-availability-button')}
        />
      </Row>
    </Container>
  )
}
