import React from 'react'
import { Outlet } from 'react-router'
import { useLocation, useNavigate } from 'react-router-dom'

import styled from 'styled-components/native'

import { NavBar, ThemeType, tID } from '@lyrahealth-inc/ui-core-crossplatform'

import {
  LC_CALENDAR_ACTIVATE_SETUP,
  LC_CALENDAR_CURRENT,
  LC_CALENDAR_SELECT_CALENDARS,
} from '../common/constants/routingConstants'

const FixedContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  position: 'sticky',
  top: '0px',
  left: '0px',
  right: '0px',
  zIndex: 100,
  background: theme.colors.backgroundPrimary,
}))

export const CalendarActivationNavBar: React.FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const showBackButton = pathname === LC_CALENDAR_ACTIVATE_SETUP.route

  return (
    <>
      <FixedContainer testID={tID('CalendarActivationNavBar')}>
        <NavBar
          showBackButton={showBackButton}
          onBack={() => {
            //TODO: Mixpanel DACN-1336
            navigate(LC_CALENDAR_SELECT_CALENDARS.route)
          }}
          onExit={() => {
            //TODO: Mixpanel DACN-1336
            navigate(LC_CALENDAR_CURRENT.route)
          }}
          isTransparent={true}
        />
      </FixedContainer>
      <Outlet />
    </>
  )
}
