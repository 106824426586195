import colors from '../../styles/_0colors.scss'

interface outcomeChartConstantsType {
  name: string
  fullName: string
  title: string
  description: string
  condition: string
  lineColor: string
  ticks: number[]
  individualAnswerDescriptions: boolean
  finalScoreDescriptions: boolean
  toolTipFormat: Intl.DateTimeFormatOptions
  fillNoValueEntries: boolean
  ranges: {
    lower: number | null
    upper: number
    description: string
  }[]
  answers?: {
    value: number
    label?: string
  }[]
}

export const outcomeChartConstants: outcomeChartConstantsType[] = [
  {
    name: 'TFM',
    fullName: 'TFM - Timeline Followback Method',
    title: 'Drinking patterns (Timeline Followback Method)',
    description: 'Measures frequency of drinking over the preceding week.',
    condition: 'Alcohol-Craving',
    lineColor: colors.x_secondary_action,
    ticks: [0, 1, 2, 3, 4, 5, 6, 7],
    individualAnswerDescriptions: false,
    finalScoreDescriptions: false,
    toolTipFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
    fillNoValueEntries: true,
    ranges: [
      {
        lower: null,
        upper: 1,
        description: '1',
      },
      {
        lower: null,
        upper: 2,
        description: '2',
      },
      {
        lower: null,
        upper: 3,
        description: '3',
      },
      {
        lower: null,
        upper: 4,
        description: '4',
      },
      {
        lower: null,
        upper: 5,
        description: '5',
      },
      {
        lower: null,
        upper: 6,
        description: '6',
      },
      {
        lower: null,
        upper: 7,
        description: '7+',
      },
    ],
  },
  {
    name: 'TLFB-OTHER-SUBSTANCES',
    fullName: 'Substance usage',
    title: 'Substance usage (by week)',
    description: 'Measures the total number of days a substance was used per week.',
    condition: 'Substance craving',
    lineColor: colors.x_secondary_action,
    ticks: [0, 1, 2, 3, 4, 5, 6, 7],
    individualAnswerDescriptions: false,
    finalScoreDescriptions: false,
    toolTipFormat: { year: '2-digit', month: 'numeric', day: 'numeric' },
    fillNoValueEntries: true,
    ranges: [
      {
        lower: null,
        upper: 1,
        description: '1',
      },
      {
        lower: null,
        upper: 2,
        description: '2',
      },
      {
        lower: null,
        upper: 3,
        description: '3',
      },
      {
        lower: null,
        upper: 4,
        description: '4',
      },
      {
        lower: null,
        upper: 5,
        description: '5',
      },
      {
        lower: null,
        upper: 6,
        description: '6',
      },
      {
        lower: null,
        upper: 7,
        description: '7',
      },
    ],
  },
  {
    name: 'BDD',
    fullName: 'BDD - Binge Drinking Days',
    title: 'Excessive drinking (Binge Drinking Days)',
    description: 'Measures how often in the past month you had 5 or more drinks in a sitting.',
    condition: 'Alcohol-Craving',
    lineColor: colors.x_secondary_action,
    ticks: [0, 5, 10, 15, 20, 25, 30],
    individualAnswerDescriptions: false,
    finalScoreDescriptions: false,
    toolTipFormat: { year: 'numeric', month: '2-digit' },
    fillNoValueEntries: true,
    ranges: [
      {
        lower: null,
        upper: 5,
        description: '5',
      },
      {
        lower: null,
        upper: 10,
        description: '10',
      },
      {
        lower: null,
        upper: 15,
        description: '15',
      },
      {
        lower: null,
        upper: 20,
        description: '20',
      },
      {
        lower: null,
        upper: 25,
        description: '25',
      },
      {
        lower: null,
        upper: 30,
        description: '30+',
      },
    ],
  },
  {
    name: 'PACS',
    fullName: 'PACS - Penn Alcohol Craving Scale',
    title: 'Alcohol craving (Penn Alcohol Craving Scale)',
    description: 'Measures urges to drink in the preceding week. A score of 15 or more is considered significant.',
    condition: 'Alcohol-Craving',
    lineColor: colors.x_secondary_action,
    ticks: [-0.25, 15, 30],
    individualAnswerDescriptions: false,
    finalScoreDescriptions: false,
    toolTipFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
    fillNoValueEntries: false,
    ranges: [
      {
        lower: 0,
        upper: 15,
        description: 'Low',
      },
      {
        lower: 16,
        upper: 30,
        description: 'High',
      },
    ],
  },
  {
    name: 'WSAS',
    fullName: 'WSAS - Functioning',
    title: 'Functioning (WSAS)',
    description:
      'WSAS is a 5-question self-report questionnaire that helps evaluate how much your mental health challenges have impaired your ability to do things. The questions ask about experiences over the last week.',
    condition: 'Functioning',
    lineColor: colors.x_secondary_action,
    ticks: [-0.25, 15, 31, 40],
    individualAnswerDescriptions: true,
    finalScoreDescriptions: true,
    toolTipFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
    fillNoValueEntries: false,
    ranges: [
      {
        lower: 0,
        upper: 14,
        description: 'Mild',
      },
      {
        lower: 15,
        upper: 30,
        description: 'Moderate',
      },
      {
        lower: 31,
        upper: 40,
        description: 'Severe',
      },
    ],
    answers: [
      {
        value: 0,
        label: 'no impairment at all',
      },
      {
        value: 1,
      },
      {
        value: 2,
      },
      {
        value: 3,
      },
      {
        value: 4,
      },
      {
        value: 5,
      },
      {
        value: 6,
      },
      {
        value: 7,
      },
      {
        value: 8,
        label: 'very severe impairment',
      },
    ],
  },
  {
    name: 'PCL5',
    fullName: 'PCL-5 - Trauma',
    title: 'Trauma (PCL-5)',
    description:
      'PCL-5 is a 20-question self-report questionnaire that helps evaluate the presence and severity of trauma symptoms. The questions ask about experiences over the last week.',
    condition: 'Trauma',
    lineColor: colors.x_secondary_action,
    ticks: [-0.25, 20, 40, 60, 80],
    individualAnswerDescriptions: true,
    finalScoreDescriptions: false,
    toolTipFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
    fillNoValueEntries: false,
    ranges: [
      {
        lower: 0,
        upper: 19,
        description: '0 - 19',
      },
      {
        lower: 20,
        upper: 39,
        description: '20 - 39',
      },
      {
        lower: 40,
        upper: 59,
        description: '40 - 59',
      },
      {
        lower: 60,
        upper: 80,
        description: '60 - 80',
      },
    ],
    answers: [
      {
        value: 0,
        label: 'Not at all',
      },
      {
        value: 1,
        label: 'A little bit',
      },
      {
        value: 2,
        label: 'Moderately',
      },
      {
        value: 3,
        label: 'Quite a bit',
      },
      {
        value: 4,
        label: 'Extremely',
      },
    ],
  },
  {
    name: 'CHRT',
    fullName: 'CHRT - Suicidal Ideation',
    title: 'Suicidal Ideation (CHRT)',
    description:
      'CHRT is a 12-question self-report questionnaire that helps evaluate the presence and severity of suicidal thoughts. The questions ask about experiences over the last week.',
    condition: 'Suicidal Ideation',
    lineColor: colors.x_secondary_action,
    ticks: [-0.25, 10, 20, 30, 40, 48],
    individualAnswerDescriptions: true,
    finalScoreDescriptions: false,
    toolTipFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
    fillNoValueEntries: false,
    ranges: [
      {
        lower: 0,
        upper: 9,
        description: '0 - 9',
      },
      {
        lower: 10,
        upper: 19,
        description: '10 - 19',
      },
      {
        lower: 20,
        upper: 29,
        description: '20 - 29',
      },
      {
        lower: 30,
        upper: 39,
        description: '30 - 39',
      },
      {
        lower: 40,
        upper: 48,
        description: '40 - 48',
      },
    ],
    answers: [
      {
        value: 0,
        label: 'Strongly disagree',
      },
      {
        value: 1,
        label: 'Disagree',
      },
      {
        value: 2,
        label: 'Neither agree or disagree',
      },
      {
        value: 3,
        label: 'Agree',
      },
      {
        value: 4,
        label: 'Strongly agree',
      },
    ],
  },
  {
    name: 'GAD',
    fullName: 'GAD - Generalized Anxiety Disorder',
    title: 'Anxiety (GAD-7)',
    description:
      'Measures the presence and severity of anxiety symptoms over the past week, with lower scores indicating less anxiety.',
    condition: 'Anxiety',
    lineColor: colors.x_secondary_action,
    ticks: [-0.25, 8, 15, 21],
    individualAnswerDescriptions: true,
    finalScoreDescriptions: true,
    toolTipFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
    fillNoValueEntries: false,
    ranges: [
      {
        lower: 0,
        upper: 7,
        description: 'Mild',
      },
      {
        lower: 8,
        upper: 14,
        description: 'Moderate Anxiety',
      },
      {
        lower: 15,
        upper: 21,
        description: 'Severe Anxiety',
      },
    ],
    answers: [
      {
        value: 0,
        label: 'Not at all',
      },
      {
        value: 1,
        label: 'Several days',
      },
      {
        value: 2,
        label: 'More than half the days',
      },
      {
        value: 3,
        label: 'Nearly every day',
      },
    ],
  },
  {
    name: 'PHQ',
    fullName: 'PHQ - Patient Health Questionnaire',
    title: 'Depression (PHQ-9)',
    description:
      'Measures presence and severity of depression symptoms over the past week, with lower scores indicating fewer depressive symptoms.',
    condition: 'Depression',
    lineColor: colors.x_secondary_action,
    ticks: [-0.25, 10, 20, 27],
    individualAnswerDescriptions: true,
    finalScoreDescriptions: true,
    toolTipFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
    fillNoValueEntries: false,
    ranges: [
      {
        lower: 0,
        upper: 9,
        description: 'Mild',
      },
      {
        lower: 10,
        upper: 19,
        description: 'Moderate Depression',
      },
      {
        lower: 20,
        upper: 27,
        description: 'Severe Depression',
      },
    ],
    answers: [
      {
        value: 0,
        label: 'Not at all',
      },
      {
        value: 1,
        label: 'Several days',
      },
      {
        value: 2,
        label: 'More than half the days',
      },
      {
        value: 3,
        label: 'Nearly every day',
      },
    ],
  },
  {
    name: 'PSS',
    fullName: 'PSS - Perceived Stress Scale',
    title: 'Stress (PSS)',
    description:
      'Measures the presence and severity of stress over the past week, with lower scores indicating less stress.',
    condition: 'Stress',
    lineColor: colors.x_secondary_action,
    ticks: [-0.25, 22, 40],
    individualAnswerDescriptions: true,
    finalScoreDescriptions: false,
    toolTipFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
    fillNoValueEntries: false,
    ranges: [
      {
        lower: 0,
        upper: 22,
        description: 'Low',
      },
      {
        lower: 23,
        upper: 40,
        description: 'High',
      },
    ],
    answers: [
      {
        value: 0,
        label: 'Never',
      },
      {
        value: 1,
        label: 'Almost Never',
      },
      {
        value: 2,
        label: 'Sometimes',
      },
      {
        value: 3,
        label: 'Fairly Often',
      },
      {
        value: 4,
        label: 'Very Often',
      },
    ],
  },
  {
    name: 'WELL-BEING',
    fullName: 'WEMWBS - Warwick-Edinburgh Mental Well-being Scale',
    title: 'Mental wellbeing (WEMWBS)',
    description: 'Measures mental wellbeing over the past week, with higher scores corresponding to higher wellbeing.',
    condition: 'Well-being',
    lineColor: colors.x_secondary_action,
    ticks: [13.75, 40, 70],
    individualAnswerDescriptions: true,
    finalScoreDescriptions: false,
    toolTipFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
    fillNoValueEntries: false,
    ranges: [
      {
        lower: 13.75,
        upper: 40,
        description: 'Low',
      },
      {
        lower: 41,
        upper: 70,
        description: 'High',
      },
    ],
    answers: [
      {
        value: 1,
        label: 'None of the time',
      },
      {
        value: 2,
        label: 'Rarely',
      },
      {
        value: 3,
        label: 'Some of the time',
      },
      {
        value: 4,
        label: 'Often',
      },
      {
        value: 5,
        label: 'All of the time',
      },
    ],
  },
  {
    name: 'RCADS-ANXIETY',
    fullName: 'RCADS - Anxiety',
    title: 'Anxiety',
    description: 'Measures anxiety symptoms over the past week. Lower scores indicate less anxiety.',
    condition: 'Anxiety',
    lineColor: colors.x_secondary_action,
    ticks: [-0.25, 8, 15, 21],
    individualAnswerDescriptions: true,
    finalScoreDescriptions: false,
    toolTipFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
    fillNoValueEntries: false,
    ranges: [
      {
        lower: 0,
        upper: 7,
        description: '',
      },
      {
        lower: 8,
        upper: 14,
        description: '',
      },
      {
        lower: 15,
        upper: 21,
        description: '',
      },
    ],
    answers: [
      {
        value: 0,
        label: 'Never',
      },
      {
        value: 1,
        label: 'Sometimes',
      },
      {
        value: 2,
        label: 'Often',
      },
      {
        value: 3,
        label: 'Always',
      },
    ],
  },
  {
    name: 'RCADS-DEPRESSION',
    fullName: 'RCADS - Depression',
    title: 'Depression',
    description: 'Measures depression symptoms over the past week. Lower scores indicate fewer depressive symptoms.',
    condition: 'Depression',
    lineColor: colors.x_secondary_action,
    ticks: [-0.25, 10, 20, 27],
    individualAnswerDescriptions: true,
    finalScoreDescriptions: false,
    toolTipFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
    fillNoValueEntries: false,
    ranges: [
      {
        lower: 0,
        upper: 9,
        description: '',
      },
      {
        lower: 10,
        upper: 19,
        description: '',
      },
      {
        lower: 20,
        upper: 27,
        description: '',
      },
    ],
    answers: [
      {
        value: 0,
        label: 'Never',
      },
      {
        value: 1,
        label: 'Sometimes',
      },
      {
        value: 2,
        label: 'Often',
      },
      {
        value: 3,
        label: 'Always',
      },
    ],
  },
  {
    name: 'PARENTAL-STRESS',
    fullName: 'Parental Stress Scale',
    title: 'Parental Stress',
    description:
      'Measures the presence and severity of parent stress, with lower scores indicating lower levels of stress.',
    condition: 'Stress',
    lineColor: colors.x_secondary_action,
    ticks: [-0.25, 30, 60, 90],
    individualAnswerDescriptions: true,
    finalScoreDescriptions: false,
    toolTipFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
    fillNoValueEntries: false,
    ranges: [
      {
        lower: 0,
        upper: 29,
        description: '',
      },
      {
        lower: 30,
        upper: 59,
        description: '',
      },
      {
        lower: 60,
        upper: 90,
        description: '',
      },
    ],
    answers: [
      {
        value: 1,
        label: 'Strongly disagree',
      },
      {
        value: 2,
        label: 'Disagree',
      },
      {
        value: 3,
        label: 'Neither agree or disagree',
      },
      {
        value: 4,
        label: 'Agree',
      },
      {
        value: 5,
        label: 'Strongly agree',
      },
    ],
  },
  {
    name: 'CGSQ',
    fullName: 'Caregiver Strain Questionnaire',
    title: 'Caregiver Strain Questionnaire',
    description:
      'Measures the presence and severity of strain as a parent/caregiver over the past 2 weeks, with lower scores indicating lower levels of strain.',
    condition: 'Stress',
    lineColor: colors.x_secondary_action,
    ticks: [-0.25, 12, 24, 36],
    individualAnswerDescriptions: true,
    finalScoreDescriptions: false,
    toolTipFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
    fillNoValueEntries: false,
    ranges: [
      {
        lower: 0,
        upper: 11,
        description: '',
      },
      {
        lower: 12,
        upper: 23,
        description: '',
      },
      {
        lower: 24,
        upper: 36,
        description: '',
      },
    ],
    answers: [
      {
        value: 1,
        label: 'Not at all',
      },
      {
        value: 2,
        label: 'A little',
      },
      {
        value: 3,
        label: 'Somewhat',
      },
      {
        value: 4,
        label: 'Quite a bit',
      },
      {
        value: 5,
        label: 'Very much',
      },
    ],
  },

  {
    name: 'PARENTING-TASK',
    fullName: '',
    title: 'Parenting Tasks',
    description:
      'Measures the level of confidence in managing difficult child behaviors, with higher scores indicating higher levels of confidence.',
    condition: 'Stress',
    lineColor: colors.x_secondary_action,
    ticks: [-0.25, 50, 100],
    individualAnswerDescriptions: true,
    finalScoreDescriptions: false,
    toolTipFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
    fillNoValueEntries: false,
    ranges: [
      {
        lower: 0,
        upper: 49,
        description: '',
      },
      {
        lower: 50,
        upper: 100,
        description: '',
      },
    ],
    answers: [
      {
        value: 0,
        label: "Certain I can't do it",
      },
      {
        value: 100,
        label: 'Certain I can do it',
      },
    ],
  },

  {
    name: 'LEAPS',
    fullName: 'Work Impairment (LEAPS)',
    title: 'Work Impairment (LEAPS)',
    description:
      'Measures level of impairment at work over the past two weeks, with higher scores indicating greater levels of impairment.',
    condition: 'Work Impairment',
    lineColor: colors.x_secondary_action,
    ticks: [0, 5, 10, 16, 22, 28],
    individualAnswerDescriptions: true,
    finalScoreDescriptions: true,
    toolTipFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
    fillNoValueEntries: false,
    ranges: [
      {
        lower: 0,
        upper: 5,
        description: 'None to minimal',
      },
      {
        lower: 6,
        upper: 10,
        description: 'Mild',
      },
      {
        lower: 11,
        upper: 16,
        description: 'Moderate',
      },
      {
        lower: 17,
        upper: 22,
        description: 'Severe',
      },
      {
        lower: 23,
        upper: 28,
        description: 'Very Severe',
      },
    ],
    answers: [
      {
        value: 0,
        label: 'None of the time',
      },
      {
        value: 1,
        label: 'Some of the time',
      },
      {
        value: 2,
        label: 'Half of the time',
      },
      {
        value: 3,
        label: 'Most of the time',
      },
      {
        value: 4,
        label: 'All of the time',
      },
    ],
  },
]

export default {
  outcomeChartConstants,
}
