import React, { FunctionComponent, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { View } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import {
  AIDraftStatus,
  ClientObject,
  formatPhoneNumberForDisplay,
  getClientPreferredNameIfExists,
  getSectionHeaders,
  NoteMetadata,
  SchemaProperties,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'

import { AIStatusBadge, BodyText } from '../../atoms'
import { BodyTextSize } from '../../styles'
import { ThemeType } from '../../utils'
import { tID } from '../../utils/utils'
import { TableOfContents } from '../tableOfContents/TableOfContents'

export type NoteSidebarProps = {
  completedNote: boolean
  sessionDate: string
  sessionNumber: string
  noteMetadata: NoteMetadata
  noteValues: object
  summaryStatus?: AIDraftStatus
  clientDetails: ClientObject | null
}

const Container = styled.View<{ theme: ThemeType; containerPadding?: number }>(
  ({ theme: { colors }, containerPadding = 16 }) => ({
    backgroundColor: colors.backgroundPrimary,
    padding: `${containerPadding}px`,
    alignItems: 'start',
    width: '200px',
    borderRadius: '16px',
    borderWidth: '1px',
    borderColor: colors.borderDefault,
  }),
)

const ClientInfoContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  marginBottom: spacing['16px'],
  alignItems: 'start',
  gap: spacing['16px'],
  display: 'flex',
  flexDirection: 'column',
}))

const Separator = styled.View<{ theme: ThemeType }>(({ theme: { colors } }) => ({
  border: `0.5px solid ${colors.dividerPrimary}`,
  width: '200px',
  marginLeft: '-15px',
  marginBottom: '8px',
}))

const TableOfContentsContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  padding: `${spacing['16px']} 0`,
}))

export const NotesSidebar: FunctionComponent<NoteSidebarProps> = ({
  completedNote,
  sessionDate,
  sessionNumber,
  noteMetadata,
  noteValues,
  summaryStatus,
  clientDetails,
}) => {
  const { colors } = useTheme()
  const { formatDate, formatMessage } = useIntl()
  const { isPreferredNameEnabled } = useFlags()
  const containerPadding = 16
  const shouldShowPreferredName = isPreferredNameEnabled && clientDetails?.employer === 'apple'

  const displayAIBadge =
    summaryStatus &&
    [AIDraftStatus.COMPLETE, AIDraftStatus.IN_PROGRESS, AIDraftStatus.NOT_STARTED].includes(summaryStatus) &&
    !completedNote

  const headers = useMemo(() => {
    const schemaProperties = noteMetadata.meta_data.schema.properties as unknown as SchemaProperties
    const headers = getSectionHeaders({ note: noteMetadata, fields: schemaProperties, noteValues })
    return headers.map(({ content, name, aiPromptEnabled }) => ({
      elementId: name ?? '',
      title: typeof content === 'string' ? content?.split('# ')[1] : '',
      badges:
        displayAIBadge && aiPromptEnabled
          ? [<AIStatusBadge hideText key={`AIStatusBadge-${name}`} status={summaryStatus} />]
          : undefined,
    }))
  }, [displayAIBadge, noteMetadata, noteValues, summaryStatus])

  return (
    <Container testID={tID('NotesSidebar')} containerPadding={containerPadding}>
      <ClientInfoContainer>
        <View>
          <BodyText
            size={BodyTextSize.CAPTION}
            color={colors.textSecondary}
            text={
              shouldShowPreferredName
                ? formatMessage({
                    defaultMessage: 'Legal Name',
                    description: "Field header for client's legal name",
                  })
                : formatMessage({
                    defaultMessage: 'Client Name',
                    description: "Field header for client's name",
                  })
            }
            testID={tID('NotesSidebar-client-name-header')}
          />
          <BodyText
            size={BodyTextSize.DEFAULT}
            text={clientDetails?.full_name}
            testID={tID('NotesSidebar-client-name')}
          />
        </View>
        {shouldShowPreferredName && (
          <View>
            <BodyText
              size={BodyTextSize.CAPTION}
              color={colors.textSecondary}
              text={formatMessage({
                defaultMessage: 'Preferred Name',
                description: "Field header for client's preferred name",
              })}
              testID={tID('NotesSidebar-clientPreferredName-header')}
            />
            <BodyText
              size={BodyTextSize.DEFAULT}
              text={getClientPreferredNameIfExists(clientDetails)}
              testID={tID('NotesSidebar-clientPreferredName-text')}
            />
          </View>
        )}
        <View>
          <BodyText
            size={BodyTextSize.CAPTION}
            color={colors.textSecondary}
            text={formatMessage({
              defaultMessage: 'Phone Number',
              description: "Field header for client's phone number",
            })}
            testID={tID('NotesSidebar-client-phone-header')}
          />
          <BodyText
            size={BodyTextSize.DEFAULT}
            text={formatPhoneNumberForDisplay(clientDetails?.phone)}
            testID={tID('NotesSidebar-client-phone')}
          />
        </View>
        <View>
          <BodyText
            size={BodyTextSize.CAPTION}
            color={colors.textSecondary}
            text={formatMessage({
              defaultMessage: 'Date Of Birth',
              description: "Field header for client's date of birth",
            })}
            testID={tID('NotesSidebar-client-dob-header')}
          />
          <BodyText
            size={BodyTextSize.DEFAULT}
            text={formatDate(clientDetails?.date_of_birth, {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              timeZone: 'UTC',
            })}
            testID={tID('NotesSidebar-client-dob')}
          />
        </View>
        {sessionDate && sessionNumber && (
          <>
            <View>
              <BodyText
                size={BodyTextSize.CAPTION}
                color={colors.textSecondary}
                text={formatMessage({
                  defaultMessage: 'Session Date',
                  description: 'Field header for the date of the session for the current note',
                })}
                testID={tID('NotesSidebar-session-number-header')}
              />
              <BodyText size={BodyTextSize.DEFAULT} text={sessionDate} testID={tID('NotesSidebar-session-number')} />
            </View>
            <View>
              <BodyText
                size={BodyTextSize.CAPTION}
                color={colors.textSecondary}
                text={formatMessage({
                  defaultMessage: 'Session Number',
                  description: 'Field header for the number of the session for the current note',
                })}
                testID={tID('NotesSidebar-session-number-header')}
              />
              <BodyText size={BodyTextSize.DEFAULT} text={sessionNumber} testID={tID('NotesSidebar-session-number')} />
            </View>
          </>
        )}
      </ClientInfoContainer>
      <Separator />
      <TableOfContentsContainer>
        <TableOfContents headers={headers} containerPadding={containerPadding} testID={'NotesSidebar'} />
      </TableOfContentsContainer>
    </Container>
  )
}
