import React, { FunctionComponent, MutableRefObject } from 'react'

import { isNil, noop } from 'lodash-es'
import styled from 'styled-components/native'

import { Contentmetadata } from '@lyrahealth-inc/shared-app-logic'

import { ThemedStatusBar } from '../../atoms/themedStatusBar/ThemedStatusBar'
import { ActivityHeader } from '../../organisms/activityHeader/ActivityHeader'
import { InfoSheetBody } from '../../organisms/infoSheetBody/InfoSheetBody'
import { Flex1View } from '../../templates/content/CommonViews'
import { ThemeType, tID } from '../../utils'

export interface InfoSheetProps {
  metaData: Contentmetadata
  title: string
  instructions: string | null
  onLinkClicked: (url: string) => void
  exit?: () => void
  onView?: () => void
  a11yFocusedElementOnLoad?: MutableRefObject<any>
  defaultFlex?: boolean
  restrictMaxWidth?: boolean
}

const Container = styled(Flex1View)<{ theme: ThemeType; restrictMaxWidth: boolean }>(
  ({ theme: { colors }, restrictMaxWidth }) => ({
    backgroundColor: restrictMaxWidth ? colors.backgroundSecondary : colors.backgroundPrimary,
    ...(restrictMaxWidth && {
      overflowY: 'auto',
    }),
  }),
)

const ContentContainer = styled(Flex1View)<{ theme: ThemeType; restrictMaxWidth: boolean }>(
  ({ theme: { colors }, restrictMaxWidth }) => ({
    ...(restrictMaxWidth && {
      backgroundColor: colors.backgroundSecondary,
      maxWidth: '544px',
      margin: '0 auto',
    }),
  }),
)

export const InfoSheet: FunctionComponent<InfoSheetProps> = ({
  metaData = {},
  title = '',
  instructions,
  onLinkClicked,
  exit,
  onView = noop,
  a11yFocusedElementOnLoad,
  defaultFlex = false,
  restrictMaxWidth = false,
}) => {
  const { content, links, imageUrl, selectedMedication, title: selectedMedTitle } = metaData ?? {}
  const headerTitle = isNil(selectedMedTitle) || selectedMedTitle === '' ? title : ''
  const infoInstructions =
    selectedMedication?.instructions === undefined ? instructions : selectedMedication?.instructions
  return (
    <Container testID={tID('InfoSheet')} restrictMaxWidth={restrictMaxWidth}>
      <ActivityHeader title={headerTitle} onClosePress={exit} a11yFocusedElementOnLoad={a11yFocusedElementOnLoad} />
      <ContentContainer
        defaultFlex={defaultFlex}
        testID={tID('InfoSheet-body-container')}
        restrictMaxWidth={restrictMaxWidth}
      >
        <InfoSheetBody
          instructions={infoInstructions}
          content={content}
          links={links}
          imageURL={imageUrl}
          onView={onView}
          onLinkClicked={onLinkClicked}
        />
      </ContentContainer>
      <ThemedStatusBar defaultStyle='light' />
    </Container>
  )
}
