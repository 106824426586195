import { createReducer } from '@reduxjs/toolkit'
import { get, isEmpty } from 'lodash-es'
import { REHYDRATE } from 'redux-persist'

import { ProviderUser } from '@lyrahealth-inc/shared-app-logic'

import * as mixpanelTracker from '../../../mixpanel/mixpanelTracking'
import { config, SUPERVISOR_ROLES } from '../common/constants/appConstants'
import {
  GET_PROVIDER_CAPACITY,
  LOGIN,
  LOGOUT,
  REFRESH_API_TOKEN,
  REGISTER_SUCCESSFUL,
  REPLACE_USER,
  SAVE_LAST_AUTHORIZED_ROUTE,
  SET_PROVIDER_CAPACITY,
  SUBMIT_PROVIDER_OUTCOMES_CONSENT,
  UPDATE_EXPEDITED_BOOKING_STATUS,
  UPDATE_GRACE_PERIOD,
  UPDATE_IN_PERSON_PREFERENCE_STATUS,
} from '../common/constants/reduxConstants'
import { hasRole } from '../common/utils/utils'
import { Config } from '../lyraTherapy/types'

type AuthState = {
  isLoggedIn: boolean
  config?: Config
  user?: ProviderUser
  apiToken: string
  hydrated: boolean
  supervisor?: ProviderUser
  lyratherapy_providers: ProviderUser[]
  lastAuthorizedRoute?: string
}

const initialState = {
  isLoggedIn: false,
  apiToken: '',
  hydrated: false,
  lyratherapy_providers: [],
  lastAuthorizedRoute: undefined,
}

export default createReducer<AuthState>(initialState, (builder) => {
  builder.addCase(LOGIN, (state, action: any) => {
    state.user = action.payload.user
    state.isLoggedIn = true
    state.apiToken = action.payload.access_token

    action.payload.user.roles
      .filter((a: any) => a in config)
      .sort((a: any, b: any) => {
        return Object.keys(config).indexOf(a) - Object.keys(config).indexOf(b)
      })
      .forEach((role: any) => {
        state.config = { ...state.config, ...config[role] }
      })
    if (hasRole(action.payload.user.roles, SUPERVISOR_ROLES)) {
      state.supervisor = action.payload.user
      state.lyratherapy_providers = action.payload.user.lyratherapy_providers
    }
    return state
  })
  builder.addCase(LOGOUT, (state) => {
    // Logging out shouldn't mark `hydrated` to `false`. Since the initialState value is `false`, we set it to `true` again
    state.hydrated = true
    return state
  })
  builder.addCase(REHYDRATE, (state, action: any) => {
    const incoming = action.payload?.auth
    state = { ...state, ...incoming }
    mixpanelTracker.initializeTracking()
    if (!isEmpty(get(incoming, 'user'))) mixpanelTracker.setUser(incoming.user)
    state.hydrated = true
    return state
  })
  builder.addCase(SAVE_LAST_AUTHORIZED_ROUTE, (state, action: any) => {
    state.lastAuthorizedRoute = action.payload
    return state
  })
  builder.addCase(REGISTER_SUCCESSFUL, (state) => {
    if (state.user && 'id' in state.user) {
      state.user.is_registered = true
    }
    return state
  })
  builder.addCase(SUBMIT_PROVIDER_OUTCOMES_CONSENT, (state) => {
    if (state.user && 'id' in state.user) {
      state.user.outcomes_agreed = true
    }
    return state
  })
  builder.addCase(REFRESH_API_TOKEN, (state, action: any) => {
    state.apiToken = action.payload
    return state
  })
  builder.addCase(REPLACE_USER, (state, action: any) => {
    const userConfig = action.user.roles
      .filter((a: any) => a in config)
      .sort((a: any, b: any) => {
        return Object.keys(config).indexOf(a) - Object.keys(config).indexOf(b)
      })
      .reduce((compositeConfig: any, role: any) => (compositeConfig = { ...compositeConfig, ...config[role] }), {})
    state.user = action.user
    state.config = userConfig
    return state
  })
  builder.addCase(`${SET_PROVIDER_CAPACITY}_FULFILLED`, (state, action: any) => {
    if (state.user && 'id' in state.user) {
      state.user.capacity_value = action.payload.data.capacity_value
      state.user.available = action.payload.data.available
      state.user.capacity_updated_at = action.payload.data.capacity_updated_at
    }
    return state
  })
  builder.addCase(GET_PROVIDER_CAPACITY, (state, action: any) => {
    if (state.user && 'id' in state.user) {
      state.user.capacity_value = action.data
    }
    return state
  })
  builder.addCase(UPDATE_GRACE_PERIOD, (state, action: any) => {
    if (state.user && 'id' in state.user) {
      state.user.grace_period = action.data.value
    }
    return state
  })
  builder.addCase(UPDATE_EXPEDITED_BOOKING_STATUS, (state, action: any) => {
    if (state.user && 'id' in state.user) {
      state.user.expedited_booking_config = action.data.value
    }
    return state
  })
  builder.addCase(UPDATE_IN_PERSON_PREFERENCE_STATUS, (state, action: any) => {
    if (state.user && 'id' in state.user) {
      state.user.in_person_preference = action.data.in_person_preference
    }
    return state
  })
})
