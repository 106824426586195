import { VideoCallEffect } from './types'
import hillLandscapeImg from '../../assets/hills_landscape.jpg'
import therapyOfficImg from '../../assets/therapy_office.jpg'

export const getVideoEffectURL = (effect: VideoCallEffect): string | undefined => {
  switch (effect) {
    case VideoCallEffect.NONE:
      return undefined
    case VideoCallEffect.BLUR:
      return 'blur'
    case VideoCallEffect.OFFICE:
      return therapyOfficImg as unknown as string
    case VideoCallEffect.HILL_LANDSCAPE:
      return hillLandscapeImg as unknown as string
  }
}

export const getMobileEffectName = (effect: VideoCallEffect): string | undefined => {
  switch (effect) {
    case VideoCallEffect.NONE:
      return ''
    case VideoCallEffect.BLUR:
      return 'Blur'
    default:
      return undefined
  }
}

export const VB_BROWSERS = ['Chrome', 'Chromium', 'Firefox', 'Edge']

export const getErrorObject = (error: object) => {
  return {
    message: 'message' in error ? error.message : null,
    name: 'name' in error ? error.name : null,
    reason: 'reason' in error ? error.reason : null,
    type: 'type' in error ? error.type : null,
  }
}
