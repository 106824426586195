import { v4 as uuid } from 'uuid'

import { ConversationMessages, ConversationObject, Message } from '@lyrahealth-inc/shared-app-logic'

import { updateSelectedConversationID } from './messagesSlice'
import { getAuthUserId } from '../../../data/auth/authSelectors'
import { baseApi, RTKQueryTag } from '../../../data/baseApi'
import { updateEndpointCache } from '../../../data/rtkQuery'
import { RootState } from '../../../data/store'

type GetConversationsArgs = {
  providerId: string
  derivedProperties?: string
}
type GetConversationArgs = {
  providerId: string
  patientId: string
}

type GetMessagesArgs = {
  conversationId: string
  cursor?: string
}

type PostMessageArgs = {
  conversationId: string
  data: {
    message_body: string
    message_type: string
    message_author_id: string
    message_author_type: string
    metadata?: any
  }
}

type GetMessageArgs = {
  conversationId: string
  messageId: string
  append: boolean
}

type PatchConversationAttributesArgs = {
  conversationId: string
  unreadProviderMessagesCount: number
}

type PostOOOMessageArgs = {
  message_body: string
  message_type: string
  provider_lyra_id: string
  delivery_datetime?: string
  time_zone?: string
}

const messagesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getConversations: builder.query<ConversationObject[], GetConversationsArgs>({
      query: ({ providerId, derivedProperties }) => ({
        method: 'get',
        url: `/lt/v1/messaging/conversations`,
        params: { provider_lyra_id: providerId, derived_properties: derivedProperties },
      }),
      providesTags: [RTKQueryTag.CONVERSATION],
    }),
    getConversation: builder.query<ConversationObject, GetConversationArgs>({
      query: ({ providerId, patientId }) => ({
        method: 'get',
        url: `/lt/v1/messaging/conversations`,
        params: { provider_lyra_id: providerId, patient_lyra_id: patientId },
      }),
      transformResponse: (response: ConversationObject[]) => {
        return response[0]
      },
      async onQueryStarted({ providerId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(
            updateEndpointCache(messagesApi, 'getConversations')
              .where({ providerId })
              .build((conversations) => {
                const conversationToUpdateIdx = conversations.findIndex(
                  (conversation) => conversation.conversation_id === data.conversation_id,
                )
                if (conversationToUpdateIdx !== -1) {
                  conversations[conversationToUpdateIdx] = data
                } else {
                  conversations.push(data)
                }
              }),
          )
        } catch {}
      },
    }),
    getAccessToken: builder.query<string, void>({
      query: () => ({
        method: 'get',
        url: `/lt/v1/messaging/accessToken`,
      }),
    }),
    getMessages: builder.query<ConversationMessages, GetMessagesArgs>({
      query: ({ conversationId, cursor }) => ({
        method: 'get',
        url: `/lt/v1/messaging/conversations/${conversationId}/messages`,
        params: { page_token: cursor, page_size: 50 },
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        return queryArgs.conversationId
      },
      merge: (currentCache, newItems) => {
        return {
          conversation_messages: [...newItems.conversation_messages, ...currentCache.conversation_messages],
          next_page_token: newItems.next_page_token,
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.conversationId !== previousArg?.conversationId
      },
    }),
    sendMessage: builder.mutation<Message & { new_conversation_id?: string }, PostMessageArgs>({
      query: ({ conversationId, data }) => ({
        method: 'post',
        url: `/lt/v1/messaging/conversations/${conversationId}/messages`,
        data,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        const tempId = uuid()
        dispatch(
          updateEndpointCache(messagesApi, 'getMessages')
            .where({ conversationId: args.conversationId })
            .build((messages) => {
              messages.conversation_messages.push({
                body: args.data.message_body,
                sendStatus: 'sending',
                attributes: JSON.stringify({
                  from: args.data.message_author_id,
                  author_type: args.data.message_author_type,
                  message_type: args.data.message_type,
                  metadata: args.data.metadata,
                }),
                date_created: new Date().toISOString(),
                message_id: tempId,
              })
            }),
        )
        try {
          const { data } = await queryFulfilled

          dispatch(
            updateEndpointCache(messagesApi, 'getMessages')
              .where({ conversationId: args.conversationId })
              .build((messages) => {
                const idx = messages.conversation_messages.findIndex((message) => message.message_id === tempId)
                if (idx !== -1) {
                  messages.conversation_messages[idx] = data
                }
              }),
          )

          if (data.new_conversation_id) {
            const oldConversationId = args.conversationId
            const newConversationId = data.new_conversation_id
            const state = getState() as RootState
            const providerId = getAuthUserId(state)
            dispatch(
              updateEndpointCache(messagesApi, 'getConversations')
                .where({ providerId })
                .items({ conversation_id: oldConversationId })
                .build((conversation) => {
                  conversation.conversation_id = newConversationId
                  conversation.conversation_state = 'active'
                  conversation.conversation_last_message_sent_datetime = new Date().toISOString()
                }),
            )
            dispatch(updateSelectedConversationID({ oldConversationId, newConversationId }))
          }
        } catch {
          dispatch(
            updateEndpointCache(messagesApi, 'getMessages')
              .where({ conversationId: args.conversationId })
              .build((messages) => {
                const idx = messages.conversation_messages.findIndex((message) => message.message_id === tempId)
                if (idx !== -1) {
                  messages.conversation_messages[idx].sendStatus = 'failed'
                }
              }),
          )
        }
      },
    }),
    getMessage: builder.query<Message, GetMessageArgs>({
      query: ({ conversationId, messageId }) => ({
        method: 'get',
        url: `/lt/v1/messaging/conversations/${conversationId}/messages/${messageId}`,
      }),
      async onQueryStarted({ conversationId, append }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (!append) {
            return
          }
          dispatch(
            updateEndpointCache(messagesApi, 'getMessages')
              .where({ conversationId })
              .build((messages) => {
                messages.conversation_messages.push(data)
              }),
          )
        } catch {}
      },
    }),
    updateConversationAttributes: builder.mutation<
      ConversationObject['conversation_attributes'],
      PatchConversationAttributesArgs
    >({
      query: ({ conversationId, unreadProviderMessagesCount }) => ({
        method: 'patch',
        url: `/lt/v1/messaging/conversations/${conversationId}/attributes`,
        data: {
          unread_provider_messages_count: unreadProviderMessagesCount,
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled
          const state = getState() as RootState
          const providerId = getAuthUserId(state)
          dispatch(
            updateEndpointCache(messagesApi, 'getConversations')
              .where({ providerId })
              .items({ conversation_id: args.conversationId })
              .build((conversation) => {
                conversation.conversation_attributes = data
              }),
          )
        } catch {}
      },
    }),
    sendOOOMessage: builder.mutation<void, PostOOOMessageArgs>({
      query: (data) => ({
        method: 'post',
        url: `/lt/v1/messaging/conversations/bulk_message`,
        data,
      }),
    }),
  }),
})

export const {
  useGetConversationsQuery,
  useGetConversationQuery,
  useGetAccessTokenQuery,
  useGetMessagesQuery,
  useLazyGetMessagesQuery,
  useSendMessageMutation,
  useLazyGetMessageQuery,
  useUpdateConversationAttributesMutation,
  useSendOOOMessageMutation,
} = messagesApi
export default messagesApi
