import React, { FunctionComponent, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { View } from 'react-native'

import { flexRender, getCoreRowModel, getPaginationRowModel, Row, useReactTable } from '@tanstack/react-table'
import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { PressableOpacity } from '../../atoms'
import { ButtonModifier, ButtonSize } from '../../atoms/baseButton/BaseButton'
import { BaseText } from '../../atoms/baseText/BaseText'
import { BodyText, Size } from '../../atoms/bodyText/BodyText'
import { IconButton } from '../../atoms/iconButton/IconButton'
import { ChevronIcon, ChevronIconDirection } from '../../atoms/icons/ChevronIcon'
import { IS_WEB } from '../../constants'
import { colors as colorsV1 } from '../../styles'
import { BodyTextSize, TextType } from '../../styles/typeStyles'
import { Flex1View } from '../../templates/content/CommonViews'
import { tID } from '../../utils'

export type DataTableProps = {
  columns: any
  data: Dict[]
  emptyTableText?: string
  headerColor?: string
  headerSize?: BodyTextSize
  bodySize?: BodyTextSize
  pagination?: boolean
  pageSize?: number
  striped?: boolean
  rowHeight?: string
  cellMargin?: string
  headerBackgroundColor?: string
  headerBottomBorderColor?: string
  headerBottomPadding?: string
  tableHeight?: string
  tableZebraColor?: string
  onRowClick?: (row: Row<Dict>) => void
  isClickable?: boolean
  stickyColumn?: string
}

export const DataTable: FunctionComponent<DataTableProps> = ({
  columns,
  data,
  emptyTableText = 'No data available',
  headerColor,
  headerSize = Size.CAPTION,
  bodySize = Size.DEFAULT,
  pagination = true,
  pageSize = 10,
  striped = true,
  rowHeight = '36px',
  cellMargin = '0',
  tableHeight,
  headerBackgroundColor,
  headerBottomBorderColor,
  headerBottomPadding = '8px',
  tableZebraColor,
  onRowClick = noop,
  isClickable = false,
  stickyColumn = false,
}) => {
  const { colors, spacing: themeSpacing } = useTheme()
  const { formatMessage } = useIntl()

  const TableContainer = styled(View)({
    height: tableHeight ?? 'auto',
    overflow: 'auto',
    paddingBottom: stickyColumn ? themeSpacing['16px'] : '',
  })

  const Table = styled(Flex1View)({
    ...(stickyColumn && {
      display: 'table',
    }),
  })

  const Header = styled(Flex1View)({
    alignSelf: 'stretch',
    flexDirection: 'row',
    padding: '8px',
    paddingBottom: headerBottomPadding,
    borderBottomWidth: '3px',
    borderBottomColor: headerBottomBorderColor || colors.borderDefault,
    margin: 0,
    position: 'sticky',
    top: 0,
    backgroundColor: headerBackgroundColor ?? 'transparent',
    ...(stickyColumn && {
      paddingLeft: 0,
      paddingRight: 0,
      display: 'inline-flex',
    }),
  })

  const Row = styled(Flex1View)<{ rowHeight: string }>(({ rowHeight }) => ({
    alignSelf: 'stretch',
    flexDirection: 'row',
    padding: '8px',
    height: rowHeight,
  }))

  const Cell = styled.View<{ cellMargin: string; width?: number }>(({ cellMargin, width }) => ({
    flex: 1,
    display: 'inline-block',
    textAlign: 'center',
    margin: cellMargin,
    ...(width && { width: `${width}px` }),
  }))

  const ClickableRow = styled(PressableOpacity)<{ rowHeight: string; rowId: number; isLastRow: boolean }>(
    ({ rowHeight, rowId, isLastRow }) => ({
      flexDirection: 'row',
      height: rowHeight,
      backgroundColor: rowId % 2 === 0 ? colors.backgroundSecondary : colors.backgroundPrimary,
      ...(isLastRow && {
        borderBottomColor: colors.borderDefault,
        borderBottomWidth: '3px',
      }),
    }),
  )

  const StickyCell = styled.View<{ cellMargin: string; width: number; id: string; rowId: number; isRowBody: boolean }>(
    ({ cellMargin, width, id, rowId, isRowBody }) => ({
      flex: 1,
      margin: cellMargin,
      width: `${width}px`,
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      ...(id === stickyColumn && {
        ...(isRowBody && {
          borderRightColor: colors.borderDefault,
          borderRightWidth: '3px',
          backgroundColor: rowId % 2 === 0 ? colors.backgroundSecondary : colors.backgroundPrimary,
        }),
        ...(!isRowBody && {
          backgroundColor: headerBackgroundColor ?? colors.backgroundPrimary,
        }),
        left: '0px',
        position: IS_WEB ? 'sticky' : undefined,
        zIndex: 1,
      }),
    }),
  )

  const MultipleLineCell = styled.View<{ width?: number }>(({ width }) => ({
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    ...(width && { width: `${width}px` }),
  }))

  const ButtonContainer = styled.View({
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: '10px',
  })

  const EmptyTable = styled(BodyText)({
    margin: themeSpacing['48px'],
  })

  const PageNumber = styled(BaseText)({
    fontSize: '17px',
    margin: '-3px 10px 0px 10px',
  })

  const PaginationButton = styled(IconButton)<{ disabled?: boolean }>(({ disabled }) => ({
    border: `1px solid ${disabled ? colors.borderDefault : colors.borderInput}`,
    borderRadius: '5',
    width: '20px',
    height: '20px',
    padding: '3px',
    margin: '5px',
  }))

  const standardTableArgs = {
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  }

  const paginationArg = {
    getPaginationRowModel: getPaginationRowModel(),
  }

  const tableArgs = pagination ? { ...standardTableArgs, ...paginationArg } : standardTableArgs

  const table = useReactTable(tableArgs)

  useEffect(() => {
    if (pagination) {
      table.setPageSize(Number(pageSize))
    }
  }, [pagination, pageSize, table])

  const renderPageNumbers = () => {
    return [...Array(table.getPageCount())].map((element, ind) => (
      <PageNumber
        key={ind + 1}
        size={Size.DEFAULT}
        color={ind === table.getState().pagination.pageIndex ? colors.textActive : colors.textPrimary}
        type={TextType.BODY}
        testID={tID(`DataTable-pagination-${ind}`)}
      >
        {ind + 1}
      </PageNumber>
    ))
  }

  const renderCells = (row: { getVisibleCells: () => any[] }) => {
    return row.getVisibleCells().map((cell) =>
      stickyColumn ? (
        <StickyCell
          key={cell.id}
          cellMargin={cellMargin}
          width={cell.column.columnDef.size}
          id={cell.column.columnDef.id}
          rowId={cell.row.id}
          isRowBody
          testID={tID(`DataTable-cell-${cell.id}`)}
        >
          <BodyText size={bodySize} color={colors.textPrimary}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </BodyText>
        </StickyCell>
      ) : (
        <Cell
          key={cell.id}
          cellMargin={cellMargin}
          id={cell.column.columnDef.id}
          testID={tID(`DataTable-cell-${cell.id}`)}
        >
          <BodyText size={bodySize} color={colors.textPrimary}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </BodyText>
        </Cell>
      ),
    )
  }

  return (
    <TableContainer>
      <Table testID={tID('DataTable-table')}>
        {table.getHeaderGroups().map((headerGroup) => (
          <Header key={headerGroup.id} testID={tID('DataTable-header')}>
            {headerGroup.headers.map((header) => {
              const HeaderCell = stickyColumn
                ? StickyCell
                : header.column.columnDef.meta && header.column.columnDef.meta === 'multiline'
                ? MultipleLineCell
                : Cell
              return (
                <HeaderCell
                  key={header.id}
                  testID={tID(`DataTable-cell-${header.id}`)}
                  id={header.id}
                  width={header.column.columnDef.size}
                >
                  <BaseText
                    size={headerSize}
                    color={headerColor || colors.textSecondary}
                    type={TextType.BODY}
                    testID={tID(`DataTable-header-${header.id}`)}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </BaseText>
                </HeaderCell>
              )
            })}
          </Header>
        ))}
        {table.getRowModel().rows.length ? (
          table.getRowModel().rows.map((row, index) =>
            isClickable ? (
              <ClickableRow
                key={row.id}
                rowHeight={rowHeight}
                rowId={index}
                onPress={() => onRowClick && onRowClick(row)}
                hoveredBackgroundColor={colorsV1.charcoal1}
                pressedBackgroundColor={colorsV1.charcoal1}
                style={index % 2 || !striped ? {} : { backgroundColor: tableZebraColor || colors.backgroundSecondary }}
                isLastRow={index === table.getRowModel().rows.length - 1}
              >
                {renderCells(row)}
              </ClickableRow>
            ) : (
              <Row
                key={row.id}
                style={index % 2 || !striped ? {} : { backgroundColor: tableZebraColor || colors.backgroundSecondary }}
                testID={tID('DataTable-dataRow')}
                rowHeight={rowHeight}
              >
                {renderCells(row)}
              </Row>
            ),
          )
        ) : (
          <EmptyTable size={Size.DEFAULT} testID={tID('DataTable-emptyTable')}>
            {emptyTableText}
          </EmptyTable>
        )}
      </Table>
      {pagination && table.getRowModel().rows.length > 0 && (
        <ButtonContainer testID={tID('DataTable-paginationButtons')}>
          <PaginationButton
            onPress={() => table.previousPage()}
            testID={tID('DataTable-paginationButton-previous')}
            iconColor={table.getCanPreviousPage() ? colors.iconActive : colors.iconInactive}
            leftIcon={<ChevronIcon direction={ChevronIconDirection.LEFT} />}
            size={ButtonSize.SMALL}
            disabled={!table.getCanPreviousPage()}
            modifier={ButtonModifier.INVERSE}
            accessibilityLabel={formatMessage({
              defaultMessage: 'Previous page',
              description: 'Button for previous table page',
            })}
          />
          {renderPageNumbers()}
          <PaginationButton
            onPress={() => table.nextPage()}
            testID={tID('DataTable-paginationButton-next')}
            iconColor={table.getCanNextPage() ? colors.iconActive : colors.iconInactive}
            leftIcon={<ChevronIcon direction={ChevronIconDirection.RIGHT} />}
            size={ButtonSize.SMALL}
            disabled={!table.getCanNextPage()}
            modifier={ButtonModifier.INVERSE}
            accessibilityLabel={formatMessage({
              defaultMessage: 'Next page',
              description: 'Button for next table page',
            })}
          />
        </ButtonContainer>
      )}
    </TableContainer>
  )
}
