import React, { useCallback, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useNavigate } from 'react-router'

import { bindActionCreators } from 'redux'
import styled from 'styled-components/native'

import { useFlags } from '@lyrahealth-inc/shared-app-logic'
import { ChatSideBar, ConversationFilter } from '@lyrahealth-inc/ui-core-crossplatform'

import BaseMessenger from './BaseMessenger'
import { CLIENT_HOME } from '../../../common/constants/routingConstants'
import { clearSelectedClientData } from '../../clients/clientDetails/data/ltClientDetailsAutoActions'
import useChatPopout from '../hooks/useChatPopout'
import useGetFilteredConversations from '../hooks/useGetFilteredConversations'

const ChatSideBarContainer = styled(ChatSideBar)(({ theme }) => ({
  borderRightWidth: '1px',
  borderRightColor: theme.colors.borderDefault,
}))

type MessengerProps = ConnectedProps<typeof connector>

const Messenger: React.FC<MessengerProps> = ({ actions: { clearSelectedClientData } }) => {
  const { isMessagingCenterEnabled } = useFlags()
  const [filters, setFilters] = useState<ConversationFilter[]>([])
  const [selectedClientId, setSelectedClientId] = useState<string | null>(null)
  const navigate = useNavigate()
  const { onOpenNewWindow } = useChatPopout(selectedClientId)

  const [isComposing, setIsComposing] = useState(false)

  const { conversations: conversationsMapped, isLoading: isLoadingConversations } = useGetFilteredConversations(filters)

  const onConversationPressed = useCallback(
    (clientId: string) => {
      setIsComposing(false)
      if (selectedClientId === clientId) {
        return
      }
      clearSelectedClientData()
      setSelectedClientId(clientId)
    },
    [clearSelectedClientData, selectedClientId],
  )

  const onOpenClientProfile = useCallback(() => {
    navigate(CLIENT_HOME.route)
  }, [navigate])

  const onComposePressed = useCallback(() => {
    setIsComposing(true)
    setSelectedClientId(null)
  }, [])

  if (!isMessagingCenterEnabled) {
    return null
  }

  return (
    <BaseMessenger
      selectedClientId={selectedClientId}
      isComposing={isComposing}
      onSelectedClientIdChanged={setSelectedClientId}
      showEmptyView={selectedClientId == null}
      onIsComposingChanged={setIsComposing}
      onOpenNewWindow={onOpenNewWindow}
      onOpenClientProfile={onOpenClientProfile}
      initialDetailPanelOpen={true}
    >
      <ChatSideBarContainer
        isLoading={isLoadingConversations}
        conversations={conversationsMapped}
        onComposePressed={onComposePressed}
        onConversationPressed={onConversationPressed}
        selectedClientId={selectedClientId}
        filters={filters}
        onFiltersChanged={setFilters}
      />
    </BaseMessenger>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      clearSelectedClientData,
    },
    dispatch,
  ),
})

const connector = connect(null, mapDispatchToProps)
export default connector(Messenger)
