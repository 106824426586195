import React, { useMemo } from 'react'

import styled, { useTheme } from 'styled-components/native'

import { AIDraftStatus, AssignmentSummary } from '@lyrahealth-inc/shared-app-logic'
import { BodyText, StyledMarkdown, Subhead, SubheadSize, ThemeType, tID } from '@lyrahealth-inc/ui-core-crossplatform'
import { AIStatusBadge, DigestType } from '@lyrahealth-inc/ui-core-crossplatform/src/atoms/aiStatusBadge/AIStatusBadge'

interface AssignmentAISummaryTextProps {
  clientIntakeSummaryResponse: AssignmentSummary
}

const BadgeContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  alignSelf: 'center',
  marginLeft: spacing['8px'],
  marginRight: spacing['16px'],
  marginTop: spacing['8px'],
}))

const SummaryContainer = styled.View<{ theme: ThemeType }>(({ theme: { colors, spacing } }) => ({
  backgroundColor: colors.backgroundElement,
  width: '100%',
  borderRadius: spacing['20px'],
  borderBottomLeftRadius: '0px',
  borderBottomRightRadius: '0px',
  padding: `0 0 ${spacing['16px']}`,
  marginTop: spacing['16px'],
}))

const SummaryHeadingContainer = styled.View({
  alignItems: 'center',
  flexDirection: 'row',
})

const SummaryHeading = styled(Subhead)(({ theme: { spacing } }) => ({
  margin: `${spacing['16px']} ${spacing['4px']} ${spacing['8px']} ${spacing['24px']}`,
}))

const SummaryTextContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  padding: `0 ${spacing['24px']} ${spacing['16px']}`,
}))

const AISummaryPreface = styled.View<{ theme: ThemeType }>(({ theme: { colors, spacing } }) => ({
  color: colors.textSecondary,
  marginBottom: spacing['16px'],
  marginRight: spacing['16px'],
  marginLeft: spacing['24px'],
}))

const AssignmentAISummaryText: React.FC<AssignmentAISummaryTextProps> = ({ clientIntakeSummaryResponse }) => {
  const { colors } = useTheme()

  const clientIntakeAISummary = useMemo(() => {
    if (
      clientIntakeSummaryResponse?.status === AIDraftStatus.IN_PROGRESS ||
      clientIntakeSummaryResponse?.status === AIDraftStatus.NOT_STARTED
    ) {
      return 'Processing your summary now - this usually takes just a few minutes. You can read the full details below first and check back shortly.'
    } else if (
      clientIntakeSummaryResponse?.status === AIDraftStatus.ERROR ||
      clientIntakeSummaryResponse?.status === AIDraftStatus.NOT_FOUND
    ) {
      return 'Summary not found'
    } else if (clientIntakeSummaryResponse?.status === AIDraftStatus.COMPLETE) {
      return clientIntakeSummaryResponse.summary_text
    }
    return undefined
  }, [clientIntakeSummaryResponse])
  return (
    <SummaryContainer testID={tID('Assignments-ai-summary-container')}>
      <SummaryHeadingContainer testID={tID('Assignments-ai-summary-header-container')}>
        <SummaryHeading size={SubheadSize.SMALL} text='Summary' />

        {clientIntakeSummaryResponse && (
          <BadgeContainer testID={tID('Assignments-ai-summary-status-badge-container')}>
            <AIStatusBadge
              digestType={DigestType.SUMMARY}
              status={clientIntakeSummaryResponse?.status}
              customText='AI GENERATED'
            />
          </BadgeContainer>
        )}
      </SummaryHeadingContainer>

      {clientIntakeSummaryResponse && clientIntakeSummaryResponse?.status === AIDraftStatus.COMPLETE && (
        <AISummaryPreface>
          <BodyText
            color={colors.textSecondary}
            text={`This summary was generated by AI based on the client's intake responses. It may contain incomplete or inaccurate information; it is your responsibility to review the full client intake before your first session`}
          />
        </AISummaryPreface>
      )}

      <SummaryTextContainer>
        <StyledMarkdown content={clientIntakeAISummary} customStyles={{ h1: { fontSize: 16 } }} />
      </SummaryTextContainer>
    </SummaryContainer>
  )
}

export default AssignmentAISummaryText
