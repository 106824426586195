import { MessageDescriptor } from 'react-intl'

import { CountryCode } from 'libphonenumber-js'

import {
  PRODUCT_TYPE,
  SelfManagedDependentRegistrationData,
  SpeculativeFilter,
  TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND,
  TREATMENT_OPTIONS_TYPE,
  TREATMENT_RECOMMENDATION_TIER,
} from '../../features'
import { MemberPreferencesForUserV2, ProviderSearchPreferencesV2 } from '../../features/memberPreferences/types'
import { PrimaryNeedsWords, TriageScreenerValue } from '../../features/searchForCare/constants'
import { TriageScreenerNames } from '../../models/triageScreener/TriageScreener'
import { ModalityPreferences, SurveyQuestionGroup } from '../modality/Modality'
import { ProviderInfo, ProviderProgramTaxonomy } from '../providers/Providers'
import { CustomerPropertiesMap, YearProgramCoverage } from '../user/User'

export enum HPIneligibleOptions {
  HEALTH_PLAN_PENDING = 'healthPlanPending',
  VIEW_OTHER_ELIGIBLE_TREATMENT_OPTIONS = 'viewOtherEligibleTreatmentOptions',
  TREATMENT_OPTION_VIEW_OTHER_ELIGIBLE_TREATMENT_OPTIONS_WRAPPER = 'treatmentOptionViewOtherEligibleTreatmentOptionsWrapper',
  HEALTH_PLAN_INELIGIBLE = 'healthPlanIneligible',
  INELIGIBLE_FOR_ONBOARDING = 'ineligibleForOnboarding',
  HIGH_ALERT_RESOURCES = 'highAlertResources',
  NO_TREATMENT_OPTION = 'noTreatmentOption',
  FIND_DIFFERENT_CARE_OPTION = 'findDifferentCareOption', // variation of health plan ineligibility, specifically for direct link profile access
  FIND_DIFFERENT_CARE_OPTION_INTL = 'findDifferentCareOptionIntl', // same as above, but with international copy and links
  CHC_OUTAGE = 'chcOutage', // CHC is having outage
  DIRECT_PATH_INELIGIBLE = 'directPathIneligible',
}

export interface ExcludedReason {
  treatmentOption: string
  reasons: string[]
}

export interface TreatmentRecommendations {
  coachingExclusionReasons: any // TODO
  modelRecommendation: string
  clinicalRecommendation: string
  shownTierPage: TREATMENT_RECOMMENDATION_TIER
  eligibleTreatmentOptions: TREATMENT_OPTIONS_TYPE[]
  excludedTreatmentOptionsReasons: ExcludedReason[]
  selfHarmIndicated: boolean
  suicidalIdeationIndicated: boolean
  riskLevel?: TriageRiskLevel
  primaryTreatmentOptions: TREATMENT_OPTIONS_TYPE[]
  secondaryTreatmentOptions: TREATMENT_OPTIONS_TYPE[]
}

export interface PostTreatmentRecommendationsActions {
  id: string
  data: PostTreatmentRecommendationsRequestBody
  params?: { stressManagement?: boolean; shouldSeeCareAdvocateRequestForm?: boolean }
}

export type PostTreatmentRecommendationsRequestBody = {
  customerProperties?: CustomerPropertiesMap
  currentProgramCoverageBreakdown: YearProgramCoverage
  countryName: string
  healthPlanEligibilityName?: string | null
  isClientBHBEligible?: boolean | null
  isInternational: boolean
  preferredAppointmentTypes: string[]
}

export interface PostModalityQuestionnaireSurveyDataActions {
  lyraId: string
  searchId: string
  groupIndex: number
  data: SurveyQuestionGroup
}

export interface PostModalityQuestionnairePreferenceData {
  lyraId: string
  searchId: string
  data?: ModalityPreferences
}

export interface PostProvidersActions {
  userId: string
  data: {
    childLyraId?: string | null
    searchRequest: SearchRequestData
  }
}

export interface GetAllTriageSearchesActions {
  userId: string
  hideError?: number[]
}

export interface PostRiskFactorsActions {
  data: {
    lyra_id: string
    monthsToLookBack: number
  }
  hideError?: number[]
}

export interface TriageSearchBody {
  age: number | string
  yob: number
  gender: string
  type: SearcherType | string
  employer: string
  clinicalFactors: Dict
  questionnaires: Dict
  child_lyra_id?: string
  childLyraId?: string
  region?: { value: string }
  productType?: PRODUCT_TYPE
  clientSelectedTopic?: string
  programPreference?: ProviderProgramTaxonomy
}

export interface PatchOrPutTriageSearchActions {
  id: string
  data: {
    search?: TriageSearch
    triage_metadata?: string | string[]
    questionnaires?: Dict
    triage_status?: TriageSearchStatus
    context?: Dict | null
    selected_treatment_option?: TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND
    metadata_version?: string
  }
}

export enum TriageSearchStatus {
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}

export interface RecommendedTreatments {
  thresholdOne?: number
  thresholdTwo?: number
  modelRecommendation?: TREATMENT_RECOMMENDATION_TIER | string
  shownTierPage?: TREATMENT_RECOMMENDATION_TIER | string
  modelId?: string
  thresholdStartDate?: string
  supplyAwareRecommendation?: TREATMENT_RECOMMENDATION_TIER | string
  contextUUID?: string
  confidence?: number[]
  selfHarmIndicated?: boolean
  clinicalRecommendation?: TREATMENT_RECOMMENDATION_TIER | string
  thresholdStatus?: number
  eligibleTreatmentOptions?: TREATMENT_OPTIONS_TYPE[] | string[]
  thresholdEndDate?: string
  suicidalIdeationIndicated?: boolean
  excludedTreatmentOptionsReasons?: ExcludedReason[]
}

export interface TriageSearch {
  lyra_id: string
  suicidal_ideation_indicated?: boolean
  submit_date?: string | null
  update_date?: string | null
  create_date?: string
  triage_status?: TriageSearchStatus | string
  version: number
  triage_metadata: string[]
  recommended_treatments?: RecommendedTreatments
  is_high_alert_email_sent?: boolean | null
  body: TriageSearchBody
  self_harm_indicated?: boolean | null
  id: string
  transaction_id: number
  context?: { [key in TriageScreenerNames]: Dict } | null
  safety_alert_indicated?: boolean | null
  selected_treatment_option?: TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND | string
  search_preferences?: Dict | null
  user_agent?: string
  risk_level?: TriageRiskLevel
}

export enum TriageRiskLevel {
  YELLOW = 'yellow',
  AMBER = 'amber',
  RED = 'red',
}

export enum SearcherType {
  ADULT = 'adult',
  OLDER_CHILD = 'olderChild',
  MIDDLE_CHILD = 'middleChild',
  YOUNGER_CHILD = 'youngerChild',
}

export type NextQuestionResponse = {
  name: TriageScreenerNames
  is_last_triage_screener: boolean
}

export type NewSearchData =
  | {
      lyraId: string
      body: { age: number | string; gender: string; employer: string; yob: number; type: SearcherType }
    }
  | object

export interface GetNextQuestionActions {
  id: string
  skipScreener?: TriageScreenerNames
  hideError?: number[]
}

export interface GetTriageMetadataActions {
  age?: number
  version?: string
  hideError?: number[]
}

export interface PutResetTriageSearchProgressActions {
  searchId: string
  metadataId: string
}

export enum LocationMatcherDisplayFormats {
  DROPDOWN_MENU_TO_RADIO_BUTTONS = 'dropdown',
  RADIO_BUTTONS_ONLY = 'radioButtons',
}

export type TriageData = {
  age: number
  ageBucket?: string | number
  gender: string
  matchFor: string
  maxCnt: number
  treatmentNaive?: string
  treatmentPreference: string
  wordGrid: string[]
}

export type SearchRequestData = Partial<TriageData> &
  Partial<ProviderProgramTaxonomy> & {
    lyraId?: string
    searchId?: string | null
    searchTransactionId?: number | null
    blendedCareProgramId?: string
    location?: string
    locationState?: string
    locationText?: string
    locationCountry?: string
    onsite?: boolean
    sessionFormat?: { [key: string]: string[] | undefined }
    isUserInternational: boolean
    preferredCareLanguageTags?: Array<string>
    providerSearchPreferences?: ProviderSearchPreferencesV2 | null
    directLinkIntent?: string
    filters?: {
      check: Array<SpeculativeFilter>
    }
  }

export type SearchProvidersRequest = {
  triageInfoSavedId?: string
  childLyraId?: string
  searchRequest: SearchRequestData
}

export interface SearchProviders {
  searchId: string | null
  userFavoriteProviderRequestId: string | null
  providerResults: ProviderResults
  providersDisplayed: Array<ProviderInfo>
  providerResultAnalysis?: {
    userModalityPreference: 'BOTH'
    wereAnyInPersonProvidersFoundAfterUserAskedForOnlyInPersonProviders: boolean
    wereAnyVideoProvidersFoundAfterUserAskedForOnlyVideoProviders: boolean
    wereAnyBCVideoProvidersFoundAfterUsersAskedForOnlyVideoProviders: boolean
    numberOfVideoProvidersFound: number
  }
  triageInfoSavedId?: string | null
  providerResultsTreatmentPreference: TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND
}

export interface SearcherInfo {
  type: SearcherType
  name: string
  age: number
  dob: string
  lastName: string
  childLyraId?: string
}

export interface PostChildConcernContact {
  userId: string
  data: {
    firstname?: string
    lastname?: string
    childLyraId?: string
    note: string
    careManagerName: string
    careManagerEmail: string
    call: string
    email: string
    customerName: string
  }
}
export interface PostAppointmentRequest {
  provider_id: string
  provider_first_name: string
  provider_last_name: string
  provider_email: string
  patient_id: string
  patient_first_name: string
  patient_last_name: string
  patient_gender?: string
  patient_legal_first_name: string
  patient_legal_last_name: string
  patient_preferred_first_name?: string | null
  patient_preferred_last_name?: string | null
  patient_display_language?: string
  selected_care_language: string
  patient_email?: string
  patient_message: string
  patient_phone?: string
  appointment_for_child: boolean
  show_client_info?: boolean
  primary_need?: string
  appointment_type?: string
  modality?: string
  customer_name?: string
  customer_display_name?: string
  lyra_code?: string
  provider_phone?: string
  provider_display_phone_number?: string
  provider_preferred_contact?: string
  patient_display_phone_number?: string
  care_navigator_url?: string
  provider_contact_email: string
  country_iso_code: CountryCode
  patient_country_name?: string
  request_id?: string | null
  is_international?: boolean
  is_enhanced_risk_protocol_enabled?: boolean
  patient_phone_type?: string | null
  patient_sms_consent?: boolean | null
  phoenix_team_email?: string | null
}

export interface PostAppointmentForAdultRequest extends PostAppointmentRequest {
  patient_dob: string
}

export interface PostAppointmentForChildRequest extends PostAppointmentRequest {
  child_first_name: string
  child_last_name: string
  child_dob: string
}

export interface ProviderResults {
  requestId: string | null
  providersInfo: Array<ProviderInfo>
  address?: any
  providerAvailabilities: Array<ProviderAvailability>
  providersWithUpcomingAvailability: Array<string>
  providersWithVideoAvailability: Array<string>
  providersByDistance: any[]
  providersWhoIdentifyAsPeopleOfColor: any[]
  topRecommendation: Array<string>
}

interface ProviderAvailability {
  id: string
  interval: number
  availability: Availability[]
  timeZoneId: string
  slotLength: number
  availabilityBreakdown: AvailabilityBreakdown
}

interface AvailabilityBreakdown {
  afternoons: Availability[]
  mornings: Availability[]
  weekends: Availability[]
  evenings: Availability[]
}

interface Availability {
  date: string
  times: string[]
}

export interface CreateChildUserRequestBody {
  customerCountryId: number | undefined
  firstname: string
  lastname: string
  dob: string
  careHistory?: string
  lyraId?: string
  gender?: string
}

export interface CreateChildUser {
  guardianLyraId: string
  data: CreateChildUserRequestBody
}

export interface RegisterSelfManagedDependentUser {
  guardianLyraId: string
  data: SelfManagedDependentRegistrationData
}

export interface PostInternationalConsent {
  data: { lyraId: string; acceptInternationalConsent: string; internationalConsentText: string; countryId?: number }
}

export interface SaveUserPreference {
  lyraId: string
  data: {
    preferredAppointmentType: string
    notes: string
  }
}

export interface CreateCrmProfileViewedEvent {
  lyraId: string
  data: ProviderProfileViewedCrmEventInfo
}

export type ProviderProfileViewedCrmEventInfo = {
  userId: string
  lyraProviderId: string
  appointmentType: string
  supportedVisitTypes: string
}

export type TriageResults = {
  search?: TriageSearch
  treatmentRecommendations?: TreatmentRecommendations
}

export interface SaveMemberPreferencesForUserV2 {
  lyraId: string
  data: MemberPreferencesForUserV2
}

export type GetTreatmentCostAction = {
  clientId: string
  providerId: string
  searchId?: string | null
  requestId?: string | null
}

export interface GetWordCloudWordsActionData {
  memberAge: number
  searcherType: SearcherType
  primaryNeedsWord: PrimaryNeedsWords | string
  triageScreenerName: TriageScreenerNames
  country: string
  lyraSocialCareEnabled: boolean
}

export type WordCloudScreeners = TriageScreenerNames.WORD_CLOUD_1 | TriageScreenerNames.WORD_CLOUD_2

export type WordCloudWords = {
  [key in WordCloudScreeners]: WordCloudData
}

export enum WordCloudCategoryName {
  EMOTIONS_FEELINGS = 'emotionsAndFeelings',
  THOUGHTS_BEHAVIORS = 'thoughtsAndBehaviors',
  SOURCES_STRESS = 'sourcesOfStress',
  UNCATEGORIZED = 'uncategorized',
}
export interface WordCloudCategory {
  word_cloud_category_name: string
  word_cloud_category_display_title: MessageDescriptor
  word_cloud_elements: MessageDescriptor[]
}

export type WordCloudData = WordCloudCategory[]

export const fallbackICASPhoneNumber = '+44 33 00 241 021'

export interface ParentLedTeenRegistrationEmailConfirmationRequest {
  childEmail: string
  childLyraId: string
  appointmentId: number
}

export interface QueueHighAlertCallbackRequest {
  phoneNumber: string
  searchId: string
}

export interface QueueHighAlertCallbackResponse {
  contactId: number
  phone: string
}

export type PrimaryNeedOptions =
  | PrimaryNeedsWords
  | TriageScreenerValue.IM_NOT_SURE
  | TriageScreenerValue.SOMETHING_ELSE
