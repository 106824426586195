import React from 'react'
import { ViewStyle } from 'react-native'
import Svg, { Circle, Rect } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

/**
 * Signals how strong a users mobile connection is
 * This component allows you specify how many bars
 * represent each strength.
 *
 * i.e. { low: 2, medium: 2, high: 3 }
 *
 * For 2 red bars for low
 * For 2 yellow bars for med
 * For 3 green bars for high
 */
export const ConnectionIndicator = ({ strength, barCount, style }: ConnectionIndicatorProps) => {
  const { colors } = useTheme()
  const { signalHigh, signalMedium, signalLow, signalDefault } = colors.components.connectionIndicator

  const barProps = {
    1: {
      x: '7.33301',
      y: '12.095',
      height: '4.57143',
    },
    2: {
      x: '10.3677',
      y: '9.23828',
      height: '7.42857',
    },
    3: {
      x: '13.4019',
      y: '6.66699',
      height: '10',
    },
  }
  const signalColors = {
    [Strength.HIGH]: signalHigh.background,
    [Strength.MEDIUM]: signalMedium.background,
    [Strength.LOW]: signalLow.background,
    default: signalDefault.background,
  }

  /**
   * Maps the barCount for each indicator to strength
   * So users can define how many bars represent each strength value and
   * this will construct the bars with each color matching the barCount for that strength
   *
   * i.e. { low: 2, medium: 2, high: 3 }
   * there should be 2 red bars when low, 2 yellow bars when medium, and 3 green bars for high
   */
  const getIndicatorsForStrength = () => {
    const defaultBarCount = { low: 1, medium: 2, high: 3 }
    const barCountForStrength = barCount ? barCount[strength.toLowerCase()] : defaultBarCount[strength.toLowerCase()]
    return [1, 2, 3].map((barNumber) => {
      const color = barNumber <= barCountForStrength ? signalColors[strength] : signalColors.default
      return (
        <Rect
          testID={tID(`barIndicator-${barNumber}-${color}`)}
          key={`barIndicator-${barNumber}`}
          {...barProps[barNumber]}
          width='1.93103'
          fill={color}
        />
      )
    })
  }
  return (
    <Svg width='24' height='24' viewBox='0 0 24 24' fill='none' style={style} testID={tID('ConnectionIndicator')}>
      <Circle cx='12' cy='12' r='12' fill='black' fill-opacity='0.4' />
      {getIndicatorsForStrength()}
    </Svg>
  )
}

export enum Strength {
  NO_CONNECTION = 'no_connection',
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export interface ConnectionIndicatorProps {
  strength: Strength
  style?: ViewStyle
  barCount?: {
    high: number
    medium: number
    low: number
  }
}
