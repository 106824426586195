import { useDispatch } from 'react-redux'

import { configureStore } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'
import { PersistConfig, PersistedState, persistReducer, persistStore } from 'redux-persist'
import getStoredStateMigrateV4 from 'redux-persist/lib/integration/getStoredStateMigrateV4'
import storage from 'redux-persist/lib/storage'
// @ts-expect-error TS(7016): Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { encryptTransform } from 'redux-persist-transform-encrypt'
import promise from 'redux-promise-middleware'

import autoThunkMiddleware from '@lyrahealth-inc/auto-thunk'

import { baseApi } from './baseApi'
import rootReducer from './reducers'
import { track } from '../../../mixpanel/mixpanelTracking'
import { actionAlertHandler, actionStyles } from '../common/constants/reduxConstants'
import { axiosAuthInstance } from '../common/http/axiosInstance'
import { logToSumoLogic } from '../common/utils/utils'

const encryptor = encryptTransform({
  secretKey: 'lhpp-enc',
})

const autoThunk = autoThunkMiddleware({
  httpClient: axiosAuthInstance,
  errorHandler: (error: any, dispatch: any, action: any) =>
    actionAlertHandler({ actionStyle: actionStyles.ERROR, message: error, action, dispatch }),
  track,
  log: logToSumoLogic,
})
const isProduction = process.env.NODE_ENV === 'production'

const persistConfig: PersistConfig<ReturnType<typeof rootReducer>> = {
  key: 'root',
  storage: storage,
  whitelist: ['auth', 'healthPlan'],
  transforms: [encryptor],
  getStoredState: getStoredStateMigrateV4({
    whitelist: ['auth', 'healthPlan'],
    transforms: [encryptor],
  }) as (config: PersistConfig<any>) => Promise<PersistedState>,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const initialState = {}
const store = configureStore({
  reducer: persistedReducer,
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) => {
    const sharedMiddleware = getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat(
      promise,
      autoThunk,
      baseApi.middleware,
    )
    if (isProduction) {
      return sharedMiddleware
    }
    return sharedMiddleware.concat(
      // Some typing issues with the logger and autoThunk
      // @ts-ignore-next-line
      createLogger({
        collapsed: true,
      }),
    )
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export const useAppDispatch: () => AppDispatch = () => useDispatch<AppDispatch>()

// Export the store to Cypress to give access to it in the tests.
if (window.Cypress) {
  ;(window as any).store = store
}

export const persistor = persistStore(store)
export default store
