import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import CSSModules from 'react-css-modules'
import { FormattedMessage, useIntl } from 'react-intl'
import { Linking, View } from 'react-native'
import { connect, useSelector } from 'react-redux'

import * as Sentry from '@sentry/react'
import { differenceInMinutes } from 'date-fns'
import { FormApi } from 'final-form'
import { cloneDeep, isEmpty, isNil, isNumber, remove } from 'lodash-es'
import moment from 'moment-timezone'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import styled, { useTheme } from 'styled-components/native'

import {
  ActivityGroupType,
  ActivityGroupTypes,
  AIDraftStatus,
  Appointment,
  Assignment,
  AssignmentGroup,
  AssignmentStatuses,
  Curriculum,
  EntityTypes,
  Episode,
  EpisodeContents,
  IS_PRODUCTION,
  NoteStatusStates,
  ProgramNames,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'
import {
  BootstrapContainer,
  dateUtils,
  DropdownButton,
  LoadingIndicator,
  NotificationBanner,
  TOOLTIP_PLACEMENTS,
} from '@lyrahealth-inc/ui-core'
import {
  AIStatusBadge,
  AssignmentsList,
  CloseIcon,
  FormBody,
  FormContentHeader,
  FullScreenOverlay,
  InfoSheet,
  ItemAction,
  Lesson,
  Modal,
  PressableOpacity,
  PrimaryButton,
  SSCAssignmentsList,
  Subhead,
  SubheadSize,
  TertiaryButton,
  TextButton,
  ThemeType,
  tID,
  useFetcher,
} from '@lyrahealth-inc/ui-core-crossplatform'
import { DigestType } from '@lyrahealth-inc/ui-core-crossplatform/src/atoms/aiStatusBadge/AIStatusBadge'

import AssignmentAISummaryStatus from './AssignmentAISummaryStatus'
import AssignmentAISummaryText from './AssignmentAISummaryText'
import styles from './assignments.module.scss'
import {
  bulkUnsetAssignment,
  clearSelectedAssignment,
  getAssignments,
  getAssignmentSummary,
  selectAssignment,
  unsetAssignment,
  updateAssignmentResponse,
} from './data/assignmentsAutoActions'
import { actions as mixpanelActions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import SelfHarmIndicator from '../../common/components/selfHarmIndicator/SelfHarmIndicator'
import SpecialAlertIndicator from '../../common/components/specialAlertIndicator/SpecialAlertIndicator'
import { affirmativeResponses, BCC_ROLES, BCT_ROLES, programs, ROLES } from '../../common/constants/appConstants'
import {
  CLIENTS_ASSIGNMENT_DETAILS,
  CLIENTS_CONTENT_LIBRARY,
  CLIENTS_TRACK_LIBRARY,
} from '../../common/constants/routingConstants'
import { getGSCDuration, hasRole } from '../../common/utils/utils'
import { getAuthSupervisor, getAuthUser } from '../../data/auth/authSelectors'
import {
  getClientAssignmentDetails,
  getClientAssignmentsData,
  getClientAssignmentSummary,
  getClientCurrentSessionCount,
  getClientDetailsData,
  getClientEpisodeProgramConfig,
  getClientEpisodesData,
  getClientNextAppointment,
  getClientNotesData,
  getClientSelectedEpisode,
  getSelectedEpisodeCurriculum,
} from '../../data/lyraTherapy/clientSelectors'
import { getLyraTherapyContentsData } from '../../data/lyraTherapy/contentSelectors'
import withRouter from '../../routing/withRouter'
import { getFeedback } from '../clientNotes/data/clientNotesAutoActions'
import { useClientAppointmentsData } from '../clients/clientDetails/data/hooks/useClientAppointmentsData'
import PanelFeedbackSection from '../clients/PanelFeedbackSection'
import { getLTVideoSessionOpen } from '../data/ltVideoSelectors'
import { updateCurriculum } from '../episodes/data/episodesAutoActions'

type AssignmentsProps = {
  router?: any
  actions?: any
  activityActions?: any[]
  notifications?: any[]
}

type DropdownItem = {
  text?: string | ReactNode
  id: string
  selectHandler: (event?: React.SyntheticEvent<unknown>) => void
  subText?: string
  header?: boolean
}

const BULK_UNASSIGN_PROGRAMS_DISALLOWED = [ProgramNames.SingleSessionCoaching, ProgramNames.Coaching]

const HeaderContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: spacing['32px'],
}))

const ButtonsContainer = styled.View({
  flexDirection: 'row',
  justifyContent: 'flex-end',
})

const PrimaryButtonContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  marginLeft: spacing['16px'],
}))

const AssignmentHeaderContainer = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex: 1, // For Session selector dropdown
})

const AssignmentBulkActionContainer = styled.View({
  flexDirection: 'row',
})

const AssignmentBulkActionCancelButton = styled(TextButton)<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  alignSelf: 'center',
  marginLeft: spacing['16px'],
}))

const ModalContainer = styled(Modal)<{ theme: ThemeType; videoSessionOpen: boolean }>(
  ({ theme, videoSessionOpen }) => ({
    marginTop: videoSessionOpen ? '120px' : theme.spacing['32px'],
    width: '600px',
  }),
)

const CloseIconContainer = styled(PressableOpacity)({
  alignSelf: 'flex-end',
})

const PanelFeedbackContainer = styled.View<{ theme: ThemeType }>(({ theme: { colors, spacing } }) => ({
  borderTopColor: colors.dividerSecondary,
  borderTopStyle: 'solid',
  borderTopWidth: '1px',
  marginTop: '0px',
  marginBottom: spacing['16px'],
}))

const Assignments: React.FC<AssignmentsProps> = ({
  router,
  actions,
  actions: { getAssignmentSummary, getFeedback },
}: AssignmentsProps) => {
  const { colors } = useTheme()
  const feedbackFormRef = useRef<FormApi<Dict>>()

  const selectedEpisode = useSelector(getClientSelectedEpisode)
  const assignments = useSelector(getClientAssignmentsData)?.filter(
    (assignment: Assignment) => assignment.episode_id === selectedEpisode?.id,
  )
  const activities = assignments.filter((assignment: Assignment) => assignment.content.group !== 'assessment')
  const assessments = assignments.filter((assignment: Assignment) => assignment.content.group === 'assessment')
  const currentSessionCount = useSelector(getClientCurrentSessionCount) ?? 0
  const currentActivities = activities.filter(
    (assignment: Assignment) =>
      assignment.session_period === currentSessionCount ||
      // filter so that session period 0s/auto assigned activities stay visible in the first session
      (currentSessionCount === 1 && assignment.session_period === 0),
  )
  const contents = useSelector(getLyraTherapyContentsData)
  const curriculum = useSelector(getSelectedEpisodeCurriculum)
  const user = useSelector(getAuthUser)
  const supervisor = useSelector(getAuthSupervisor)
  const programConfig = useSelector(getClientEpisodeProgramConfig)
  const nextAppointment = useSelector(getClientNextAppointment)
  const episodes = useSelector(getClientEpisodesData)
  const selectedAssignment = useSelector(getClientAssignmentDetails)
  const videoSessionOpen = useSelector(getLTVideoSessionOpen)
  const clientDetails = useSelector(getClientDetailsData)

  const [session, setSession] = useState(router?.location?.state?.sessionCount || currentSessionCount)
  const [assessmentIDsFetched, setAssessmentIDsFetched] = useState<string[] | undefined>(undefined)
  const [hasCalledForSummaryText, setHasCalledForSummaryText] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const prevCurrentSessionCount = useRef<number>(currentSessionCount)
  const intl = useIntl()
  const [modalContent, setModalContent] = useState<React.ReactElement | null>(null)
  const [bulkEditMode, setBulkEditMode] = useState(false)
  const [bulkActionSelectedItems, setBulkActionSelectedItems] = useState<(Assignment | EpisodeContents)[]>([])
  const [unassignableAssignmentsList, setUnassignableAssignmentsList] = useState<(Assignment | EpisodeContents)[]>([])
  const { enableAIClientIntakeSummaryFetch, showAIClientIntakeSummary, isBulkUnassignEnabled } = useFlags()

  const { data: allAppointments } = useClientAppointmentsData()
  const appointments = useMemo(
    () => allAppointments?.filter((appt: Appointment) => appt.episodeId === selectedEpisode?.id) ?? [],
    [allAppointments, selectedEpisode?.id],
  )
  const allNotes = useSelector(getClientNotesData) ?? undefined

  const isLCxProvider = hasRole(user?.roles, [...BCT_ROLES, ...BCC_ROLES, ROLES.LT_PRESCRIBER])
  const isProviderUS = user?.country === 'US'

  const intakeApppointmentId = useMemo(() => {
    const intakeSession =
      Array.isArray(appointments) && appointments?.find((appointment) => appointment.sessionNumber === 1)
    return intakeSession ? intakeSession.appointmentId : undefined
  }, [appointments])

  const isIntakeSessionNoteSubmitted = useMemo(() => {
    if (!intakeApppointmentId) {
      return undefined
    }
    return (
      allNotes &&
      Array.isArray(allNotes) &&
      allNotes?.some(
        (note) => note.appointment_id === intakeApppointmentId && note.status === NoteStatusStates.COMPLETED,
      )
    )
  }, [allNotes, intakeApppointmentId])

  const clientAssignmentSummaries = useSelector(getClientAssignmentSummary) ?? undefined

  const updateResponseThenFetchAssignments = useCallback(
    (response: any) => {
      actions
        .updateAssignmentResponse(response)
        .then(() => actions.getAssignments({ patient_id: clientDetails?.id, provider_id: user?.id }))
    },
    [actions, clientDetails?.id, user?.id],
  )

  const onBulkUnassign = useCallback(() => {
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: mixpanelActions.CONFIRM_BULK_UNASSIGN,
    })

    const selectedCurriculum = curriculum?.findIndex((sessionObject: Curriculum) => sessionObject.session === session)
    const newCurriculum = cloneDeep(curriculum)
    const assignmentIdsToUnset: string[] = []
    bulkActionSelectedItems.forEach((item) => {
      // Build list of Assignments to unset
      if (item.id) {
        assignmentIdsToUnset.push(item.id)
      }
      // Update Curriculum if in future session
      if (newCurriculum && typeof selectedCurriculum === 'number' && session > currentSessionCount) {
        if (item.id) {
          newCurriculum[selectedCurriculum].contents = newCurriculum[selectedCurriculum].contents.filter(
            (content: EpisodeContents) => content.id != item.id,
          )
        } else {
          remove(newCurriculum[selectedCurriculum].contents, item as EpisodeContents)
        }
      }
    })

    if (session > currentSessionCount) {
      actions.updateCurriculum({ id: selectedEpisode?.id, curriculum: newCurriculum })
      assignmentIdsToUnset.forEach((id) => actions.unsetAssignment({ id }))
    } else if (assignmentIdsToUnset.length > 0) {
      actions.bulkUnsetAssignment(assignmentIdsToUnset)
    }

    setModalContent(null)
    setBulkEditMode(false)
    setBulkActionSelectedItems([])
  }, [actions, bulkActionSelectedItems, currentSessionCount, curriculum, selectedEpisode?.id, session])

  const onCheckboxPress = (assignment: Assignment | EpisodeContents) => {
    if (bulkActionSelectedItems.includes(assignment)) {
      setBulkActionSelectedItems(bulkActionSelectedItems.filter((id) => id !== assignment))
    } else {
      setBulkActionSelectedItems([...bulkActionSelectedItems, assignment])
    }
  }

  const onConfirmPress = useCallback(() => {
    setModalContent(
      <>
        <HeaderContainer>
          <Subhead
            size={SubheadSize.MEDIUM}
            text={'Are you sure you want to remove all selected activities from this session?'}
          />
        </HeaderContainer>
        <ButtonsContainer>
          <TertiaryButton
            onPress={() => setModalContent(null)}
            text={<FormattedMessage defaultMessage='Cancel' description='Cancel button to go back to session page' />}
          ></TertiaryButton>
          <PrimaryButtonContainer>
            <PrimaryButton
              onPress={onBulkUnassign}
              text={'Confirm'}
              testID={tID('Assignments-modal-bulkConfirm')}
            ></PrimaryButton>
          </PrimaryButtonContainer>
        </ButtonsContainer>
      </>,
    )
  }, [onBulkUnassign])

  const onSelectAllPress = useCallback(() => {
    setBulkActionSelectedItems(bulkActionSelectedItems.length === 0 ? unassignableAssignmentsList : [])
  }, [bulkActionSelectedItems.length, unassignableAssignmentsList])

  const appointmentDateFromSessionCount = useCallback(
    (count: number) => {
      const selectedAppointment =
        Array.isArray(appointments) &&
        appointments?.find((appointment: Appointment) => appointment.sessionNumber === count)
      if (selectedAppointment) return moment(selectedAppointment.startDate, 'YYYY-MM-DD').format('MMM Do')
      return 'unscheduled'
    },
    [appointments],
  )

  const getAssessmentsForSession = useCallback(
    (sessionCount: number) => {
      if (!sessionCount) {
        return []
      }
      let previousAppt: Appointment | undefined,
        sessionStartDate: Date | moment.Moment | undefined,
        sessionEndDate: Date | moment.Moment | undefined
      const sessionAppt =
        Array.isArray(appointments) &&
        appointments?.find((appt: Appointment) => {
          return appt.appointmentStatus === 'completed' && appt.sessionNumber === sessionCount
        })
      if (sessionAppt) {
        sessionEndDate = dateUtils.getAppointmentDateTimeObject(sessionAppt)
      }
      if (sessionCount > 1) {
        previousAppt = Array.isArray(appointments)
          ? appointments?.find(
              (appt: Appointment) => appt.appointmentStatus === 'completed' && appt.sessionNumber === sessionCount - 1,
            )
          : undefined
        if (previousAppt) {
          sessionStartDate = dateUtils.getAppointmentDateTimeObject(previousAppt)
        }
      } else if (selectedEpisode?.start_date) {
        sessionStartDate = moment.utc(selectedEpisode.start_date)
      }

      if (!sessionStartDate) {
        console.error(`the start date for session ${sessionCount} hasn't been found`)
        return []
      }
      const sessionAssessments = assessments?.filter((assessment: Assignment) => {
        const updateDate = moment.utc(assessment.update_date)
        const submitDate = moment.utc(assessment.assignment_responses?.[0]?.submit_date || '')
        switch (assessment.status) {
          case AssignmentStatuses.missed:
            return (
              updateDate.isSameOrAfter(sessionStartDate) && (updateDate.isBefore(sessionEndDate) || !sessionEndDate)
            )
          case AssignmentStatuses.completed:
            return (
              submitDate.isSameOrAfter(sessionStartDate) && (submitDate.isBefore(sessionEndDate) || !sessionEndDate)
            )
          default:
            // Assessment is active (status is new or in-progress)
            return currentSessionCount === sessionCount
        }
      })
      return sessionAssessments
    },
    [appointments, selectedEpisode?.start_date, assessments, currentSessionCount],
  )

  const programName = selectedEpisode?.program_name
  const clientFirstName = clientDetails?.first_name

  const currentSessionAssignments = useMemo(() => {
    return getAssessmentsForSession(session)
  }, [getAssessmentsForSession, session])

  const isAssignmentAnIntake = useCallback(
    (assignmentName?: string) => {
      if (isNil(assignmentName)) {
        return false
      }
      return (
        [programConfig?.clientIntake, programConfig?.oldClientIntake, programConfig?.clientIntakeNonUS].includes(
          assignmentName,
        ) ?? false
      )
    },
    [programConfig?.clientIntake, programConfig?.clientIntakeNonUS, programConfig?.oldClientIntake],
  )
  const clientIntakeAssignment = useMemo(() => {
    return programName && Array.isArray(currentSessionAssignments)
      ? currentSessionAssignments?.find((item) => {
          return isAssignmentAnIntake(item?.content?.name)
        })
      : undefined
  }, [currentSessionAssignments, isAssignmentAnIntake, programName])

  const clientIntakeSummaryResponse = useMemo(() => {
    return !isNil(clientAssignmentSummaries) && Array.isArray(clientAssignmentSummaries)
      ? clientAssignmentSummaries?.find((item) => item.assignment_id === clientIntakeAssignment?.id)
      : undefined
  }, [clientAssignmentSummaries, clientIntakeAssignment])

  // make call to fetch summary status
  useEffect(() => {
    const hasAssignmentAISummaryStatus = (assignmentId: string) =>
      clientAssignmentSummaries?.find((a) => a.assignment_id === assignmentId)
    currentSessionAssignments?.forEach((assessment: Assignment) => {
      if (
        !isNil(allNotes) &&
        !isNil(programName) &&
        isAssignmentAnIntake(assessment?.content?.name) &&
        currentSessionCount === 1 &&
        clientIntakeAssignment?.status === AssignmentStatuses.completed &&
        enableAIClientIntakeSummaryFetch &&
        !hasAssignmentAISummaryStatus(assessment.id) &&
        !isIntakeSessionNoteSubmitted &&
        !assessmentIDsFetched?.find((item) => item === assessment.id) &&
        isProviderUS &&
        isLCxProvider
      ) {
        getAssignmentSummary({
          id: assessment.id,
          providerId: user?.id,
          statusOnly: true,
        })
        setAssessmentIDsFetched((prev) => [...(prev || []), assessment.id])
      }
    })
  }, [
    allNotes,
    programName,
    currentSessionAssignments,
    enableAIClientIntakeSummaryFetch,
    getAssignmentSummary,
    user?.id,
    currentSessionCount,
    isAssignmentAnIntake,
    clientAssignmentSummaries,
    assessmentIDsFetched,
    clientIntakeAssignment?.status,
    programConfig?.clientIntake,
    programConfig?.clientIntakeNonUS,
    programConfig?.oldClientIntake,
    isIntakeSessionNoteSubmitted,
    isProviderUS,
    isLCxProvider,
  ])

  // return all selected session assignments, and add the client intake AI status badge if available
  const getCurrentAssignments = useCallback(
    (assignments) => {
      return assignments?.map((currentSessionAssignment: Assignment) => {
        const copyOfCurrentAssignment = { ...currentSessionAssignment }
        if (
          !isNil(programName) &&
          isAssignmentAnIntake(copyOfCurrentAssignment?.content?.name) &&
          enableAIClientIntakeSummaryFetch
        ) {
          const assessment =
            clientAssignmentSummaries?.find(
              (summary) =>
                summary.assignment_id === currentSessionAssignment.id &&
                currentSessionAssignment.status === AssignmentStatuses.completed &&
                !isIntakeSessionNoteSubmitted &&
                showAIClientIntakeSummary,
            ) ?? false
          if (assessment) {
            copyOfCurrentAssignment.badge = <AIStatusBadge digestType={DigestType.SUMMARY} status={assessment.status} />
          }
        }
        return copyOfCurrentAssignment
      })
    },
    [
      clientAssignmentSummaries,
      enableAIClientIntakeSummaryFetch,
      isAssignmentAnIntake,
      isIntakeSessionNoteSubmitted,
      programName,
      showAIClientIntakeSummary,
    ],
  )

  const getDropdownItems = useCallback(() => {
    const futureCurriculum = curriculum?.filter((curr: Curriculum) => curr.session > currentSessionCount)
    const dropdownItems: DropdownItem[] = []
    const chunkedPastAssignments = {}

    const renderSubtext = (numActivities: number, sessionCount: number) => {
      const pluralize = numActivities === 1 ? 'y' : 'ies'
      const date = appointmentDateFromSessionCount(sessionCount)
      const dateText = date === 'unscheduled' ? sessionCount : 'on ' + date
      return numActivities + ` activit${pluralize} for session ` + dateText
    }

    // Past Sessions
    if (currentSessionCount > 1) {
      dropdownItems.push({ text: 'Past', id: 'past-header', header: true, selectHandler: () => {} })
      for (let i = 1; i < currentSessionCount; i++) {
        const filteredActivities = activities.filter(
          (assignment: Assignment) =>
            assignment.session_period === i ||
            // filter so that session period 0s/auto assigned activities stay visible in the first session
            (i === 1 && assignment.session_period === 0),
        )
        const assessments = getAssessmentsForSession(i)
        chunkedPastAssignments[i] = [...filteredActivities, ...assessments]
      }

      Object.entries(chunkedPastAssignments).forEach(([sessionCount, assignments]) => {
        const convertedSessionCount = parseInt(sessionCount)
        dropdownItems.push({
          text: 'Session ' + convertedSessionCount,
          subText: renderSubtext((assignments as Assignment[]).length, convertedSessionCount),
          id: convertedSessionCount.toString(),
          selectHandler: () => {
            setBulkEditMode(false)
            setBulkActionSelectedItems([])
            changeSessionPeriod(convertedSessionCount, 'Past')
          },
        })
      })
    }

    // Current Sessions
    const assignments = [...currentActivities, ...getAssessmentsForSession(currentSessionCount)]
    dropdownItems.push(
      { text: 'Current', id: 'current-header', header: true, selectHandler: () => {} },
      {
        text: 'Session ' + currentSessionCount,
        subText: renderSubtext(assignments.length, currentSessionCount),
        id: currentSessionCount.toString(),
        selectHandler: () => {
          setBulkEditMode(false)
          setBulkActionSelectedItems([])
          changeSessionPeriod(currentSessionCount, 'Current')
        },
      },
    )

    // Future Sessions
    if (futureCurriculum) {
      dropdownItems.push({ text: 'Future', id: 'future-header', header: true, selectHandler: () => {} })
      futureCurriculum.map((sessionObject: Curriculum) => {
        const session = sessionObject.session
        const assignments = sessionObject.contents
        return dropdownItems.push({
          text: 'Session ' + session,
          subText: renderSubtext(assignments.length, session),
          id: session.toString(),
          selectHandler: () => {
            setBulkEditMode(false)
            setBulkActionSelectedItems([])
            changeSessionPeriod(session, 'Future')
          },
        })
      })
    }
    return dropdownItems
  }, [
    curriculum,
    currentSessionCount,
    currentActivities,
    appointmentDateFromSessionCount,
    activities,
    getAssessmentsForSession,
  ])

  const onModalClose = () => {
    if (
      !isNil(selectedAssignment) &&
      isAssignmentAnIntake(selectedAssignment?.content?.name) &&
      enableAIClientIntakeSummaryFetch &&
      isProviderUS &&
      isLCxProvider &&
      showAIClientIntakeSummary
    ) {
      getFeedback({ providerId: user?.id, entityId: selectedAssignment?.id })
    }
    setShowModal(false)
    actions.clearSelectedAssignment()
  }

  useEffect(() => {
    if (prevCurrentSessionCount.current != currentSessionCount) {
      setSession(currentSessionCount)
    }
    prevCurrentSessionCount.current = currentSessionCount
  }, [currentSessionCount])

  const changeSessionPeriod = (session: number, period: string) => {
    track({ event: 'FILTER_CHANGE', action: 'CHANGE_SESSION_PERIOD', details: period })
    setSession(session)
    setBulkEditMode(false)
    setBulkActionSelectedItems([])
  }

  const addTrack = () => {
    track({ event: 'BUTTON_PRESS', action: 'ADD_ACTIVITY' })
    router.navigate(CLIENTS_TRACK_LIBRARY.route, { state: { sessionCount: session } })
  }

  const addActivity = () => {
    track({ event: 'BUTTON_PRESS', action: 'ADD_ACTIVITY' })
    router.navigate(CLIENTS_CONTENT_LIBRARY.route, { state: { sessionCount: session } })
  }

  const getActivitiesToDisplay = useCallback((): Assignment[] => {
    /* session: Indicates the current selected session in the provider portal filter
     * currentSessionCount: Indicates the current session number for the client
     */

    // Past Session
    if (session < currentSessionCount) {
      const filteredActivities = activities.filter(
        (assignment: Assignment) =>
          assignment.session_period === session ||
          // filter so that session period 0s/auto assigned activities stay visible in the first session
          (assignment.session_period === 0 && session === 1),
      )
      const assessments = getAssessmentsForSession(session)
      return [...filteredActivities, ...assessments]

      // Current Session
    } else if (session === currentSessionCount) {
      return [...currentActivities, ...(getCurrentAssignments(currentSessionAssignments) || [])]
    } else {
      return []
    }
  }, [
    activities,
    currentActivities,
    currentSessionAssignments,
    currentSessionCount,
    getAssessmentsForSession,
    getCurrentAssignments,
    session,
  ])

  const getFutureEpisodesToDisplay = useCallback((): (Assignment | EpisodeContents)[] => {
    // Future Session
    let futureEpisodeActivities: (Assignment | EpisodeContents)[] = []

    if (session > currentSessionCount) {
      const sessionObject =
        Array.isArray(curriculum) && curriculum?.find((sessionObject: Curriculum) => sessionObject.session === session)

      if (sessionObject) {
        futureEpisodeActivities = sessionObject.contents.map((content: EpisodeContents) => {
          if (content.id) {
            const activity = Array.isArray(activities) && activities?.find((activity) => activity.id === content.id)
            return activity || content
          } else {
            return content
          }
        })
      }
    }
    return futureEpisodeActivities
  }, [activities, currentSessionCount, curriculum, session])

  const assignmentClicked = useCallback(
    (assignment: Assignment) => {
      const contentGroup: ActivityGroupType = assignment?.content?.group
      switch (contentGroup) {
        case ActivityGroupTypes.assessment:
          if (assignment.response_count === 0) return
          actions.selectAssignment(assignment)
          const isClientIntake = isAssignmentAnIntake(assignment?.content?.name)
          const aiSummaryStatus = isClientIntake ? clientIntakeSummaryResponse?.status : undefined
          const assignmentResponseId =
            assignment?.assignment_responses && assignment?.assignment_responses?.length
              ? assignment?.assignment_responses[0].id
              : ''
          track({
            event: isClientIntake ? mixpanelEvents.CLIENT_INTAKE_FORM_OPEN_CLIENT_HOME : 'REVIEW_CONTENT',
            details: {
              type: ActivityGroupTypes.assessment,
              title: assignment?.content?.title,
              summaryStatus: isClientIntake ? aiSummaryStatus : undefined,
              userId: user?.id,
              assignmentId: assignment?.id,
              assignmentResponseId: assignmentResponseId,
            },
          })
          setShowModal(true)
          break
        case ActivityGroupTypes.lesson:
          actions.selectAssignment(assignment)
          track({
            event: 'REVIEW_CONTENT',
            details: {
              type: ActivityGroupTypes.lesson,
              title: assignment?.content?.title,
            },
          })
          setShowModal(true)
          break
        case ActivityGroupTypes.infosheet:
          actions.selectAssignment(assignment)
          track({
            event: 'REVIEW_CONTENT',
            details: {
              type: ActivityGroupTypes.infosheet,
              title: assignment?.content?.title,
            },
          })
          setShowModal(true)
          break
        default:
          actions.selectAssignment(assignment)
          track({
            event: 'REVIEW_CONTENT',
            details: {
              type: ActivityGroupTypes.exercise,
              title: assignment?.content?.title,
            },
          })

          router.navigate(`${CLIENTS_ASSIGNMENT_DETAILS.route}?assignmentId=${encodeURIComponent(assignment.id)}`, {
            state: { assignment, sessionCount: session },
          })
      }
    },
    [actions, clientIntakeSummaryResponse?.status, isAssignmentAnIntake, router, session, user?.id],
  )

  const sentryLogMissingUISchemaKeys = useCallback((keys: string) => {
    Sentry.captureMessage(`Missing keys in uiSchema[ui:order] --> ${keys}`, 'warning')
  }, [])

  const [isLoadingFeedback, , hasFetchedFeedback] = useFetcher(
    [
      getFeedback,
      { providerId: user?.id, entityId: selectedAssignment?.id },
      !isNil(selectedAssignment) &&
        isAssignmentAnIntake(selectedAssignment?.content?.name) &&
        enableAIClientIntakeSummaryFetch &&
        isProviderUS &&
        isLCxProvider &&
        showAIClientIntakeSummary,
    ],
    [user?.id, selectedAssignment?.id],
  )

  const renderModalContent = () => {
    if (isEmpty(selectedAssignment)) {
      return (
        <div styleName='loading-container'>
          <LoadingIndicator size={45} />
        </div>
      )
    }
    if (isEmpty(supervisor)) {
      const selectedAssignmentResponse = cloneDeep(selectedAssignment?.assignment_responses?.[0])
      if (!isEmpty(selectedAssignmentResponse) && !selectedAssignmentResponse?.viewed) {
        updateResponseThenFetchAssignments({ ...selectedAssignmentResponse, viewed: true })
        selectedAssignmentResponse.viewed = true
      }
    }
    if (selectedAssignment.content?.group === ActivityGroupTypes.lesson) {
      return (
        <Lesson
          onBackPress={() => onModalClose()}
          contentMetaData={selectedAssignment.content_meta_data}
          content={selectedAssignment.content}
          assignmentResponses={selectedAssignment.assignment_responses}
          assignmentId={selectedAssignment.id}
          instructions={selectedAssignment.instructions}
        />
      )
    } else if (selectedAssignment.content?.group === ActivityGroupTypes.infosheet) {
      return (
        <InfoSheet
          metaData={selectedAssignment.content_meta_data}
          instructions={selectedAssignment?.instructions ?? null}
          title={selectedAssignment.content?.title}
          onLinkClicked={(url) => Linking.openURL(url)}
        />
      )
    } else {
      if (
        showAIClientIntakeSummary &&
        enableAIClientIntakeSummaryFetch &&
        selectedEpisode &&
        isAssignmentAnIntake(selectedAssignment?.content?.name) &&
        !clientIntakeSummaryResponse?.summary_text &&
        !hasCalledForSummaryText &&
        isProviderUS &&
        isLCxProvider
      ) {
        // make call to fetch summary text
        getAssignmentSummary({
          id: selectedAssignment?.id,
          providerId: user?.id,
        })
        setHasCalledForSummaryText(true)
      }
      const content_meta_data = selectedAssignment?.content_meta_data
      const selectedAssignmentResponse = selectedAssignment?.assignment_responses?.[0]
      const response = selectedAssignmentResponse?.response
      const date = selectedAssignmentResponse?.submit_date
      const friendlyDate =
        differenceInMinutes(new Date(), new Date(date ?? 0)) < 1
          ? 'just now'
          : dateUtils.getFriendlyDate(date, false, true)
      const dateDisplay = `Submitted ${friendlyDate}`
      const group = selectedAssignment?.content?.group
      const title = selectedAssignment?.content?.title
      const shouldShowNewPreferredName = clientDetails?.employer === 'apple'
      const formContext = {
        externalValues: {
          shouldSendNewWLQ: false,
          shouldShowNewPreferredName,
          onShowTopicDetailsPress: false,
        },
      }
      return (
        <View testID={tID('Assignments-formbody-container')}>
          <CloseIconContainer testID={tID('Assignments-modal-closeIcon')} onPress={() => setShowModal(false)}>
            <CloseIcon size={16} />
          </CloseIconContainer>
          <FormContentHeader title={title} group={group} dateDisplay={dateDisplay} />
          {showAIClientIntakeSummary &&
          isAssignmentAnIntake(selectedAssignment?.content?.name) &&
          clientIntakeSummaryResponse &&
          clientIntakeSummaryResponse?.status &&
          isProviderUS &&
          isLCxProvider &&
          !isIntakeSessionNoteSubmitted ? (
            <AssignmentAISummaryStatus status={clientIntakeSummaryResponse?.status} />
          ) : (
            ''
          )}
          <FormBody
            schema={content_meta_data?.schema}
            initialValues={response}
            uiSchema={content_meta_data?.uiSchema}
            instructions={selectedAssignment?.instructions}
            name={selectedAssignment?.content?.name}
            saveForm={() => {}}
            readOnly
            formContext={formContext}
            intl={intl}
            formButton={undefined}
            withPageBreaks={false}
            useCustomizedActivityIntroduction={selectedAssignment?.content_meta_data?.useCustomizedActivityIntroduction}
            hasNewLookAndFeel
            sentryLogMissingUISchemaKeys={IS_PRODUCTION ? sentryLogMissingUISchemaKeys : undefined}
          />
          {showAIClientIntakeSummary &&
            isAssignmentAnIntake(selectedAssignment?.content?.name) &&
            !isEmpty(clientIntakeSummaryResponse) &&
            !isNil(clientIntakeSummaryResponse) &&
            isProviderUS &&
            isLCxProvider &&
            !isIntakeSessionNoteSubmitted && (
              <>
                <AssignmentAISummaryText clientIntakeSummaryResponse={clientIntakeSummaryResponse} />
                {clientIntakeSummaryResponse &&
                  clientIntakeSummaryResponse?.status === AIDraftStatus.COMPLETE &&
                  !isLoadingFeedback &&
                  hasFetchedFeedback &&
                  clientIntakeAssignment && (
                    <PanelFeedbackContainer>
                      <PanelFeedbackSection
                        appointmentId={selectedAssignment?.id}
                        backgroundColor={colors.backgroundElement}
                        feedbackFormRef={feedbackFormRef}
                        entityId={selectedAssignment?.id}
                        entityType={EntityTypes.ASSIGNMENTS}
                        promptType={clientIntakeSummaryResponse?.prompt_type ?? ''}
                      />
                    </PanelFeedbackContainer>
                  )}
              </>
            )}
        </View>
      )
    }
  }

  const { activityActions, notifications } = router.outletContext

  const onBulkEditModePress = useCallback(() => {
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: mixpanelActions.BULK_UNASSIGN_EDIT_MODE,
    })

    setBulkEditMode(!bulkEditMode)

    if (bulkEditMode) {
      setBulkActionSelectedItems([])
      return
    }

    const assignments = getActivitiesToDisplay()
    const futureEpisodes = getFutureEpisodesToDisplay()
    const allUnassignableItems: (Assignment | EpisodeContents)[] = []
    if (session > currentSessionCount) {
      allUnassignableItems.push(...futureEpisodes)
    } else {
      assignments.forEach((assignment) => {
        const itemActions: ItemAction[] =
          activityActions?.filter((action: ItemAction) => action.shouldShow(assignment)) || []
        const group = assignment?.content?.group || assignment?.group
        if (
          group !== AssignmentGroup.ASSESSMENT &&
          itemActions.some((action: ItemAction) => action.name === 'unassign')
        ) {
          allUnassignableItems.push(assignment)
        }
      })
    }

    setUnassignableAssignmentsList(allUnassignableItems)
  }, [activityActions, bulkEditMode, currentSessionCount, getActivitiesToDisplay, getFutureEpisodesToDisplay, session])

  const isLoading = !contents || !clientDetails || !currentSessionCount
  let sessionsComplete
  if (isNumber(currentSessionCount)) {
    sessionsComplete = !currentSessionCount
      ? ''
      : `${currentSessionCount - 1} ${
          programConfig?.recommendedEpisodeLength &&
          !isNil(selectedEpisode?.program_name) &&
          selectedEpisode?.program_name &&
          programConfig?.showSessionLimit
            ? `of ${programConfig.recommendedEpisodeLength} complete`
            : 'complete'
        }`
  }
  if (selectedEpisode?.program_name === programs.SingleSessionCoaching) {
    const duration = getGSCDuration(
      clientDetails?.first_appointment?.startDate,
      programConfig?.recommendedEpisodeLength ?? null,
    )
    sessionsComplete = duration
      ? `${duration} ${
          programConfig?.recommendedEpisodeLength ? `of ${programConfig.recommendedEpisodeLength} complete` : 'complete'
        }`
      : '0 complete'
  }
  const nextSession =
    !nextAppointment ||
    nextAppointment?.appointmentStatus === 'canceled' ||
    nextAppointment?.episodeId !== selectedEpisode?.id
      ? 'Not scheduled'
      : dateUtils.getAppointmentDateTimeObject(nextAppointment).tz(moment.tz.guess()).format('MMMM Do, h:mm a')
  const episodeStarted = moment(selectedEpisode?.start_date).format('MMMM Do YYYY')
  const lessonIsSelected = selectedAssignment?.content?.group === ActivityGroupTypes.lesson

  const isFullScreenOverlayOpen = lessonIsSelected && !!renderModalContent()

  return (
    <div>
      <BootstrapContainer col='col-md-10 col-md-offset-1'>
        <div styleName='container'>
          <div styleName='overview-card' data-test-id='Assignments-overview-card'>
            {clientDetails?.country && clientDetails?.country !== 'US' && (
              <div>
                <div styleName='section-title'>Client Time</div>
                <div styleName='section-content'>{`${moment()
                  .tz(clientDetails?.time_zone_full || '')
                  ?.format('h:mm A')} ${clientDetails.time_zone}`}</div>
              </div>
            )}
            <div>
              <div styleName='section-title'>Episode Started</div>
              <div styleName='section-content'>{episodeStarted}</div>
            </div>
            <div styleName='section-title'>{`${programConfig?.sessionLabel}s`}</div>
            <div styleName='section-content'>{sessionsComplete}</div>
            <div styleName='section-title'>Next Session</div>
            <div styleName='section-content'>{nextSession}</div>
            {clientDetails?.self_harm && (
              <div style={{ marginTop: '15px' }}>
                <SelfHarmIndicator />{' '}
              </div>
            )}
            {clientDetails?.eligibility_fields?.nuclear_badge_flag &&
              affirmativeResponses.includes(clientDetails?.eligibility_fields?.nuclear_badge_flag) && (
                <div style={{ marginTop: '15px' }}>
                  <SpecialAlertIndicator alertType='nuclearBadged' tooltipPlacement={TOOLTIP_PLACEMENTS.BOTTOM} />
                </div>
              )}
          </div>
          <div styleName='assignment-list'>
            {isLoading ? (
              <div styleName='loading-container'>
                <LoadingIndicator size={45} />
              </div>
            ) : (
              <div>
                {!isEmpty(notifications) && selectedEpisode?.state === 'in_progress' && (
                  <NotificationBanner
                    // @ts-expect-error TS(2769): No overload matches this call.
                    notifications={notifications}
                    style={{ marginBottom: '10px' }}
                    data-test-id='Assignments-NotificationBanner'
                  />
                )}
                {programName === programs.SingleSessionCoaching && selectedEpisode ? (
                  <>
                    <SSCAssignmentsList
                      activities={activities}
                      activityActions={activityActions}
                      // @ts-expect-error TS(2322): Type '(() => void) | null' is not assignable to ty... Remove this comment to see the full error message
                      addTrackHandler={
                        selectedEpisode?.state !== 'in_progress' &&
                        Array.isArray(episodes) &&
                        episodes?.find((ep: Episode) => ep.state === 'in_progress')
                          ? null
                          : addTrack
                      }
                      appointments={appointments}
                      assessments={getCurrentAssignments(assessments)}
                      currentEpisode={selectedEpisode}
                      itemClickHandler={assignmentClicked}
                      sessionCount={session}
                      userRole='provider'
                      combineLinkedAssignments={false}
                      simplifyStatus={false}
                    />
                  </>
                ) : (
                  <>
                    <AssignmentHeaderContainer>
                      <h3 styleName='title'>
                        <span>Complete before&nbsp;</span>
                        <DropdownButton
                          styling='inline'
                          dropdownItems={getDropdownItems()}
                          data-test-id='Assignments-session-dropdown'
                        >
                          {`Session ${session} ( ${appointmentDateFromSessionCount(session)} )`}
                        </DropdownButton>
                      </h3>
                      {isBulkUnassignEnabled &&
                        !bulkEditMode &&
                        session >= currentSessionCount &&
                        programConfig &&
                        !BULK_UNASSIGN_PROGRAMS_DISALLOWED.includes(
                          programConfig.program as unknown as ProgramNames,
                        ) && (
                          <PrimaryButton
                            onPress={onBulkEditModePress}
                            text={'Select Activities'}
                            testID={tID('Assignments-bulkEdit')}
                          />
                        )}
                      {bulkEditMode && (
                        <AssignmentBulkActionContainer>
                          <PrimaryButton
                            disabled={bulkActionSelectedItems.length === 0}
                            onPress={onConfirmPress}
                            text='Remove'
                            testID={tID('Assignments-bulkConfirm')}
                          />
                          <AssignmentBulkActionCancelButton
                            onPress={onBulkEditModePress}
                            text='Cancel'
                            testID={tID('Assignments-bulkEdit')}
                          />
                        </AssignmentBulkActionContainer>
                      )}
                    </AssignmentHeaderContainer>
                    <AssignmentsList
                      // @ts-expect-error TS(2769): No overload matches this call.
                      addActivityHandler={
                        selectedEpisode?.state !== 'in_progress' &&
                        Array.isArray(episodes) &&
                        episodes?.find((ep: Episode) => ep.state === 'in_progress')
                          ? null
                          : addActivity
                      }
                      assignments={getActivitiesToDisplay()}
                      futureEpisodes={getFutureEpisodesToDisplay()}
                      itemClickHandler={session <= currentSessionCount ? assignmentClicked : undefined}
                      userRole='provider'
                      itemActions={activityActions}
                      sessionData={{ sessionCount: session }}
                      clientFirstName={clientFirstName}
                      combineLinkedAssignments={false}
                      simplifyStatus={false}
                      bulkEditMode={bulkEditMode}
                      onCheckboxPress={onCheckboxPress}
                      bulkActionSelectedItems={bulkActionSelectedItems}
                      onSelectAllPress={onSelectAllPress}
                      unassignableAssignmentsList={unassignableAssignmentsList}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </BootstrapContainer>
      {showModal &&
        (isFullScreenOverlayOpen ? (
          <FullScreenOverlay isOpen={isFullScreenOverlayOpen} isFocused={false}>
            {renderModalContent()}
          </FullScreenOverlay>
        ) : (
          <ModalContainer
            visible={showModal}
            onRequestClose={onModalClose}
            modalContents={renderModalContent()}
            videoSessionOpen={!!videoSessionOpen}
            closeOnScrim
            showCloseIcon={true}
            style={{ paddingBottom: 8 }}
          />
        ))}
      <Modal
        closeOnScrim
        visible={!!modalContent}
        onRequestClose={() => setModalContent(null)}
        modalContents={modalContent}
      />
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    actions: bindActionCreators(
      {
        getAssignments,
        getAssignmentSummary,
        getFeedback,
        selectAssignment,
        clearSelectedAssignment,
        updateAssignmentResponse,
        bulkUnsetAssignment,
        updateCurriculum,
        unsetAssignment,
      },
      dispatch,
    ),
  }
}

export default withRouter(connect(null, mapDispatchToProps)(CSSModules(Assignments, styles)))
