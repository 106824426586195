import React, { FunctionComponent, useContext, useMemo } from 'react'
import { FormattedDateTimeRange, FormattedMessage, useIntl } from 'react-intl'
import { View } from 'react-native'

import { formatRange } from '@fullcalendar/core'
import { parseISO } from 'date-fns'
import styled, { useTheme } from 'styled-components/native'

import {
  EventExtendedProps,
  formatPhoneNumberForDisplay,
  getProgramNameFromId,
  getProgramNameLabel,
  isBookableEvent,
  MEETING_FORMATS,
} from '@lyrahealth-inc/shared-app-logic'

import { ProviderCalendarPopoverDescription } from './ProviderCalendarPopoverDescription'
import {
  AlignLeftIcon,
  BodyText,
  ChatLiveIcon,
  ClockIcon,
  CloseIcon,
  EditIcon,
  ExternalLinkIcon,
  IconButton,
  Link,
  LocationIcon,
  PhoneIcon,
  PrimaryButton,
  ProfileCircleIcon,
  ScheduleIcon,
  Subhead,
  TrashIcon,
  VideoIcon,
} from '../../atoms'
import { AppContext } from '../../context'
import { BodyTextSize, SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'
import { ProviderCalendarEventBadges } from '../providerCalendarEventBadges/ProviderCalendarEventBadges'

export type ProviderCalendarPopoverProps = {
  title: string
  startDate: string
  endDate: string
  extendedProps: EventExtendedProps
  eventEndStr: string
  eventStartStr: string
  timeZone: string
  onClientProfilePressed?: (clientId: string) => void
  onClose?: () => void
  onDelete?: () => void
  onEdit?: () => void
  onOpenInGoogleCalendar?: (externalId: string, calendarConfigurationId: string) => void
  onOpenVideoConferencing?: (externalId: string, calendarConfigurationId: string) => void
  isTemplate?: boolean
}

const Container = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  alignSelf: 'flex-start',
  backgroundColor: theme.colors.backgroundPrimary,
  borderRadius: '16px',
  boxShadow: `0 2px 16px ${theme.colors.shadowLow}`,
  paddingBottom: theme.spacing['24px'],
  width: '450px',
  gap: theme.spacing['16px'],
}))

const HeaderButtonContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  alignSelf: 'center',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['8px'],
}))

const HeaderButton = styled(IconButton)({
  height: '24px',
  width: '24px',
})

const HeaderTopRow = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: theme.spacing['16px'],
  justifyContent: 'space-between',
}))

const Header = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  gap: theme.spacing['4px'],
  borderBottomColor: theme.colors.borderDefault,
  borderBottomWidth: '1px',
  padding: `${theme.spacing['24px']} ${theme.spacing['24px']} ${theme.spacing['16px']} ${theme.spacing['24px']}`,
}))

const Section = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  padding: `0px ${theme.spacing['24px']}`,
  gap: theme.spacing['16px'],
}))

const SectionItem = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['16px'],
}))

const JoinButton = styled(PrimaryButton)(({ theme }) => ({
  margin: `0px ${theme.spacing['24px']}`,
  flexGrow: 1,
}))

export const ProviderCalendarPopover: FunctionComponent<ProviderCalendarPopoverProps> = ({
  title,
  startDate,
  endDate,
  extendedProps,
  eventEndStr,
  eventStartStr,
  timeZone,
  onClose,
  onClientProfilePressed,
  onDelete,
  onEdit,
  onOpenInGoogleCalendar,
  onOpenVideoConferencing,
  isTemplate,
}) => {
  const theme = useTheme() as ThemeType
  const { formatMessage } = useIntl()
  const { isInProductCalendarAddModalEnabled } = useContext(AppContext)

  const eventTitle = useMemo(() => {
    if (extendedProps.lyraEventType === 'bookable' && !isTemplate) {
      return title + ' (one-time)'
    }

    if (extendedProps.lyraEventType !== 'session') {
      return title
    }
    const programName = getProgramNameFromId(extendedProps.lyraProgramId)
    const programNameLabel = programName ? getProgramNameLabel(programName) : ''

    if (extendedProps.isNoShow) {
      return (
        <FormattedMessage
          defaultMessage='{firstName} {lastName} • No Show • {programName}'
          description='Event title for lyra appointment'
          values={{
            firstName: extendedProps.clientFirstName,
            lastName: extendedProps.clientLastName,
            programName: programNameLabel,
          }}
        />
      )
    }
    if (extendedProps.appointmentClass === 'initial') {
      return (
        <FormattedMessage
          defaultMessage='{firstName} {lastName} • Intake • {programName}'
          description='Event title for lyra appointment'
          values={{
            firstName: extendedProps.clientFirstName,
            lastName: extendedProps.clientLastName,
            programName: programNameLabel,
          }}
        />
      )
    }
    return (
      <FormattedMessage
        defaultMessage='{firstName} {lastName} • Session {sessionNumber} • {programName}'
        description='Event title for lyra appointment'
        values={{
          firstName: extendedProps.clientFirstName,
          lastName: extendedProps.clientLastName,
          sessionNumber: extendedProps.appointmentSessionNumber,
          programName: programNameLabel,
        }}
      />
    )
  }, [extendedProps, title])

  const meetingFormatIcon = useMemo(() => {
    if (extendedProps.lyraEventType !== 'session') {
      return <ScheduleIcon fillColor={theme.colors.iconDefault} size={24} />
    }
    switch (extendedProps.appointmentMeetingFormat) {
      case MEETING_FORMATS.IN_PERSON: {
        return <LocationIcon fillColor={theme.colors.iconDefault} size={24} />
      }
      case MEETING_FORMATS.PHONE:
      case MEETING_FORMATS.CALL: {
        return <PhoneIcon fillColor={theme.colors.iconDefault} size={24} />
      }
      case MEETING_FORMATS.LIVE_MESSAGING: {
        return <ChatLiveIcon fillColor={theme.colors.iconDefault} size={24} />
      }
      default: {
        return <VideoIcon fillColor={theme.colors.iconDefault} size={24} />
      }
    }
  }, [extendedProps, theme.colors.iconDefault])

  const sections = useMemo(() => {
    switch (extendedProps.lyraEventType) {
      case 'bookable':
      case 'bookable_recurring':
        return null
      case 'generic':
        if (extendedProps.isPrivateEvent) {
          return null
        }
        return (
          <>
            {extendedProps.description && (
              <SectionItem>
                <View style={{ flexShrink: 0, alignSelf: 'flex-start' }}>
                  <AlignLeftIcon size={24} fillColor={theme.colors.iconDefault} />
                </View>
                <ProviderCalendarPopoverDescription description={extendedProps.description} />
              </SectionItem>
            )}
            <SectionItem>
              <ExternalLinkIcon size={24} strokeColor={theme.colors.iconDefault} />
              <Link
                testID={tID('ProviderCalendarPopover-open-in-google-calendar-link')}
                size={BodyTextSize.SMALL}
                underline
                onPress={() =>
                  onOpenInGoogleCalendar?.(extendedProps.externalId, extendedProps.calendarConfigurationId)
                }
                text={
                  <FormattedMessage
                    defaultMessage='Open Google Calendar'
                    description='Link to event in google calendar'
                  />
                }
              />
            </SectionItem>
          </>
        )
      case 'session':
        const { clientId } = extendedProps
        return (
          <>
            {extendedProps.appointmentMeetingFormat && (
              <SectionItem>
                {meetingFormatIcon}
                <BodyText
                  text={
                    <FormattedMessage
                      defaultMessage='{appointmentMeetingFormat, select,
                        inPerson {In-person}
                        phone {Phone}
                        call {Phone}
                        live_messaging {Live messaging}
                        other {Video}
                        } session'
                      values={{
                        appointmentMeetingFormat: extendedProps.appointmentMeetingFormat,
                      }}
                      description='Client timezone displayed on event'
                    />
                  }
                  size={BodyTextSize.SMALL}
                />
              </SectionItem>
            )}
            {extendedProps.clientTimeZone && (
              <SectionItem>
                <ClockIcon size={24} fillColor={theme.colors.iconDefault} />
                <BodyText
                  text={
                    <FormattedMessage
                      defaultMessage='Client timezone: {timeZone}'
                      values={{
                        timeZone: extendedProps.clientTimeZone,
                      }}
                      description='Client timezone displayed on event'
                    />
                  }
                  size={BodyTextSize.SMALL}
                />
              </SectionItem>
            )}
            {extendedProps.clientPhoneNumber && (
              <SectionItem>
                <PhoneIcon size={24} fillColor={theme.colors.iconDefault} />
                <BodyText
                  text={
                    <FormattedMessage
                      defaultMessage='{phoneNumber} — Dial *67 to block your number'
                      values={{ phoneNumber: formatPhoneNumberForDisplay(extendedProps.clientPhoneNumber) }}
                      description='Phone number displayed on event'
                    />
                  }
                  size={BodyTextSize.SMALL}
                />
              </SectionItem>
            )}
            {clientId && (
              <SectionItem>
                <ProfileCircleIcon size={24} fillColor={theme.colors.iconDefault} />
                <Link
                  testID={tID('ProviderCalendarPopover-client-details-link')}
                  size={BodyTextSize.SMALL}
                  underline
                  onPress={() => onClientProfilePressed?.(clientId)}
                  text={<FormattedMessage defaultMessage='Client details' description='Link to client details' />}
                />
              </SectionItem>
            )}
          </>
        )
    }
  }, [extendedProps, meetingFormatIcon, onClientProfilePressed, onOpenInGoogleCalendar, theme.colors.iconDefault])
  const isBookable = isBookableEvent(extendedProps)
  return (
    <>
      <Container testID={tID('ProviderCalendarPopover')}>
        <Header>
          <HeaderTopRow>
            <ProviderCalendarEventBadges title={title} extendedProps={extendedProps} />
            <HeaderButtonContainer>
              {isBookable && (
                <>
                  {isInProductCalendarAddModalEnabled && (
                    <HeaderButton
                      accessibilityLabel={formatMessage({
                        defaultMessage: 'Edit',
                        description: 'Button for editing an availability on provider calendar or template',
                      })}
                      onPress={() => onEdit?.()}
                      iconColor={theme.colors.iconDefault}
                      leftIcon={<EditIcon fillColor={theme.colors.iconDefault} size={20} isFilled={false} />}
                      tabIndex={0}
                      testID={tID('ProviderCalendarPopover-edit-button')}
                    />
                  )}
                  <HeaderButton
                    accessibilityLabel={formatMessage({
                      defaultMessage: 'Delete',
                      description: 'Button for deleting an availability on provider calendar or template',
                    })}
                    onPress={() => onDelete?.()}
                    iconColor={theme.colors.iconDefault}
                    leftIcon={<TrashIcon size={20} />}
                    tabIndex={0}
                    testID={tID('ProviderCalendarPopover-delete-button')}
                  />
                </>
              )}
              <HeaderButton
                accessibilityLabel={formatMessage({
                  defaultMessage: 'Close',
                  description: 'Button for closing the availability popover on provider calendar or template',
                })}
                onPress={() => onClose?.()}
                iconColor={theme.colors.iconDefault}
                leftIcon={<CloseIcon size={16} />}
                tabIndex={0}
                testID={tID('ProviderCalendarPopover-close-button')}
              />
            </HeaderButtonContainer>
          </HeaderTopRow>
          <Subhead size={SubheadSize.MEDIUM} text={eventTitle} />
        </Header>
        <Section>
          <SectionItem>
            <ScheduleIcon fillColor={theme.colors.iconDefault} size={24} />
            <BodyText
              text={
                isTemplate ? (
                  formatRange(eventStartStr, eventEndStr, {
                    hour: 'numeric',
                    minute: 'numeric',
                    timeZone,
                    weekday: 'long',
                  })
                ) : (
                  <FormattedDateTimeRange
                    from={parseISO(startDate)}
                    to={parseISO(endDate)}
                    weekday='long'
                    month='long'
                    day='numeric'
                    hour='numeric'
                    minute='numeric'
                    timeZoneName='short'
                    timeZone={timeZone}
                  />
                )
              }
              size={BodyTextSize.SMALL}
            />
          </SectionItem>
          {sections}
        </Section>
        {extendedProps.lyraEventType === 'generic' && extendedProps.hasConferenceData && (
          <JoinButton
            testID={tID('ProviderCalendarPopover-join-zoom-button')}
            fullWidth
            text={<FormattedMessage defaultMessage='Join' description='Button to join zoom meeting' />}
            onPress={() => onOpenVideoConferencing?.(extendedProps.externalId, extendedProps.calendarConfigurationId)}
          />
        )}
      </Container>
    </>
  )
}
