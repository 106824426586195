import React, { FunctionComponent, useCallback, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'

import { cloneDeep, isEmpty } from 'lodash-es'
import queryString from 'query-string'
import { bindActionCreators } from 'redux'
import styled from 'styled-components/native'

import { Assignment, AssignmentResponse, ClientObject, ConversationObject } from '@lyrahealth-inc/shared-app-logic'
import { ExerciseResponsePage, ItemAction, LoadingIndicator, ThemeType } from '@lyrahealth-inc/ui-core-crossplatform'

import { track } from '../../../../../mixpanel/mixpanelTracking'
import { CLIENT_HOME, LT_CLIENTS } from '../../../common/constants/routingConstants'
import { getAuthIsLTSupervisor, getAuthUserId } from '../../../data/auth/authSelectors'
import {
  getClientAssignmentDetails,
  getClientCurrentSessionCount,
  getClientDetailsData,
} from '../../../data/lyraTherapy/clientSelectors'
import { RootState } from '../../../data/store'
import { getProviderFullName } from '../../../providers/data/providerSelectors'
import { useLazyGetMessageQuery, useSendMessageMutation } from '../../messages/data/messagesApi'
import { getSelectedConversationId } from '../../messages/data/messagesSelectors'
import MiniBackNav from '../../miniBackNav/MiniBackNav'
import {
  clearSelectedAssignment,
  decreaseAssignmentUnreadCount,
  getAssignment,
  updateAssignmentResponse,
} from '../data/assignmentsAutoActions'

type AssignmentDetailsProps = {
  actions?: any
  clientDetails?: ClientObject
  currentSessionCount?: number
  isLtSupervisor: boolean
  selectedAssignment?: Assignment
  selectedConversation?: ConversationObject
  userId?: string
}

const AssignmentDetailsContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  maxWidth: theme.breakpoints.isMinWidthDesktop ? '1140px' : '970px',
  margin: '30px auto',
  padding: theme.breakpoints.isMinWidthDesktop ? '0px' : '0px 15px',
}))

const ResponsePageContainer = styled.View(() => ({
  marginTop: '10px',
  flexShrink: 1,
  flexGrow: 1,
}))

const AssignmentDetails: FunctionComponent<AssignmentDetailsProps> = ({
  actions: { clearSelectedAssignment, decreaseAssignmentUnreadCount, getAssignment, updateAssignmentResponse },
  clientDetails,
  currentSessionCount,
  isLtSupervisor,
  selectedAssignment,
  userId,
}) => {
  const { search, state } = useLocation()
  const { activityActions } = useOutletContext<{ activityActions: ItemAction[] }>()
  const [getMessage] = useLazyGetMessageQuery()
  const [sendMessage] = useSendMessageMutation()
  const selectedConversationId = useSelector(getSelectedConversationId)
  const navigate = useNavigate()

  const sessionCount: number = state?.sessionCount ?? currentSessionCount

  const navigateBackToAssignments = useCallback(() => {
    navigate(CLIENT_HOME.route, { state: { sessionCount: sessionCount } })
  }, [navigate, sessionCount])

  const updateResponse = useCallback(
    (response: AssignmentResponse) => {
      updateAssignmentResponse(response).then(() =>
        decreaseAssignmentUnreadCount({
          assignmentId: selectedAssignment?.id,
        }),
      )
    },
    [decreaseAssignmentUnreadCount, selectedAssignment?.id, updateAssignmentResponse],
  )

  const fetchComment = useCallback(
    ({ messageId }: { messageId: string }) => {
      if (!selectedConversationId) {
        return Promise.reject()
      }
      return getMessage({ conversationId: selectedConversationId, messageId, append: false }).unwrap()
    },
    [getMessage, selectedConversationId],
  )

  useEffect(() => {
    if (!clientDetails) {
      navigate(LT_CLIENTS.route)
    }
  }, [clientDetails, navigate])

  useEffect(() => {
    return () => {
      clearSelectedAssignment()
    }
  }, [clearSelectedAssignment])

  useEffect(() => {
    const assignmentId = state?.assignmentId || queryString.parse(search).assignmentId
    if (!selectedAssignment) {
      if (assignmentId) {
        getAssignment({ id: assignmentId })
      } else {
        navigateBackToAssignments()
      }
    }
  }, [getAssignment, navigateBackToAssignments, search, selectedAssignment, state])

  const handleSubmitComment = async (inputValue: string, response: AssignmentResponse) => {
    if (!selectedConversationId || !userId) {
      return Promise.reject()
    }
    const data = {
      message_body: inputValue,
      message_type: 'feedback',
      message_author_id: userId,
      message_author_type: 'provider',
      metadata: {
        activity_id: response.assignment_id,
        activity_response_id: response.id,
        title: selectedAssignment?.content.title,
        linkText: 'View Entry',
        submit_date: response.submit_date,
      },
    }
    const message = await sendMessage({
      conversationId: selectedConversationId,
      data: {
        ...data,
      },
    }).unwrap()
    const updatedResponse = await updateAssignmentResponse({
      ...response,
      provider_feedback: message.message_id,
      viewed: true,
    })
    return updatedResponse
  }

  return !selectedAssignment || isEmpty(selectedAssignment) || !currentSessionCount ? (
    <LoadingIndicator size={45} />
  ) : (
    <AssignmentDetailsContainer>
      <MiniBackNav backFunc={navigateBackToAssignments} subText={`Activities for Session ${sessionCount}`} />
      <ResponsePageContainer>
        <ExerciseResponsePage
          content={cloneDeep({ ...(selectedAssignment as any), meta_data: selectedAssignment?.content_meta_data })}
          data={(selectedAssignment?.assignment_responses ?? []) as AssignmentResponse[] & never[]}
          fetchComment={fetchComment}
          itemActions={activityActions.filter((action: ItemAction) =>
            action.shouldShow(selectedAssignment, sessionCount),
          )}
          receiverName={clientDetails?.first_name ?? ''}
          responseIdFromNavigation={state?.responseId}
          sessionCount={currentSessionCount}
          submitComment={handleSubmitComment}
          supervisorMode={isLtSupervisor}
          track={track}
          updateResponse={updateResponse}
        />
      </ResponsePageContainer>
    </AssignmentDetailsContainer>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    selectedAssignment: getClientAssignmentDetails(state),
    clientDetails: getClientDetailsData(state),
    providerFullname: getProviderFullName(state),
    userId: getAuthUserId(state),
    currentSessionCount: getClientCurrentSessionCount(state),
    isLtSupervisor: getAuthIsLTSupervisor(state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators(
      {
        getAssignment,
        updateAssignmentResponse,
        clearSelectedAssignment,
        decreaseAssignmentUnreadCount,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentDetails)
