import React, { useEffect, useState } from 'react'
import CSSModules from 'react-css-modules'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { cloneDeep, isEmpty, sumBy } from 'lodash-es'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import { ClientListClientObject, ConversationObject, useFlags } from '@lyrahealth-inc/shared-app-logic'
import { Avatar, Dashlet, Label, RefreshButton, stringUtils } from '@lyrahealth-inc/ui-core'

import styles from './messagesDashlet.module.scss'
import { CLIENT_HOME } from '../../../common/constants/routingConstants'
import { getClientFullName } from '../../../common/utils/utils'
import { getAuthConfig } from '../../../data/auth/authSelectors'
import { getInactiveV2Clients } from '../../../data/lyraTherapy/clientSelectors'
import { RootState, useAppDispatch } from '../../../data/store'
import { clearSelectedAssignment } from '../../assignments/data/assignmentsAutoActions'
import { selectLtClient } from '../../clients/clientDetails/data/ltClientDetailsAutoActions'
import { useConversationsData } from '../../messages/data/hooks/useConversationsData'
import { clearSelectedConversation, setSelectedConversation } from '../../messages/data/messagesSlice'
import MessagesPopover from '../../messages/MessagesPopover'
export const MessagesDashlet: React.FC<MessagesDashletProps> = ({
  clients,
  activeClientsFetched,
  clientsInactive,
  actions: { selectLtClient },
}) => {
  const showOverdueMessageFlag = useSelector(getAuthConfig)?.showOverdueMessageFlag ?? false
  const [msgPopoverOpen, setMsgPopoverOpen] = useState(false)
  const navigate = useNavigate()
  const { isPreferredNameEnabled } = useFlags()
  const dispatch = useAppDispatch()

  const {
    data: conversations,
    isFetching: isLoadingConversations,
    isSuccess: conversationsFetched,
    refetch: refetchConversations,
  } = useConversationsData()
  const isRefreshingMessages = isLoadingConversations && conversationsFetched

  useEffect(() => {
    return () => {
      dispatch(clearSelectedConversation())
    }
  }, [dispatch])

  const unreadCount =
    sumBy(
      conversations,
      (conversation) => conversation?.conversation_attributes?.unread_provider_messages_count ?? 0,
    ) || 0
  const onMessageItemClicked = (client: ClientListClientObject, conversation: ConversationObject) => {
    selectLtClient(client)
    dispatch(setSelectedConversation(conversation.conversation_id))
    setMsgPopoverOpen(true)
  }

  const closePopover = () => {
    dispatch(clearSelectedConversation())
    setMsgPopoverOpen(false)
  }

  const renderClientListItem = (conversation: ConversationObject) => {
    const client = Array.isArray(clients) && clients?.find(({ id }: any) => id === conversation.patient_lyra_id)
    if (!client) {
      // in the case where we have a conversation but no matching client
      return
    }
    const initials = stringUtils.getInitials(getClientFullName(client, isPreferredNameEnabled))
    return (
      <div
        className={styles['dashlet-item-container']}
        data-test-id={`MessagesDashlet-message-item-${client.first_name}`}
        key={conversation.conversation_id}
      >
        <button
          className={styles['dashlet-item-content']}
          key={conversation.conversation_id}
          onClick={() => onMessageItemClicked(client, conversation)}
        >
          <div className={styles.left}>
            <Avatar ltStyle size={35}>
              {initials}
            </Avatar>
            <button
              className={styles.clientName}
              onClick={() => {
                selectLtClient(client)
                navigate(CLIENT_HOME.route)
              }}
            >
              {getClientFullName(client, isPreferredNameEnabled)}
            </button>
            {clientsInactive?.some((c: any) => c.id === client.id) && (
              <div className={styles['inactive-tag']} data-test-id='MessagesDashlet-inactiveTag'>
                inactive
              </div>
            )}
          </div>
          <div className={styles.right}>
            {showOverdueMessageFlag && conversation?.overdue && (
              <div className={styles['overdue-tag']} data-test-id='MessagesDashlet-overdueTag'>
                overdue
              </div>
            )}
            <Label
              textColor={styles.x_white}
              backgroundColor={styles.x_alert}
              borderRadius='6px'
              padding='0 7px'
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              text={conversation?.conversation_attributes?.unread_provider_messages_count}
            />
          </div>
        </button>
      </div>
    )
  }

  return (
    <div className={styles['dashlet-container']} data-test-id='MessagesDashlet-container'>
      {msgPopoverOpen && (
        <div className={styles['messenger-container']}>
          {<MessagesPopover closePopover={closePopover} onUpdateMessageCount={refetchConversations} />}
        </div>
      )}
      <div style={{ marginBottom: '20px', width: '100%', height: msgPopoverOpen ? '400px' : 'auto' }}>
        <Dashlet
          title={`Unread messages${activeClientsFetched ? ` (${unreadCount})` : ''}`}
          buttons={<RefreshButton size='20' isLoading={isRefreshingMessages} onClick={refetchConversations} />}
          isLoading={!activeClientsFetched || (!conversationsFetched && isEmpty(conversations))}
        >
          {unreadCount === 0 ? (
            <div className={styles['empty-dashlet']}>No new messages</div>
          ) : (
            cloneDeep(conversations)
              ?.sort(
                (a, b) =>
                  new Date(b.conversation_update_datetime).getTime() -
                  new Date(a.conversation_update_datetime).getTime(),
              )
              ?.map(
                (conversation: any) =>
                  conversation?.conversation_attributes?.unread_provider_messages_count > 0 &&
                  renderClientListItem(conversation),
              )
          )}
        </Dashlet>
      </div>
    </div>
  )
}

type MessagesDashletProps = {
  actions: {
    selectLtClient: (client: ClientListClientObject) => void
    clearSelectedAssignment: () => void
  }
  clients: ClientListClientObject[]
  activeClientsFetched: boolean
  clientsInactive?: ClientListClientObject[]
  providerId: string
}

const mapStateToProps = (state: RootState) => ({
  clientsInactive: getInactiveV2Clients(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): any => ({
  actions: bindActionCreators(
    {
      selectLtClient,
      clearSelectedAssignment,
    },
    dispatch,
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CSSModules(MessagesDashlet, styles, { allowMultiple: true }))
