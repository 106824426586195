export enum AIDraftStatus {
  COMPLETE = 'COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
  ERROR = 'ERROR',
  NOT_FOUND = 'NOT_FOUND',
}

interface AppointmentSummaryEntry {
  prompt_type: string
  summary_text: string
}

export interface AppointmentSummary {
  appointment_id: string
  status: AIDraftStatus
  summary?: AppointmentSummaryEntry[]
}

export const noteSummaryStatusText = {
  [AIDraftStatus.COMPLETE]: 'DRAFT AVAILABLE',
  [AIDraftStatus.IN_PROGRESS]: 'DRAFT PROCESSING',
  [AIDraftStatus.NOT_STARTED]: 'DRAFT PROCESSING',
  [AIDraftStatus.ERROR]: 'DRAFT UNAVAILABLE',
  [AIDraftStatus.NOT_FOUND]: 'DRAFT UNAVAILABLE',
}

export interface AssignmentSummary {
  assignment_id: string
  status: AIDraftStatus
  prompt_type?: string
  summary_text?: string
}

export const summaryStatusText = {
  [AIDraftStatus.COMPLETE]: 'SUMMARY READY',
  [AIDraftStatus.IN_PROGRESS]: 'SUMMARY PROCESSING',
  [AIDraftStatus.NOT_STARTED]: 'SUMMARY PROCESSING',
  [AIDraftStatus.ERROR]: 'SUMMARY UNAVAILABLE',
  [AIDraftStatus.NOT_FOUND]: 'SUMMARY UNAVAILABLE',
}

export const aiSummaryStatusMessages = {
  [AIDraftStatus.IN_PROGRESS]: 'Generating summary - usually ready within 5 minutes',
  [AIDraftStatus.COMPLETE]: 'AI generated summary ready at bottom',
  [AIDraftStatus.NOT_STARTED]: 'Summary not started',
  [AIDraftStatus.ERROR]: 'Error generating summary',
  [AIDraftStatus.NOT_FOUND]: 'Summary not found',
}

export enum EntityTypes {
  NOTES = 'notes',
  ASSIGNMENTS = 'assignments',
}
