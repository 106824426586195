import React from 'react'
import { FlatList, ListRenderItem } from 'react-native'

import styled from 'styled-components/native'

import { LoadingSkeleton } from '../..'

const Container = styled.View(({ theme }) => ({
  padding: theme.spacing['12px'],
  flexDirection: 'column',
  gap: theme.spacing['4px'],
  // Left offset of text is 30px
  // 12px padding + 10px unread icon + 8px gap
  marginLeft: '18px',
}))

const LoadingSkeletonSeperator = () => {
  return <LoadingSkeleton height={1} width='100%' />
}

const NUM_ITEMS_TO_RENDER = 25
export const ConversationListSkeleton: React.FC = () => {
  const renderItem: ListRenderItem<null> = () => (
    <Container>
      <LoadingSkeleton height={22} width={140} />
      <LoadingSkeleton height={18} width={100} />
    </Container>
  )
  return (
    <FlatList
      data={Array(NUM_ITEMS_TO_RENDER).fill(null)}
      renderItem={renderItem}
      keyExtractor={(_, index) => index.toString()}
      ItemSeparatorComponent={LoadingSkeletonSeperator}
      scrollEnabled={false}
    />
  )
}
