import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { ViewStyle } from 'react-native'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { bindActionCreators } from 'redux'
import styled from 'styled-components/native'

import {
  ProviderCalendarAuthorizationModal,
  ProviderCalendarSetupCard,
  ProviderHybridCalendarAuthorizationModal,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { OAUTH_SUCCESS_RESPONSE } from './constants'
import { getOAuthURL } from './data/calendarActions'
import { getCalendarRequiresAuthorization } from './data/calendarSelectors'
import { useSetupRoute } from './hooks/useSetupRoute'
import { actions as mixpanelActions, mixpanelEvents } from '../../../mixpanel/mixpanelConstants'
import { track } from '../../../mixpanel/mixpanelTracking'
import { getAuthConfig, getAuthUserEmail, getAuthUserId } from '../data/auth/authSelectors'

const OuterContainer = styled.View({
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
})

const SetupContainer = styled.View({
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  width: '100%',
  maxWidth: '944px',
})

const CalendarSetupCard: FunctionComponent<CalendarSetupCardProps> = ({ style, actions: { getOAuthURL } }) => {
  const userId = useSelector(getAuthUserId)
  const config = useSelector(getAuthConfig)
  const userEmail = useSelector(getAuthUserEmail)
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'America/Los_Angeles'
  const requiresAuth = useSelector(getCalendarRequiresAuthorization)
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false)
  const navigate = useNavigate()

  const setupRoute = useSetupRoute(config)

  const startOAuthFlow = useCallback(() => {
    if (!userEmail || !userId) {
      return
    }
    getOAuthURL({
      providerId: userId,
      providerEmail: userEmail,
      timeZone,
    }).then(({ data: url }) => {
      window.open(url)
    })
  }, [getOAuthURL, timeZone, userEmail, userId])

  useEffect(() => {
    const onMessage = (event: MessageEvent<any>) => {
      if (event.origin !== window.location.origin) {
        return
      }
      if (typeof event.data !== 'string') {
        return
      }
      if (event.data === OAUTH_SUCCESS_RESPONSE) {
        navigate(setupRoute)
      }
    }
    window.addEventListener('message', onMessage)
    return () => {
      window.removeEventListener('message', onMessage)
    }
  }, [navigate, userId, setupRoute])

  return (
    <OuterContainer style={style}>
      <SetupContainer>
        <ProviderCalendarSetupCard
          //TODO: May need unique mixpanel event for DA: DACN-1336
          onLearnMorePressed={() => {
            track({ event: mixpanelEvents.BUTTON_PRESS, action: mixpanelActions.CALENDAR_SETUP_LEARN_MORE })
            window.open(
              config?.inProductCalendar?.hybridBookingMode
                ? 'https://provider-support.lyrahealth.com/hc/en-us/articles/38528605874963--HOLD-New-CC-self-service-guide'
                : 'https://bct-lyrahealth.zendesk.com/hc/en-us/articles/35842185897235--PRODUCT-UPDATE-Calendar-Schedule-Feature-How-to-Set-up-your-Lyra-Calendar',
              '_blank',
            )
          }}
          onSetupPressed={() => {
            track({ event: mixpanelEvents.BUTTON_PRESS, action: mixpanelActions.CALENDAR_SETUP_SET_UP_CALENDAR })
            if (requiresAuth) {
              setIsAuthModalOpen(true)
            } else {
              navigate(setupRoute)
            }
          }}
          hybridBookingMode={config?.inProductCalendar?.hybridBookingMode}
        />
        {config?.inProductCalendar?.hybridBookingMode ? (
          <ProviderHybridCalendarAuthorizationModal
            onAuthorizePressed={startOAuthFlow}
            onCancelPressed={() => setIsAuthModalOpen(false)}
            isVisible={isAuthModalOpen}
          />
        ) : (
          <ProviderCalendarAuthorizationModal
            onAuthorizePressed={startOAuthFlow}
            onCancelPressed={() => setIsAuthModalOpen(false)}
            isVisible={isAuthModalOpen}
          />
        )}
      </SetupContainer>
    </OuterContainer>
  )
}

export type CalendarSetupCardProps = {
  style?: ViewStyle
  actions: {
    getOAuthURL: (params: Parameters<typeof getOAuthURL>[0]) => Promise<{ data: string }>
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      getOAuthURL: getOAuthURL as any,
    },
    dispatch,
  ),
})

const connector = connect(null, mapDispatchToProps)

export default connector(CalendarSetupCard)
