import React, { FunctionComponent, ReactNode, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { ScheduleIcon, Tooltip } from '../../atoms'
import { tID } from '../../utils'

export type DirectBookingLinkInlineProps = {
  onPress: () => void
  disabled?: boolean
  disabledTooltipContent?: ReactNode
}

const Container = styled.View(({ theme }) => ({
  alignSelf: 'flex-start',
  borderRadius: '36px',
  alignItems: 'center',
  padding: theme.spacing['8px'],
}))
const TOOLTIP_DELAY_MS = 2000

export const DirectBookingLinkInline: FunctionComponent<DirectBookingLinkInlineProps> = ({
  onPress,
  disabled = false,
  disabledTooltipContent,
}) => {
  const { formatMessage } = useIntl()
  const [pressDelay, setPressDelay] = useState(false)
  const onTooltipPress = () => {
    onPress()
    setPressDelay(true)
    setTimeout(() => setPressDelay(false), TOOLTIP_DELAY_MS)
  }
  const { colors } = useTheme()

  return (
    <Tooltip
      hoverEnabled
      accessibilityLabel={formatMessage({
        defaultMessage: 'Insert direct booking link',
        description: 'Button to insert direct booking link into message',
      })}
      content={
        disabled && !pressDelay ? (
          disabledTooltipContent
        ) : (
          <FormattedMessage
            defaultMessage='Click to insert direct booking link'
            description='Tooltip to insert direct booking link into messages'
          />
        )
      }
      onPress={!disabled ? onTooltipPress : undefined}
      placement='top'
    >
      <Container testID={tID('DirectBookingLinkInline-button')}>
        <ScheduleIcon fillColor={disabled ? colors.iconInactive : colors.iconDefault} />
      </Container>
    </Tooltip>
  )
}
