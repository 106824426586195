import React, { FunctionComponent, useCallback, useRef } from 'react'
import { FormattedMessage } from 'react-intl'

import BottomSheetGorhom from '@gorhom/bottom-sheet'
import styled, { useTheme } from 'styled-components/native'

import {
  BodyText,
  BulletedList,
  Link,
  PermissionsIllustration,
  PressableOpacity,
  PrimaryButton,
  Subhead,
  TextButton,
  XIcon,
} from '../../atoms'
import { Modal } from '../../organisms'
import { SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

type ProviderHybridCalendarAuthorizationModalProps = {
  onAuthorizePressed?: () => void
  onCancelPressed?: () => void
  isVisible?: boolean
}

const Container = styled.View({
  flexDirection: 'column',
  alignSelf: 'flex-start',
  alignItems: 'center',
})

const Header = styled.View({
  flexDirection: 'column',
  alignItems: 'center',
})

const Row = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing['16px'],
  alignSelf: 'flex-end',
  alignItems: 'center',
  marginTop: theme.spacing['32px'],
}))

const LinkButton = styled(TextButton)({
  alignSelf: 'center',
})

const HeaderText = styled(Subhead)(({ theme }) => ({
  marginTop: theme.spacing['24px'],
}))

const TipsHeaderText = styled(Subhead)(({ theme }) => ({
  marginTop: theme.spacing['16px'],
  alignSelf: 'flex-start',
}))

const CloseButton = styled(PressableOpacity)({
  alignSelf: 'flex-end',
})

export const ProviderHybridCalendarAuthorizationModal: FunctionComponent<
  ProviderHybridCalendarAuthorizationModalProps
> = ({ onAuthorizePressed, onCancelPressed, isVisible = false }) => {
  const theme = useTheme() as ThemeType
  const ref = useRef<BottomSheetGorhom>(null)
  const openBottomSheet = useCallback(() => {
    ref.current?.expand()
  }, [])

  return (
    <Modal
      visible={isVisible}
      bottomSheetRef={ref}
      snapPoints={[550]}
      width='600px'
      onLayout={openBottomSheet}
      modalContents={
        <Container>
          <CloseButton hitSlop={60} onPress={() => onCancelPressed?.()}>
            <XIcon size={24} fillColor={theme.colors.iconDefault} />
          </CloseButton>
          <Header>
            <PermissionsIllustration />
            <HeaderText
              textAlign='center'
              size={SubheadSize.MEDIUM}
              text={
                <FormattedMessage
                  defaultMessage='Connect your Google Calendar'
                  description='Subheader for provider Google Calendar authorization card'
                />
              }
            />
            <BodyText
              textAlign='center'
              color={theme.colors.textSecondary}
              text={
                <FormattedMessage
                  defaultMessage='You’ll be prompted to connect your Google account with Lyra to enable sync.'
                  description='Body text describing provider Google Calendar authorization process'
                />
              }
            />
          </Header>
          <TipsHeaderText
            size={SubheadSize.XSMALL}
            text={
              <FormattedMessage
                defaultMessage='Tips'
                description='Subheader for tips section on provider Google Calendar authorization card'
              />
            }
            color={theme.colors.textSecondary}
          />
          <BulletedList
            spaceBetween={16}
            textColor={theme.colors.textSecondary}
            bulletColor={theme.colors.textSecondary}
            data={[
              {
                value: (
                  <BodyText
                    color={theme.colors.textSecondary}
                    text={
                      <FormattedMessage
                        defaultMessage='Don’t see your preferred Google Account on the next page?, Click <bold>“Use another account”</bold> and log in to the desired one.'
                        description='Bullet point describing how to select the proper Google account during authorization'
                        values={{
                          bold: (text: string) => (
                            <BodyText bold color={theme.colors.textSecondary}>
                              {text}
                            </BodyText>
                          ),
                        }}
                      />
                    }
                  />
                ),
              },
              {
                value: (
                  <BodyText
                    color={theme.colors.textSecondary}
                    text={
                      <FormattedMessage
                        defaultMessage='If you do not have a Google Account, sign up for free by clicking <bold>“Create Account”</bold> on the next screen.'
                        description='Bullet point describing how to create a Google account during authorization'
                        values={{
                          bold: (text: string) => (
                            <BodyText bold color={theme.colors.textSecondary}>
                              {text}
                            </BodyText>
                          ),
                        }}
                      />
                    }
                  />
                ),
              },
              {
                value: (
                  <BodyText
                    color={theme.colors.textSecondary}
                    text={
                      <FormattedMessage
                        defaultMessage='(Optional) After you link a Google Account, you can sync your appointment data from other systems (ex. Simple Practice, iCloud, Outlook, etc.) visit our <link>Help Center</link> for more'
                        description='Bullet point describing the option to sync other appointment data after Google Authorization'
                        values={{
                          link: (text: string) => (
                            <Link
                              text={text}
                              //TODO: Will eventually need a Mixpanel event: DACN-1336
                              onPress={() =>
                                window.open(
                                  'https://provider-support.lyrahealth.com/hc/en-us/articles/35833741844115-Understanding-the-Google-Calendar-Integration-with-Lyra',
                                  '_blank',
                                )
                              }
                            />
                          ),
                        }}
                      />
                    }
                  />
                ),
              },
            ]}
          />
          <Row>
            <LinkButton
              text={
                <FormattedMessage defaultMessage='Cancel' description='Button to cancel authorization of calendar' />
              }
              onPress={() => onCancelPressed?.()}
            />
            <PrimaryButton
              testID={tID('ProviderHybridCalendarAuthorizationModal-authorize-button')}
              text={<FormattedMessage defaultMessage='Connect' description='Button to connect Google calendar' />}
              onPress={() => onAuthorizePressed?.()}
            />
          </Row>
        </Container>
      }
      onRequestClose={() => onCancelPressed?.()}
      onCloseEnd={() => onCancelPressed?.()}
    />
  )
}
